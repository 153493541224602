import { Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import IconBox from "assets/icons/IconBox";
import { useAppSelector } from "hook";
import { NavLink, useLocation } from "react-router-dom";

export function SidebarLinks(props: { routes: RoutesType[] }) {
  let location = useLocation();
  let activeColor = "#0B63F8";
  let bgColor = useColorModeValue(
    "rgba(244, 247, 254, 0.2)",
    "rgba(11,20,55,0.5)"
  );
  let textColor = useColorModeValue("secondaryGray.500", "white");
  const { routes } = props;
  const activeRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };
  const loggedInas: any = useAppSelector((state: any) => state.auth.loggedInAs);
  const createLinks = (routes: RoutesType[]) => {
    return routes.map((route: any, index: number) => {
      if (
        (route.layout === "/admin" ||
          route.layout === "/auth" ||
          route.layout === "/rtl") &&
        route.roles.includes(loggedInas)
      ) {
        return (
          <NavLink key={index} to={route.layout + route.path}>
            <Flex
              key={route.name}
              boxSize="initial"
              justifyContent="flex-start"
              alignItems="start"
              borderRadius="0"
              mb={{
                xl: "2px",
              }}
              pl={5}
              mx={{
                xl: "auto",
              }}
              bg={bgColor}
              py="12px"
              w="100%"
              _active={{
                borderLeft: "solid 3px #0B63F8",
                transform: "none",
              }}
              _focus={{
                borderLeft: "solid 3px #0B63F8",
                boxShadow: "none",
              }}
            
            >
              <IconBox
                bg="navy.950"
                color="white"
                h="10px"
                w="10px"
                me="12px"
                icon={
                  <Icon
                    as={route.icon}
                    color={
                      activeRoute(route.path.toLowerCase())
                        ? activeColor
                        : textColor
                    }
                    w="24px"
                    h="24px"
                  />
                }
              />
              <Text
                fontSize="12px"
                color={
                  activeRoute(route.path.toLowerCase())
                    ? activeColor
                    : textColor
                }
                fontWeight="normal"
              >
                {route.name}
              </Text>
            </Flex>
          </NavLink>
        );
      }
    });
  };

  return <>{createLinks(routes)}</>;
}

export default SidebarLinks;
