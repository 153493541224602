
import { FormControl, FormLabel, Input, Select, Text, useToast } from '@chakra-ui/react';
import { PrimaryButton } from 'components/Atoms/Button/Buttons';
import { useDispatch } from 'react-redux';
import { updateExam } from 'reduxtoolkit/reducers/examGenerateSlice';
const EditExamsForm=({id, subjects, units, sections, grades, years, editExamsModal , editExamsForm, examVersions}: any)=>{
  
    const dispatch = useDispatch();
	const toast = useToast();
    const onEditExams=(values: any)=>{
		
		const data = {
			id: id,
			payload: {
				examName: values.examName,
				grade: values.grade,
				subject: values.subject,
				year: values.year,
				examVersion: values.examVersion,
				duration:  Number(values.duration),
			passingScore:  Number(values.passingScore),
			noOfQuestions:  Number(values.noOfQuestions),
			examType: values.examType,
			},
		}
	    dispatch(updateExam(data)).then((data:any)=>{
			if(data.type === "exam/Update/fulfilled")
			{
				toast({
					title: 'Success',
					description: "Exam updated successfully",
					status: 'success',
					duration: 9000,
					isClosable: true,
				  });
				  

				}
				else{
					toast({
						title: 'Error',
						description:   `${data.payload.response.data.message}`,
						status: 'error',
						duration: 9000,
						isClosable: true,
					  });
					  
				  }
		      editExamsModal.onClose();
		});
		 
	}
    const { register, handleSubmit , formState:{ errors: errors}} = editExamsForm;
    return(

    <form onSubmit={handleSubmit(onEditExams)}>
    <FormControl mt={4}>
							<FormLabel>Grade</FormLabel>
							<Select 
							className='select-input'
							_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...register('grade', {
									required: 'Grade is required',
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})} variant='unstyled' 
								>
							  {grades?.map((item:any, index:any) => (
								<option key={item.id} value={item.grade}>{item.grade}</option>
								))}
							</Select>
							{errors.grade && <Text color='red'>{errors.grade.message.toString()}</Text>}
							</FormControl>

							<FormControl mt={4}>
							<FormLabel>Exam Name</FormLabel>
							<Input
								id='number'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="Exam Name"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...register('examName', {
									required: 'Exam Name is required',
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})}
								/>
								{errors.examName && <Text color='red'>{errors.examName.message.toString()}</Text>}
							</FormControl>
							<FormControl mt={4}>
							<FormLabel>Exam TYpe</FormLabel>
							<Select
							className='select-input'
							_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...register('examType', {
									required: 'Exam Type is required',
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})} variant='unstyled' >
								<option key="exam" value="EXAM">Exam</option>
								<option key="custom" value="CUSTOM">Custom</option>
								
							</Select>
							{errors.examType && <Text color='red'>{errors.examType.message.toString()}</Text>}
							</FormControl>
							
							<FormControl mt={4}>
							<FormLabel>Subject</FormLabel>
							<Select 
							className='select-input'
							_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...register('subject', {
									required: 'Subject is required',
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})} variant='unstyled' >
							  {subjects?.map((item:any) => (
								<option key={item.id} value={item.subject.subject}>{item.subject.subject}</option>
								))}
							</Select>
							{errors.subject && <Text color='red'>{errors.subject.message.toString()}</Text>}
							</FormControl>

									
							<FormControl mt={4}>
							<FormLabel>Year</FormLabel>
							<Select 
							className='select-input'
							_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...register('year', {
									required: 'Year is required',
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})} variant='unstyled' >
							  {years?.map((item:any) => (
								<option key={item.id} value={item.year}>{item.year}</option>
								))}
							</Select>
							{errors.year && <Text color='red'>{errors.year.message.toString()}</Text>}
							</FormControl>

							<FormControl mt={4}>
							<FormLabel>Exam Version</FormLabel>
										<Select 
										className='select-input'
										_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
											{ ...register('examVersion', {
												required: 'Exam version is required',
												minLength: { value: 0, message: 'Minimum length should be 4' },
											})} variant='unstyled' >
										{examVersions?.map((item:any) => (
											<option key={item.id} value={item.examVersion}>{item.examVersion}</option>
											))}
										</Select>
										{errors.examVersion && <Text color='red'>{errors.examVersion.message.toString()}</Text>}
						 </FormControl>

						 <FormControl mt={4}>
							<FormLabel>Time Duration in Minutes</FormLabel>
							<Input
								id='number'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="Duration in Minutes"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...register('duration', {
									required: 'Duration is required',
									
								})}
								/>
								{errors.duration && <Text color='red'>{errors.duration.message.toString()}</Text>}
							</FormControl>

							<FormControl mt={4}>
							<FormLabel>Passing Score</FormLabel>
							<Input
								id='passingScore'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="Passing Score"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...register('passingScore', {
									required: 'Passing Score is required',
									
								})}
								/>
								{errors.passingScore && <Text color='red'>{errors.passingScore.message.toString()}</Text>}
							</FormControl>

							<FormControl mt={4}>
							<FormLabel>number Of Questions </FormLabel>
							<Input
								id='noOfQuestions'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="No Of Questions"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...register('noOfQuestions', {
									required: 'No of question is required',
									
								})}
								/>
								{errors.noOfQuestions && <Text color='red'>{errors.noOfQuestions.message.toString()}</Text>}
							</FormControl>
                            <PrimaryButton type='submit'>
                            Save
                            </PrimaryButton>
                </form>
   )
}

export default EditExamsForm;
