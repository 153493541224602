import { Box } from "@chakra-ui/react";

const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.slice(0, maxLength) + '...';
    }
  };
  
  const TruncatedText = ({ text, maxLength }: any) => {
    const truncatedText = truncateText(text, maxLength);
  
    return <Box color='secondaryGray.900' fontSize={{sm: '10px', md: '10px', lg: '14'}} fontWeight='normal'>
       <span 
                              dangerouslySetInnerHTML={{
                                __html: truncatedText.replace(/(<? *script)/gi, ""),
                              }}
                            />
      </Box>
  };
export default TruncatedText;