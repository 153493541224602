import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  ThemeProvider,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { createTheme } from "@mui/material/styles";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import CustomReactQuill from "components/Atoms/fields/PrimaryRichText";
// import {
// 	MaterialReactTable, MRT_RowSelectionState,
// 	type MaterialReactTableProps
// } from 'material-react-table';
import QuestionList from "components/organisms/questionList";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_RowSelectionState
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  deleteQuestion,
  getQuestions
} from "reduxtoolkit/reducers/questionSlice";
import { studyRemoveQuestion } from "reduxtoolkit/reducers/studiesSlice";
import { rtxModules } from "utils/tableconstants";
import AlertBox from "../Alerts/AlertBox";
const theme = createTheme({
  transitions: {
    duration: {
      enteringScreen: 2000,
      leavingScreen: 100,
    },
  },
});

type Person = {
  name: {
    firstName: string;
    lastName: string;
  };
  address: string;
  city: string;
  state: string;
};

export default function ViewStudyQuestionTable(props: any) {
  const editQuestionForm = useForm();
  const {
    handleSubmit: handleSubmitQuestion,
    control,
    getValues,
  } = editQuestionForm;
  const [themes, setTheme] = useState("snow");
  const [datas, setData] = React.useState<any>([]);
  const [questionType, setExamTypes] = useState<any>();
  const [units, setUnits] = useState([]);
  const [gradeParam, setGradeParam] = useState<any>();
  const [subjectParam, setSubjectParam] = useState<any>();
  const [yearParam, setYearParam] = useState<any>();
  const grades: any = useSelector((state: any) => state.grade.grade);
  const subjects = useSelector((state: any) => state.subject.data);
  const questionsData: any = useSelector((state: any) => state.question);
  const years: any = useSelector((state: any) => state.year.data);
  const examVersions: any = useSelector((state: any) => state.examVersion.data);
  const sections: any = useSelector((state: any) => state.section.data);
  const studyQuestions: any = useSelector(
    (state: any) => state.study.data.studies
  );
  const dispatch = useDispatch();
  const [selectedExam, setSelectedExam] = useState<any>([]);
  const [params, setParams] = useState<any>([]);
  let { id }: any = useParams();
  useEffect(() => {
    if (questionsData.isSuccess == true) {
      var q = studyQuestions.filter((exam: any) => exam.id === id);
      setSelectedExam(q[0]);

      setData(q[0].selectedQuestion);
    }
  }, [questionsData.loading]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "number",
        id: "number",
        header: "number",
      },
      {
        accessorKey: "questionType",
        id: "questionType",
        header: "Question Type",
        Edit: ({ cell, column, table }: any) => (
          <Select
            className="select-input"
            _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
            variant="unstyled"
          >
            <option key="exam" value="EXAM">
              Exam
            </option>
            <option key="custom" value="CUSTOM">
              Custom
            </option>
          </Select>
        ),
      },
      {
        accessorKey: "grade",
        id: "grade",
        header: "GRADE",
        Edit: ({ cell, column, table }: any) => (
          <Select
            className="select-input"
            placeholder="Select Grades"
            _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
            variant="unstyled"
          >
            {grades?.map((item: any, index: any) => (
              <option key={item.grade} value={item.grade}>
                {item.grade}
              </option>
            ))}
          </Select>
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span>{renderedCellValue?.grade}</span>
        ),
      },
      {
        accessorKey: "subject",
        id: "subject",
        header: "Subject",
        Edit: ({ cell, column, table }: any) => (
          <Select
            className="select-input"
            placeholder="Select Subject"
            onChange={(e: any) => setSubjectParam(e.target.value)}
            _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
            variant="unstyled"
          >
            {subjects?.map((item: any, index: any) => (
              <option key={item.id} value={item.grade}>
                {item.subject}
              </option>
            ))}
          </Select>
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span>{renderedCellValue?.subject}</span>
        ),
      },
      {
        accessorKey: "year",
        id: "year",
        header: "Year",
        Edit: ({ cell, column, table }: any) => (
          <Select
            className="select-input"
            placeholder="Select year"
            onChange={(e: any) => setYearParam(e.target.value)}
            _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
            variant="unstyled"
          >
            {years?.map((item: any, index: any) => (
              <option key={item.id} value={item.year}>
                {item.year}
              </option>
            ))}
          </Select>
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span>{renderedCellValue?.year}</span>
        ),
      },
      // {
      //   accessorKey: 'createdBy',
      //   id: 'createdBy',
      //   header:'createdBy',
      //   Cell: ({ renderedCellValue, row }: any) => (
      //     <span>{renderedCellValue?.firstName}</span>
      //   )
      // },
      {
        accessorKey: "examVersion",
        id: "examVersion",
        header: "exam Version",
        Edit: ({ cell, column, table }: any) => (
          <Select
            className="select-input"
            _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
            variant="unstyled"
          >
            {examVersions?.map((item: any) => (
              <option key={item.id} value={item.examVersion}>
                {item.examVersion}
              </option>
            ))}
          </Select>
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span>{renderedCellValue?.examVersion}</span>
        ),
      },
      {
        accessorKey: "unit",
        id: "unit",
        header: "Unit",
        Edit: ({ cell, column, table }: any) => (
          <Select
            className="select-input"
            _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
            variant="unstyled"
          >
            {units?.map((item: any) => (
              <option key={item.id} value={item.unit}>
                {item.unit}
              </option>
            ))}
          </Select>
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span>{renderedCellValue?.unit}</span>
        ),
      },
      {
        accessorKey: "section",
        id: "section",
        header: "Section",
        Edit: ({ cell, column, table }: any) => (
          <Select
            className="select-input"
            _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
            variant="unstyled"
          >
            {sections?.map((item: any) => (
              <option key={item.id} value={item.section}>
                {item.section}
              </option>
            ))}
          </Select>
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span>{renderedCellValue?.section}</span>
        ),
      },

      {
        accessorKey: "question",
        header: "question",
        id: "question",
        Edit: ({ cell, column, table }: any) => (
          <Controller
            name="A"
            control={control}
            rules={{
              required: "Please enter task description",
            }}
            render={({ field }) => (
              <CustomReactQuill
                      field={field}
                      themes={themes}
                      modules={rtxModules}
                      defaultValue="B"
                      placeholder="Enter your question"
                      onChange={(text: string) => {
                        field.onChange(text); 
                      }}
                    />
            )}
          />
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span dangerouslySetInnerHTML={{ __html: row.original.question }} />
        ),
      },
      {
        accessorKey: "A",
        header: "A",
        Edit: ({ cell, column, row, table }: any) => (
          <Controller
            name="A"
            control={control}
            rules={{
              required: "Please enter task description",
            }}
            render={({ field }) => (
              <CustomReactQuill
										field={field}
										themes={themes}
										modules={rtxModules}
										defaultValue="B"
										placeholder="Enter choice A"
										onChange={(text: string) => {
											field.onChange(text); 
										}}
										/>            )}
          />
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span dangerouslySetInnerHTML={{ __html: row.original.A }} />
        ),
      },

      {
        accessorKey: "B",
        header: "B",
        Edit: ({ cell, column, table }: any) => (
          <Controller
            name="B"
            control={control}
            rules={{
              required: "Please enter task description",
            }}
            render={({ field }) => (
              <CustomReactQuill
              field={field}
              themes={themes}
              modules={rtxModules}
              defaultValue="B"
              placeholder="Enter choice B"
              onChange={(text: string) => {
                field.onChange(text); 
              }}
              />   
            )}
          />
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span dangerouslySetInnerHTML={{ __html: row.original.B }} />
        ),
      },

      {
        accessorKey: "C",
        header: "C",
        Edit: ({ cell, column, table }: any) => (
          <Controller
            name="C"
            control={control}
            rules={{
              required: "Please enter task description",
            }}
            render={({ field }) => (
              <CustomReactQuill
              field={field}
              themes={themes}
              modules={rtxModules}
              defaultValue="B"
              placeholder="Enter choice C"
              onChange={(text: string) => {
                field.onChange(text); 
              }}
              />   
            )}
          />
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span dangerouslySetInnerHTML={{ __html: row.original.C }} />
        ),
      },
      {
        accessorKey: "D",
        header: "D",
        Edit: ({ cell, column, table }: any) => (
          <Controller
            name="D"
            control={control}
            rules={{
              required: "Please enter task description",
            }}
            render={({ field }) => (
              <CustomReactQuill
              field={field}
              themes={themes}
              modules={rtxModules}
              defaultValue="B"
              placeholder="Enter choice D"
              onChange={(text: string) => {
                field.onChange(text); 
              }}
              />   
            )}
          />
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span dangerouslySetInnerHTML={{ __html: row.original.D }} />
        ),
      },
      {
        accessorKey: "answer",
        header: "answer",
        Edit: ({ cell, column, table }: any) => (
          <Select
            className="select-input"
            _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
            {...editQuestionForm.register("answer", {
              required: "Answer is required",
              minLength: { value: 0, message: "Minimum length should be 4" },
            })}
            variant="unstyled"
          >
            <option key="a" value="A">
              A
            </option>
            <option key="b" value="B">
              B
            </option>
            <option key="c" value="C">
              C
            </option>
            <option key="d" value="D">
              D
            </option>
          </Select>
        ),
      },

      {
        accessorKey: "description",
        header: "description",
      },
    ],
    []
  );
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const alert = useDisclosure();
  const cancelRef = React.useRef();
  const [idq, setId] = useState<any>("");
  const table = useMaterialReactTable({
    columns,
    data: datas,
    enableRowSelection: true,
    getRowId: (row: any) => row.userId, //give each row a more useful id
    onRowSelectionChange: setRowSelection, //connect internal row selection state to your own
    state: { rowSelection }, //pass our managed row selection state to the table to use
  });

  //   useEffect(() => {
  //     //do something when the row selection changes...
  //     console.info({ rowSelection });
  //   }, [rowSelection]);

  useEffect(() => {
    // const params = {
    //   questionType: questionType,
    //   grade: gradeParam,
    //   subject: subjectParam,
    //   year: yearParam,
    // }
    dispatch(getQuestions(params));
  }, [questionType, gradeParam, subjectParam]);
  const handleChangeExamType = (value: any) => {
    setExamTypes(value);
  };
  const handleChangeGrade = (value: any) => {
    setGradeParam(value);
  };

  const handleDeleteQuestion = (value: any) => {
    setId(value);

    alert.onOpen();
  };

  const addQuestionModal = useDisclosure();
  const handleAddExamQuestion = () => {
    setParams({
      grade: selectedExam.grade.grade,
      subject: selectedExam.subject.subject,
    });
    addQuestionModal.onOpen();
  };
  const handleAddQuestions = () => {
    let ids = [];
    // const selecteQuestions = Object.keys(rowSelection).filter((id) => rowSelection[id]);

    // const data= {
    //   id: id,
    //   payload: {
    //     selecteQuestions
    //   }
    // }
    // dispatch(examAddQuestion(data))
  };
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const toast = useToast();
  const onDeleteQuestion = () => {
    dispatch(deleteQuestion(idq)).then((data: any) => {
      if (data.type === "questions/deleteData/fulfilled") {
        toast({
          title: "Success",
          description: "Exam deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      }
    });
    alert.onClose();
  };
  let selectedQuestions: any[] = [];
  const handleRemoveQuestions = (value: any) => {
    const selectedRows = table.getSelectedRowModel().rows.map((q: any) => {
      if (q.original?.id !== undefined) {
        selectedQuestions.push(q.original?.id);
      }
    });

    let ids = [];
    const data = {
      id: id,
      payload: { selecteQuestions: selectedQuestions },
    };
    dispatch(studyRemoveQuestion(data)).then((data: any) => {
      if (data.type === "studies/removeStudyQuestion/fulfilled") {
        toast({
          title: "Success",
          description: "Questions Removed successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        setData(data.payload.data.study.selectedQuestion);
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const addQuestions = (values: any) => {};

  return (
    <div className="pt-10">
      <div className="h-2" />
      <Box overflowX="auto">
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Flex width="100%">
            {" "}
            <Box>
              <Button
                fontSize={{ sm: "10px", md: "10px", lg: "14" }}
                variant="brand"
                fontWeight="500"
                onClick={(e: any) => handleRemoveQuestions(e)}
              >
                Remove Questions
              </Button>
            </Box>
          </Flex>
        </Flex>

        <ThemeProvider theme={theme}>
          <MaterialReactTable table={table} />;
        </ThemeProvider>

        <Modal
          isOpen={addQuestionModal.isOpen}
          onClose={addQuestionModal.onClose}
        >
          <ModalOverlay />

          <ModalContent maxW="1000px">
            <form onSubmit={handleSubmitQuestion(addQuestions)}>
              <ModalHeader>Create Questions</ModalHeader>
              <ModalCloseButton />
              <QuestionList actionsEnabled={true} data={datas} />

              <ModalBody pb={6}></ModalBody>

              <ModalFooter>
                <PrimaryButton>Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <AlertBox
          cancelRef={cancelRef}
          alert={alert}
          header="Delete Question?"
          alertBody="Are you sure you want to delete this Question"
          onDelete={onDeleteQuestion}
        />
      </Box>
    </div>
  );
}
