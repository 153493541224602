import { MultiSelect } from "components/Atoms/Selectors/multiSelect";

const MultiRoleSelect=({control, name, defaultValue, roles}: any)=>{

    return(
		<MultiSelect 
			id="roles" 
			name={name}
			control={control} 
			validation={{ required: "Please enter at least role" }} 
			label="Roles" 
			defaultValue={defaultValue}
			placeholder="Select Roles"
			options={roles}
			errorMessage="Select atleast one role"
			/>
       
    )
}
export { MultiRoleSelect };
