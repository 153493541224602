import { AddIcon, EditIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text, useColorModeValue,
  useDisclosure,
  useToast, VStack
} from "@chakra-ui/react";
import { ArrowBack } from "@mui/icons-material";
import { ColumnDef } from "@tanstack/react-table";
import { ProgressModal } from "components/Molecules/Modals/ProgressModal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getQuestions } from "reduxtoolkit/reducers/questionSlice";
import {
  addStudLinks,
  getStudiesById,
  studyAddQuestion,
  studyDeleteMobileVideoLinks,
  studyRemoveQuestion
} from "reduxtoolkit/reducers/studiesSlice";
// Custom components
import { CloseButton, Image, Link } from "@chakra-ui/react";
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel
} from "@tanstack/react-table";
import Project1 from "assets/images/pdf.png";
import { PrimaryText } from "components/Atoms/Title/Titles";
import AlertBox from "components/Molecules/Alerts/AlertBox";
import * as React from "react";
import { generateExam, getExam } from "reduxtoolkit/reducers/examGenerateSlice";
import { updateGrade } from "reduxtoolkit/reducers/gradeSlice";
import {
  studyDeletePdfs,
  studyDeleteVideoLinks
} from "reduxtoolkit/reducers/studiesSlice";
// Assets

import { addStudyPdf } from "reduxtoolkit/reducers/studiesSlice";

import { Input } from "@chakra-ui/react";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import TableContent from "components/Atoms/Label/TableCell";
import SecondaryTable from "components/Molecules/Tables/SelectTable";
import VideoPreview from "components/Molecules/VideoPreview";
import DynamicInputForms from "screens/admin/studies/adlink";
type RowObj = {
  number: string;
  questionType: any;
  question: any;
  grade: any;
  unit: any;
  year: any;
  answer: string;
  description: string;
  actions: any;
};



const StudyAddQuestion = () => {
  const tableCellColor = useColorModeValue("navy.700", "white");
  const [selectedData, setSelectedData] = useState([]);

  const columns = React.useMemo<ColumnDef<RowObj>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => <div></div>,
        cell: ({ row }) => {
          return <div className="px-1"></div>;
        },
      },
      {
        accessorKey: "number",
        id: "number",
        header: "number",
      },
      {
        accessorKey: "questionType",
        id: "questionType",
        header: "Question Type",
      },

      {
        accessorKey: "grade",
        id: "grade",
        header: "GRADE",
        cell: (info) => {
          return (
            <TableContent
              textColor={tableCellColor}
              title={info.cell.row.original.grade.grade}
            />
          );
        },
      },

      {
        accessorKey: "year",
        id: "year",
        header: "Year",
        cell: (info) => {
          return (
            <TableContent
              textColor={tableCellColor}
              title={info.cell.row.original.year.year}
            />
          );
        },
      },

      {
        accessorKey: "unit",
        id: "unit",
        header: "Unit",

        cell: (info) => {
          return (
            <TableContent
              textColor={tableCellColor}
              title={info.cell.row.original.unit.unit}
            />
          );
        },
      },
      {
        accessorKey: "question",
        id: "question",
        header: "Question",

        cell: (info) => (
          <span
            dangerouslySetInnerHTML={{
              __html: info.cell.row.original.question,
            }}
          />
        ),
      },
    ],
    []
  );

  const handleRemoveQuestions = (value: any) => {
    const filteredObject = Object.fromEntries(
      Object.entries(checkedItems).filter(([key, value]) => value === true)
    );
    const myId = Object.keys(filteredObject);

    if (myId.length > 0) {

      const data = {
        id: id,
        payload: { selecteQuestions: myId },
      };
      dispatch(studyRemoveQuestion(data)).then((data: any) => {
        if (data.type === "studies/removeStudyQuestion/fulfilled") {
          toast({
            title: "Success",
            description: "Questions Removed successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setSelectCount(0);
          setCheckedItems({});
          setQuestionsTotal(totalquestion - myId.length);
          setStudy(data.payload.data.study);
          setEnableSelect(true);
          setSelectedExam(data.payload.data.study.selectedQuestion);
        } else {
          toast({
            title: "Error",
            description: `${data.payload.response.data.message}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      });
    } else {
      toast({
        title: "Error",
        description: "No Questions Selected",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleAddQuestions = () => {
    let selectedQuestions: any[] = [];
    selectedData.map((q: any) => {
      if (q.id !== undefined) {
        selectedQuestions.push(q.id);
      }
    });

    if (selectedQuestions.length > 5 - selectedExam.length) {
      toast({
        title: "Error",
        description: "Selected Questions more Than Limit",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {

      const data = {
        id: id,
        payload: { selecteQuestions: selectedQuestions },
      };
      dispatch(studyAddQuestion(data)).then((data: any) => {
        if (data.type === "studies/addStudyQuestion/fulfilled") {
          toast({
            title: "Success",
            description: "Study Questions Added successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setStudy(data.payload.data.study);
          setQuestionsTotal(totalquestion + selectedQuestions.length);
          setEnableSelect(true);
          setSelectedData([]);
          setSelectedExam(data.payload.data.study.selectedQuestion);

          addStudyQuestionModal.onClose();
        } else {
          toast({
            title: "Error",
            description: `${data.payload.response.data.message}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      });
    }
  };
  const handleAddStudyLinks = () => {
    resetLinks();
    addStudyLinksModal.onOpen();
  };
  const gradesData: any = useSelector((state: any) => state.grade);
  const subjectsData = useSelector((state: any) => state.subject);
  const unitsData: any = useSelector((state: any) => state.unit);
  const yearsData: any = useSelector((state: any) => state.year);
  const sectiondata: any = useSelector((state: any) => state.section);
  const examVersiondata: any = useSelector((state: any) => state.examVersion);


  const addStudyQuestionForm = useForm();
  const { handleSubmit: handleSubmitStudyQuestion } = addStudyQuestionForm;

  const addStudyFilesForm = useForm();
  const { handleSubmit: handleSubmitStudyFiles } = addStudyFilesForm;
  const addStudyLinksForm = useForm();
  const {
    handleSubmit: handleSubmitStudyLinks,
    reset: resetLinks,
    control: addLinkControl,
  } = addStudyLinksForm;

  const addLinks = (values: any) => {
    const videoLinksArray: any = values.videoLinks.map(
      (item: any) => item.videoLinks
    );
    const data = { id: id, payload: { videoLinks: videoLinksArray } };

    dispatch(addStudLinks(data)).then((data: any) => {
      if (data.type === "studies/addStudyLinks/fulfilled") {
        toast({
          title: "Success",
          description: "Links added successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setStudy(data.payload.data.study);
        resetLinks();
        addStudyLinksModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      addStudyLinksModal.onClose();
    });
  };

  const [updated, setUpdated] = useState(false);
  let { id }: any = useParams();

  const onGenerateExam = (values: any) => {
    const data = {
      examName: values.examName,
      grade: values.grade,
      subject: values.subject,
      year: values.year,
      examVersion: values.examVersion,
      duration: Number(values.duration),
      passingScore: Number(values.passingScore),
      noOfQuestions: Number(values.noOfQuestions),
      selecteQuestions: ["003d3494-2746-405b-b5e5-ff6aeae9da67"],
    };
    dispatch(generateExam(data)).then((data: any) => { });
  };

  const onEditGrades = (values: any) => {
    const data = {
      grade: values.grade,
    };
    dispatch(updateGrade());
  };
  const onDeleteGrade = (value: any) => {
    //	setId('id'));
  };
  const questionsData: any = useSelector((state: any) => state.question);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [years, setYears] = useState([]);
  const [units, setUnits] = useState([]);
  const [sections, setSections] = useState([]);

  const studyQuestions: any = useSelector((state: any) => state.study);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getExam()).then((data: any) => {
      setExamVersion(examVersiondata.data);
      setUnits(unitsData.data);
      setGrades(gradesData.grade);
      setSections(sectiondata.data);
      setSubjects(subjectsData.data);
      setYears(yearsData.data);
    });
    setData(questionsData.data);
  }, []);
  const [selectedExam, setSelectedExam] = useState<any>([]);

  const [study, setStudy] = useState<any>([]);
  const [questionNum, setQuestionNum] = useState(0);
  useEffect(() => {
    dispatch(getStudiesById(id)).then((data: any) => {
      let val = data.payload.exam;
      setStudy(val);

      setQuestionsTotal(val.selectedQuestion.length);
      setSelectedExam(val.selectedQuestion);
    });
  }, []);
  useEffect(() => {
    if (studyQuestions.isSuccess === true) {
      //	var q = studyQuestions.data.studies.filter((study:any) => study.id === id);
      //
    }
  }, [studyQuestions.loading]);
  const generateExamForm = useForm();
  const addStudyQuestionModal = useDisclosure();
  const addStudyFilesModal = useDisclosure();
  const addStudyLinksModal = useDisclosure();
  const { handleSubmit: handleSubmitGeneratedExam, control } = generateExamForm;

  const [questionData, setQuestionData] = useState([]);
  const [totalquestion, setQuestionsTotal] = useState(0);
  function selectDistinctValues(request1: any, request2: any) {
    var arr = request2.filter(function (item: any) {
      return (
        request1?.findIndex(function (obj: any) {
          return obj?.id === item?.id;
        }) === -1
      );
    });
    return arr;
  }
  const handleAddStudyQuestion = () => {
    const param = {
      grade: study?.grade.grade,
      subject: study?.subject.subject,
      unit: study?.unit.unit,
      section: study?.section.section,
      limit: 30,
    };

    dispatch(getQuestions(param)).then((data: any) => {
      setQuestionData(
        selectDistinctValues(selectedExam, data.payload.data.questions)
      );

      addStudyQuestionModal.onOpen();
    });
  };
  const [examVersions, setExamVersion] = useState([]);

  const [remainingQuestion, setRemainingQuestion] = useState(0);
  const [removeSelected, setRemoveSelected] = useState(false);
  let selecteQuestions: any[] = [];
  const alert = useDisclosure();
  const cancelRef = React.useRef();
  const [data, setData] = React.useState([]);

  const [rowSelection, setRowSelection] = React.useState({});
  const toast = useToast();
  const [enableSelect, setEnableSelect] = useState(true);

  const qtable = {
    data: questionData,
    columns,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection,
    },
    enableRowSelection: true, //enable row selection for all rows
    // enableRowSelection: row => row.original.age > 18, // or enable row selection conditionally per row

    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  };

  const history = useHistory();
  const handleEditQuestion = (value: any) => {
    history.push(`/admin/questions/${value}`);
  };
  const addFiles = (values: any) => {
    const formData = new FormData();
    formData.append("pdfs", values.pdfs[0]);
    const data = {
      id: id,
      payload: formData,
    };
    dispatch(addStudyPdf(data)).then((data: any) => {
      if (data.type === "studies/addStudyPdf/fulfilled") {
        toast({
          title: "Success",
          description: "Files added successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setStudy(data.payload.data.study);
        addStudyFilesModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const addQuestions = (values: any) => {
    const data = {
      id: id,
      payload: { selecteQuestions: selecteQuestions },
    };
    dispatch(studyAddQuestion(data)).then((data: any) => {
      if (data.type === "studies/addStudyQuestion/fulfilled") {
        toast({
          title: "Success",
          description: "Study Questions Added successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const handleDeleteStudyMobileUrl = (value: any) => {
    const data = {
      id: id,
      payload: { mobileVideoLinks: [value] },
    };
    dispatch(studyDeleteMobileVideoLinks(data)).then((data: any) => {
      if (data.type === "studies/deleteStudyLinks/fulfilled") {
        toast({
          title: "Success",
          description: "Study Video Links Removed successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        setStudy(data.payload.data.study);
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const handleDeleteStudyVideo = (value: any) => {
    const data = {
      id: id,
      payload: { videoLinks: [value] },
    };
    dispatch(studyDeleteVideoLinks(data)).then((data: any) => {
      if (data.type === "studies/deleteStudyLinks/fulfilled") {
        toast({
          title: "Success",
          description: "Study Video Links Removed successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        setStudy(data.payload.data.study);
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const handleDeleteStudyPdf = (value: any) => {
    const data = {
      id: id,
      payload: { deletePdfs: [value] },
    };
    dispatch(studyDeletePdfs(data)).then((data: any) => {
      if (data.type === "studies/deleteStudyPdfs/fulfilled") {
        toast({
          title: "Success",
          description: "Study Pdf Removed successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        setStudy(data.payload.data.study);
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const [checkedItems, setCheckedItems] = useState<any>({});

  useEffect(() => {
    const filteredObject = Object.fromEntries(
      Object.entries(checkedItems).filter(([key, value]) => value === true)
    );

    if (Object.entries(filteredObject).length === 0) {
      setRemoveSelected(true);
    } else {
      setEnableSelect(false);
    }
  }, [checkedItems]);
  const handleChange = (event: any) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });

    countTrueValues({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [selectCount, setSelectCount] = useState(0);

  const countTrueValues = (val: any) => {
    let count = 0;

    Object.values(val).forEach((value) => {
      if (value === true) {
        count++;
      }
    });
    setSelectCount(count);
  };

  const createProgressModal = useDisclosure();
  useEffect(() => {
    if (studyQuestions.loading === true) {
      setIsOpen(true);
      setLoading(true);
    } else {
      setLoading(false);
      setIsOpen(false);
      createProgressModal.onClose();
    }
  }, [studyQuestions.loading]);
  return (
    <Box
      mt={10}
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {loading ? <Progress id="user" w="1/2" isIndeterminate /> : null}
      <ProgressModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <Divider borderWidth="1px" borderColor="#D9D9D9" />

      <Divider borderWidth="1px" borderColor="#D9D9D9" />
      <Box>
        <Box my={10} p={5} bg="white" borderRadius={0}>
          <Button
            onClick={() => {
              history.goBack();
            }}
            fontSize="15px"
          >
            <ArrowBack />
            Go back
          </Button>
          <Flex>
            <PrimaryText> Study Details </PrimaryText>
          </Flex>
          <Box my={3}>
            <Text fontSize="sm" px={10} fontWeight="700">
              Objective :
              <span
                dangerouslySetInnerHTML={{
                  __html: study?.objective,
                }}
              />
            </Text>
            <Divider borderWidth="1px" borderColor="#D9D9D9" />
          </Box>
          <Box my={3}>
            <Text fontSize="sm" px={10} fontWeight="700">
              Grade : {study?.grade?.grade}
            </Text>
            <Divider borderWidth="1px" borderColor="#D9D9D9" />
          </Box>
          <Box my={3}>
            <Text fontSize="sm" px={10} fontWeight="700">
              Subject : {study?.subject?.subject}
            </Text>
            <Divider borderWidth="1px" borderColor="#D9D9D9" />
          </Box>
          <Box my={3}>
            <Text fontSize="sm" px={10} fontWeight="700">
              Section : {study?.section?.section}
            </Text>
            <Divider borderWidth="1px" borderColor="#D9D9D9" />
          </Box>
          <Box my={3}>
            <Text fontSize="sm" px={10} fontWeight="700">
              Unit : {study?.unit?.unit}
            </Text>
            <Divider borderWidth="1px" borderColor="#D9D9D9" />
          </Box>
          <Box my={3}>
            <Flex>
              {" "}
              {study?.pdf?.map((pdf: any) => (
                <div key={pdf.id}>
                  <CloseButton
                    size="lg"
                    onClick={() => handleDeleteStudyPdf(pdf.id)}
                  />
                  <Link href={pdf?.pdfDocument}>
                    <Image
                      h="80px"
                      w="80px"
                      src={Project1}
                      borderRadius="8px"
                      me="20px"
                    />
                  </Link>
                </div>
              ))}
              <Button
                fontSize={{ sm: "10px", md: "10px", lg: "14" }}
                bg="white"
                variant="outline"
                fontWeight="500"
                color="gray"
                borderRadius="0"
                onClick={() => addStudyFilesModal.onOpen()}
              >
                <AddIcon w={4} h={4} px="1px" color="gray" />
                Add Pdfs
              </Button>
              {study?.pdf?.length === 0 ? (
                <Text my={10}>No Pdfs added</Text>
              ) : (
                <></>
              )}
            </Flex>

            <Divider borderWidth="1px" borderColor="#D9D9D9" />
          </Box>
          <Box my={3}>
            <Button
              fontSize={{ sm: "10px", md: "10px", lg: "14" }}
              bg="white"
              variant="outline"
              fontWeight="500"
              color="gray"
              borderRadius="0"
              onClick={() => handleAddStudyLinks()}
            >
              <AddIcon w={4} h={4} px="1px" color="gray" />
              Add Video Links
            </Button>
            <Flex m={3} width="800px">
              {" "}
              {study?.videoLink?.map((video: any) => (
                <Box
                  alignItems="center"
                  flexDirection="column"
                  w="500px"
                  p="20px 15px"
                >
                  <CloseButton
                    size="lg"
                    onClick={() => handleDeleteStudyVideo(video.id)}
                  />
                  <VideoPreview m={2} youtubeUrl={video?.videoLink} />
                </Box>
              ))}
              {study?.videoLink?.length === 0 ? (
                <Text my={10}>No video links added</Text>
              ) : (
                <></>
              )}
            </Flex>
            <Divider borderWidth="1px" borderColor="#D9D9D9" />
          </Box>
          <Box my={3}></Box>

          <Text fontSize="sm" px={10} fontWeight="700">
            Self Assesment Question
          </Text>

          {selectedExam?.map((record: any) => {
            return (
              <Box m={5}>
                <Flex key={record.id}>
                  <Box w="80%" key={record.id}>
                    <Box>
                      <Text fontSize="13px" color="#706A6A">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: record?.metadata?.replace(
                              /(<? *script)/gi,
                              ""
                            ),
                          }}
                        />
                      </Text>

                      <Flex>
                        <Checkbox
                          name={record.id}
                          isChecked={checkedItems[record.id]}
                          onChange={handleChange}
                          fontSize="13px"
                        />
                        <Text fontSize="13px" color="#706A6A">
                          {" "}
                          {record.number + ".)"}{" "}
                        </Text>
                        <Text fontSize="13px" color="#706A6A">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: record?.question?.replace(
                                /(<? *script)/gi,
                                ""
                              ),
                            }}
                          ></span>
                        </Text>
                      </Flex>
                      <Box>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: record?.description?.replace(
                              /(<? *script)/gi,
                              ""
                            ),
                          }}
                        ></span>
                      </Box>

                      <Flex>
                        <Box>
                          <Flex>
                            <Checkbox
                              fontSize="13px"
                              disabled
                              checked={record?.answer === "A"}
                            >
                              A.
                            </Checkbox>

                            <Text
                              fontSize="13px"
                              style={{ whiteSpace: 'pre-wrap' }}
                              dangerouslySetInnerHTML={{
                                __html: record?.A?.replace(
                                  /(<? *script)/gi,
                                  ""
                                ),
                              }}
                            ></Text>
                          </Flex>
                          <Flex>
                            <Checkbox disabled checked={record?.answer === "B"}>
                              B.
                            </Checkbox>

                            <Text
                              fontSize="13px"
                              style={{ whiteSpace: 'pre-wrap' }}
                              dangerouslySetInnerHTML={{
                                __html: record?.B?.replace(
                                  /(<? *script)/gi,
                                  ""
                                ),
                              }}
                            ></Text>
                          </Flex>
                          <Flex>
                            <Checkbox
                              fontSize="13px"
                              disabled
                              checked={record?.answer === "C"}
                            >
                              C.
                            </Checkbox>

                            <Text
                              fontSize="13px"
                              style={{ whiteSpace: 'pre-wrap' }}
                              dangerouslySetInnerHTML={{
                                __html: record?.C?.replace(
                                  /(<? *script)/gi,
                                  ""
                                ),
                              }}
                            ></Text>
                          </Flex>
                          <Flex>
                            <Checkbox
                              fontSize="13px"
                              disabled
                              checked={record?.answer === "D"}
                            >
                              D.
                            </Checkbox>

                            <Text
                              fontSize="13px"
                              style={{ whiteSpace: 'pre-wrap' }}
                              dangerouslySetInnerHTML={{
                                __html: record?.D?.replace(
                                  /(<? *script)/gi,
                                  ""
                                ),
                              }}
                            ></Text>
                          </Flex>
                        </Box>
                      </Flex>
                    </Box>
                  </Box>
                  <Box>
                    <VStack>
                      <Flex gap={4} justify="space-evenly" w="10%">
                        <ViewIcon w={4} h={4} color="#1890FF" />
                        <EditIcon
                          onClick={() => handleEditQuestion(record.id)}
                          w={4}
                          h={4}
                          color="#1890FF"
                        />
                      </Flex>
                      <Box h={50}></Box>
                      <Flex gap={1} alignContent="end">
                        <VStack>
                          <Text fontSize="10px" color="#706A6A">
                            {" "}
                            Unit
                          </Text>
                          <Badge
                            fontSize="10px"
                            color="#706A6A"
                            borderRadius="0px"
                            border="solid 1px gray"
                          >
                            {record?.unit?.unit}
                          </Badge>
                        </VStack>
                        <VStack>
                          <Text fontSize="10px" color="#706A6A">
                            Section
                          </Text>
                          <Badge
                            fontSize="10px"
                            color="#706A6A"
                            borderRadius="0px"
                            border="solid 1px gray"
                          >
                            {record?.section?.section}
                          </Badge>
                        </VStack>
                      </Flex>
                    </VStack>
                  </Box>
                </Flex>
                <Divider
                  borderWidth="1px"
                  borderColor="#D9D9D9"
                  display="block"
                  my={10}
                />
              </Box>
            );
          })}
          {selectedExam.length === 0 ? (
            <PrimaryText mx="auto">
              No self assesment Questions added{" "}
            </PrimaryText>
          ) : (
            <></>
          )}
        </Box>

        {selectedExam.length > 0 ? (
          <Button
            mx={5}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            bg="#0076FD"
            fontWeight="500"
            color="white"
            borderRadius="0"
            disabled={false}
            onClick={(e: any) => handleRemoveQuestions(e)}
          >
            Remove Questions {selectCount}
          </Button>
        ) : (
          <></>
        )}
        {totalquestion < 5 ? (
          <Button
            mx={5}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            bg="#0076FD"
            fontWeight="500"
            color="white"
            borderRadius="0"
            onClick={() => handleAddStudyQuestion()}
          >
            <AddIcon w={4} h={4} px="1px" color="white" />
            Add Questions
          </Button>
        ) : (
          <></>
        )}
        <Modal
          isOpen={addStudyQuestionModal.isOpen}
          onClose={addStudyQuestionModal.onClose}
        >
          <ModalOverlay />

          <ModalContent maxW="1200px">
            <form onSubmit={handleSubmitStudyQuestion(addQuestions)}>
              <ModalHeader>Add Self Assesment Questions</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <SecondaryTable
                  totalquestion={totalquestion}
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  tableOptions={qtable}
                />
                <Flex>
                  <PrimaryButton onClick={() => handleAddQuestions()}>
                    Add Selected Questions
                  </PrimaryButton>
                </Flex>
              </ModalBody>

              <ModalFooter></ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={addStudyFilesModal.isOpen}
          onClose={addStudyFilesModal.onClose}
        >
          <ModalContent>
            <form onSubmit={handleSubmitStudyFiles(addFiles)}>
              <ModalHeader>Add Files</ModalHeader>
              <ModalBody>
                <Input
                  id="file"
                  fontWeight="500"
                  variant="main"
                  h="44px"
                  maxH="44px"
                  type="file"
                  placeholder="Pdf"
                  _placeholder={{
                    fontWeight: "400",
                    color: "secondaryGray.600",
                  }}
                  {...addStudyFilesForm.register("pdfs", {
                    required: "This is required",
                  })}
                />
              </ModalBody>
              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={addStudyLinksModal.isOpen}
          onClose={addStudyLinksModal.onClose}
        >
          <ModalContent>
            <Box borderLeftWidth="3px" borderColor="#7F77F1">
              <form onSubmit={handleSubmitStudyLinks(addLinks)}>
                <ModalHeader>Add Study Links</ModalHeader>

                <Box p={5}>
                  {" "}
                  <DynamicInputForms form={addStudyLinksForm} />
                </Box>
                <ModalBody></ModalBody>
                <ModalFooter>
                  <PrimaryButton type="submit">Save</PrimaryButton>
                </ModalFooter>
              </form>
            </Box>
          </ModalContent>
        </Modal>

        <AlertBox
          cancelRef={cancelRef}
          alert={alert}
          header="Delete Question?"
          alertBody="Are you sure you want to delete this Question"
          onDelete={onDeleteGrade}
        />
      </Box>
    </Box>
  );
};

export default StudyAddQuestion;
