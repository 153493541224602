import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from 'utils/axiosMiddleware';


export const getExam:any =  createAsyncThunk(
  "exams/getData",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
      
    try {
      const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/exam/exams`,{headers: { Authorization: `Bearer ${token}` },
      params: arg,
      },);
      
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });
  export const getExamById:any =  createAsyncThunk(
    "exams/getExamByID",
    async (arg, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
      try {
        const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/exam/exam/${arg}`,{headers: { Authorization: `Bearer ${token}` },
        
        },);
        
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })
  
  export const getSingleExam:any =  createAsyncThunk(
    "exams/singleExam",
    async (arg, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
      try {
        const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/exam/exam/${arg}`,{headers: { Authorization: `Bearer ${token}` },
        
        },);
        
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })

    export const updateExam:any =  createAsyncThunk(
      "exam/Update",
      async (arg:any, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
        try {
          const data = await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/exam/update/${arg.id}`,
            headers: { Authorization: `Bearer ${token}` },
            data: arg.payload,
            
        });
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      })

    export const deleteExam:any =  createAsyncThunk(
      "exams/deleteData",
      async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
        try {
     
          const data = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/exam/delete/${arg}`,
            headers: { Authorization: `Bearer ${token}` },
        });
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      })
      
      export const generateExam:any =  createAsyncThunk(
        "exam/GenerateExam",
        async (arg, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';  
          try {   
            const data:any = await axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URL}/exam/create`,
              headers: { Authorization: `Bearer ${token}` },
              data: arg,
          });
          return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })
        export const publishExam:any =  createAsyncThunk(
          "exam/Publish",
          async (arg:any, { rejectWithValue }) => {
            const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
              
            try {
              const data = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_API_URL}/exam/publish/${arg}`,
                headers: { Authorization: `Bearer ${token}` },
               
                
            });
            return data;
            } catch (error) {
              return rejectWithValue(error);
            }
          })
    
          export const unPublishExam:any =  createAsyncThunk(
            "exam/unPublish",
            async (arg:any, { rejectWithValue }) => {
              const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
                
              try {
                const data = await axios({
                  method: 'put',
                  url: `${process.env.REACT_APP_API_URL}/exam/unpublish/${arg}`,
                  headers: { Authorization: `Bearer ${token}` },
                  
              });
              return data;
              } catch (error) {
                return rejectWithValue(error);
              }
            })
            export const   examAddQuestion:any =  createAsyncThunk(
              "exam/addQuestion",
              async (arg:any, { rejectWithValue }) => {
                const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
                  
                try {
                  const data = await axios({
                    method: 'put',
                    url: `${process.env.REACT_APP_API_URL}/exam/addquestion/${arg.id}`,
                    headers: { Authorization: `Bearer ${token}` },
                    data: arg.payload
                });
                return data;
                } catch (error) {
                  return rejectWithValue(error);
                }
              })
              export const   examRemoveQuestion:any =  createAsyncThunk(
                "exam/RemoveQuestion",
                async (arg:any, { rejectWithValue }) => {
                  const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
                    
                  try {
                    const data = await axios({
                      method: 'delete',
                      url: `${process.env.REACT_APP_API_URL}/exam/deletequestion/${arg.id}`,
                      headers: { Authorization: `Bearer ${token}` },
                      data: arg.payload
                  });
                  return data;
                  } catch (error) {
                    return rejectWithValue(error);
                  }
                })
const generateExamSlice:any = createSlice({
  name: "exam",
  initialState: {
    data: [],
    isSuccess: false,
    message: "",
    loading: false,
  },
  reducers: {
       

  },
  extraReducers: {
    

    [getSingleExam.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getSingleExam.fulfilled]: (state, { payload }) => {
      state.loading = false;
      
      state.isSuccess = true;
    },
    [getSingleExam.rejected]: (state, { payload }) => {
      
      state.loading = false;
      state.isSuccess = false;
    },
    [getExam.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getExam.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getExam.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [getExamById.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getExamById.fulfilled]: (state, { payload }) => {
      state.loading = false;
      
      state.isSuccess = true;
    },
    [getExamById.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [generateExam.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [generateExam.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
 
      state.data.exams.push(payload.data.exam);
    
      state.isSuccess = true;
    }, 
    [generateExam.rejected]: (state, { payload }) => {
      state.message = payload.data;
      state.loading = false;
      state.isSuccess = false;
    },
    [updateExam.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateExam.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      var temp = current(state);
      let exams = [...temp.data.exams];
        const index = temp.data.exams.findIndex((exam:any) => exam.id === payload.data.exam.id);
             if (index !== -1) {
            
              exams[index] = payload.data.exam;
      
      
              temp.data = {...temp.data, exams }
              
              state.data = temp.data;
          }
      state.isSuccess = true;
    },
    [updateExam.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [deleteExam.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteExam.fulfilled]: (state:any, action) => {
      state.loading = false;
     
      state.data.exams = state.data.exams.filter((exam:any) => exam.id !== action.meta.arg);
      state.isSuccess = true;
    },
    [deleteExam.rejected]: (state:any, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [publishExam.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [publishExam.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      var temp = current(state);
      
       let exams = [...temp.data.exams];
       var index = state.data.exams.findIndex((exam:any) => exam.id === payload.data.exam.id);
   
       exams[index] = payload.data.exam;
      
      
      temp.data = {...temp.data, exams }
     // temp.data.exams = [...exams, payload.data.exam];
      
      state.data = temp.data;
      state.isSuccess = true;
    },
    [publishExam.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [unPublishExam.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [unPublishExam.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      var temp = current(state);
      
       let exams = [...temp.data.exams];
       var res = temp.data.exams.findIndex((exam:any) => exam.id === payload.data.exam.id);
     // temp.data.exams[res.id] =  payload.data.exam;
    
     exams[res] = payload.data.exam;
     

     temp.data = {...temp.data, exams }
     // temp.data.exams = [...exams, payload.data.exam];
     
      state.data = temp.data;
      state.isSuccess = true;
    },
    [unPublishExam.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [examAddQuestion.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [examAddQuestion.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
    
     
   
      
      state.isSuccess = true;
    },
    [examAddQuestion.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [examRemoveQuestion.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [examRemoveQuestion.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
    
   
      
      state.isSuccess = true;
    },
    [examRemoveQuestion.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    
  },
  
});

export default generateExamSlice;
