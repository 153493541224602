import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
// Custom components
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import AlertBox from "components/Molecules/Alerts/AlertBox";
import PrimaryTable from "components/Molecules/Tables/PrimaryTable";
import MainTab from "components/Molecules/Tabs/MainTab";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getComments } from "reduxtoolkit/reducers/commentSlice";
import { createFaqs, deleteFaqs } from "reduxtoolkit/reducers/faqSlice";

export default function Overview() {
  const addFaqsModal = useDisclosure();

  const cancelRef = useRef();
  const alert = useDisclosure();
  const createFaqsForm = useForm();
  const editFaqsForm = useForm();

  const {
    handleSubmit: handleSubmitFaqs,
    control,
    formState: { errors },
  } = createFaqsForm;
  const handleCreateFaqs = () => {
    addFaqsModal.onOpen();
  };
  const [id, setId] = useState<any>();

  const handleDeleteFaqs = (value: any) => {
    setId(value);

    alert.onOpen();
  };

  const [updated, setUpdated] = useState(false);
  const textColor = "gray.400";
  const unitdata: any = useSelector((state: any) => state.unit.data);
  const comments = useSelector((state: any) => state.comment);

  const dispatch = useDispatch();
  const onCreateFaqs = (values: any) => {
    dispatch(createFaqs(values)).then((data: any) => {
      if (data.type === "admin/createFaqs/fulfilled") {
        toast({
          title: "Success",
          description: "Faqs Created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        addFaqsModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        addFaqsModal.onClose();
      }
    });
  };

  const toast = useToast();

  const onDeleteFaqs = () => {
    dispatch(deleteFaqs(id)).then((data: any) => {
      if (data.type === "faq/deleteData/fulfilled") {
        toast({
          title: "Success",
          description: "Comments deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      }
    });
  };

  const [data, setData] = useState<any>([]);

  useEffect(() => {
    dispatch(getComments()).then((data: any) => { });
  }, []);
  useEffect(() => {
    if (comments.isSuccess == true) {
      setData(comments.data.comment);
      //  setData(faqs.data.faqs);
    }
  }, [comments.loading]);

  type RowObj = {
    id: string;
    actions: any;
    comment: string;
    user: any;
  };

  const columnHelper = createColumnHelper<RowObj>();

  const columns = [
    columnHelper.accessor("comment", {
      id: "comment",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          COMMENT
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("user", {
      id: "user",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          USER
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.row.original?.user?.firstName}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("actions", {
      id: "actions",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          Actions
        </Text>
      ),
      cell: (info) => (
        <>
          <Icon as={FaEdit} color="gray.400" />
          <Icon as={MdDeleteOutline} color="red" />
        </>
      ),
    }),
  ];

  const createProgressModal = useDisclosure();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (comments.loading == true) {
      setLoading(true);
      createProgressModal.onOpen();
    } else {
      createProgressModal.onClose();
      setLoading(false);
    }
  }, [comments.loading]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {loading ? <Progress w="1/2" isIndeterminate /> : null}
      <Modal
        isOpen={createProgressModal.isOpen}
        onClose={createProgressModal.onClose}
      >
        <ModalOverlay />
        <ModalContent bg="transparent" maxW="100%">
          <Box borderLeftWidth="3px" borderColor="#7F77F1">
            <ModalBody pb={6}></ModalBody>
          </Box>
        </ModalContent>
      </Modal>
      <MainTab />
      <Flex justify="space-between">
        <HStack w="60%" spacing="24px">
          <Box w="300px" h="10"></Box>
        </HStack>

        <HStack w="30%" spacing="10px" justify="end"></HStack>
      </Flex>

      <PrimaryTable data={data} columns={columns} />
      <Modal isOpen={addFaqsModal.isOpen} onClose={addFaqsModal.onClose}>
        <ModalOverlay />

        <ModalContent>
          <Box borderLeftWidth="3px" borderColor="#7F77F1">
            <form onSubmit={handleSubmitFaqs(onCreateFaqs)}>
              <ModalHeader>Creating FAQs</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel>Faqs Questions</FormLabel>
                  <Input
                    id="faqsTitle"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    name="question	"
                    placeholder="faqs Title"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createFaqsForm.register(`question`, {
                      required: "faqs Question is required",
                    })}
                  />

                  {errors.question && (
                    <Text color="red">
                      {errors.question.message.toString()}
                    </Text>
                  )}
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Answer</FormLabel>
                  <Input
                    id="answer"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    name="answer"
                    placeholder="Answer"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createFaqsForm.register(`answer`, {
                      required: "Answer is required",
                    })}
                  />

                  {errors.answer && (
                    <Text color="red">{errors.answer.message.toString()}</Text>
                  )}
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </Box>
        </ModalContent>
      </Modal>

      <AlertBox
        cancelRef={cancelRef}
        alert={alert}
        header="Delete Comments?"
        alertBody="Are you sure you want to delete this comment"
        onDelete={onDeleteFaqs}
      />
    </Box>
  );
}
