import {
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useToast,
} from "@chakra-ui/react";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { sendGeneralNotification } from "reduxtoolkit/reducers/notificationSlice";

const SendFilteredNotification = ({
  grades,
  regions,
  isOpen,
  notifications,
  sendNotificationModal,
  setLoading,
}: any) => {
  const sendNotificationForm = useForm();
  const toast = useToast();
  const {
    handleSubmit: handleSubmitNotification,
    register,
    reset,
  } = sendNotificationForm;

  const dispatch = useDispatch();

  const onSendNotification = (values: any) => {
    const tmp = {
      region: values.region == "all" ? null : values.region,
      gender: values.gender == "all" ? null : values.gender,
      grade: values.grade == "all" ? null : values.grade,
      notificationId: values.notificationId,
    };
    setLoading(true);
    dispatch(sendGeneralNotification(tmp)).then((res: any) => {
      if (res.type == "admin/sendgeneralnotification/fulfilled") {
        toast({
          title: "Success",
          description: `${res.payload.data.message}`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        reset();
        setLoading(false);
        sendNotificationModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${res.payload.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  return (
    <Modal
      isOpen={sendNotificationModal.isOpen}
      onClose={sendNotificationModal.onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmitNotification(onSendNotification)}>
          <ModalHeader>Send Notification</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl mt={4}>
              <FormLabel>Grade</FormLabel>
              <Select
                className="select-input"
                placeholder="select Grade"
                {...sendNotificationForm.register("grade", {})}
                _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
                variant="filled"
              >
                <option key="all" value="all">
                  All
                </option>
                {grades?.map((grade: any) => (
                  <option key={grade.id} value={grade.grade}>
                    {grade.grade}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Gender</FormLabel>
              <Select
                className="select-input"
                placeholder="select Gender"
                {...sendNotificationForm.register("gender", {})}
                _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
                variant="filled"
              >
                <option key="all" value="all">
                  All
                </option>
                <option key="female" value="FEMALE">
                  FEMALE
                </option>
                <option key="male" value="MALE">
                  MALE
                </option>
              </Select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Region</FormLabel>
              <Select
                className="select-input"
                placeholder="select Region"
                {...sendNotificationForm.register("region", {})}
                _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
                variant="filled"
              >
                <option key="all" value="all">
                  All
                </option>
                {regions?.map((region: any) => (
                  <option key={region.id} value={region.region}>
                    {region.region}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Notification</FormLabel>
              <Select
                className="select-input"
                placeholder="select Notification"
                {...sendNotificationForm.register("notificationId", {})}
                _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
                variant="filled"
              >
                {notifications?.map((notification: any) => (
                  <option key={notification.id} value={notification.id}>
                    {notification.notification}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <PrimaryButton type="submit">SEND</PrimaryButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
export { SendFilteredNotification };
