import { createColumnHelper } from "@tanstack/react-table";
// Custom components
import { AddIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  Select,
  Switch,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { PrimaryLabel } from "components/Atoms/Label/Labels";
import TableContent from "components/Atoms/Label/TableCell";
import TableHeader from "components/Atoms/Label/TableHeader";
import AlertBox from "components/Molecules/Alerts/AlertBox";
import { SearchBar } from "components/Molecules/navbar/searchBar/SearchBar";
import PrimaryTable from "components/Molecules/Tables/PrimaryTable";
import * as React from "react";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useForm } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import { FaAmazonPay } from "react-icons/fa6";
import { MdDelete, MdPrint } from "react-icons/md";
// Custom components
import { usePagination } from "@ajna/pagination";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import { MainPagination } from "components/Molecules/Pagination";
import UserManualPayment from "components/Templates/userManagement/usersManualPaymentForm";
import { useDispatch, useSelector } from "react-redux";
import { getGrades } from "reduxtoolkit/reducers/gradeSlice";
import { getRegions } from "reduxtoolkit/reducers/regionSlice";
import {
  createUser,
  deleteUser,
  getUsers,
  searchUsers,
  updateUser
} from "reduxtoolkit/reducers/userSlice";
import { gradeType, regionType } from "types/examTypes";
import CreateUsersForm from "../Templates/userManagement/createUsersForm";
import EditUsersForm from "../Templates/userManagement/editUsersForm";

type RowObj = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  region: regionType;
  gender: string;
  grade: gradeType;
  registeredBy: string;
  actions: any;
  isActive: boolean;
  isVerified: boolean;
  subscriptionStatus: string;
};

const columnHelper = createColumnHelper<RowObj>();

export default function ComplexTable() {
  const [usersTotal, setUsersTotal] = useState<number | undefined>(undefined);
  const usersData: any = useSelector((state: any) => state.users);
  const toast = useToast();
  const dispatch = useDispatch();
  const deleteIconColor = useColorModeValue("red.600", "white");
  const createUserModal = useDisclosure();
  const editUserModal = useDisclosure();
  const manualPaymentModal = useDisclosure();
  const editUserForm = useForm();
  const { reset } = editUserForm;
  const textColorSecondary = "gray.400";
  const [regions, setRegions] = useState([]);

  const onCreateUser = (values: any) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      region: values.region,
      password: values.password,
      gender: values.gender,
      grade: values.grade,
    };
    dispatch(createUser(data)).then((data: any) => {
      if (data.type === "user/createUser/fulfilled") {
        toast({
          title: "Success",
          description: "user Created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
    createUserModal.onClose();
  };
  const onEditUser = (values: any) => {
    const data = {
      id: id,
      payload: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phoneNumber: values.phoneNumber,
        password: values.password,
        grade: values.grade,
        region: values.region,
        gender: values.gender,
      },
    };

    dispatch(updateUser(data)).then((data: any) => {
      if (data.type === "user/updateData/fulfilled") {
        toast({
          title: "Success",
          description: "user Updated Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setData(usersData?.data?.users);
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });

    editUserModal.onClose();
  };

  const onDeleteUser = () => {
    dispatch(deleteUser(id)).then((data: any) => {
      if (data.type === "user/deleteData/fulfilled") {
        toast({
          title: "Success",
          description: "User Deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
    alert.onClose();
  };

  const outerLimit = 2;
  const innerLimit = 2;
  const {
    pages,
    pagesCount,
    currentPage,
    setCurrentPage,
    setIsDisabled,
    isDisabled,
    pageSize,
    setPageSize,
  } = usePagination({
    total: usersTotal,
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  });

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };
  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const pageSize = Number(event.target.value);

    setPageSize(pageSize);
  };
  const handleBackPagination = (current: any) => {
    if (current > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleForwardPagination = (current: any) => {
    if (current < pagesCount) setCurrentPage(currentPage + 1);
  };

  const handleEditUser = (value: any) => {
    const data = value.original;
    setId(data.id);
    reset({
      id: data.id,
      firstName: data.firstName,
      middleName: data.middleName,
      lastName: data.lastName,
      email: data.email,
      region: data.region?.region,
      gender: data.gender,
      grade: data.grade?.grade,
      phoneNumber: data.phoneNumber,
    });
    editUserModal.onOpen();
  };
  const handleManualPayment = (value: any) => {
    setSelectedUser(value.original);

    manualPaymentModal.onOpen();
  };
  const alert = useDisclosure();
  const cancelRef = React.useRef();
  const handleDeleteUser = (value: any) => {
    setId(value.id);
    alert.onOpen();
  };

  const [id, setId] = useState<any>("");
  const [grades, setGrades] = useState([]);
  const [gradesParam, setGradesParam] = useState<any>();
  const [subscriptionStatus, setSubscriptionStatus] = useState<any>();
  const [regionsParam, setRegionsParam] = useState<any>();
  const [genderParam, setGenderParam] = useState<any>();

  useEffect(() => {

    dispatch(getRegions()).then((data: any) => {
      setRegions(data.payload);
    });
    dispatch(getGrades()).then((data: any) => {
      setGrades(data.payload);
    });
  }, []);
  useEffect(() => {
    let params = {
      region: regionsParam,
      grade: gradesParam,
      gender: genderParam,
      paymentStatus: subscriptionStatus,
      page: currentPage,
      limit: pageSize,
    };
    dispatch(getUsers(params)).then((data: any) => {
      setUsersTotal(data.payload.totalCount);

      setData(data.payload?.users);
    });
  }, [currentPage, pageSize, gradesParam, subscriptionStatus, genderParam, regionsParam]);

  const columns = [
    columnHelper.accessor("firstName", {
      id: "firstName",
      header: () => <TableHeader title="FIRST NAME" />,
      cell: (info: any) => <TableContent title={info.getValue()} />,
    }),
    columnHelper.accessor("lastName", {
      id: "lastName",
      header: () => <TableHeader title="LAST NAME" />,
      cell: (info) => <TableContent title={info.getValue()} />,
    }),

    columnHelper.accessor("phoneNumber", {
      id: "phoneNumber",
      header: () => <TableHeader title="PHONE NUMBER" />,
      cell: (info) => <TableContent title={info.getValue()} />,
    }),

    columnHelper.accessor("email", {
      id: "email",
      header: () => <TableHeader title="EMAIL" />,
      cell: (info) => <TableContent title={info.getValue()} />,
    }),
    columnHelper.accessor("region", {
      id: "region",
      header: () => <TableHeader title="REGION" />,
      cell: (info) => (
        <TableContent title={info.row.original?.region?.region} />
      ),
    }),
    columnHelper.accessor("gender", {
      id: "gender",
      header: () => <TableHeader title="GENDER" />,
      cell: (info) => <TableContent title={info.getValue()} />,
    }),
    columnHelper.accessor("grade", {
      id: "grade",
      header: () => <TableHeader title="GRADE" />,
      cell: (info) => <TableContent title={info.row.original?.grade?.grade} />,
    }),
    columnHelper.accessor("isActive", {
      id: "isActive",
      header: () => <TableHeader title="Status" />,
      cell: (info) => (
        <>
          <Switch size="md" isChecked={info.row.original.isActive} />
        </>
      ),
    }),
    columnHelper.accessor("isVerified", {
      id: "isVerified",
      header: () => <TableHeader title="isVerified" />,
      cell: (info) => (
        <>
          <Badge
            mx={2}
            bg={info.row?.original?.isVerified ? "#198D48" : "#EA474E"}
            color={"#FFFFFF"}
          >
            {info.row?.original?.isVerified ? "active" : "incative"}{" "}
          </Badge>
        </>
      ),
    }),
    columnHelper.accessor("subscriptionStatus", {
      id: "subscriptionStatus",
      header: () => <TableHeader title="subscriptionStatus" />,
      cell: (info) => (
        <>
          <Badge
            mx={2}
            bg={info.row?.original?.isVerified ? "#198D48" : "#EA474E"}
            color={"#FFFFFF"}
          >
            {info.row?.original?.subscriptionStatus}
          </Badge>

        </>
      ),
    }),

    columnHelper.accessor("actions", {
      id: "actions",
      header: () => <TableHeader title="Actions" />,
      cell: (info) => (
        <>
          <Flex justify="space-evenly">
            <Icon
              color={textColorSecondary}
              as={FaAmazonPay}
              onClick={() => handleManualPayment(info.row)}
            />
            <Icon
              color={textColorSecondary}
              as={FaEdit}
              onClick={() => handleEditUser(info.row)}
            />
            <Icon
              color={deleteIconColor}
              as={MdDelete}
              onClick={() => handleDeleteUser(info.row.original)}
            />
          </Flex>
        </>
      ),
    }),
  ];
  const handleSearchAdmin = (values: any) => {
    dispatch(searchUsers(values)).then((data: any) => {
      setData(data.payload?.users);
    });
  };
  const [selectedUser, setSelectedUser] = useState([]);
  const [data, setData] = useState<any>([]);
  return (
    <Box
      borderRadius="0px"
      flexDirection="column"
      w="100%"
      px="5px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <CreateUsersForm
        {...{ createUserModal, onCreateUser, grades, regions }}
      />
      <UserManualPayment {...{ manualPaymentModal, selectedUser }} />
      <EditUsersForm
        {...{ editUserModal, onEditUser, grades, regions, editUserForm }}
      />
      <Box bg="white">
        <PrimaryLabel py="5px"> Filters</PrimaryLabel>
        <Divider borderWidth="1px" borderColor="#D9D9D9" my={2} />
        <Flex my={4} justify="space-between">
          <HStack spacing="24px">
            <Box h="10">
              <SearchBar
                placeholder="Search by First Name Or Phone"
                handle={handleSearchAdmin}
              />
            </Box>
            <Box h="10">
              <Select
                className=""
                borderRadius="0px"
                variant="filled"
                placeholder="Status"
                fontSize={{ sm: "9px", md: "10px", lg: "14" }}
                size="sm"
                onChange={(e: any) => setSubscriptionStatus(e.target.value)}
              >
                <option key="success" value='success'>Success</option>
                <option key="rejected" value='rejected'>Rejected</option>
                <option key="pending" value='pending'>Pending</option>

              </Select>
            </Box>
            <Box h="10">
              <Select
                className=""
                borderRadius="0px"
                variant="filled"
                placeholder="Select Grades"
                fontSize={{ sm: "9px", md: "10px", lg: "14" }}
                size="sm"
                onChange={(e: any) => setGradesParam(e.target.value)}
              >
                {grades?.map((grade: any) => (
                  <option key={grade.id}>{grade.grade}</option>
                ))}
              </Select>
            </Box>
            <Box h="10">
              <Select
                borderRadius="0px"
                variant="filled"
                placeholder="Select Region"
                size="sm"
                fontSize={{ sm: "10px", md: "10px", lg: "14" }}
                onChange={(e: any) => setRegionsParam(e.target.value)}
              >
                {regions.map((region: any) => (
                  <option key={region.id}>{region.region}</option>
                ))}
              </Select>
            </Box>
            <Box w="150px" h="10">
              <Select
                borderRadius="0px"
                size="sm"
                variant="filled"
                onChange={(e: any) => setGenderParam(e.target.value)}
                placeholder="Select Gender"
              >
                <option value="FEMALE">Female</option>
                <option value="MALE">Male</option>
              </Select>
            </Box>
            <Box>
              <PrimaryButton
                onClick={() => {
                  setGenderParam(null);
                  setRegionsParam(null);
                  setGradesParam(null);
                  setSubscriptionStatus(null);
                }}
              >
                Reset
              </PrimaryButton>
            </Box>
          </HStack>

          <HStack w="30%" justify="end">
            <Box h="10">
              <Button
                bg="#0076FD"
                fontWeight="500"
                color="white"
                borderRadius="0"
                fontSize={{ sm: "9px", md: "10px", lg: "14" }}
                size="sm"
                onClick={createUserModal.onOpen}
              >
                <AddIcon w={4} h={4} px="1px" color="white" />
                Add New User
              </Button>
            </Box>
            <Box px="10px" h="10">
              <CSVLink data={data} filename="results.csv" target="_blank">
                <Button
                  borderRadius="0"
                  bg="#71737D"
                  color="#FFFFFF"
                  onClick={() => { }}
                >
                  <Icon color="#FFFFFF" size="large" as={MdPrint} />
                  Print
                </Button>
              </CSVLink>
            </Box>
          </HStack>
        </Flex>
      </Box>

      <Box>
        <PrimaryTable id="users" data={data} columns={columns} />

        <Flex w="100%" py="10">
          <MainPagination
            pagesCount={pagesCount}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            handleBackPagination={handleBackPagination}
            handleForwardPagination={handleForwardPagination}
            pages={pages}
            handlePageSizeChange={handlePageSizeChange}
          />
        </Flex>
        <AlertBox
          cancelRef={cancelRef}
          alert={alert}
          header="Delete User?"
          alertBody="Are you sure you want to delete this user"
          onDelete={onDeleteUser}
        />
      </Box>
    </Box>
  );
}
