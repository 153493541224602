import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from 'utils/axiosMiddleware';


export const getYear:any =  createAsyncThunk(
  "year/getData",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
      
    try {
      const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/year/year`,{headers: { Authorization: `Bearer ${token}` },
      },);
      
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  })

  export const updateYear:any =  createAsyncThunk(
    "year/updateData",
    async (arg:any, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
      try {
        const data = await axios({
          method: 'put',
          url: `${process.env.REACT_APP_API_URL}/year/update/${arg.id}`,
          headers: { Authorization: `Bearer ${token}` },
          data: arg.payload,
          
      });
      return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })

    export const deleteYear:any =  createAsyncThunk(
      "year/deleteData",
      async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
        try {
     
          const data = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/year/delete/${arg}`,
            headers: { Authorization: `Bearer ${token}` },
        });
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      })
      export const createYear:any =  createAsyncThunk(
        "admin/createYear",
        async (arg, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';  
          try {   
            const data:any = await axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URL}/year/create`,
              headers: { Authorization: `Bearer ${token}` },
              data: arg,
          });
          return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })

const yearSlice:any = createSlice({
  name: "year",
  initialState: {
    data: [],
    isSuccess: false,
    message: "",
    loading: false,
  },
  reducers: {
       

  },
  extraReducers: {
    [getYear.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getYear.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getYear.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [createYear.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createYear.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      
      state.data.push(payload.data.year);
      state.isSuccess = true;
    },
    [createYear.rejected]: (state, { payload }) => {
      state.message = payload.data;
      state.loading = false;
      state.isSuccess = false;
    },
    [updateYear.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateYear.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      
       const index = state.data.findIndex((year:any) => year.id === payload.data.year.id);
             if (index !== -1) {
            
           state.data[index] = payload.data.year;
         }
      state.isSuccess = true;
    },
    [updateYear.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [deleteYear.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteYear.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
  
      state.data = state.data.filter((year:any) => year.year !== payload.data.year);
      state.isSuccess = true;
    },
    [deleteYear.rejected]: (state:any, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
  },
});

export default yearSlice;
