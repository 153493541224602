// Chakra imports
import { Flex, Input } from '@chakra-ui/react';
import { CustomLabel } from '../Label/Labels';
// Custom components

export default function Default(props: {
	form: any,
	label: string;
	placeholder: string;
	type: string;
}) {
	const { form,label, placeholder, type, ...rest } = props;
	// Chakra Color Mode
	

	return (
		<Flex direction='column' mb='30px'>
			<CustomLabel>
			{label}
			</CustomLabel>
			<Input
			id='email'
			{...rest}
			fontWeight='500'
			variant='main'
			h='44px'
			maxH='44px' 
			type={type}

			placeholder={placeholder}
			_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
			{ ...form.register('email', {
				required: 'Email is required',
				
				minLength: { value: 0, message: 'Minimum length should be 4' },
			})}
			/>
			
		</Flex>
	);
}
