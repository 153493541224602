import { Box, TabPanels, Tabs } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getGrades } from "reduxtoolkit/reducers/gradeSlice";

const MainTab=(props:any)=>
{
  const dispatch = useDispatch();
  const [grades, setGrades] = useState([]);
  useEffect(()=>{
    dispatch(getGrades()).then((data:any)=>{
      
      setGrades(data.payload)});
    getGrades();
  },[])
return (
  <Box pb={{ base: '40px', md: '30px', xl: '10px' }}>
    <Tabs>
         {props.headers}
          
          {props?.filters}
          <TabPanels>
           
              {props.children}
          </TabPanels>
</Tabs>
</Box>
)
}
export default MainTab;