import {
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useToast
} from "@chakra-ui/react";
import { createTheme } from "@mui/material/styles";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import DateTimePicker from "components/Atoms/CustomeDateTimePicker";
import TextInput from "components/Atoms/fields/TextInput";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { sendGeneralPushNotification } from "reduxtoolkit/reducers/notificationSlice";

const theme = createTheme({
  transitions: {
    duration: {
      enteringScreen: 2000,
      leavingScreen: 100,
    },
  },
});

const SendPushNotification = ({
  grades,
  regions,
  isOpen,
  setLoading,
  sendPushNotificationModal,
}: any) => {
  const sendNotificationForm = useForm();
  const toast = useToast();
  const {
    handleSubmit: handleSubmitNotification,
    register,
    reset,
    formState: { errors },
    control,
  } = sendNotificationForm;
  const [dateTime, setDateTime] = useState("");
  const dispatch = useDispatch();
  const [onlineTimeBefore, setOnlineTimeBefore] = useState();

  const onSendNotification = (values: any) => {
    const tmp = {
      region: values.region == "all" ? null : values.region,
      gender: values.gender == "all" ? null : values.gender,
      grade: values.grade == "all" ? null : values.grade,
      onlineTimeBefore: onlineTimeBefore,
      title: values.title,
      body: values.body,
    };
    
    setLoading(true);
    dispatch(sendGeneralPushNotification(tmp)).then((res: any) => {
      if (res.type == "admin/sendgeneralpushnotification/fulfilled") {
        toast({
          title: "Success",
          description: `${res.payload.data.message}`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        reset();
        setLoading(false);
        sendPushNotificationModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${res.payload.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <Modal
      isOpen={sendPushNotificationModal.isOpen}
      onClose={sendPushNotificationModal.onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={handleSubmitNotification(onSendNotification)}>
          <ModalHeader>Send Notification</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl mt={4}>
              <FormLabel>Grade</FormLabel>
              <Select
                className="select-input"
                placeholder="select Grade"
                {...sendNotificationForm.register("grade", {})}
                _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
                variant="filled"
              >
                <option key="all" value="all">
                  All
                </option>
                {grades?.map((grade: any) => (
                  <option key={grade.id} value={grade.grade}>
                    {grade.grade}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Gender</FormLabel>
              <Select
                className="select-input"
                placeholder="select Gender"
                {...sendNotificationForm.register("gender", {})}
                _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
                variant="filled"
              >
                <option key="all" value="all">
                  All
                </option>
                <option key="female" value="FEMALE">
                  FEMALE
                </option>
                <option key="male" value="MALE">
                  MALE
                </option>
              </Select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Region</FormLabel>
              <Select
                className="select-input"
                placeholder="select Region"
                {...sendNotificationForm.register("region", {})}
                _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
                variant="filled"
              >
                <option key="all" value="all">
                  All
                </option>
                {regions?.map((region: any) => (
                  <option key={region.id} value={region.region}>
                    {region.region}
                  </option>
                ))}
              </Select>
            </FormControl>
            <TextInput
              id="title"
              label="Title"
              type="text"
              control={control}
              validation={{
                required: "Title is required.",
              }}
              errorMessage="Please enter Title"
            />
            {errors.title && (
              <Text color="red">{errors.title.message.toString()}</Text>
            )}
            <FormControl mt={4}>
              <FormLabel>Body</FormLabel>
              <Textarea
                id="body"
                size="sm"
                placeholder="Enter notification body"
                _placeholder={{
                  fontWeight: "400",
                  color: "secondaryGray.600",
                }}
                {...sendNotificationForm.register("body", {})}
              />

              {errors.body && (
                <Text color="red">{errors.body.message.toString()}</Text>
              )}
            </FormControl>
            <FormControl id="date">
              <FormLabel>Online Time Before</FormLabel>

              <DateTimePicker setTimestampValue={setOnlineTimeBefore} />
            </FormControl>
            {errors.body && (
              <Text color="red">{errors.body.message.toString()}</Text>
            )}
          </ModalBody>
          <ModalFooter>
            <PrimaryButton type="submit">SEND</PrimaryButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};
export { SendPushNotification };

