// Chakra imports
import { AddIcon } from "@chakra-ui/icons";
import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack,
  Wrap
} from "@chakra-ui/react";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import VideoPreview from "components/Molecules/VideoPreview";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createexamVersion,
  deleteexamVersion,
  getexamVersion,
  updateexamVersion
} from "reduxtoolkit/reducers/examVersionSlice";
import {
  createSection,
  deleteSection,
  getSection,
  updateSection
} from "reduxtoolkit/reducers/sectionSlice";
import {
  createUnit,
  deleteUnit,
  getUnits,
  updateUnit
} from "reduxtoolkit/reducers/unitSlice";

import { createColumnHelper } from "@tanstack/react-table";
import { PrimaryLabel } from "components/Atoms/Label/Labels";
import AlertBox from "components/Molecules/Alerts/AlertBox";
import CustomCard from "components/Molecules/card/Card";
import MultipleInput from "components/Molecules/multipleInputText";
import { FaEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import {
  getGuestTrialPeriod,
  getTrialPeriod,
  updateGuestTrialVersion,
  updateTrialVersion
} from "reduxtoolkit/reducers/adminSlice";
import {
  createContactus,
  deleteContactus,
  getcontactus,
  updateContactUs
} from "reduxtoolkit/reducers/contactUsSlice";
import { createGrade } from "reduxtoolkit/reducers/gradeSlice";
import {
  getTrialVersionDate,
  updateTrialVersionDate
} from "reduxtoolkit/reducers/paymentSlice";
import {
  createUserGuide,
  deleteUserGuide,
  getUserGuide,
  updateUserGuide
} from "reduxtoolkit/reducers/userGuideSlice";
import {
  createYear,
  deleteYear,
  getYear,
  updateYear
} from "reduxtoolkit/reducers/yearSlice";
// Assets
type RowObj = {
  unit: string;

  actions: any;
};

type sectionsRowObj = {
  section: string;

  actions: any;
};
type examsRowObj = {
  examVersion: string;

  actions: any;
};

const columnHelper = createColumnHelper<RowObj>();
const examcolumhHelper = createColumnHelper<examsRowObj>();
const sectioncolumnHelper = createColumnHelper<sectionsRowObj>();
const textColor = "gray.400";

export default function Overview() {
  const tableCellColor = useColorModeValue("navy.700", "white");
  const [id, setId] = useState<any>("");

  const [trialPeriod, setTrialPeriod] = useState([]);
  const [guestTrialVersionDate, setGuestTrialVersionDate] = useState([]);

  const [trialVersionDate, setTrialVersionDate] = useState([]);

  const createUnitForm = useForm();
  const { handleSubmit: handleSubmitUnit } = createUnitForm;
  const editUserGuideForm = useForm();
  const { handleSubmit: handleSubmitEditUserGuide, reset: resetUserGuide } =
    editUserGuideForm;
  const createYearorm = useForm();
  const { handleSubmit: handleSubmitYear } = createYearorm;

  const createExamVersionForm = useForm();
  const { handleSubmit: handleSubmitExamVersion } = createExamVersionForm;

  const createSectionForm = useForm();
  const { handleSubmit: handleSubmitSection } = createSectionForm;

  const editUnitform = useForm();
  const { handleSubmit: handleSubmitEditUnit, reset, getValues } = editUnitform;

  const editYearForm = useForm();
  const { handleSubmit: handleSubmitEditYear, reset: resetYear } = editYearForm;

  const editExamVersionForm = useForm();
  const { handleSubmit: handleSubmitEditExamVersion, reset: resetExamVersion } =
    editExamVersionForm;

  const contactEditform = useForm();
  const { handleSubmit: handleSubmitEditcontact, reset: resetContact } =
    contactEditform;
  const editSectinForm = useForm();
  const editTrialForm = useForm();
  const editTrialVersionDateForm = useForm();
  const editGuestTrialVersionDateForm = useForm();
  const {
    handleSubmit: handleSubmitEditGuestTrialVersion,
    reset: resetGustTrialVersion,
  } = editGuestTrialVersionDateForm;
  const { handleSubmit: handleSubmitEditSection, reset: resetSection } =
    editSectinForm;
  const { handleSubmit: handleSubmitEditTrial, reset: resetTrial } =
    editTrialForm;
  const {
    handleSubmit: handleSubmitEditTrialVersionDate,
    reset: resetTrialVersionDate,
  } = editTrialVersionDateForm;
  const onCreateUnit = (values: any) => {
    const data = {
      unit: values.unit,
    };

    dispatch(createUnit(data));
  };
  const oncreateYear = (values: any) => {
    const data = {
      year: values.year,
    };

    dispatch(createYear(data)).then((data: any) => {
      if (data.type === "admin/createYear/fulfilled") {
        toast({
          title: "Success",
          description: "Year Created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        createYearModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const onCreateExamVersion = (values: any) => {
    const data = {
      examVersion: values.exam,
    };

    dispatch(createexamVersion(data)).then((data: any) => {
      if (data.type === "admin/createExamVersion/fulfilled") {
        toast({
          title: "Success",
          description: "Exam Version Created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        deleteContactAlert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const onCreateSection = (values: any) => {
    const data = {
      section: values.section,
    };
    dispatch(createSection(data)).then((data: any) => {
      if (data.type === "admin/createSection/fulfilled") {
        toast({
          title: "Success",
          description: "Section Created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        deleteContactAlert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const onEditTrial = (values: any) => {
    const data = {
      id: id,
      payload: {
        message: values.message,
        updateUrl: values.updateUrl,
        remainingDays: values.remainingDays,
      },
    };
    dispatch(updateTrialVersion(data)).then((data: any) => {
      if (data.type === "admin/updateTrial/fulfilled") {
        toast({
          title: "Success",
          description: "Trial Version Updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        dispatch(getTrialPeriod()).then((data: any) => {
          setTrialPeriod(data.payload);
        });

        editTrialModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const onEditGuestTrialVersion = (values: any) => {
    const data = {
      id: id,
      payload: Number(values.duration),
    };

    dispatch(updateGuestTrialVersion(data)).then((data: any) => {
      
      if (data.type === "payment/updateGuestTrialVersion/fulfilled") {
        toast({
          title: "Success",
          description: "Guest Trial Version Duration Updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        dispatch(getGuestTrialPeriod()).then((data: any) => {
          setGuestTrialVersionDate(data.payload);
        });

        editGuestTrialVersionDateModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const onEditTrialVersionDate = (values: any) => {
    const data = {
      id: id,
      payload: {
        duration: Number(values.duration),
      },
    };
    dispatch(updateTrialVersionDate(data)).then((data: any) => {
      if (data.type === "payment/updateTrialversionDate/fulfilled") {
        toast({
          title: "Success",
          description: "Trial Version Duration Updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        dispatch(getTrialVersionDate()).then((data: any) => {
          setTrialVersionDate(data.payload);
        });

        editTrialVersionDateModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const onEditSection = (values: any) => {
    const data = {
      id: id,
      payload: {
        section: values.section,
      },
    };
    dispatch(updateSection(data)).then((data: any) => {
      if (data.type === "section/updateData/fulfilled") {
        toast({
          title: "Success",
          description: "Section Updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        deleteContactAlert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const onEditExamVersion = (values: any) => {
    const data = {
      id: id,
      payload: {
        examVersion: values.examVersion,
      },
    };
    dispatch(updateexamVersion(data)).then((data: any) => {
      if (data.type === "examVersion/updateData/fulfilled") {
        toast({
          title: "Success",
          description: "Exam Version Updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        deleteContactAlert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const onEditYear = (values: any) => {
    const data = {
      id: id,
      payload: {
        year: values.year,
      },
    };
    dispatch(updateYear(data)).then((data: any) => {
      if (data.type === "year/updateData/fulfilled") {
        toast({
          title: "Success",
          description: "Year Updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        deleteContactAlert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const onEditUnits = (values: any) => {
    const data = {
      id: id,
      payload: {
        unit: values.unit,
      },
    };
    dispatch(updateUnit(data)).then((data: any) => {
      if (data.type === "unit/updateData/fulfilled") {
        toast({
          title: "Success",
          description: "Year Updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        deleteContactAlert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const ondeleteYear = (value: any) => {
    dispatch(deleteYear(id)).then((data: any) => {
      if (data.type === "year/deleteData/fulfilled") {
        toast({
          title: "Success",
          description: "Year Deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        deleteContactAlert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const ondeleteUserGuide = (value: any) => {
    dispatch(deleteUserGuide(id)).then((data: any) => {
      if (data.type === "UserGuide/deleteData/fulfilled") {
        toast({
          title: "Success",
          description: "User Guide Deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        deleteUserGuideAlert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const onDeleteContact = (value: any) => {
    dispatch(deleteContactus(id)).then((data: any) => {
      if (data.type === "contactus/deleteData/fulfilled") {
        toast({
          title: "Success",
          description: "Contact Deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        deleteContactAlert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const onDeleteUnit = (value: any) => {
    dispatch(deleteUnit(id)).then((data: any) => {
      if (data.type === "unit/deleteData/fulfilled") {
        toast({
          title: "Success",
          description: "Unit Deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        createContactUsModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const onDeleteSection = (value: any) => {
    dispatch(deleteSection(id)).then((data: any) => {
      if (data.type === "section/deleteData/fulfilled") {
        toast({
          title: "Success",
          description: "Section Deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        alert1.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const onDeleteExamVersion = (value: any) => {
    dispatch(deleteexamVersion(id)).then((data: any) => {
      if (data.type === "examVersion/deleteDatass/fulfilled") {
        toast({
          title: "Success",
          description: "Exam Version deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        createContactUsModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const form3 = useForm();
  const { handleSubmit: handleSubmit3 } = form3;
  const contactform = useForm();
  const { handleSubmit: handleSubmitcontact } = contactform;

  const userGuideform = useForm();
  const { handleSubmit: handleSubmitUserGuide } = userGuideform;

  const toast = useToast();
  const createGradeModal = useDisclosure();
  const createContactUsModal = useDisclosure();
  const createUserGuideModal = useDisclosure();

  const onEditUserGuide = (values: any) => {
    const data = {
      id: id,
      payload: {
        title: values.title,
        description: values.description,
        videoLink: values.videoLink,
        mobileVideoLink: values.videoLink,
      },
    };
    dispatch(updateUserGuide(data)).then((res: any) => {
      if (res.type === "UserGuide/updateData/fulfilled") {
        toast({
          title: "Success",
          description: "Contact Updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        editUserGuideModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${res.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const onEditContact = (values: any) => {
    const data = {
      id: id,
      payload: {
        name: values.name,
        email: [values.email],
        phoneNumber: phoneNumbers,
        website: [values.website],
        telegram: [values.telegram],
      },
    };
    dispatch(updateContactUs(data)).then((res: any) => {
      if (res.type === "contactus/updateData/fulfilled") {
        toast({
          title: "Success",
          description: "Contact Updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        editContactModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${res.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const onCreateContact = (values: any) => {
    const data = {
      name: values.name,
      email: [values.email],
      phoneNumber: phoneNumbers,
      website: [values.website],
      telegram: [values.telegram],
    };
    dispatch(createContactus(data)).then((res: any) => {
      if (res.type === "admin/createContactUs/fulfilled") {
        toast({
          title: "Success",
          description: "Contact created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        createContactUsModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${res.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const onCreateUserGuide = (values: any) => {
    const data = {
      title: values.title,
      description: values.description,
      videoLink: values.videoLink,
      mobileVideoLink: values.videoLink,
    };
    dispatch(createUserGuide(data)).then((res: any) => {
      if (res.type === "admin/createUserGuide/fulfilled") {
        toast({
          title: "Success",
          description: "User Guide created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        createUserGuideModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${res.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const onCreateGrade = (values: any) => {
    const data = {
      grade: values.grade,
    };

    dispatch(createGrade(data)).then((res: any) => {
      if (res.type === "admin/createGrade/fulfilled") {
        toast({
          title: "Success",
          description: "Grade Updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: `${res.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const handleEditTrial = (value: any) => {
    setId(value.id);
    const data = value;
    resetTrial({
      message: data.message,
      updateUrl: data.updateUrl,
      remainingDays: data.remainingDays,
    });
    editTrialModal.onOpen();
  };
  const handleEditTrialVersionDate = (value: any) => {
    setId(value.id);
    const data = value;
    resetTrialVersionDate({
      duration: data.duration,
    });
    editTrialVersionDateModal.onOpen();
  };
  const handleEditGuestTrialVersion = (value: any) => {
    setId(value.id);
    const data = value;
    resetGustTrialVersion({
      duration: data.duration,
    });
    editGuestTrialVersionDateModal.onOpen();
  };
  const handleEditSection = (value: any) => {
    setId(value.id);
    const data = value;
    resetSection({
      section: data.section,
    });
    editSectionModal.onOpen();
  };
  const handleEditExamVersion = (value: any) => {
    setId(value.id);
    const data = value;
    resetExamVersion({
      examVersion: data.examVersion,
    });
    editExamVersionModal.onOpen();
  };

  const handleEditUnits = (value: any) => {
    setId(value.id);
    const data = value;
    reset({
      unit: data.unit,
    });
    editUnitModal.onOpen();
  };

  const handleEditYear = (value: any) => {
    setId(value.id);

    const data = value;
    resetYear({
      year: data.year,
    });
    editYearModal.onOpen();
  };

  const alert = useDisclosure();
  const alert1 = useDisclosure();
  const alert2 = useDisclosure();
  const alert3 = useDisclosure();
  const deleteContactAlert = useDisclosure();
  const deleteUserGuideAlert = useDisclosure();
  const cancelRef = React.useRef();

  const handleDeleteYear = (value: any) => {
    setId(value);
    alert3.onOpen();
  };
  const handleDeleteUnit = (value: any) => {
    setId(value);
    alert.onOpen();
  };
  const handleEditContact = (values: any) => {
    setId(values.id);

    const data = values;

    resetContact({
      name: data.name,
      website: data.website[0],
      email: data.email[0],
      telegram: data.telegram[0],
      phoneNumber: data.phoneNumber[0],
    });
    editContactModal.onOpen();
  };
  const handleEditUserGuide = (values: any) => {
    setId(values.id);

    const data = values;

    resetUserGuide({
      title: data.title,
      description: data.description,
      videoLink: data.videoLink,
    });
    editUserGuideModal.onOpen();
  };

  const handleDeleteContact = (value: any) => {
    setId(value);
    deleteContactAlert.onOpen();
  };

  const handleDeleteUserGuide = (value: any) => {
    setId(value);
    deleteUserGuideAlert.onOpen();
  };
  const handleDeleteSection = (value: any) => {
    setId(value);
    alert1.onOpen();
  };
  const handleDeleteExamVersion = (value: any) => {
    setId(value);
    alert2.onOpen();
  };

  const [loading, setLoading] = useState(true);
  const gradesData: any = useSelector((state: any) => state.grade);
  const contactsData: any = useSelector((state: any) => state.contact);
  const userGuideData: any = useSelector((state: any) => state.userGuide);
  const subjectsData = useSelector((state: any) => state.subject);
  const unitsData: any = useSelector((state: any) => state.unit);
  const yearsData: any = useSelector((state: any) => state.year);
  const sectiondata: any = useSelector((state: any) => state.section);
  const examVersiondata: any = useSelector((state: any) => state.examVersion);
  const editUnitModal = useDisclosure();
  const createUnitModal = useDisclosure();
  const createSectionModal = useDisclosure();
  const editExamVersionModal = useDisclosure();
  const editSectionModal = useDisclosure();
  const editTrialModal = useDisclosure();
  const editTrialVersionDateModal = useDisclosure();
  const editGuestTrialVersionDateModal = useDisclosure();

  const editContactModal = useDisclosure();
  const editUserGuideModal = useDisclosure();
  const createExamVersionModal = useDisclosure();
  const createYearModal = useDisclosure();

  const editYearModal = useDisclosure();

  const users: any = useSelector((state: any) => state.admin);

  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [years, setYears] = useState<any>([]);
  const [units, setUnits] = useState([]);
  const [sections, setSections] = useState<any>([]);
  const [examVersions, setExamVersion] = useState<any>([]);
  const [contactUs, setContactUs] = useState<any>([]);
  const [userGuide, setUserGuide] = useState<any>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUnits()).then((data: any) => {});
    dispatch(getTrialPeriod()).then((data: any) => {
      setTrialPeriod(data.payload);
    });
    dispatch(getGuestTrialPeriod()).then((data: any) => {
      setGuestTrialVersionDate(data.payload);
    });

    dispatch(getTrialVersionDate()).then((data: any) => {
      setTrialVersionDate(data.payload);
    });
    dispatch(getYear()).then((data: any) => {});
    dispatch(getSection()).then((data: any) => {});
    dispatch(getexamVersion()).then((data: any) => {});

    dispatch(getUserGuide()).then((data: any) => {});
    dispatch(getcontactus()).then((data: any) => {});
  }, []);

  useEffect(() => {
    if (contactsData.isSuccess === true) {
      setContactUs(contactsData.data);
    }
    if (userGuideData.isSuccess === true) {
      setUserGuide(userGuideData.data);
    }
    if (examVersiondata.isSuccess === true) {
      setExamVersion(examVersiondata.data);
    }
    if (sectiondata.isSuccess === true) {
      setSections(sectiondata.data);
    }
    if (yearsData.isSuccess === true) {
      setYears(yearsData.data);
    }
    if (unitsData.isSuccess === true) {
      setUnits(unitsData.data);
    }
  }, [
    contactsData.loading,
    sectiondata.loading,
    examVersiondata.loading,
    yearsData.loading,
    unitsData.loading,
    userGuideData.loading,
  ]);

  const createProgressModal = useDisclosure();
  useEffect(() => {
    if (
      yearsData.loading === true ||
      examVersiondata.loading === true ||
      sectiondata.loading === true ||
      unitsData.loading === true
    ) {
      setLoading(true);
      createProgressModal.onOpen();
    } else {
      setLoading(false);
      createProgressModal.onClose();
    }
  }, [
    examVersiondata.loading,
    sectiondata.loading,
    unitsData.loading,
    yearsData.loading,
  ]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      {loading ? <Progress w="1/2" isIndeterminate /> : null}
      <Modal
        isOpen={createProgressModal.isOpen}
        onClose={createProgressModal.onClose}
      >
        <ModalOverlay />
        <ModalContent bg="transparent" maxW="100%">
          <Box borderLeftWidth="3px" borderColor="#7F77F1">
            <ModalBody pb={6}></ModalBody>
          </Box>
        </ModalContent>
      </Modal>
      <Box></Box>

      <Box>
        <Box border="1px solid gray.700" m={5} p={5}>
          <Box position="relative" padding="10">
            <Divider borderWidth="1px" borderColor="#D9D9D9" />
            <AbsoluteCenter bg="white" px="4">
              Units
            </AbsoluteCenter>
          </Box>

          <HStack style={{ overflow: "scroll" }} spacing="30px">
            <Box>
              <CustomCard bg="#0076FD">
                <Button
                  fontSize={{ sm: "10px", md: "10px", lg: "14" }}
                  bg="#0076FD"
                  fontWeight="500"
                  color="white"
                  borderRadius="0"
                  onClick={createUnitModal.onOpen}
                >
                  <VStack>
                    <AddIcon />
                    <Text> Create Units</Text>
                  </VStack>
                </Button>
              </CustomCard>
            </Box>

            {units.map((unit: any) => {
              return (
                <Box>
                  <CustomCard>
                    <Flex justify="space-evenly">
                      <Icon
                        onClick={() => handleEditUnits(unit)}
                        as={FaEdit}
                        color="gray.400"
                      />
                      <Icon
                        onClick={() => handleDeleteUnit(unit.id)}
                        as={MdDeleteOutline}
                        color="red"
                      />
                    </Flex>
                    <PrimaryLabel w="100%">{unit.unit}</PrimaryLabel>
                  </CustomCard>
                </Box>
              );
            })}
          </HStack>
        </Box>
        <Divider color="black" />
        <Box m={5} p={5}>
          <Box position="relative" padding="10">
            <Divider borderWidth="1px" borderColor="#D9D9D9" />
            <AbsoluteCenter bg="white" px="4">
              Years
            </AbsoluteCenter>
          </Box>
          <HStack style={{ overflow: "scroll" }} spacing="30px">
            <Box>
              <CustomCard bg="#0076FD">
                <Button
                  fontSize={{ sm: "10px", md: "10px", lg: "14" }}
                  bg="#0076FD"
                  fontWeight="500"
                  color="white"
                  borderRadius="0"
                  onClick={createYearModal.onOpen}
                >
                  <VStack>
                    <AddIcon />
                    <Text>Add Years</Text>
                  </VStack>
                </Button>
              </CustomCard>
            </Box>
            {years?.map((year: any) => {
              return (
                <Box>
                  <CustomCard>
                    <Flex justify="space-evenly">
                      <Icon
                        onClick={() => handleEditYear(year)}
                        as={FaEdit}
                        color="gray.400"
                      />
                      <Icon
                        onClick={() => handleDeleteYear(year.id)}
                        as={MdDeleteOutline}
                        color="red"
                      />
                    </Flex>
                    <PrimaryLabel w="100%">{year.year}</PrimaryLabel>
                  </CustomCard>
                </Box>
              );
            })}
          </HStack>
        </Box>
        <Box m={5} p={5}>
          <Box position="relative" padding="10">
            <Divider borderWidth="1px" borderColor="#D9D9D9" />
            <AbsoluteCenter bg="white" px="4">
              Exam version
            </AbsoluteCenter>
          </Box>
          <HStack style={{ overflow: "scroll" }} spacing="30px">
            <Box>
              <CustomCard bg="#0076FD">
                <Button
                  fontSize={{ sm: "10px", md: "10px", lg: "14" }}
                  bg="#0076FD"
                  fontWeight="500"
                  color="white"
                  borderRadius="0"
                  onClick={createExamVersionModal.onOpen}
                >
                  <VStack>
                    <AddIcon />
                    <Text>Add Exam Versions</Text>
                  </VStack>
                </Button>
              </CustomCard>
            </Box>
            {examVersions.map((examVersion: any) => {
              return (
                <Box>
                  <CustomCard>
                    <Flex justify="space-evenly">
                      <Icon
                        onClick={() => handleEditExamVersion(examVersion)}
                        as={FaEdit}
                        color="gray.400"
                      />
                      <Icon
                        onClick={() => handleDeleteExamVersion(examVersion.id)}
                        as={MdDeleteOutline}
                        color="red"
                      />
                    </Flex>
                    <PrimaryLabel w="100%">
                      {examVersion.examVersion}
                    </PrimaryLabel>
                  </CustomCard>
                </Box>
              );
            })}
          </HStack>
        </Box>
        <Divider borderWidth="1px" borderColor="#D9D9D9" />
        <Box border="1px solid gray.700" m={5} p={5}>
          <Box position="relative" padding="10">
            <Divider borderWidth="1px" borderColor="black" />
            <AbsoluteCenter bg="white" px="4">
              Sections
            </AbsoluteCenter>
          </Box>
          <HStack style={{ overflow: "scroll" }} spacing="30px">
            <Box>
              <CustomCard bg="#0076FD">
                <Button
                  fontSize={{ sm: "10px", md: "10px", lg: "14" }}
                  bg="#0076FD"
                  fontWeight="500"
                  color="white"
                  borderRadius="0"
                  onClick={createSectionModal.onOpen}
                >
                  <VStack>
                    <AddIcon />
                    <Text>Create Sections</Text>
                  </VStack>
                </Button>
              </CustomCard>
            </Box>
            {sections.map((section: any) => {
              return (
                <Box>
                  <CustomCard>
                    <Flex justify="space-evenly">
                      <Icon
                        onClick={() => handleEditSection(section)}
                        as={FaEdit}
                        color="gray.400"
                      />
                      <Icon
                        onClick={() => handleDeleteSection(section.id)}
                        as={MdDeleteOutline}
                        color="red"
                      />
                      <Divider borderWidth="1px" borderColor="#D9D9D9" />
                    </Flex>
                    <PrimaryLabel w="100%">{section.section}</PrimaryLabel>
                  </CustomCard>
                </Box>
              );
            })}
          </HStack>
        </Box>

        <Divider border="1px solid black" color="black" />

        <Box border="1px solid gray.700" m={5} p={5}>
          <Box position="relative" padding="10">
            <Divider borderWidth="1px" borderColor="black" />
            <AbsoluteCenter bg="white" px="4">
              Contacts
            </AbsoluteCenter>
          </Box>
          <Wrap spacing="30px">
            <Box>
              <CustomCard bg="#0076FD">
                <Button
                  fontSize={{ sm: "10px", md: "10px", lg: "14" }}
                  bg="#0076FD"
                  fontWeight="500"
                  color="white"
                  borderRadius="0"
                  onClick={createContactUsModal.onOpen}
                >
                  <VStack>
                    <AddIcon />
                    <Text>Create Contacts</Text>
                  </VStack>
                </Button>
              </CustomCard>
            </Box>
            {contactUs?.map((constact: any) => {
              return (
                <Box>
                  <CustomCard>
                    <Flex justify="space-evenly">
                      <Divider
                        borderWidth="1px"
                        borderColor="#D9D9D9"
                        width="10px"
                        orientation="horizontal"
                        color="black"
                      />
                    </Flex>
                    <Flex>
                      <PrimaryLabel w="100%">Name</PrimaryLabel>{" "}
                      <PrimaryLabel w="100%">{constact.name}</PrimaryLabel>
                      <Icon
                        onClick={() => handleEditContact(constact)}
                        as={FaEdit}
                        color="gray.400"
                      />
                      <Icon
                        onClick={() => handleDeleteContact(constact.id)}
                        as={MdDeleteOutline}
                        color="red"
                      />
                    </Flex>
                    <Flex>
                      <PrimaryLabel w="100%">Email</PrimaryLabel>{" "}
                      <PrimaryLabel w="100%">{constact.email}</PrimaryLabel>
                    </Flex>

                    <Flex>
                      <PrimaryLabel w="100%">Website</PrimaryLabel>{" "}
                      <PrimaryLabel w="100%">{constact?.website}</PrimaryLabel>
                    </Flex>
                    <Flex>
                      <PrimaryLabel w="100%">Phone Number</PrimaryLabel>{" "}
                      <PrimaryLabel w="100%">
                        {constact?.phoneNumber.map((number: any) => {
                          return <Text>{number}</Text>;
                        })}
                      </PrimaryLabel>
                    </Flex>

                    <Flex>
                      <PrimaryLabel w="100%">Telegram</PrimaryLabel>{" "}
                      <PrimaryLabel w="100%">{constact?.telegram}</PrimaryLabel>
                    </Flex>
                  </CustomCard>
                </Box>
              );
            })}
          </Wrap>
        </Box>
        <Divider border="1px solid black" color="black" />

        <Box border="1px solid gray.700" m={5} p={5}>
          <Box position="relative" padding="10">
            <Divider borderWidth="1px" borderColor="black" />
            <AbsoluteCenter bg="white" px="4">
              Trial Version
            </AbsoluteCenter>
          </Box>
          <HStack style={{ overflow: "scroll" }} spacing="30px">
            <Wrap spacing="30px">
              {trialPeriod?.map((trial: any) => {
                return (
                  <Box>
                    <CustomCard>
                      <Flex justify="space-evenly">
                        <Divider
                          borderWidth="1px"
                          borderColor="#D9D9D9"
                          width="10px"
                          orientation="horizontal"
                          color="black"
                        />
                      </Flex>
                      <Flex>
                        <PrimaryLabel w="100%">Message</PrimaryLabel>{" "}
                        <PrimaryLabel w="100%">{trial?.message}</PrimaryLabel>
                        <Icon
                          onClick={() => handleEditTrial(trial)}
                          as={FaEdit}
                          color="gray.400"
                        />
                      </Flex>
                      <Flex>
                        <PrimaryLabel w="100%">Remaining Days</PrimaryLabel>{" "}
                        <PrimaryLabel w="100%">
                          {trial?.remainingDays}
                        </PrimaryLabel>
                      </Flex>
                      <Flex>
                        <PrimaryLabel w="100%">Url</PrimaryLabel>{" "}
                        <PrimaryLabel w="100%">{trial?.updateUrl}</PrimaryLabel>
                      </Flex>
                    </CustomCard>
                  </Box>
                );
              })}
            </Wrap>

            <Wrap spacing="30px">
              {trialVersionDate?.map((trial: any) => {
                return (
                  <Box>
                    <CustomCard>
                      <Flex justify="space-evenly">
                        <Divider
                          borderWidth="1px"
                          borderColor="#D9D9D9"
                          width="10px"
                          orientation="horizontal"
                          color="black"
                        />
                      </Flex>
                      <Flex>
                        <PrimaryLabel w="100%">
                          Trial Version duration :
                        </PrimaryLabel>{" "}
                        <PrimaryLabel w="100%">{trial?.duration}</PrimaryLabel>
                        <Icon
                          onClick={() => handleEditTrialVersionDate(trial)}
                          as={FaEdit}
                          color="gray.400"
                        />
                      </Flex>
                    </CustomCard>
                  </Box>
                );
              })}
            </Wrap>
            <Wrap spacing="30px">
              {guestTrialVersionDate?.map((trial: any) => {
                return (
                  <Box>
                    <CustomCard>
                      <Flex justify="space-evenly">
                        <Divider
                          borderWidth="1px"
                          borderColor="#D9D9D9"
                          width="10px"
                          orientation="horizontal"
                          color="black"
                        />
                      </Flex>
                      <Flex>
                        <PrimaryLabel w="100%">
                          Guest Trial Version duration :
                        </PrimaryLabel>{" "}
                        <PrimaryLabel w="100%">{trial?.duration}</PrimaryLabel>
                        <Icon
                          onClick={() => handleEditGuestTrialVersion(trial)}
                          as={FaEdit}
                          color="gray.400"
                        />
                      </Flex>
                    </CustomCard>
                  </Box>
                );
              })}
            </Wrap>
          </HStack>
        </Box>

        <Divider border="1px solid black" color="black" />

        <Box border="1px solid gray.700" m={5} p={5}>
          <Box position="relative" padding="10">
            <Divider borderWidth="1px" borderColor="black" />
            <AbsoluteCenter bg="white" px="4">
              UserGuide
            </AbsoluteCenter>
          </Box>
          <Wrap spacing="30px">
            <Box>
              <CustomCard bg="#0076FD">
                <Button
                  fontSize={{ sm: "10px", md: "10px", lg: "14" }}
                  bg="#0076FD"
                  fontWeight="500"
                  color="white"
                  borderRadius="0"
                  onClick={createUserGuideModal.onOpen}
                >
                  <VStack>
                    <AddIcon />
                    <Text>Add User Guides</Text>
                  </VStack>
                </Button>
              </CustomCard>
            </Box>

            {userGuide?.map((userguide: any) => {
              return (
                <Box>
                  <CustomCard>
                    <Flex justify="space-evenly">
                      <Divider
                        borderWidth="1px"
                        borderColor="#D9D9D9"
                        width="10px"
                        orientation="horizontal"
                        color="black"
                      />
                    </Flex>
                    <Flex>
                      <PrimaryLabel w="100%">Name:</PrimaryLabel>{" "}
                      <PrimaryLabel w="100%">{userguide.title}</PrimaryLabel>
                      <Icon
                        onClick={() => handleEditUserGuide(userguide)}
                        as={FaEdit}
                        color="gray.400"
                      />
                      <Icon
                        onClick={() => handleDeleteUserGuide(userguide.id)}
                        as={MdDeleteOutline}
                        color="red"
                      />
                    </Flex>
                    <Flex>
                      <PrimaryLabel w="100%">Email</PrimaryLabel>{" "}
                      <PrimaryLabel w="100%">
                        {userguide.description}
                      </PrimaryLabel>
                    </Flex>
                    <Flex>
                      <PrimaryLabel w="100%">Video Link</PrimaryLabel>{" "}
                      <VideoPreview m={2} youtubeUrl={userguide?.videoLink} />
                    </Flex>
                  </CustomCard>
                </Box>
              );
            })}
          </Wrap>
        </Box>

        <Modal
          isOpen={editContactModal.isOpen}
          onClose={editContactModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitEditcontact(onEditContact)}>
              <ModalHeader>Editing Contact</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Name</FormLabel>
                  <Input
                    id="name"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Name"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...contactEditform.register("name", {
                      required: "This is required",
                      minLength: {
                        value: 0,
                        message: "Minimum length should be 4",
                      },
                    })}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    id="email"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="Email"
                    placeholder="Email"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...contactEditform.register("email", {
                      required: "This is required",
                      minLength: {
                        value: 0,
                        message: "Minimum length should be 4",
                      },
                    })}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Phone Number</FormLabel>
                  <MultipleInput setData={setPhoneNumbers} />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Website</FormLabel>
                  <Input
                    id="website"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="website"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...contactEditform.register("website", {
                      required: "website is required",
                    })}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>TeleGram</FormLabel>
                  <Input
                    id="telegram"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="telegram"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...contactEditform.register("telegram", {
                      required: "telegram is required",
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={createContactUsModal.isOpen}
          onClose={createContactUsModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitcontact(onCreateContact)}>
              <ModalHeader>Add Contacts</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Name</FormLabel>
                  <Input
                    id="name"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Name"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...contactform.register("name", {
                      required: "This is required",
                      minLength: {
                        value: 0,
                        message: "Minimum length should be 4",
                      },
                    })}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Email</FormLabel>
                  <Input
                    id="email"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="Email"
                    placeholder="Email"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...contactform.register("email", {
                      required: "This is required",
                      minLength: {
                        value: 0,
                        message: "Minimum length should be 4",
                      },
                    })}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Phone Number</FormLabel>
                  <MultipleInput setData={setPhoneNumbers} />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Website</FormLabel>
                  <Input
                    id="website"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="website"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...contactform.register("website", {
                      required: "website is required",
                    })}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>TeleGram</FormLabel>
                  <Input
                    id="telegram"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="telegram"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...contactform.register("telegram", {
                      required: "telegram is required",
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>


        <Modal
          isOpen={createUserGuideModal.isOpen}
          onClose={createUserGuideModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitUserGuide(onCreateUserGuide)}>
              <ModalHeader>Creating User Guide</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Title</FormLabel>
                  <Input
                    id="name"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Title"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...userGuideform.register("title", {
                      required: "Title is required",
                    })}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Description</FormLabel>
                  <Input
                    id="description"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Description"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...userGuideform.register("description", {
                      required: "Description is required",
                    })}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Video Link</FormLabel>
                  <Input
                    id="videoLink"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Video Link"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...userGuideform.register("videoLink", {
                      required: "Video Link is required",
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={editUserGuideModal.isOpen}
          onClose={editUserGuideModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitEditUserGuide(onEditUserGuide)}>
              <ModalHeader>Editing User Guide</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Title</FormLabel>
                  <Input
                    id="name"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Title"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editUserGuideForm.register("title", {
                      required: "Title is required",
                    })}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Description</FormLabel>
                  <Input
                    id="description"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Description"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editUserGuideForm.register("description", {
                      required: "Description is required",
                    })}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Video Link</FormLabel>
                  <Input
                    id="videoLink"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Video Link"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editUserGuideForm.register("videoLink", {
                      required: "Video Link is required",
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={createGradeModal.isOpen}
          onClose={createGradeModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmit3(onCreateGrade)}>
              <ModalHeader>Create Grade</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Grade Name</FormLabel>
                  <Input
                    id="grade"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Grade"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...form3.register("grade", {
                      required: "This is required",
                      minLength: {
                        value: 0,
                        message: "Minimum length should be 4",
                      },
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
        <Modal isOpen={editUnitModal.isOpen} onClose={editUnitModal.onClose}>
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitEditUnit(onEditUnits)}>
              <ModalHeader>Editing Unit</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Unit Name</FormLabel>
                  <Input
                    id="unit"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Unit Name"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editUnitform.register("unit", {
                      required: "Unit is required",
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={editExamVersionModal.isOpen}
          onClose={editExamVersionModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitEditExamVersion(onEditExamVersion)}>
              <ModalHeader>Edit Exam Version</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Exam Version</FormLabel>
                  <Input
                    id="examversion"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Exam Version"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editExamVersionForm.register("examVersion", {
                      required: " Exam Version is required",
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <Modal isOpen={editYearModal.isOpen} onClose={editYearModal.onClose}>
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitEditYear(onEditYear)}>
              <ModalHeader>Editing Year</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Year</FormLabel>
                  <Input
                    id="year"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Year"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editYearForm.register("year", {
                      required: "Year is required",
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={createUnitModal.isOpen}
          onClose={createUnitModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitUnit(onCreateUnit)}>
              <ModalHeader>Add Units</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Unit number</FormLabel>
                  <Input
                    id="unit"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Unit "
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createUnitForm.register("unit", {
                      required: "This is required",
                      minLength: {
                        value: 0,
                        message: "Minimum length should be 4",
                      },
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={createYearModal.isOpen}
          onClose={createYearModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitYear(oncreateYear)}>
              <ModalHeader>Creating Year</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>year</FormLabel>
                  <Input
                    id="year"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter year "
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createYearorm.register("year", {
                      required: "year is required",
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={createSectionModal.isOpen}
          onClose={createSectionModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitSection(onCreateSection)}>
              <ModalHeader>Add Sections</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Section name</FormLabel>
                  <Input
                    id="section"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Section "
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createSectionForm.register("section", {
                      required: "This is required",
                      minLength: {
                        value: 0,
                        message: "Minimum length should be 4",
                      },
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={editSectionModal.isOpen}
          onClose={editSectionModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitEditSection(onEditSection)}>
              <ModalHeader>Editing Section</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Section</FormLabel>
                  <Input
                    id="unit"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Section"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editSectinForm.register("section", {
                      required: "Section is required",
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <Modal isOpen={editTrialModal.isOpen} onClose={editTrialModal.onClose}>
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitEditTrial(onEditTrial)}>
              <ModalHeader>Editing Trial Version</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Message</FormLabel>
                  <Input
                    id="unit"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Message"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editTrialForm.register("message", {
                      required: "message is required",
                    })}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Url</FormLabel>
                  <Input
                    id="url"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Update Url"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editTrialForm.register("updateUrl", {
                      required: "Url is required",
                    })}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Remaining Days</FormLabel>
                  <Input
                    id="remainingDays"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Remaining Days"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editTrialForm.register("remainingDays", {
                      required: "remaining days is required",
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={editTrialVersionDateModal.isOpen}
          onClose={editTrialVersionDateModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form
              onSubmit={handleSubmitEditTrialVersionDate(
                onEditTrialVersionDate
              )}
            >
              <ModalHeader>Editing Trial Version Duration</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Duration</FormLabel>
                  <Input
                    id="durations"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Duration Days"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editTrialVersionDateForm.register("duration", {
                      required: "Duration days is required",
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
        <Modal
          isOpen={editGuestTrialVersionDateModal.isOpen}
          onClose={editGuestTrialVersionDateModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form
              onSubmit={handleSubmitEditGuestTrialVersion(
                onEditGuestTrialVersion
              )}
            >
              <ModalHeader>Editing Guest Trial Duration</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Duration</FormLabel>
                  <Input
                    id="durations"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Duration Days"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editGuestTrialVersionDateForm.register("duration", {
                      required: "Duration days is required",
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={createExamVersionModal.isOpen}
          onClose={createExamVersionModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitExamVersion(onCreateExamVersion)}>
              <ModalHeader>Add Exam Version</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Exam Version</FormLabel>
                  <Input
                    id="unit"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Exams "
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createExamVersionForm.register("exam", {
                      required: "This is required",
                      minLength: {
                        value: 0,
                        message: "Minimum length should be 4",
                      },
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <AlertBox
          cancelRef={cancelRef}
          alert={alert}
          header="Delete Unit?"
          alertBody="Are you sure you want to delete this unit"
          onDelete={onDeleteUnit}
        />
        <AlertBox
          cancelRef={cancelRef}
          alert={alert1}
          header="Delete Section?"
          alertBody="Are you sure you want to delete this Section"
          onDelete={onDeleteSection}
        />

        <AlertBox
          cancelRef={cancelRef}
          alert={deleteContactAlert}
          header="Delete Contact?"
          alertBody="Are you sure you want to delete this Contact"
          onDelete={onDeleteContact}
        />
        <AlertBox
          cancelRef={cancelRef}
          alert={alert2}
          header="Delete Exam Version?"
          alertBody="Are you sure you want to delete this ExamVersion"
          onDelete={onDeleteExamVersion}
        />

        <AlertBox
          cancelRef={cancelRef}
          alert={deleteUserGuideAlert}
          header="Delete User Guide?"
          alertBody="Are you sure you want to delete User Guide"
          onDelete={ondeleteUserGuide}
        />
        <AlertBox
          cancelRef={cancelRef}
          alert={alert3}
          header="Delete this Year?"
          alertBody="Are you sure you want to delete this Year"
          onDelete={ondeleteYear}
        />
      </Box>
    </Box>
  );
}
