import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from "utils/axiosMiddleware";

export const getOnlineUsers: any = createAsyncThunk(
  "analytic/GetOnlineUSer",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/user/onlineusers`,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPeakDayInWeek: any = createAsyncThunk(
  "analytic/GetPeakDayInWeek",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/user/peakdayinweek`,
        params: arg,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSubscriberAnalytics: any = createAsyncThunk(
  "analytic/GetPeakDayInWeek",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/payment/subscribercount`,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPeakDayInMonth: any = createAsyncThunk(
  "analytic/GetPeakDayInMonth",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/user/peakdayinmonth`,
        params: arg,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getUsersCountbyRegion: any = createAsyncThunk(
  "analytic/getUsersCountbyRegion",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/user/countbyregion`,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getUsersCountbyScore: any = createAsyncThunk(
  "analytic/usersCountbyScore",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/exam-result/admin/userscountbyscore`,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


const analyticsSlice: any = createSlice({
  name: "analytics",
  initialState: {
    data: [],
    isSuccess: false,
    message: "",
    loading: false,
  },
  reducers: {},
  extraReducers: {
    //   [getChallenge.pending]: (state, { payload }) => {
    //     state.loading = true;
    //   },
    //   [getChallenge.fulfilled]: (state, { payload }) => {
    //     state.loading = false;
    //     state.data = payload;
    //     state.isSuccess = true;
    //   },
    //   [getChallenge.rejected]: (state, { payload }) => {
    //     state.message = payload;
    //     state.loading = false;
    //     state.isSuccess = false;
    //   },
  },
});

export default analyticsSlice;
