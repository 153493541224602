import { Text, useColorModeValue } from '@chakra-ui/react';
const  PrimaryText=({ ...props })=>{
    const children: React.ReactNode = props.children;

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    return( 
         <Text color={textColor} my="15px" fontSize={{ base: '12px', xl: '12px', '12px': '12px' }} fontWeight='500'>
                {children}
         </Text>)
    }
    const  SecondaryText=({ ...props })=>{
        const children: React.ReactNode = props.children;
    
        const textColor = useColorModeValue('secondaryGray.900', 'white');
        return( 
             <Text color={textColor} my="15px" fontSize={{ base: 'xl', xl: 'xl', '3xl': 'xl' }} fontWeight='700'>
                    {children}
             </Text>)
        }
export { PrimaryText, SecondaryText };
