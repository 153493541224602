import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text
} from "@chakra-ui/react";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import PasswordInputForm from "components/Atoms/fields/passwordInputForm";
import PhoneNumberInput from "components/Atoms/fields/phoneNumberInputForm";
import TextInputForm from "components/Atoms/fields/textInputForm";
const EditUsersForm = ({
  editUserModal,
  onEditUser,
  grades,
  regions,
  editUserForm,
}: any) => {
  const {
    handleSubmit: handleSubmitEditUser,
    formState: { errors: err },
    control,
  } = editUserForm;
  return (
    <Modal isOpen={editUserModal.isOpen} onClose={editUserModal.onClose}>
      <ModalOverlay />
      <ModalContent>
        <Box borderLeftWidth="3px" borderColor="#7F77F1">
          <form onSubmit={handleSubmitEditUser(onEditUser)}>
            <ModalHeader>Editing a User</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl>
                <FormLabel>First Name</FormLabel>
                <TextInputForm
                  id={"firstName"}
                  placeholder={"First Name"}
                  formName={editUserForm}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Last Name</FormLabel>
                <TextInputForm
                  id={"lastName"}
                  placeholder={"Last Name"}
                  formName={editUserForm}
                />
                {err.lastName && (
                  <Text color="red">{err.lastName.message.toString()}</Text>
                )}
              </FormControl>

              <FormControl mt={4}>
                <PhoneNumberInput
                  id="phoneNumber"
                  label="Phone Number"
                  control={control}
                  validation={{
                    pattern: {
                      value: /^\+251\d{9}$/,
                      message:
                        "Phone number must start with +251 and be 12 digits long (including the country code)",
                    },
                  }}
                  errorMessage="Please enter your Phone Number"
                />
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>E-mail</FormLabel>
                <Input
                  id="email"
                  fontWeight="500"
                  variant="main"
                  h="44px"
                  maxH="44px"
                  type="text"
                  placeholder="E-mail"
                  _placeholder={{
                    fontWeight: "400",
                    color: "secondaryGray.600",
                  }}
                  {...editUserForm.register("email", {
                    required: "This is required",
                    minLength: {
                      value: 0,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
              </FormControl>
              <FormControl mt={4}>
                <PasswordInputForm
                  id="password"
                  label="Password"
                  placeholder="Password"
                  validation={{
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Minimum length should be 8",
                    },
                    maxLength: {
                      value: 32,
                      message: "Maximum length should be 32",
                    },
                  }}
                  errorMessage="Password should not be empty"
                  control={control}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Region</FormLabel>
                <Select
                  className="select-input"
                  placeholder="Select Regions"
                  {...editUserForm.register("region", {
                    required: "This is required",
                    minLength: {
                      value: 0,
                      message: "Minimum length should be 4",
                    },
                  })}
                >
                  {regions.map((region: any) => (
                    <option key={region.id}>{region.region}</option>
                  ))}
                </Select>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Gender</FormLabel>
                <Select
                  className="select-input"
                  _placeholder={{
                    fontWeight: "400",
                    color: "secondaryGray.600",
                  }}
                  {...editUserForm.register("gender", {
                    required: "This is required",
                    minLength: {
                      value: 0,
                      message: "Minimum length should be 4",
                    },
                  })}
                  variant="unstyled"
                >
                  <option key="male" value="MALE">
                    Male
                  </option>
                  <option key="female" value="FEMALE">
                    Female
                  </option>
                </Select>
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Grade</FormLabel>
                <Select
                  className="select-input"
                  placeholder="Select Grades"
                  {...editUserForm.register("grade", {
                    required: "This is required",
                    minLength: {
                      value: 0,
                      message: "Minimum length should be 4",
                    },
                  })}
                >
                  {grades.map((grade: any) => (
                    <option key={grade.id}>{grade.grade}</option>
                  ))}
                </Select>
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <PrimaryButton type="submit">Save</PrimaryButton>
            </ModalFooter>
          </form>
        </Box>
      </ModalContent>
    </Modal>
  );
};
export default EditUsersForm;
