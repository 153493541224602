import {
  Box,
  Button,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { flexRender, useReactTable } from "@tanstack/react-table";
import React, { useState } from "react";

const SecondaryTable: React.FC<any> = ({ tableOptions }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const table = useReactTable(tableOptions);
  let navbarBg = useColorModeValue("#ffffff", "rgba(11,20,55,0.5)");

  return (
    <Box overflowX="auto">
      <Table
        bg={navbarBg}
        border="1px solid #D9D9D9"
        variant="simple"
        color="gray.900"
        mb="24px"
        mt="10px"
      >
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr border="1px solid #D9D9D9" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th
                  border="1px solid #D9D9D9"
                  bg="#EDF3F8"
                  borderColor={borderColor}
                  cursor="pointer"
                  key={header.id}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            const isSelected = selectedRows.includes(row.id);
            return (
              <Tr border="1px solid #D9D9D9" borderColor="black" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td border="1px solid #D9D9D9" key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <Flex>
        <Button
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {"<<"}
        </Button>
        <Button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {"<"}
        </Button>
        <Button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {">"}
        </Button>
        <Button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {">>"}
        </Button>
        <Text className="flex items-center gap-1">
          <div>Page</div>
          <Text>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </Text>
        </Text>
      </Flex>
    </Box>
  );
};
export default SecondaryTable;
