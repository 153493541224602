
import { Box, Modal, ModalBody, ModalContent, ModalOverlay, Progress, Spinner, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from "react";
// Chakra imports
import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "components/Templates/layouts/auth/Default";
// Assets
import illustration from "assets/images/auth/logo.jpg";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import InputField from 'components/Atoms/fields/InputField';
import { CustomLabel } from "components/Atoms/Label/Labels";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { login } from "reduxtoolkit/reducers/authSlice";


function SignIn() {

  // Chakra color mode
  const dispatch = useDispatch();





  const form = useForm();
  const {
    register,
    handleSubmit, formState: { errors } } = form;
  const toast = useToast();
  const onSubmit = (values: any) => {

    dispatch(login(values)).then((data: any) => {
      if (data.type === "auth/Login/fulfilled") {


      }
      else {
        toast({
          title: 'Error',
          description: `${data.payload.response.data.message}`,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });

      }
    });

  }


  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const [loading, setLoading] = useState(true);
  const auth: any = useSelector((state: any) => state.auth);
  const createProgressModal = useDisclosure();
  useEffect(() => {
    if (auth.loading === true) {
      createProgressModal.onOpen();
      setLoading(true);
    }
    else {
      setLoading(false);
      createProgressModal.onClose();
    }
  }, [auth.loading]);
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  return (

    <DefaultAuth illustrationBackground={illustration} image={illustration}>

      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        {loading ? <Progress id="user" w='1/2' isIndeterminate /> : null}
        <Modal isCentered
          isOpen={createProgressModal.isOpen}
          onClose={createProgressModal.onClose}>
          <ModalOverlay />
          <ModalContent bg="transparent" maxW="100%">
            <Box borderLeftWidth='3px' borderColor='#7F77F1'>
              <ModalBody pb={6}>
                <Flex justify='center'> <Spinner mx='auto' size='lg' /></Flex>
              </ModalBody>
            </Box>
          </ModalContent>
        </Modal>
        <Box me='auto'>
          <Heading color={textColor} fontSize='36px' mb='10px'>
            Exam Time Admin
          </Heading>
          <Text
            mb='36px'
            ms='4px'
            color={textColorSecondary}
            fontWeight='400'
            fontSize='md'>
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>

          <form onSubmit={handleSubmit(onSubmit)}>

            <FormControl>
              <InputField placeholder="Email" form={form} type='email' label="Email" />
              {errors.email && <Text color='red'>{errors.email.message.toString()}</Text>}
            </FormControl>

            <FormErrorMessage>{errors.email && "Email is required"}</FormErrorMessage>
            <FormControl>
              <CustomLabel>
                Password
              </CustomLabel>

              <InputGroup size='md'>
                <Input
                  isRequired={true}
                  fontSize={{ sm: '10px', md: '10px', lg: '14' }}
                  placeholder='Min. 8 characters'
                  mb='24px'
                  size='lg'
                  name='password'
                  type={show ? 'text' : 'password'}
                  variant='auth'
                  {...register('password', {
                    required: 'This is required',
                    minLength: { value: 8, message: 'Minimum length should be 4' },
                    maxLength: { value: 32, message: 'Minimum length should be 4' },

                  })}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />

                </InputRightElement>
                {errors.password && <Text color='red'>{errors.password.message.toString()}</Text>}
              </InputGroup>



              <Flex justifyContent='space-between' align='center' mb='24px'>
                <FormControl display='flex' alignItems='center'>
                  <Checkbox
                    id='remember-login'
                    colorScheme='brandScheme'
                    me='10px'
                  />
                  <FormLabel
                    htmlFor='remember-login'
                    mb='0'
                    fontWeight='normal'
                    color={textColor}
                    fontSize={{ sm: '10px', md: '10px', lg: '14' }}>
                    Keep me logged in
                  </FormLabel>
                </FormControl>

              </Flex>
              <PrimaryButton type="submit">
                Sign In
              </PrimaryButton>
            </FormControl>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
