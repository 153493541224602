import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from 'utils/axiosMiddleware';


export const getexamVersion:any =  createAsyncThunk(
  "examVersion/getData",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
      
    try {
      const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/exam-version/exam-version`,{headers: { Authorization: `Bearer ${token}` },
      },);
      
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  })

  export const updateexamVersion:any =  createAsyncThunk(
    "examVersion/updateData",
    async (arg:any, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
      try {
        const data = await axios({
          method: 'put',
          url: `${process.env.REACT_APP_API_URL}/exam-version/update/${arg.id}`,
          headers: { Authorization: `Bearer ${token}` },
          data: arg.payload,
          
      });
      return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })

    export const deleteexamVersion:any =  createAsyncThunk(
      "examVersion/deleteData",
      async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
        try {
     
          const data = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/exam-version/delete/${arg}`,
            headers: { Authorization: `Bearer ${token}` },
        });
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      })
      export const createexamVersion:any =  createAsyncThunk(
        "admin/createExamVersion",
        async (arg, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';  
          try {   
            const data:any = await axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URL}/exam-version/create`,
              headers: { Authorization: `Bearer ${token}` },
              data: arg,
          });
          return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })

const examVersionSlice:any = createSlice({
  name: "examVersion",
  initialState: {
    data: [],
    isSuccess: false,
    message: "",
    loading: false,
  },
  reducers: {
       

  },
  extraReducers: {
    [getexamVersion.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getexamVersion.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getexamVersion.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [createexamVersion.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createexamVersion.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      
      state.data.push(payload.data.examVersion);
      state.isSuccess = true;
    },
    [createexamVersion.rejected]: (state, { payload }) => {
      state.message = payload.data;
      state.loading = false;
      state.isSuccess = false;
    },
    [updateexamVersion.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateexamVersion.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
   
       const index = state.data.findIndex((examVersion:any) => examVersion.id === payload.data.examVersion.id);
             if (index !== -1) {
            
           state.data[index] = payload.data.examVersion;
         }
      state.isSuccess = true;
    },
    [updateexamVersion.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [deleteexamVersion.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteexamVersion.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
     
      state.data = state.data.filter((examVersion:any) => examVersion.examVersion !== payload.data.examVersion);
      state.isSuccess = true;
    },
    [deleteexamVersion.rejected]: (state:any, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
  },
});

export default examVersionSlice;
