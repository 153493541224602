import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
  Textarea, useDisclosure,
  useToast, Wrap,
  WrapItem
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { FaEdit } from "react-icons/fa";
// Custom components
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import AlertBox from "components/Molecules/Alerts/AlertBox";
import PrimaryTable from "components/Molecules/Tables/PrimaryTable";
import MainTab from "components/Molecules/Tabs/MainTab";
import TextWithLinks from "components/Molecules/textWithLinks";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  createFaqs,
  deleteFaqs,
  getFaqs,
  updateFaqs
} from "reduxtoolkit/reducers/faqSlice";
import { extractLinks } from "utils/get/generalhelpers";

export type FaqsUnits = {
  id: any;
  days: string;
  units: any[];
};

export type Faqs = {
  title: string;
  week: string;
  grade: string;
  subject: string;
  faqsUnits: FaqsUnits[];
};

export default function Overview() {
  const addFaqsModal = useDisclosure();
  const editFaqsModal = useDisclosure();
  const cancelRef = useRef();
  const alert = useDisclosure();
  const createFaqsForm = useForm();
  const editFaqsForm = useForm();

  const {
    handleSubmit: handleSubmitFaqs,
    control,
    formState: { errors },
  } = createFaqsForm;
  const {
    handleSubmit: handleSubmitEditFaqs,
    reset,
    control: controlEdit,
    formState: { errors: Editerrors },
    getValues,
  } = editFaqsForm;
  const handleCreateFaqs = () => {
    addFaqsModal.onOpen();
  };
  const [id, setId] = useState<any>();
  const handleEditFaqs = (value: any) => {
    setId(value.id);
    const data = value;
    const lnk = data?.links?.map((item: any) => item.link).join('\n') + ' ';
    reset({
      question: data.question,
      answer: `${data.answer} ${lnk}`,
    });
    editFaqsModal.onOpen();
  };
  const handleDeleteFaqs = (value: any) => {
    setId(value.id);

    alert.onOpen();
  };

  const [updated, setUpdated] = useState(false);
  const textColor = "gray.400";
  const unitdata: any = useSelector((state: any) => state.unit.data);
  const faqs = useSelector((state: any) => state.faq);

  const dispatch = useDispatch();
  const onCreateFaqs = (values: any) => {
    let links = extractLinks(values.answer);
    const data = {
      question: values.question,
      answer: links.texts.length > 0 ? links.texts.join(' ') : '',
      links: links.links,
    }


    dispatch(createFaqs(data)).then((data: any) => {
      if (data.type === "admin/createFaqs/fulfilled") {
        toast({
          title: "Success",
          description: "Faqs Created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        addFaqsModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        addFaqsModal.onClose();
      }
    });
  };

  const toast = useToast();
  const onEditFaqs = (values: any) => {
    let links = extractLinks(values.answer);
    let newdata = {
      id: id,
      payload: {
        question: values.question,
        answer: links.texts.length > 0 ? links.texts.join(' ') : '',
        link: links.links,
      },
    };
    dispatch(updateFaqs(newdata)).then((data: any) => {
      if (data.type === "faq/updateData/fulfilled") {
        toast({
          title: "Success",
          description: "Faqs updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        editFaqsModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        editFaqsModal.onClose();
      }
    });
  };

  const onDeleteFaqs = () => {

    dispatch(deleteFaqs(id)).then((data: any) => {
      if (data.type === "faq/deleteData/fulfilled") {
        toast({
          title: "Success",
          description: "Faqs deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      }
    });
  };

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    dispatch(getFaqs()).then((data: any) => { });
  }, []);
  useEffect(() => {
    if (faqs.isSuccess == true) {
      setTableData(faqs.data);
      //  setData(faqs.data.faqs);
    }
  }, [faqs.loading]);

  type RowObj = {
    question: string;
    answer: any;
    actions: any;
    user: any;
  };

  const columnHelper = createColumnHelper<RowObj>();

  const columns = [
    columnHelper.accessor("question", {
      id: "question",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          QUESTION
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.getValue()}

          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("answer", {
      id: "answer",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          ANSWER
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Wrap>
            {info
              .getValue()
              .split(" ")
              .map((word: string, index: any) => (
                <WrapItem key={index}>
                  <Text>
                    <TextWithLinks text={word} /></Text>
                </WrapItem>
              ))}
            <div>
              {info.row.original?.links?.map((link: any) => {
                return (<span>
                  {link.link}
                </span>)
              })}
            </div>
          </Wrap>


        </Flex>
      ),
    }),

    columnHelper.accessor("actions", {
      id: "actions",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          Actions
        </Text>
      ),
      cell: (info) => (
        <>
          <Icon
            as={FaEdit}
            onClick={() => handleEditFaqs(info.row.original)}
            color="gray.400"
          />
          <Icon
            onClick={() => handleDeleteFaqs(info.row.original)}
            as={MdDeleteOutline}
            color="red"
          />
        </>
      ),
    }),
  ];

  const createProgressModal = useDisclosure();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (faqs.loading == true) {
      setLoading(true);
      createProgressModal.onOpen();
    } else {
      createProgressModal.onClose();
      setLoading(false);
    }
  }, [faqs.loading]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      {loading ? <Progress w="1/2" isIndeterminate /> : null}
      <Modal
        isOpen={createProgressModal.isOpen}
        onClose={createProgressModal.onClose}
      >
        <ModalOverlay />
        <ModalContent bg="transparent" maxW="100%">
          <Box borderLeftWidth="3px" borderColor="#7F77F1">
            <ModalBody pb={6}></ModalBody>
          </Box>
        </ModalContent>
      </Modal>
      <MainTab />
      <Flex justify="space-between">
        <HStack w="60%" spacing="24px">
          <Box w="300px" h="10"></Box>
        </HStack>

        <HStack w="30%" spacing="10px" justify="end">
          <Box h="10">
            <Button
              fontSize={{ sm: "10px", md: "10px", lg: "14" }}
              bg="#0076FD"
              fontWeight="500"
              color="white"
              borderRadius="0"
              onClick={(e) => handleCreateFaqs()}
            >
              <AddIcon w={4} h={4} px="1px" color="white" />
              Create Faqs
            </Button>
          </Box>
        </HStack>
      </Flex>

      <PrimaryTable data={tableData} columns={columns} />


      <Modal isOpen={addFaqsModal.isOpen} onClose={addFaqsModal.onClose}>
        <ModalOverlay />

        <ModalContent>
          <Box borderLeftWidth="3px" borderColor="#7F77F1">
            <form onSubmit={handleSubmitFaqs(onCreateFaqs)}>
              <ModalHeader>Creating FAQs</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel>Faqs Questions</FormLabel>
                  <Input
                    id="faqsTitle"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    name="question	"
                    placeholder="faqs Title"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createFaqsForm.register(`question`, {
                      required: "faqs Question is required",
                    })}
                  />

                  {errors.question && (
                    <Text color="red">
                      {errors.question.message.toString()}
                    </Text>
                  )}
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Answer</FormLabel>
                  <Textarea
                    id="answer"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"

                    name="answer"
                    placeholder="Answer"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createFaqsForm.register(`answer`, {
                      required: "Answer is required",
                    })}
                  />

                  {errors.answer && (
                    <Text color="red">{errors.answer.message.toString()}</Text>
                  )}
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </Box>
        </ModalContent>
      </Modal>

      <Modal isOpen={editFaqsModal.isOpen} onClose={editFaqsModal.onClose}>
        <ModalOverlay />

        <ModalContent>
          <Box borderLeftWidth="3px" borderColor="#7F77F1">
            <form onSubmit={handleSubmitEditFaqs(onEditFaqs)}>
              <ModalHeader>Editing Faqs</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel>Faqs Question</FormLabel>
                  <Input
                    id="question"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    name="question"
                    placeholder="Faqs Question"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editFaqsForm.register(`question`, {
                      required: "faqs question is required",
                    })}
                  />

                  {Editerrors.faqsTitle && (
                    <Text color="red">
                      {Editerrors.faqsTitle.message.toString()}
                    </Text>
                  )}
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Answer</FormLabel>
                  <Textarea
                    id="answer"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    name="answer"
                    placeholder="answer"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editFaqsForm.register(`answer`, {
                      required: "answer is required",
                    })}
                  />

                  {Editerrors.answer && (
                    <Text color="red">
                      {Editerrors.answer.message.toString()}
                    </Text>
                  )}
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </Box>
        </ModalContent>
      </Modal>
      <AlertBox
        cancelRef={cancelRef}
        alert={alert}
        header="Delete Faqs?"
        alertBody="Are you sure you want to delete this Faq"
        onDelete={onDeleteFaqs}
      />
    </Box>
  );
}
