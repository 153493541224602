import {
    Box,
    Button,
    Flex,
    Select,
    Table,
    Tbody,
    Td,
    Text,
    Thead,
    useColorModeValue,
    useToast,
} from "@chakra-ui/react";
import { flexRender, useReactTable } from "@tanstack/react-table";
import React, { useState } from "react";

const SecondaryTable: React.FC<any> = ({
  tableOptions,
  selectedData,
  totalquestion,
  setSelectedData,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  let navbarBg = useColorModeValue(
    "rgba(250, 251, 252, 0.9)",
    "rgba(11,20,55,0.5)"
  );
  const toast = useToast();
  const table = useReactTable(tableOptions);
  const handleRowSelection = (row: any) => {
    table.setRowSelection((old) => ({ ...old, row }));

    const isSelected = selectedRows.includes(row.id);
    if (selectedRows.length < 5 - totalquestion) {
      setSelectedRows(
        isSelected
          ? selectedRows.filter((id) => id !== row.id)
          : [...selectedRows, row.id]
      );
      setSelectedData(
        isSelected
          ? selectedData.filter((rw: any) => rw.id !== row.original.id)
          : [...selectedData, row.original]
      );
    } else {
      if (isSelected) {
        setSelectedRows(
          isSelected
            ? selectedRows.filter((id) => id !== row.id)
            : [...selectedRows, row.id]
        );
        setSelectedData(
          isSelected
            ? selectedData.filter((rw: any) => rw.id !== row.original.id)
            : [...selectedData, row.original]
        );
      } else {
        toast({
          title: "Warning",
          description: "Selection limit reached",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box overflowX="auto">
      <Table variant="simple" bg={navbarBg}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            const isSelected = selectedRows.includes(row.id);
            return (
              <tr key={row.id} onClick={() => handleRowSelection(row)}>
                {row.getVisibleCells().map((cell) => (
                  <Td bg={isSelected ? "gray" : "wheat"} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </tr>
            );
          })}
        </Tbody>
      </Table>

      <Flex>
        <Button
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {"<<"}
        </Button>
        <Button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {"<"}
        </Button>
        <Button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {">"}
        </Button>
        <Button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {">>"}
        </Button>
        <Text className="flex items-center gap-1">
          <div>Page</div>
          <Text>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </Text>
        </Text>

        <Select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Select>
      </Flex>
    </Box>
  );
};
export default SecondaryTable;
