

// Chakra imports
import {
	Box, FormControl,
	FormLabel,
	Grid,
	Icon,
	Input,
	InputGroup,
	InputRightElement,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay, Progress, useDisclosure, useToast, VStack
} from '@chakra-ui/react';
// Custom components
import Banner from 'components/Templates/profile/Banner';
// Assets
import banner from 'assets/images/auth/banner.png';
import avatar from 'assets/images/avatars/avatar4.png';
import { PrimaryButton } from 'components/Atoms/Button/Buttons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { changePassword, editProfile } from 'reduxtoolkit/reducers/authSlice';
export default function Overview() {
    const [updated, setUpdated] = useState(false);
	const textColorSecondary = "gray.400";
	const [userProfile, setUserProfile] = useState<any>([]);

	
	const changePasswordModal = useDisclosure()
	const changeProfileModal =useDisclosure();

	const dispatch = useDispatch();
	

	const changeProfileForm = useForm();
	const form2 = useForm();
	
	  const {
	
		handleSubmit: handleSubmit1, reset: reset1 } = changeProfileForm;

		const {
		
			handleSubmit: handleSubmit2 ,reset: reset2 } = form2;
			const toast = useToast();	
	const onChangePassword=(values: any)=> {
       
		const data = {
			currentPassword: values.currentPassword,
			newPassword: values.newPassword
		}
		dispatch(changePassword(data)).then((data:any)=>{
				if(data.type == 'auth/changePassword/fulfilled')
				{
					toast({
						title: 'Success',
						description: "user Created successfully",
						status: 'success',
						duration: 9000,
						isClosable: true,
					  });
				}
				else
				{
					toast({
						title: 'Error',
						description:   `${data.payload.response.data.message}`,
						status: 'error',
						duration: 9000,
						isClosable: true,
					  });
					}
				
		});
	
	
	   }

	const onChangeProfileSubmit=(values: any)=>{

		const data = {
			firstName: values.firstName,
			lastName: values.lastName,
			middleName: values.middleName,
			email: values.email,
			phoneNumber: values.phoneNumber,

		}
		dispatch(editProfile(data));
		//update(data);
		
	}
	const handleChangeProfile=()=>{
		reset1({
			firstName: userProfile.firstName,
			middleName: userProfile.middleName,
			lastName: userProfile.lastName,
			email: userProfile.email,
			phoneNumber: userProfile.phoneNumber,
		})
		changeProfileModal.onOpen()
	}
	const handleChangePassword=()=>{
		
		reset2({
			email: userProfile.email
			
		})
		changePasswordModal.onOpen();
		}

const initialRef = React.useRef(null)
const finalRef = React.useRef(null)
const [show, setShow] = useState(false);
const handleClick = () => setShow(!show);
const [show1, setShow1] = useState(false);
const handleClick1 = () => setShow1(!show1);
const [loading, setLoading] = useState(true);
const createProgressModal = useDisclosure();
    useEffect(()=>{
		
		setUserProfile(user.auth.user);
	},[updated]);
	const user:any = useSelector((state:any)=> state);
	useEffect(() => {
		if(user.loading == true){
		  setLoading(true);
		  createProgressModal.onOpen();
		}
		else {
		   setLoading(false);
		  createProgressModal.onClose();
		}
	  }, [user.loading]);
	return (
		<Box borderRadius='0px' pt={{ base: '130px', md: '80px', xl: '80px' }}>
				{ loading ?<Progress w='1/2' isIndeterminate />: null}
				<Modal 
					isOpen={createProgressModal.isOpen}
					onClose={createProgressModal.onClose}>
						<ModalOverlay />
						<ModalContent bg="transparent" maxW="100%">
						<Box borderLeftWidth='3px' borderColor='#7F77F1'>
						<ModalBody pb={6}>
						</ModalBody>
						</Box>
						</ModalContent>
						</Modal>
			{/* Main Fields */}
			<Grid 
				borderRadius='0px'
				templateColumns={{
					base: '1fr',
					lg: '1.34fr 1fr 1.62fr'
				}}
				templateRows={{
					base: 'repeat(3, 1fr)',
					lg: '1fr'
				}}
				gap={{ base: '20px', xl: '20px' }}>
				 <Banner
					gridArea='1 / 1 / 2 / 2'
					banner={banner}
					avatar={avatar}
					name={`${userProfile.firstName} ${userProfile.lastName}`}
					profile={userProfile}
					
				/> 
				<VStack>
				<PrimaryButton   onClick={()=>handleChangePassword()}	>Change Password</PrimaryButton>
				<PrimaryButton   onClick={()=>handleChangeProfile()}	>Edit Profile</PrimaryButton>
				
				</VStack>
				<Modal
				initialFocusRef={initialRef}
				finalFocusRef={finalRef}
				isOpen={changePasswordModal.isOpen}
				onClose={changePasswordModal.onClose}
			>
				<ModalOverlay />
				<ModalContent>
						<form onSubmit={handleSubmit2(onChangePassword)}>
						<ModalHeader>Change Your Password</ModalHeader>
						<ModalCloseButton />		
						<ModalBody pb={6}>
							
							<FormControl mt={4}>
							<FormLabel>current password</FormLabel>
							
							<InputGroup size='md'>
							<Input
								id='currentPassword'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type={show ? 'text' : 'password'}
								placeholder="current password"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...form2.register('currentPassword', {
									required: 'This is required',
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})}
								/>
								<InputRightElement  display='flex' alignItems='center' mt='4px'>
							<Icon
							color={textColorSecondary}
							_hover={{ cursor: "pointer" }}
							as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
							onClick={handleClick}
							/>
							
						</InputRightElement>
						</InputGroup>

							</FormControl>
							<FormControl mt={4}>
							<FormLabel>new password</FormLabel>
							<InputGroup size='md'>
							<Input
								id='newPassword'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type={show1 ? 'text' : 'password'}
								placeholder="new password"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...form2.register('newPassword', {
									required: 'This is required',
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})}
								/>
									<InputRightElement  display='flex' alignItems='center' mt='4px'>
							<Icon
							color={textColorSecondary}
							_hover={{ cursor: "pointer" }}
							as={show1 ? RiEyeCloseLine : MdOutlineRemoveRedEye}
							onClick={handleClick1}
							/>
							
						</InputRightElement>
								</InputGroup>
							</FormControl>
							
						</ModalBody>

						<ModalFooter>
						 <PrimaryButton type='submit'>
							Change Password
							</PrimaryButton>
							
						</ModalFooter>
						</form>
						</ModalContent>
					</Modal>

					<Modal 
					
					isOpen={changeProfileModal.isOpen}
					onClose={changeProfileModal.onClose}>
						<ModalOverlay />
						<ModalContent>
						<form onSubmit={handleSubmit1(onChangeProfileSubmit)}>
						<ModalHeader>update your Profile</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<FormControl>
							<FormLabel>First Name</FormLabel>
								<Input
								id='firstName'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="First Name"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...changeProfileForm.register('firstName', {
									required: 'This is required',
									pattern: /^[A-Za-z]+$/i,
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})}
								/>
							</FormControl>
							<FormControl mt={4}>
							<FormLabel>Middle Name</FormLabel>
							<Input
								id='middleName'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="Middle Name"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...changeProfileForm.register('middleName', {
									required: 'This is required',
									pattern: /^[A-Za-z]+$/i,
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})}
								/>
							</FormControl>
							<FormControl mt={4}>
							<FormLabel>Last Name</FormLabel>
							<Input
								id='lastName'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="Last Name"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...changeProfileForm.register('lastName', {
									required: 'This is required',
									pattern: /^[A-Za-z]+$/i,
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})}
								/>
							</FormControl>
							<FormControl mt={4}>
							<FormLabel>Phone Number</FormLabel>
							<Input
								id='phoneNumber'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder=""
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...changeProfileForm.register('phoneNumber', {
									required: 'This is required',
									
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})}
								/>
							</FormControl>	
							<FormControl mt={4}>
							<FormLabel>E-mail</FormLabel>
							<Input
								id='email'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="E-mail"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...changeProfileForm.register('email', {
									required: 'This is required',
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})}
								/>
							</FormControl>
						</ModalBody>
						<ModalFooter>
						<PrimaryButton type='submit'>
            Save
            </PrimaryButton>
							
						</ModalFooter>
						</form>
						</ModalContent>
					</Modal>		
				</Grid>
				</Box>
	);
}


