

// Chakra imports
import { Box, Modal, ModalBody, ModalContent, ModalOverlay, Progress, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import QuestionsTable from '../dataTables/components/QuestionDataTable';
// Assets

export default function Overview() {
	
	const questions:any = useSelector((state:any)=> state.question);
	useEffect(() => {
		if(questions.loading == true)
		  setLoading(true);
		else 
		   setLoading(false);
	  }, [questions.loading]);

	const [loading, setLoading] = useState(true);
    const createProgressModal = useDisclosure();
	useEffect(() => {
		if(questions.loading == true){
		  setLoading(true);
		  createProgressModal.onOpen();
		}
		else {
		   setLoading(false);
		  createProgressModal.onClose();
		}
	  }, [questions.loading]);
	  const history = useHistory();
	
	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			
			{ loading ?<Progress w='1/2' isIndeterminate />: null}
			<Modal 
					isOpen={createProgressModal.isOpen}
					onClose={createProgressModal.onClose}>
						<ModalOverlay />
						<ModalContent bg="transparent" maxW="100%">
						<Box borderLeftWidth='3px' borderColor='#7F77F1'>
						<ModalBody pb={6}>
						</ModalBody>
						</Box>
						</ModalContent>
						</Modal>				    
	    		<QuestionsTable />
		</Box>
	);
}
