import { usePagination } from "@ajna/pagination";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, Progress, Text,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";

import { PrimaryButton } from "components/Atoms/Button/Buttons";
// Custom components
import TableHeader from "components/Atoms/Label/TableHeader";
import AlertBox from "components/Molecules/Alerts/AlertBox";
import { MainPagination } from "components/Molecules/Pagination";
import PrimaryTable from "components/Molecules/Tables/PrimaryTable";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getFeedbacks } from "reduxtoolkit/reducers/feedbackSlice";
import { deleteGrade, updateGrade } from "reduxtoolkit/reducers/gradeSlice";

// Assets

type RowObj = {
  feedback: string;
  user: any;
  phoneNumber: any;
  actions: any;
};

const columnHelper = createColumnHelper<RowObj>();

const textColor = "gray.400";

export default function ComplexTable() {
  const tableCellColor = useColorModeValue("navy.700", "white");
  const columns = [
    columnHelper.accessor("feedback", {
      id: "feedback",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          FEEDBACK
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("user", {
      id: "user",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          FULL NAME
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.row.original?.user?.firstName &&
              `${info.row.original?.user?.firstName} ${info.row.original?.user?.lastName}`}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("phoneNumber", {
      id: "phoneNumber",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          PHONE NUMBER
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.row.original?.user?.phoneNumber}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("actions", {
      id: "actions",
      header: () => <TableHeader textColor={textColor} title="ACTIONS" />,
      cell: (info) => (
        <>
          <Icon
            onClick={() => handleEditGrade(info.row)}
            as={FaEdit}
            color="gray.400"
          />
          <Icon
            onClick={() => handleDeleteGrade(info.row.original)}
            as={MdDeleteOutline}
            color="red"
          />
        </>
      ),
    }),
  ];

  const form5 = useForm();
  const { handleSubmit: handleSubmit5, reset, getValues } = form5;

  const [updated, setUpdated] = useState(false);
  const [id, setId] = useState<any>("");

  const toast = useToast();

  const onEditGrades = (values: any) => {
    const data = {
      id: id,
      payload: { grade: values.grade },
    };

    dispatch(updateGrade(data)).then((res: any) => {
      if (res.type == "grade/updateData/fulfilled") {
        toast({
          title: "Success",
          description: "Grade Updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: `${res.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      editGradeModal.onClose();
    });
  };

  const onDeleteGrade = (value: any) => {
    setId(getValues("id"));

    dispatch(deleteGrade(id)).then((res: any) => {
      if (res.type == "grade/deleteData/fulfilled") {
        toast({
          title: "Success",
          description: "Grade deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: `${res.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const feedbackData: any = useSelector((state: any) => state.feedback);
  const dispatch = useDispatch();

  const handleEditGrade = (value: any) => {
    setId(value.original.id);
    const data = value.original;
    reset({
      grade: data.grade,
    });
    editGradeModal.onOpen();
  };



  const alert = useDisclosure();
  const cancelRef = React.useRef();
  const handleDeleteGrade = (value: any) => {
    setId(value.id);
    alert.onOpen();
  };
  const [total, setTotal] = useState(1);
  const editGradeModal = useDisclosure();
  const [data, setData] = React.useState([]);
  const outerLimit = 2;
  const innerLimit = 2;
  const {
    pages,
    pagesCount,
    currentPage,
    setCurrentPage,
    setIsDisabled,
    isDisabled,
    pageSize,
    setPageSize,
  } = usePagination({
    total: total,
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  });

  const createProgressModal = useDisclosure();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (feedbackData.loading == true) {
      setLoading(true);
      createProgressModal.onOpen();
    } else {
      createProgressModal.onClose();
      setLoading(false);
    }
  }, [feedbackData.loading]);


  useEffect(() => {
    let params = {

      page: currentPage,
      limit: pageSize,
    };
    dispatch(getFeedbacks(params));
  }, [currentPage, pageSize]);


  useEffect(() => {
    if (feedbackData.isSuccess == true) {
      setData(feedbackData.data.feedback);
      setTotal(feedbackData.data.totalCount);
    }
  }, [feedbackData.loading, currentPage, pageSize]);


  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };
  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const pageSize = Number(event.target.value);

    setPageSize(pageSize);
  };
  const handleBackPagination = (current: any) => {
    if (current > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleForwardPagination = (current: any) => {
    if (current < pagesCount) setCurrentPage(currentPage + 1);
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Box>

        {loading ? <Progress w="1/2" isIndeterminate /> : null}
        <Modal
          isOpen={createProgressModal.isOpen}
          onClose={createProgressModal.onClose}
        >
          <ModalOverlay />
          <ModalContent bg="transparent" maxW="100%">
            <Box borderLeftWidth="3px" borderColor="#7F77F1">
              <ModalBody pb={6}></ModalBody>
            </Box>
          </ModalContent>
        </Modal>
        <PrimaryTable id="feedback" data={data} columns={columns} />
        <MainPagination
          pagesCount={pagesCount}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          handleBackPagination={handleBackPagination}
          handleForwardPagination={handleForwardPagination}
          pages={pages}
          handlePageSizeChange={handlePageSizeChange}
        />


        <Modal isOpen={editGradeModal.isOpen} onClose={editGradeModal.onClose}>
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmit5(onEditGrades)}>
              <ModalHeader>Edit Grade</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Grade Name</FormLabel>
                  <Input
                    id="grade"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Grade Name"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...form5.register("grade", {
                      required: "This is required",
                      minLength: {
                        value: 0,
                        message: "Minimum length should be 4",
                      },
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
        <AlertBox
          cancelRef={cancelRef}
          alert={alert}
          header="Delete Grade?"
          alertBody="Are you sure you want to delete this grade"
          onDelete={onDeleteGrade}
        />
      </Box>
    </Box >
  );
}
