import { useToast } from "@chakra-ui/react";
import AlertBox from "components/Molecules/Alerts/AlertBox";
import React from "react";
import { useDispatch } from "react-redux";
import { deleteAdmin } from "reduxtoolkit/reducers/adminSlice";

const DeleteAdmin=({id, alert}: any)=>{
 
    const cancelRef = React.useRef();
    const dispatch= useDispatch();
    const toast = useToast();
    const onDeleteUser=()=>
	{		
		dispatch(deleteAdmin(id)).then((data:any)=>{
			if(data.type == "admin/DeleteAdmin/fulfilled") {
			toast({
				title: 'Success',
				description: "user Deleted successfully",
				status: 'success',
				duration: 9000,
				isClosable: true,
			  });
			}
			else{
				toast({
					title: 'Error',
					description:   `${data.payload.response.data.message}`,
					status: 'error',
					duration: 9000,
					isClosable: true,
				  });
			  }
		})
		alert.onClose();

	}
    return(
        <AlertBox 
							cancelRef={cancelRef} 
							alert={alert}
							header="Delete User?"
							alertBody="Are you sure you want to delete this user"
							onDelete={onDeleteUser}
							/>
    )
}
export { DeleteAdmin };
