import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex, Icon, Input } from "@chakra-ui/react";
import React, { useState } from "react";
import { useFieldArray } from "react-hook-form";
import { MdRemove } from "react-icons/md";

const DynamicInputForms = (props: any) => {
  const { form, label, ...rest } = props;
  const [numFields, setNumFields] = useState(1);

  const handleAddField = () => {
    append({ videoLinks: "" });
  };

  const handleRemoveField = (index: any) => {
    remove(index);
  };

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "videoLinks", // Replace with your actual field name
  });
  React.useEffect(() => {
    append({}); // Add an empty object or initial values here
  }, [append]);

  return (
    <>
      {fields.map((field, index) => (
        <div key={field.id}>

          <Flex>
            <Input
              fontWeight="500"
              variant="main"
              h="44px"
              my={2}
              maxH="44px"
              type="text"
              borderRadius="0px"
              placeholder="Add youtube video link"
              {...form.register(`videoLinks[${index}].videoLinks`)}
            />

            <Button mt={2} onClick={() => handleRemoveField(index)}>
              <Icon as={MdRemove} />
            </Button>
          </Flex>
        </div>
      ))}

      <Button onClick={handleAddField}>
        <AddIcon />
      </Button>
    </>
  );
};
export default DynamicInputForms;
