import React from 'react';

interface TableColumn {
  accessorKey: string;
  id?: string;
  header: string;
  Cell?: React.FC<{ renderedCellValue: any, row: any }>;
}

const questionsColumn: TableColumn[] = [
  {
    accessorKey: "number",
    id: "number",
    header: "number",
  },
  {
    accessorKey: "questionType",
    id: "questionType",
    header: "Question Type",
    Cell: ({ renderedCellValue, row }) => (
      <span>{row.original.questionType}</span>
    ),
  },
  {
    accessorKey: "grade",
    id: "grade",
    header: "GRADE",
  },
  {
    accessorKey: "subject",
    id: "subject",
    header: "Subject",
    Cell: ({ renderedCellValue, row }) => (
      <span>{row.original.subject}</span>
    ),
  },
  // Add other columns similarly
  {
    accessorKey: "answer",
    header: "answer",
  },
  {
    accessorKey: "description",
    header: "description",
  },
  {
    accessorKey: "metadata",
    id: "metadata",
    header: "metadata",
  },
];

export default questionsColumn;
