import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import PasswordInputForm from "components/Atoms/fields/passwordInputForm";
import PhoneNumberInput from "components/Atoms/fields/phoneNumberInputForm";
import TextInput from "components/Atoms/fields/TextInput";
import { MultiRoleSelect } from "components/Molecules/MultiRoleSelector";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createAdmin } from "reduxtoolkit/reducers/adminSlice";

const CreateAdmin = ({ roles, createUserModal }: any) => {
  const toast = useToast();
  const dispatch = useDispatch();
  useEffect(() => {
    if (createUserModal.isOpen === false) {
      reset();
      clearErrors();
    }
  }, [createUserModal.isOpen]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm();

  const onCreateUser = (values: any) => {
    const roles: any = [];
    values.roles.map((item: any) => {
      roles.push(item.label);
    });

    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      password: values.password,
      adminRole: roles,
    };
    dispatch(createAdmin(data)).then((data: any) => {
      if (data.type == "admin/createAdmin/fulfilled") {
        toast({
          title: "Success",
          description: "Admin Created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        reset();
        createUserModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  return (
    <Modal isOpen={createUserModal.isOpen} onClose={createUserModal.onClose}>
      <ModalOverlay />
      <ModalContent>
        <Box borderLeftWidth="3px" borderColor="#7F77F1">
          <form onSubmit={handleSubmit(onCreateUser)}>
            <ModalHeader>Add new Admin User</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <TextInput
                id="firstName"
                label="First Name"
                type="text"
                control={control}
                validation={{
                  required: "First Name is required.",
                  pattern: { value: /^[A-Za-z]+$/i, message: "Invalid Input" },
                }}
                errorMessage="Please enter your First Name."
              />
              {errors.firstName && (
                <Text color="red.500" mt={2} fontSize="sm">
                  {errors.firstName.message.toString()}
                </Text>
              )}
              <TextInput
                id="middleName"
                label="Middle Name"
                type="text"
                control={control}
                validation={{
                  required: "Middle Name is required.",
                  pattern: { value: /^[A-Za-z]+$/i, message: "Invalid Input" },
                }}
                errorMessage="Please enter your Middle Name."
              />
              {errors.middleName && (
                <Text color="red.500" mt={2} fontSize="sm">
                  {errors.middleName.message.toString()}
                </Text>
              )}
              <TextInput
                id="lastName"
                label="Last Name"
                type="text"
                control={control}
                validation={{
                  required: "Last Name is required.",
                  pattern: { value: /^[A-Za-z]+$/i, message: "Invalid Input" },
                }}
                errorMessage="Please enter your Last Name."
              />
              {errors.middleName && (
                <Text color="red.500" mt={2} fontSize="sm">
                  {errors.middleName.message.toString()}
                </Text>
              )}
              <PhoneNumberInput
                id="phoneNumber"
                label="Phone Number"
                control={control}
                validation={{
                  pattern: {
                    value: /^\+251\d{9}$/,
                    message:
                      "Phone number must start with +251 and be 12 digits long (including the country code)",
                  },
                }}
                errorMessage="Please enter your Phone Number"
              />
              {errors.phoneNumber && (
                <Text color="red.500" mt={2} fontSize="sm">
                  {errors.phoneNumber.message.toString()}
                </Text>
              )}
              <TextInput
                id="email"
                label="Email"
                type="email"
                control={control}
                validation={{
                  required: "Email is required.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid Input",
                  },
                }}
                errorMessage="Please enter your Email."
              />
              {errors.email && (
                <Text color="red.500" mt={2} fontSize="sm">
                  {errors.email.message.toString()}
                </Text>
              )}
              <PasswordInputForm
                id="password"
                label="Password"
                placeholder="Password"
                validation={{
                  required: "Password is required",
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_+=])[A-Za-z\d!@#$%^&*()\-_+=]{8,}$/,
                    message:
                      "Password must contain at least one uppercase letter, one lowercase letter, one special character, one number, and be at least 8 characters long",
                  },
                  minLength: {
                    value: 8,
                    message: "Minimum length should be 8",
                  },
                  maxLength: {
                    value: 32,
                    message: "Maximum length should be 32",
                  },
                }}
                errorMessage="Password should not be empty"
                control={control}
              />
              {errors.password && (
                <Text color="red">{errors.password.message.toString()}</Text>
              )}
              <MultiRoleSelect roles={roles} control={control} />
            </ModalBody>
            <ModalFooter>
              <PrimaryButton type="submit">Save</PrimaryButton>
            </ModalFooter>
          </form>
        </Box>
      </ModalContent>
    </Modal>
  );
};
export { CreateAdmin };
