import { Box } from '@chakra-ui/react';
import ExamsTable from 'components/organisms/ExamsTable';

export default function Overview() {

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<ExamsTable />
		</Box>
	);
}
