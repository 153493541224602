import {
  FormControl,
  FormLabel,
  Input,
  InputProps,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { Controller, useForm } from "react-hook-form";

interface PhoneNumberInputProps extends InputProps {
  id: any;
  label: string;
  defaultValue?: string;
  validation: any;
  control: any;
  errorMessage: any;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  id,
  label,
  defaultValue = "+251",
  validation,
  control,
}) => {
  const {
    formState: { errors },
  } = useForm();
  const defaultErrorMessage = "This field is required.";
  const errorColor = useColorModeValue("red.500", "red.200");
  const isTouched = !!errors[id];
  const hasError = isTouched && !!errors[id]?.message;

  const inputProps = {
    id,

    isInvalid: hasError,
    errorBorderColor: errorColor,
  };

  return (
    <FormControl id={id}>
      <FormLabel>{label}</FormLabel>

      <Controller
        render={({ field: { onChange, ref } }) => (
          <Input
            type="tel"
            defaultValue={defaultValue}
            autoComplete="off"
            {...inputProps}
            ref={ref}
            onChange={onChange}
          />
        )}
        name={id}
        control={control}
        rules={validation}
      />
    </FormControl>
  );
};

export default PhoneNumberInput;
