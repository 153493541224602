import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from 'utils/axiosMiddleware';

export const getComments:any =  createAsyncThunk(
  "comment/getData",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
      
    try {
      const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/comment/comments`,{headers: { Authorization: `Bearer ${token}` },
      },);
      
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  })

  export const updateComments:any =  createAsyncThunk(
    "comment/updateData",
    async (arg:any, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
      try {
        const data = await axios({
          method: 'put',
          url: `${process.env.REACT_APP_API_URL}/comment/update/${arg.id}`,
          headers: { Authorization: `Bearer ${token}` },
          data: arg.payload,
          
      });
      return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })

    export const deleteComments:any =  createAsyncThunk(
      "comment/deleteData",
      async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
        try {
     
          const data = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/comment/delete/${arg}`,
            headers: { Authorization: `Bearer ${token}` },
        });
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      })
      export const createComments:any =  createAsyncThunk(
        "admin/createComments",
        async (arg, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';  
          try {   
            const data = await axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URL}/comment/create`,
              headers: { Authorization: `Bearer ${token}` },
              data: arg,
          });
          return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })

const commentSlice:any = createSlice({
  name: "comment",
  initialState: {
    data: {},
    isSuccess: false,
    message: "",
    loading: false,
  },
  reducers: {
       

  },
  extraReducers: {
    [getComments.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getComments.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getComments.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [createComments.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createComments.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      
      state.data.push(payload.data.comment);
      state.isSuccess = true;
    },
    [createComments.rejected]: (state, { payload }) => {
      state.message = payload.data;
      state.loading = false;
      state.isSuccess = false;
    },
    [updateComments.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateComments.fulfilled]: (state:any, { payload , meta}) => {
     
     state.loading = false;
    
     const index = state.data.findIndex((comment:any) => comment.id === meta.arg.id);
      if (index !== -1) {

        state.data[index] = payload.data.comment;
      }
      state.isSuccess = true;
    },
    [updateComments.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [deleteComments.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteComments.fulfilled]: (state:any, action:any) => {
      state.loading = false;
      
      state.data = state.data.filter((comment:any) => comment.id !== action.meta.arg);
      state.isSuccess = true;
    },
    [deleteComments.rejected]: (state:any, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
  },
});

export default commentSlice;
