import { Box, FormControl, FormLabel, Icon, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';
import {
	createColumnHelper
} from '@tanstack/react-table';

import { PrimaryButton } from 'components/Atoms/Button/Buttons';
// Custom components
import TableContent from 'components/Atoms/Label/TableCell';
import TableHeader from 'components/Atoms/Label/TableHeader';
import AlertBox from 'components/Molecules/Alerts/AlertBox';
import PrimaryTable from 'components/Molecules/Tables/PrimaryTable';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { deleteGrade, getGrades, updateGrade } from 'reduxtoolkit/reducers/gradeSlice';




  
// Assets

type RowObj = {
	id: string;
	grade: string;
	actions: any;
};

const columnHelper = createColumnHelper<RowObj>();

const textColor = 'gray.400';

export default function ComplexTable() {
	
	const tableCellColor  = useColorModeValue("navy.700", "white");
const columns = [
	
	
	columnHelper.accessor('grade', {
		id: 'grade',
		header:  () => (<TableHeader textColor={textColor} title="GRADE"/>),
		cell:  (info) => (
			<TableContent textColor={tableCellColor} title={info.getValue()}/>
		  ),
	}),
	columnHelper.accessor('actions', {
		id: 'actions',
		header: () => (<TableHeader textColor={textColor} title="ACTIONS"/>),
		cell: (info) => (
			< >
			<Icon onClick={()=>handleEditGrade(info.row)} as={FaEdit} color='gray.400' /><Icon onClick={()=> handleDeleteGrade(info.row.original)} as={MdDeleteOutline} color='red' />
		</>
		)	
		}),	
];

	const form5 = useForm();
	const {
		
	handleSubmit: handleSubmit5 ,reset ,getValues} = form5;
	
	const [updated, setUpdated] = useState(false);	
	const [id, setId]= useState<any>("");
	
	



	const toast = useToast();

	const onEditGrades=(values: any)=>{
		
     
		const data = {
			id: id,
			payload:{ grade: values.grade},
		}
	    
		dispatch(updateGrade(data)).then((res:any)=>{
			if(res.type == "grade/updateData/fulfilled")
			{
				toast({
					title: 'Success',
					description: "Grade Updated successfully",
					status: 'success',
					duration: 9000,
					isClosable: true,
				  });
			}
			else {
				toast({
					title: 'Error',
					description:   `${res.payload.response.data.message}`,
					status: 'error',
					duration: 9000,
					isClosable: true,
				  });
			}
			editGradeModal.onClose();
		});
		
	}


	const onDeleteGrade=(value:any)=>
	{
		setId(getValues('id'));
		
		dispatch(deleteGrade(id)).then((res:any)=>{
			if(res.type == "grade/deleteData/fulfilled")
			{
				toast({
					title: 'Success',
					description: "Grade deleted successfully",
					status: 'success',
					duration: 9000,
					isClosable: true,
				  });
			}
			else {
				toast({
					title: 'Error',
					description:   `${res.payload.response.data.message}`,
					status: 'error',
					duration: 9000,
					isClosable: true,
				  });
			}
		});
	
	}
	const loading = false;
	const getGradesData:any = useSelector((state:any) => state.grade)
	const dispatch = useDispatch();
	useEffect(()=>{
		dispatch(getGrades());
	},[])
    useEffect(()=>{
		if(getGradesData.isSuccess == true)
        setData(getGradesData?.grade);
	},[getGradesData.loading])

		
	const handleEditGrade=(value:any)=>{
		setId(value.original.id);
		const data =value.original;;
		reset({
			grade: data.grade,
		})
		editGradeModal.onOpen();
		}

     const alert = useDisclosure();
	 const cancelRef = React.useRef()
	const handleDeleteGrade=(value:any)=>{
		 setId(value.id);
		 alert.onOpen();
	}
	const editGradeModal = useDisclosure();
	const [data, setData] = React.useState([]);

	return (
		<Box borderRadius='0px' flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
		
			<Box>
			{ loading ?  <Spinner size='lg' /> :
				<PrimaryTable id="grades" data={data} columns={columns} />
				}
					<Modal 
					
					isOpen={editGradeModal.isOpen}
					onClose={editGradeModal.onClose}>
						<ModalOverlay />
						<ModalContent>
						<form onSubmit={handleSubmit5(onEditGrades)}>
						<ModalHeader>Edit Grade</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<FormControl mt={4}>
							<FormLabel>Grade Name</FormLabel>
							<Input
								id='grade'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="Grade Name"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...form5.register('grade', {
									required: 'This is required',
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})}
								/>
							</FormControl>
						</ModalBody>

						<ModalFooter>
						<PrimaryButton type='submit'>
            Save
            </PrimaryButton>
							
						</ModalFooter>
						</form>
						</ModalContent>
	
					</Modal>
					<AlertBox 
							cancelRef={cancelRef} 
							alert={alert}
							header="Delete Grade?"
							alertBody="Are you sure you want to delete this grade"
							onDelete={onDeleteGrade}
							/>
			</Box>
		</Box>
	);
}

	


