import { MdBadge, MdDashboard, MdDirections, MdEventNote, MdLibraryBooks, MdLightbulbOutline, MdLocalMall, MdMailLock, MdNotificationsActive, MdOutlinePayment, MdSecurity, MdSettings } from 'react-icons/md';

// Admin Imports
import MainDashboard from 'screens/admin/default';
import Exams from 'screens/admin/exams';
import GeneralConfigs from 'screens/admin/general config';
import Questions from 'screens/admin/questions';
import TeamManagement from 'screens/admin/teamManagement';
import UserManagement from 'screens/admin/userManagement';

import { FaUsers } from 'react-icons/fa';
import ChallengePhase from 'screens/admin/challengephase';
import Comments from 'screens/admin/comments';
import DataTables from 'screens/admin/dataTables';
import FAQs from 'screens/admin/faq';
import Feedback from 'screens/admin/feedbacks';
import Grades from 'screens/admin/grades';
import Notification from 'screens/admin/notification';
import Payment from 'screens/admin/payment';
import DirectPayment from 'screens/admin/paymentbyReference';

import Profile from 'screens/admin/profile';
import Region from 'screens/admin/regions';
import Studies from 'screens/admin/studies';
import Tips from 'screens/admin/tips';

const routes = [
	
	    {
		name: 'Dashboard',
		layout: '/admin',
		path: '/default',
		icon: MdDashboard,
		component: MainDashboard,
		roles: ["SUPERADMIN","ANALYTICSADMIN" ,"USERADMIN", "CONTENTADMIN"],
		
	   }
	,
	
	{
		name: 'Admin Management',
		layout: '/admin',
		path: '/team-management',
		icon: MdSecurity,
		component: TeamManagement,
		secondary: true,
		roles: ["SUPERADMIN"],
		
	},

	{
		name: 'User Management',
		layout: '/admin',
		path: '/user-management',
		icon: FaUsers,
		component: UserManagement,
		secondary: true,
		roles: ["SUPERADMIN", "USERADMIN"]
	},
	
	{
		name: 'Subject Management',
		layout: '/admin',
		icon: FaUsers,
		path: '/subject',
		component: DataTables,
		roles: ["SUPERADMIN","CONTENTADMIN",]
	},
	{
		name: 'Studies',
		layout: '/admin',
		path: '/studies',
		icon: MdLocalMall,
		component: Studies,
		roles: ["SUPERADMIN","CONTENTADMIN"]
	},
	{
		name: 'Tips',
		layout: '/admin',
		path: '/tips',
		icon: MdLightbulbOutline,
		component: Tips,
		roles: ["SUPERADMIN","CONTENTADMIN"]
	},
	{
		name: 'Grades',
		layout: '/admin',
		path: '/grades',
		icon: MdLibraryBooks,
		component: Grades,
		roles: ["SUPERADMIN","CONTENTADMIN"]
	},
		
	{
		name: 'Region',
		layout: '/admin',
		path: '/regions',
		icon: MdLibraryBooks,
		component: Region,
		roles: ["SUPERADMIN"]
	},
	{	
		name: 'General Configs',
		layout: '/admin',
		path: '/generalConfigs',
		icon: MdSettings,
		component: GeneralConfigs,
		roles: ["SUPERADMIN","CONTENTADMIN"]
	},
	{	
		name: 'Questions',
		layout: '/admin',
		path: '/questions',
		icon: MdMailLock,
		component: Questions,
		roles: ["SUPERADMIN","CONTENTADMIN"]
	},
	{	
		name: 'Exams',
		layout: '/admin',
		path: '/exams',
		icon: MdEventNote,
		component: Exams,
		roles: ["SUPERADMIN","CONTENTADMIN"]
	},

	{
		name: 'Notification',
		layout: '/admin',
		path: '/notifications',
		icon: MdNotificationsActive,
		component: Notification,
		roles: ["SUPERADMIN","USERADMIN","CONTENTADMIN"]
	},

	{
		name: 'Payment',
		layout: '/admin',
		path: '/payment',
		icon: MdOutlinePayment,
		component: Payment,
		roles: ["SUPERADMIN","USERADMIN","ANALYTICSADMIN"]
	},
	{
		name: 'Direct Payment',
		layout: '/admin',
		path: '/directpayment',
		icon: MdOutlinePayment,
		component: DirectPayment,
		roles: ["SUPERADMIN","USERADMIN","ANALYTICSADMIN"]
	},
	{
		name: 'Challenge Phase',
		layout: '/admin',
		path: '/challengephase',
		icon: MdDirections,
		component: ChallengePhase,
		roles: ["SUPERADMIN","CONTENTADMIN"]
	},
	{
		name: 'FAQ',
		layout: '/admin',
		path: '/faq',
		icon: MdBadge,
		component: FAQs,
		roles: ["SUPERADMIN","CONTENTADMIN"]
	},
	{
		name: 'Comments',
		layout: '/admin',
		path: '/comments',
		icon: MdBadge,
		component: Comments,
		roles: ["SUPERADMIN","CONTENTADMIN"]
	},
	{
		name: 'Feedbacks',
		layout: '/admin',
		path: '/feedback',
		icon: MdBadge,
		component: Feedback,
		roles: ["SUPERADMIN","CONTENTADMIN"]
	},
	{
		name: 'Profiles',
		layout: '/admin',
		path: '/profiles',
		icon: MdBadge,
		component: Profile,
		roles: ["SUPERADMIN","ANALYTICSADMIN" ,"USERADMIN", "CONTENTADMIN"]
	},
];
export default routes;
