// Chakra imports
import { Flex, Select, Text } from '@chakra-ui/react';

// Custom components

import { HSeparator } from 'components/Atoms/separator/Separator';
import { PrimaryText } from 'components/Atoms/Title/Titles';
import { useAppSelector } from 'hook';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateLogin } from 'reduxtoolkit/reducers/authSlice';

export function SidebarBrand() {

	const user = useSelector((state: any) => state.user)
	const dispatch = useDispatch()

	const testroles = useAppSelector((state: any) => state.auth.user);


	const userRoles = Object.values(testroles);

	const [roles, setRoles] = useState<any>([]);
	const [userProfile, setUserProfile] = useState<any>([]);
	const [currentUser, setCurrentUser] = useState<any>([]);

	//   Chakra color mode

	const loggedInas: any = useAppSelector((state: any) => state.auth.user.loggedInAs)
	const adminRoles: any = useAppSelector((state: any) => state.auth.user.adminRole)
	const adminUser: any = useAppSelector((state: any) => state.auth.user);
	useEffect(() => {
		setCurrentUser(adminUser);
		setRoles(adminRoles);

	}, [])

	const handleRoleChange = (value: any) => {
		dispatch(updateLogin(value.target.value))
	}


	return (
		<Flex alignItems='center' flexDirection='column'>
			<PrimaryText>Exam Time Admin</PrimaryText>
			<Text>{`${currentUser.firstName} ${currentUser.lastName}`}</Text>
			<Flex>
				<Text mx="10px">as: </Text>
				<Select value={loggedInas} variant='unstyled' onChange={(e) => handleRoleChange(e)}>
					{roles?.map((role: any, key: number) => <option value={role.adminRole} key={key}>{role.adminRole}</option>)}
				</Select>
			</Flex>
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
