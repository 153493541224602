import {
  Box,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import PhoneNumberInput from "components/Atoms/fields/phoneNumberInputForm";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getSubscriptionPackages } from "reduxtoolkit/reducers/paymentSlice";
import { initiateManualPayment } from "reduxtoolkit/reducers/userSlice";

const UserManualPayment = ({
  manualPaymentModal,

  selectedUser,
}: any) => {
  const dispatch = useDispatch();
  const [packages, setPackages] = useState([]);
  useEffect(() => {
    dispatch(getSubscriptionPackages()).then((res: any) => {
      setPackages(res.payload);
    });
  }, []);
  const toast = useToast();
  const manualPaymentForm = useForm();
  const onManualPayment = (values: any) => {
    const data = {
      phoneNumber: values.phoneNumber,
      subscriptionPackageId: values.subscriptionPackageId,
    };

    dispatch(initiateManualPayment(data)).then((res: any) => {
      if (res.type === "user/initiateManualPayment/fulfilled") {
        toast({
          title: "Success",
          description: "Payed successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: `${res.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
    manualPaymentModal.onClose();
  };
  const {
    handleSubmit: handleSubmit,
    formState: { errors },
    register,
    control,
  } = manualPaymentForm;
  return (
    <Modal
      isOpen={manualPaymentModal.isOpen}
      onClose={manualPaymentModal.onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <Box borderLeftWidth="3px" borderColor="#7F77F1">
          <form onSubmit={handleSubmit(onManualPayment)}>
            <ModalHeader>Manual Payment</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <PhoneNumberInput
                id="phoneNumber"
                label="Phone Number"
                control={control}
                validation={{
                  pattern: {
                    value: /^\+251\d{9}$/,
                    message:
                      "Phone number must start with +251 and be 12 digits long (including the country code)",
                  },
                }}
                errorMessage="Please enter your Phone Number"
              />
              {errors.phoneNumber && (
                <Text color="red">{errors.phoneNumber.message.toString()}</Text>
              )}
              <FormControl mt={4}>
                <Select
                  name="subscriptionPackageId"
                  id="subscriptionPackageId"
                  className="select-input"
                  placeholder="select Package"
                  {...manualPaymentForm.register("subscriptionPackageId", {})}
                  _placeholder={{
                    fontWeight: "400",
                    color: "secondaryGray.600",
                  }}
                  variant="filled"
                >
                  {packages.map((pack: any) => {
                    return (
                      <option key={pack.id} value={pack.id}>
                        {pack.subscriptionPlan}
                      </option>
                    );
                  })}
                </Select>

                {errors.package && (
                  <Text color="red">{errors.package.message.toString()}</Text>
                )}
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <PrimaryButton type="submit">Save</PrimaryButton>
            </ModalFooter>
          </form>
        </Box>
      </ModalContent>
    </Modal>
  );
};
export default UserManualPayment;
