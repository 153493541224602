

// Chakra imports
import { Box, Flex, Progress, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSubjects } from 'reduxtoolkit/reducers/subjectSlice';

import SubjectsManagementTable from 'screens/admin/dataTables/components/SubjectManagement';
import SubjectsTable from 'screens/admin/dataTables/components/SubjectsTable';

import subjectTableDataComplex from 'screens/admin/dataTables/variables/subjectTableData';
export default function Settings() {
	const subjects = useSelector((state:any) => state.subject);
	const dispatch = useDispatch();
	useEffect(()=>{
		dispatch(getSubjects());
		
	},[])

	const [loading, setLoading] = useState(true);
	const createProgressModal = useDisclosure();
	useEffect(() => {
		
		if(subjects.loading == true){
		
		  setLoading(true);
		  createProgressModal.onOpen();
		}
		else {
		   setLoading(false);
		   createProgressModal.onClose();
		}
	  }, [subjects.loading]);
	// Chakra Color Mode
	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>

			
			{ loading ?<Progress id="admin" w='1/2' isIndeterminate />: null}
		
			<Flex justify="space-evenly" gap='0' direction="row">
				<Box w='28%'>
				<SubjectsTable   tableData={subjectTableDataComplex} />
				</Box>
				<Box w='70%'>
				<SubjectsManagementTable />

			</Box>
			</Flex>
		</Box>
	);
}
