import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
// Custom components
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { generateExam } from "reduxtoolkit/reducers/examGenerateSlice";

const GenerateExamForm = ({
  subjects,
  units,
  sections,
  grades,
  years,
  generateExamsModal,
  examVersions,
}: any) => {
  const dispatch = useDispatch();

  const toast = useToast();
  const onGenerateExam = (values: any) => {
    var arr: any[] = [];
    const data = {
      examName: values.examName,
      grade: selectedGrade,
      subject: values.subject,
      year: values.year,
      examVersion: values.examVersion,
      duration: Number(values.duration),
      passingScore: Number(values.passingScore),
      noOfQuestions: Number(values.noOfQuestions),
      selecteQuestions: arr,
      examType: values.examType,
    };
    dispatch(generateExam(data)).then((data: any) => {
      if (data.type === "exam/GenerateExam/fulfilled") {
        toast({
          title: "Success",
          description: "Exam Generated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      generateExamsModal.onClose();
    });
  };
  const [selectedGrade, setSelectedGrade] = useState("");
  useEffect(() => {
    setSelectedGrade(grades[0].grade);
  }, []);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors: examErrors },
  } = useForm();

  return (
    <form onSubmit={handleSubmit(onGenerateExam)}>
      <FormControl mt={4}>
        <FormLabel>Grade </FormLabel>
        <Controller
          name="grade"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Select
              {...field}
              value={selectedGrade}
              onChange={(e: any) => {
                setSelectedGrade(e.target.value);
              }}
            >
              {grades?.map((item: any, index: any) => (
                <option key={item.id} value={item.grade}>
                  {item.grade}
                </option>
              ))}
            </Select>
          )}
        />
        {examErrors.grade && (
          <Text color="red">{examErrors.grade.message.toString()}</Text>
        )}
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>Exam Name</FormLabel>
        <Input
          id="number"
          fontWeight="500"
          variant="main"
          h="44px"
          maxH="44px"
          type="text"
          placeholder="Exam Name"
          _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
          {...register("examName", {
            required: "Exam name is required",
            minLength: { value: 0, message: "Minimum length should be 4" },
          })}
        />
        {examErrors.examName && (
          <Text color="red">{examErrors.examName.message.toString()}</Text>
        )}
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>Exam TYpe</FormLabel>
        <Select
          className="select-input"
          _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
          {...register("examType", {
            required: "Exam type is required",
            minLength: { value: 0, message: "Minimum length should be 4" },
          })}
          variant="unstyled"
        >
          <option key="exam" value="EXAM">
            Exam
          </option>
          <option key="custom" value="CUSTOM">
            Custom
          </option>
        </Select>
        {examErrors.examType && (
          <Text color="red">{examErrors.examType.message.toString()}</Text>
        )}
      </FormControl>

      <FormControl mt={4}>
        <FormLabel>Subject</FormLabel>
        <Select
          placeholder="Select Subject"
          className="select-input"
          _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
          {...register("subject", {
            required: "Subject is required",
            minLength: { value: 0, message: "Minimum length should be 4" },
          })}
          variant="unstyled"
        >
          {subjects
            ?.filter((subject: any) => subject?.grade?.grade === selectedGrade)
            .map((item: any, index: any) => (
              <option key={item.id} value={item.subject.subject}>
                {item.subject.subject}
              </option>
            ))}
        </Select>
        {examErrors.subject && (
          <Text color="red">{examErrors.subject.message.toString()}</Text>
        )}
      </FormControl>

      <FormControl mt={4}>
        <FormLabel>Year</FormLabel>
        <Select
          className="select-input"
          placeholder="Select year"
          _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
          {...register("year", {
            required: "Year is required",
            minLength: { value: 0, message: "Minimum length should be 4" },
          })}
          variant="unstyled"
        >
          {years?.map((item: any) => (
            <option key={item.id} value={item.year}>
              {item.year}
            </option>
          ))}
        </Select>
        {examErrors.year && (
          <Text color="red">{examErrors.year.message.toString()}</Text>
        )}
      </FormControl>

      <FormControl mt={4}>
        <FormLabel>Exam Version</FormLabel>
        <Select
          placeholder="Select Exam version"
          className="select-input"
          _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
          {...register("examVersion", {
            required: "ExamVersion is required",
            minLength: { value: 0, message: "Minimum length should be 4" },
          })}
          variant="unstyled"
        >
          {examVersions?.map((item: any) => (
            <option key={item.id} value={item.examVersion}>
              {item.examVersion}
            </option>
          ))}
        </Select>
        {examErrors.examVersion && (
          <Text color="red">{examErrors.examVersion.message.toString()}</Text>
        )}
      </FormControl>

      <FormControl mt={4}>
        <FormLabel>Duration in Minutes</FormLabel>

        <NumberInput name="duration" defaultValue={0} min={0}>
          <NumberInputField {...register("duration")} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>

        {examErrors.duration && (
          <Text color="red">{examErrors.duration.message.toString()}</Text>
        )}
      </FormControl>

      <FormControl mt={4}>
        <FormLabel>Passing Score</FormLabel>
        <NumberInput name="passingScore" defaultValue={0} min={0}>
          <NumberInputField {...register("passingScore")} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        {examErrors.passingScore && (
          <Text color="red">{examErrors.passingScore.message.toString()}</Text>
        )}
      </FormControl>

      <FormControl mt={4}>
        <FormLabel>number Of Questions </FormLabel>

        <NumberInput name="noOfQuestions" defaultValue={0} min={0}>
          <NumberInputField {...register("noOfQuestions")} />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>

        {examErrors.noOfQuestions && (
          <Text color="red">{examErrors.noOfQuestions.message.toString()}</Text>
        )}
      </FormControl>
      <PrimaryButton type="submit">Save</PrimaryButton>
    </form>
  );
};

export default GenerateExamForm;
