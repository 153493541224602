import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from 'utils/axiosMiddleware';

export const getTips:any =  createAsyncThunk(
  "tip/getData",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
      
    try {
      const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/tips/tips`,
      {headers: { Authorization: `Bearer ${token}` },
      params: arg,
      },);
      
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  })

  export const updateTips:any =  createAsyncThunk(
    "tip/updateData",
    async (arg:any, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
      try {
        const data = await axios({
          method: 'put',
          url: `${process.env.REACT_APP_API_URL}/tips/update${arg.id}`,
          headers: { Authorization: `Bearer ${token}` },
          data: arg.payload,
          
      });
      return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })

    export const deleteTips:any =  createAsyncThunk(
      "tip/deleteData",
      async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
        try {
     
          const data:any = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/tips/delete/${arg}`,
            headers: { Authorization: `Bearer ${token}` },
        });
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      })
      export const createTips:any =  createAsyncThunk(
        "admin/createTips",
        async (arg, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';  
          try {   
            const data = await axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URL}/tips/create`,
              headers: { Authorization: `Bearer ${token}` },
              data: arg,
          });
          return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })

const tipSlice:any = createSlice({
  name: "tip",
  initialState: {
    data: {},
    isSuccess: false,
    message: "",
    loading: false,
  },
  reducers: {
       

  },
  extraReducers: {
    [getTips.pending]: (state, { payload }) => {
      state.loading = true;
      
    },
    [getTips.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getTips.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [createTips.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createTips.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      
      state.data.tips.push(payload.data.tip);
      state.isSuccess = true;
    },
    [createTips.rejected]: (state, { payload }) => {
      state.message = payload.data;
      state.loading = false;
      state.isSuccess = false;
    },
    [updateTips.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateTips.fulfilled]: (state:any, { payload , meta}) => {
     
     state.loading = false;
    
     const index = state.data.tips.findIndex((tip:any) => tip.id === meta.arg.id);
      if (index !== -1) {

        state.data.tips[index] = payload.data.tip;
      }
      state.isSuccess = true;
    },
    [updateTips.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [deleteTips.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteTips.fulfilled]: (state:any, action:any) => {
      state.loading = false;
      
      state.data.tips = state.data.tips.filter((tip:any) => tip.id !== action.meta.arg);
      state.isSuccess = true;
    },
    [deleteTips.rejected]: (state:any, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
  },
});

export default tipSlice;
