import { Button, Flex, Select, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createSingleChallengeDay } from "reduxtoolkit/reducers/challengeSlice";

const CreateSingleChallenge = ({
  id,
  subjects,
  units,
  sections,
  setReload,
  studies,
}: any) => {
  const { register, reset, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const toast = useToast();
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedStudy, setSelectedStudy] = useState("");
  const onSubmit = (data: any) => {
    const arg = { id: id, payload: data };
    dispatch(createSingleChallengeDay(arg)).then((data: any) => {
      if (data.type === "challenge/createSingleData/fulfilled") {
        toast({
          title: "Success",
          description: "Challenge Created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setSelectedSubject("");
        setSelectedStudy("");
        reset();
        setReload(true);
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex gap={3}>
        <Select
          w="50%"
          placeholder="Select Subject"
          value={selectedSubject}
          onChange={(e: any) => {
            setSelectedSubject(e.target.value);
          }}
        >
          {subjects.map((subject: any) => (
            <option key={subject.id} value={subject.subject.subject}>
              {subject.subject.subject}
            </option>
          ))}
        </Select>
        <Select
          w="50%"
          placeholder="Select Studies"
          value={selectedStudy}
          onChange={(e: any) => {
            setSelectedStudy(e.target.value);
            const selectedStudy = studies.find(
              (study: any) => study.id === e.target.value
            );
            setValue("subject", selectedStudy.subject.subject);
            setValue("unit", selectedStudy.unit.unit);
            setValue("section", selectedStudy.section.section);
          }}
        >
          {studies
            .filter(
              (challenge: any) =>
                challenge?.subject?.subject === selectedSubject
            )
            .map((study: any) => (
              <option key={study.id} value={study.id}>
                {study.title}
              </option>
            ))}
        </Select>
      </Flex>
      <Button type="submit">Add Challange</Button>
    </form>
  );
};

export default CreateSingleChallenge;
