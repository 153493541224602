// Chakra imports
import { Box, useDisclosure } from "@chakra-ui/react";
import { ProgressModal } from "components/Molecules/Modals/ProgressModal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NotificationsTable from "../dataTables/components/NotificationsTable";

// Assets

export default function Overview() {
  const [isOpen, setIsOpen] = useState(true);
  const notifications: any = useSelector((state: any) => state.notification);
  const createProgressModal = useDisclosure();
  useEffect(() => {
    if (notifications.loading === true) {
      setIsOpen(true);
    } else {
      setTimeout(() => {
        setIsOpen(false);
      }, 2000);
    }
  }, [notifications.loading]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ProgressModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <NotificationsTable />
    </Box>
  );
}
