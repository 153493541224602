import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from 'utils/axiosMiddleware';


export const getQuestions:any =  createAsyncThunk(
  "questions/getData",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
    
    try {
      const data:any = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/question/questionbyparameter?`,
        headers: { Authorization: `Bearer ${token}` },
        params: arg,
        
    });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  })

  export const unPublishQuestion:any = createAsyncThunk(
    "questions/unpublish",
    async (arg:any, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
      try {
        const data = await axios({
          method: 'put',
          url: `${process.env.REACT_APP_API_URL}/question/unpublish/${arg}`,
          headers: { Authorization: `Bearer ${token}` },
          
          
      });
      return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })

    export const publishQuestion:any = createAsyncThunk(
      "questions/publish",
      async (arg:any, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
        try {
          const data = await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/question/publish/${arg}`,
            headers: { Authorization: `Bearer ${token}` },
            
            
        });
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      })
      
  export const getQuestionById:any =  createAsyncThunk(
    "questions/getquestinbyId",
    async (arg, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
      
      try {
        const data:any = await axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_URL}/question/question/${arg}`,
          headers: { Authorization: `Bearer ${token}` },
         
          
      });
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })

  export const updateQuestion:any =  createAsyncThunk(
    "questions/updateData",
    async (arg:any, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
      try {
        const data = await axios({
          method: 'put',
          url: `${process.env.REACT_APP_API_URL}/question/update/${arg.id}`,
          headers: { Authorization: `Bearer ${token}` },
          data: arg.payload,
          
      });
      return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })

    export const deleteQuestion:any =  createAsyncThunk(
      "questions/deleteData",
      async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
        try {
     
          const data:any = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/question/delete/${arg}`,
            headers: { Authorization: `Bearer ${token}` },
        });
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      })
      
      export const createQuestion:any =  createAsyncThunk(
        "admin/createQuestins",
        async (arg, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';  
          try {   
            const data:any = await axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URL}/question/create`,
              headers: { Authorization: `Bearer ${token}` },
              data: arg,
          });
          return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })
        export const createBulkQuestion:any =  createAsyncThunk(
          "admin/createBulkQuestions",
          async (arg, { rejectWithValue }) => {
            const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';  
            try {   
              const data:any = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/question/bulk/create`,
                headers: { Authorization: `Bearer ${token}` },
                data: arg,
            });
            return data;
            } catch (error) {
              return rejectWithValue(error);
            }
          })

const questionSlice:any = createSlice({
  name: "questions",
  initialState: {
    data: [],
    isSuccess: false,
    message: "",
    loading: false,
  },
  reducers: {
       

  },
  extraReducers: {
    [getQuestions.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getQuestions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
      state.isSuccess = true;
    }, 
    [getQuestions.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [createQuestion.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createQuestion.fulfilled]: (state:any, { payload }) => {
      state.loading = false;    
      state.data.questions.push(payload.data.question);
      //  temp.data = {...temp.data, questions};
      // state.data = temp.data;
      state.isSuccess = true;
    },
    [createQuestion.rejected]: (state, { payload }) => {
      state.message = payload.data;
      state.loading = false;
      state.isSuccess = false;
    },
    [createBulkQuestion.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createBulkQuestion.fulfilled]: (state:any, { payload }) => {
      state.loading = false;    
    
      //  temp.data = {...temp.data, questions};
      // state.data = temp.data;
      state.isSuccess = true;
    },
    [createBulkQuestion.rejected]: (state, { payload }) => {
      state.message = payload.data;
      state.loading = false;
      state.isSuccess = false;
    },
    
    [updateQuestion.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateQuestion.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
     
          state.isSuccess = true;
    },
    [updateQuestion.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },

    [publishQuestion.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [publishQuestion.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      var temp = current(state);

      let questions = [...temp.data.questions];
      let index = questions.findIndex((question:any) => question.id === payload.data.question.id);
       questions[index] = payload.data.question;
      
     
       state.data = {...state.data, questions}
      state.isSuccess = true;
    },
    [publishQuestion.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },

    [unPublishQuestion.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [unPublishQuestion.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      var temp = current(state);
    
      let questions = [...temp.data.questions];
      let index = state.data.questions.findIndex((question:any) => question.id === payload.data.question.id);
       questions[index] = payload.data.question;
      
      
       state.data = {...state.data, questions}
      state.isSuccess = true;
    },
    [unPublishQuestion.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [deleteQuestion.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteQuestion.fulfilled]: (state:any, action) => {
      state.loading = false;
      
      let questions = [...state.data.questions];
      questions = questions.filter((question:any) => question.id !== action.meta.arg);
      state.data = {...state.data, questions}
      state.isSuccess = true;
    },
    [deleteQuestion.rejected]: (state:any, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
  },
});
export default questionSlice;
