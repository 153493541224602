import {
	Box,
	FormControl,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader, ModalOverlay, Select, Text
} from '@chakra-ui/react';
import { PrimaryButton } from 'components/Atoms/Button/Buttons';
import CustomReactQuill from 'components/Atoms/fields/PrimaryRichText';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { rtxModules } from 'utils/tableconstants';



const EditStudyForm=({editStudyModal, onEditStudy, editStudyForm, selectedGrade, subjects}: any)=>{
    const {
		handleSubmit: handleSubmitEditStudy , control , formState:{errors: err}  } = editStudyForm;
        const grades:any = useSelector((state:any) => state.grade.grade);
        const units:any = useSelector((state:any) => state.unit.data);
	    const years:any = useSelector((state:any) => state.year.data);
	    const sections:any = useSelector((state:any) => state.section.data);
        const [themes, setTheme] = useState("snow");

     
		  return(
   			 <Modal 
					isOpen={editStudyModal.isOpen}
					onClose={editStudyModal.onClose}>
						<ModalOverlay />
						<ModalContent>
						<Box borderLeftWidth='3px' borderColor='#7F77F1'>
						<form onSubmit={handleSubmitEditStudy(onEditStudy)}>
						<ModalHeader>Editing Study</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
						<FormControl>
							<FormLabel>Title</FormLabel>
							<Input
									id='title'
									fontWeight='500'
									variant='main'
									h='44px'
									maxH='44px' 
									type="text"
									placeholder="Title"
									_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
									{ ...editStudyForm.register('title', {
										required: 'This is required',
									
									})}
									/> 
            			     {err.title && <Text color='red'>{err.title.message.toString()}</Text>}
							 </FormControl>
                             <FormControl>
							<FormLabel>Objective</FormLabel>
							<Controller
									name="objective"
									control={control}
									rules={{
										required: "Please enter Objective",
									}}
									render={({ field }) => (
										<CustomReactQuill
										field={field}
										themes={themes}
										modules={rtxModules}
										defaultValue="B"
										placeholder="Enter Objective"
										onChange={(text: string) => {
											field.onChange(text); 
										}}
										/>
									)}
									/>
						
						
            			     {err.objective && <Text color='red'>{err.objective.message.toString()}</Text>}
							 </FormControl>
                             <FormControl mt={4}>
							<FormLabel>Subject</FormLabel>
							<Select
									className='select-input'
									placeholder="Select Subjects"
									{ ...editStudyForm.register('subject', {
										required: 'Subject is required',
										minLength: { value: 0, message: 'Minimum length should be 4' },
									})}
									>
									  {subjects?.map((item:any) => (
								<option key={item.id} value={item.subject.subject}>{item.subject.subject}</option>
								))}
									</Select>
							</FormControl> 

							
                            <FormControl mt={4}>
							<FormLabel>Unit</FormLabel>
							<Select
									className='select-input'
									placeholder="Select Unit"
									{ ...editStudyForm.register('unit', {
										required: 'This is required',
										minLength: { value: 0, message: 'Minimum length should be 4' },
									})}
									>
										{units?.map((unit:any) => <option key={unit.id}>{unit.unit}</option>)}
									</Select>
							</FormControl> 
                            <FormControl mt={4}>
							<FormLabel>section</FormLabel>
							<Select
									className='select-input'
									placeholder="Select section"
									{ ...editStudyForm.register('section', {
										required: 'This is required',
										minLength: { value: 0, message: 'Minimum length should be 4' },
									})}
									>
										{sections?.map((section:any) => <option key={section.id}>{section.section}</option>)}
									</Select>
							</FormControl> 
                          
						
						</ModalBody>

						<ModalFooter>
						<PrimaryButton type='submit'>
            Save
            </PrimaryButton>
							
						</ModalFooter>
						</form>
						</Box>
						</ModalContent>
	
					</Modal>
	        
 )
}
export default EditStudyForm;