// Chakra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/Molecules/card/Card";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch } from "react-redux";
import { getSubscribersByRegion } from "reduxtoolkit/reducers/dashboardSlice";
const SubscribersByRegion = () => {
    const [data, setData] = useState([]);
    const [regions, setRegions] = useState([]);
    const [counts,  setCounts] = useState(0);
 const dispatch = useDispatch();
  useEffect(() => {
   
        dispatch(getSubscribersByRegion()).then((res:any)=>{
          
           setCounts(res.payload?.subscriberCountByRegion.map((item:any) => item.count));
          setRegions(res.payload?.subscriberCountByRegion.map((item:any) => item.region))
        },
    )
    
   
   
  }, []);
  const options = {
    labels: regions,
    chart: {
        width: "50px",
      },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };

  const series: any = counts;
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card 
        p="20px" 
        alignItems="center" 
        flexDirection="column" 
        w="100%">
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
         Subscribers By Region
        </Text>
      </Flex>
      
      <Chart
        options={options}
        series={series}
        type="pie"
        width="500"
      />
    </Card>
  );
};
export default SubscribersByRegion;
