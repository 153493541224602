import { Select } from '@chakra-ui/react';

interface SelectProps {
  options: any[];
  placeholder: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  variant?: 'outline' | 'unstyled' | 'flushed' | 'filled';
  value: string;
  onChange: any;
}
const SingleSelect:  React.FC<SelectProps> = ({ options, placeholder, value, onChange, ...props }: any) => {
  return (
     <Select placeholder={placeholder} onChange={onChange} {...props}>
    {options.map((value:any) => (
      <option key={value} value={value}>
        {value}
      </option>
    ))}
  </Select>
     );
};
export { SingleSelect };
