// Chakra imports
import { Avatar, Box, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/Molecules/card/Card';
import { useAppSelector } from 'hook';
import { useEffect, useState } from 'react';
import Information from './Information';


export default function Banner(props: {
	banner: string;
	avatar: string;
	name: string;
	profile: any;
	[x: string]: any;
}) {
	const { banner, avatar, name, profile,...rest } = props;
	// Chakra Color Mode
	const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
	const bg = useColorModeValue('white', 'navy.700');
	const textColorSecondary = 'gray.400';
	const infoShadow = useColorModeValue('0px 18px 40px rgba(112, 144, 176, 0.12)', 'unset');
   
	const [userRoles, setUserRoles] = useState<any>([]);
	const rolesData:any = useAppSelector((state: any)=> state.auth.user.adminRole);
	useEffect(()=>{
		setUserRoles(rolesData);
		
	},[])
	return (
		<Card mb={{ base: '0px', lg: '20px' }} w='100%'  alignItems='center' {...rest}>
			<Box bg={`url(${banner})`} bgSize='cover' borderRadius='16px' h='131px' w='100%' />
			
			<Avatar
						_hover={{ cursor: 'pointer' }}
						color='white'
						name={name}
						bg='#11047A'
						size='sm'
						w='40px'
						h='40px'
					/>
			
			<Text color={textColorPrimary} fontWeight='bold' fontSize='xl' mt='10px'>
				{name}
			</Text>
			<Card bg={bg} {...rest}>
				 <Box>
					<Text fontWeight='500' color={textColorSecondary} fontSize={{sm: '10px', md: '10px', lg: '14'}}>
						Role
					</Text>			
					{userRoles.map((role: any)=>( 
						<Text key={role.id}>{role.adminRole}</Text>
				 ))}   
				</Box> 
			</Card>
			<Information mt="10px" boxShadow={infoShadow} title='Phone Number' value={profile.phoneNumber} />
			<Information mt="10px" boxShadow={infoShadow} title='Email' value={profile.email} />
		</Card>
	);
}
