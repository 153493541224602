// Custom components
import {
  Pagination, PaginationContainer, PaginationPage, PaginationPageGroup, PaginationSeparator
} from "@ajna/pagination";
import { Flex, Icon, Select } from '@chakra-ui/react';
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

const MainPagination =({pagesCount ,currentPage, handlePageChange, handleBackPagination, handleForwardPagination, pages, handlePageSizeChange }: any)=> {
 
 
	
  return (
    <Flex w="100%" py="10">
  
<Pagination
        pagesCount={pagesCount}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        
        >
    <PaginationContainer mx="auto">
    <PrimaryButton
 
 variant="outline"
  size='sm'
  onClick={() =>
    handleBackPagination(currentPage)
  }
>  
<Icon as={MdArrowBackIos}/>
</PrimaryButton>
    <PaginationPageGroup
        isInline
        align="center"
        separator={
            <PaginationSeparator
            onClick={() =>
               {}
            }
            bg="blue.300"
            fontSize="sm"
            w={7}
            mx={2}
            jumpSize={11}
            />
        }
        >
        {pages.map((page: number) => (
        <PaginationPage 
           
        border= 'solid 0px #1890FF'
        mx={2}
        key={`pagination_page_${page}`}
        page={page}
        onClick={() =>
        {}
        }
        borderRadius='0'
        fontSize="sm"
        _hover={{
          bg: "gray.300"
        }}
        _current={{                                       
              
          border: 'solid 1px #1890FF',                                          
          fontSize: "sm",
          w: 7
        }}
        />
        ))}
    </PaginationPageGroup>
    <PrimaryButton
  variant="outline"
   size='sm'
   onClick={() =>
    handleForwardPagination(currentPage)
   }
   > <Icon as={MdArrowForwardIos}/> </PrimaryButton>
    </PaginationContainer>
</Pagination>
<Select ml={3} onChange={handlePageSizeChange} w={40}>
            <option value="10">10 / page</option>
            <option value="25">25 / page</option>
            <option value="50">50 / page</option>
            <option value="100">100 / page</option>
        </Select>
</Flex>
  )
}

export { MainPagination };

