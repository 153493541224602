import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";

import { PrimaryButton } from "components/Atoms/Button/Buttons";
import { PrimaryLabel } from "components/Atoms/Label/Labels";
// Custom components
import { usePagination } from "@ajna/pagination";
import TableContent from "components/Atoms/Label/TableCell";
import TableHeader from "components/Atoms/Label/TableHeader";
import AlertBox from "components/Molecules/Alerts/AlertBox";
import { ProgressModal } from "components/Molecules/Modals/ProgressModal";
import { MainPagination } from "components/Molecules/Pagination";
import PrimaryTable from "components/Molecules/Tables/PrimaryTable";
import EditExamsForm from "components/Templates/exams/editExamsForm";
import GenerateExamForm from "components/Templates/exams/generateExamsForm";
import ExamMobilePreview from "components/Templates/ExamsMobilePreview";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import { MdDeleteOutline, MdPhoneIphone, MdRemoveRedEye } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  deleteExam,
  getExam,
  publishExam,
  unPublishExam
} from "reduxtoolkit/reducers/examGenerateSlice";
import { getexamVersion } from "reduxtoolkit/reducers/examVersionSlice";
import { getGrades } from "reduxtoolkit/reducers/gradeSlice";
import { getSection } from "reduxtoolkit/reducers/sectionSlice";
import { getSubjectsManagement } from "reduxtoolkit/reducers/subjectManagementSlice";
import { getUnits } from "reduxtoolkit/reducers/unitSlice";
import { getYear } from "reduxtoolkit/reducers/yearSlice";

// Assets

type RowObj = {
  duration: number;
  subject: any;
  examName: string;
  noOfQuestions: number;
  addedQuestions: number;
  passingScore: number;
  year: any;
  isPublished: boolean;
  actions: any;
};

const columnHelper = createColumnHelper<RowObj>();

const textColor = "gray.400";

const ExamsTable = () => {
  const [isOpen, setIsOpen] = useState(true);
  const tableCellColor = useColorModeValue("navy.700", "white");
  const columns = [
    columnHelper.accessor("examName", {
      id: "examName",
      header: () => <TableHeader textColor={textColor} title="examName" />,
      cell: (info) => (
        <TableContent textColor={tableCellColor} title={info.getValue()} />
      ),
    }),
    columnHelper.accessor("subject", {
      id: "subject",
      header: () => <TableHeader title="SUBJECT" />,
      cell: (info) => (
        <TableContent title={info.row.original?.subject?.subject} />
      ),
    }),
    columnHelper.accessor("year", {
      id: "year",
      header: () => <TableHeader textColor={textColor} title="Year" />,
      cell: (info) => <TableContent title={info.row.original?.year?.year} />,
    }),
    columnHelper.accessor("passingScore", {
      id: "passingScore",
      header: () => <TableHeader textColor={textColor} title="passing Score" />,
      cell: (info) => (
        <TableContent textColor={tableCellColor} title={info.getValue()} />
      ),
    }),
    columnHelper.accessor("noOfQuestions", {
      id: "noOfQuestions",
      header: () => <TableHeader textColor={textColor} title="Total" />,
      cell: (info) => (
        <TableContent textColor={tableCellColor} title={info.getValue()} />
      ),
    }),
    columnHelper.accessor("addedQuestions", {
      id: "addedQuestions",
      header: () => <TableHeader textColor={textColor} title="Added" />,
      cell: (info) => (
        <TableContent textColor={tableCellColor} title={info.getValue()} />
      ),
    }),
    columnHelper.accessor("duration", {
      id: "duration",
      header: () => <TableHeader textColor={textColor} title="Duration" />,
      cell: (info) => (
        <TableContent textColor={tableCellColor} title={info.getValue()} />
      ),
    }),
    columnHelper.accessor("isPublished", {
      id: "isPublished",
      header: () => <TableHeader textColor={textColor} title="IsPublished" />,
      cell: (info) => (
        <>
          <Switch
            size="md"
            onChange={() => handleExamPublish(info.row.original)}
            isChecked={info.row.original.isPublished}
          />
        </>
      ),
    }),
    columnHelper.accessor("actions", {
      id: "actions",
      header: () => <TableHeader textColor={textColor} title="ACTIONS" />,
      cell: (info) => (
        <>
          <Icon
            boxSize={6}
            onClick={() => handleEditExam(info.row.original)}
            as={FaEdit}
            color="gray.400"
          />
          <Icon
            boxSize={6}
            onClick={() => handlePreviewMobile(info.row.original)}
            as={MdPhoneIphone}
          />
          <Icon
            boxSize={6}
            onClick={() => handlePreviewExam(info.row.original)}
            as={MdRemoveRedEye}
          />
          <Icon
            boxSize={6}
            onClick={() => handleDeleteExam(info.row.original)}
            as={MdDeleteOutline}
            color="red"
          />
        </>
      ),
    }),
  ];

  const handleExamPublish = (values: any) => {
    if (values.isPublished) {
      dispatch(unPublishExam(values.id)).then((data: any) => {
        if (data.type == "exam/unPublish/fulfilled") {
          toast({
            title: "Success",
            description: "Exam UnPublished successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: `${data.payload.response.data.message}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      });
      setUpdated(!updated);
    } else {
      dispatch(publishExam(values.id)).then((data: any) => {
        if (data.type == "exam/Publish/fulfilled") {
          toast({
            title: "Success",
            description: "Exam Published successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: `${data.payload.response.data.message}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      });
      setUpdated(!updated);
    }
  };
  const [subjectParam, setSubjectParam] = useState<any>();
  const [gradeParam, setGradeParam] = useState<any>();
  const [yearParam, setYearParam] = useState<any>();
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [years, setYears] = useState([]);
  const [units, setUnits] = useState([]);
  const [sections, setSections] = useState([]);
  const [totalExam, setTotalExam] = useState(1);
  const exams: any = useSelector((state: any) => state.exam);
  const gradesData: any = useSelector((state: any) => state.grade);
  const subjectsData = useSelector((state: any) => state.subjectManagement);
  const unitsData: any = useSelector((state: any) => state.unit);
  const yearsData: any = useSelector((state: any) => state.year);
  const sectiondata: any = useSelector((state: any) => state.section);
  const examVersiondata: any = useSelector((state: any) => state.examVersion);
  const [updated, setUpdated] = useState(false);
  const [id, setId] = useState<any>("");

  const toast = useToast();
  const onDeleteExam = (value: any) => {
    dispatch(deleteExam(id)).then((data: any) => {
      if (data.type == "exams/deleteData/fulfilled") {
        toast({
          title: "Success",
          description: "Exam Deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      }
    });
  };
  const outerLimit = 2;
  const innerLimit = 2;
  const {
    pages,
    pagesCount,
    currentPage,
    setCurrentPage,
    setIsDisabled,
    isDisabled,
    pageSize,
    setPageSize,
  } = usePagination({
    total: totalExam,
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubjectsManagement());
    dispatch(getGrades());
    dispatch(getYear());
    dispatch(getUnits());
    dispatch(getSection());
    dispatch(getexamVersion());
  }, []);

  useEffect(() => {
    let params = {
      grade: gradeParam == "all" ? null : gradeParam,
      subject: subjectParam == "all" ? null : subjectParam,
      year: yearParam == "all" ? null : yearParam,
      page: currentPage,
      limit: pageSize,
    };
    dispatch(getExam(params)).then((data: any) => {
      if (data.type === "exams/getData/fulfilled") {
        setData(data.payload.exams);
        setTotalExam(data.payload.totalCount);
      } else {
        toast({
          title: "No Data Found",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        setData([]);
      }
    });
  }, [subjectParam, gradeParam, pageSize, yearParam, currentPage]);
  useEffect(() => {
    if (
      subjectsData.isSuccess == true &&
      unitsData.isSuccess == true &&
      yearsData.isSuccess == true &&
      gradesData.isSuccess == true &&
      sectiondata.isSuccess == true
    )
      setUnits(unitsData.data);
    setExamVersion(examVersiondata.data);
    setGrades(gradesData.grade);
    setSections(sectiondata.data);

    setSubjects(subjectsData.data.subjects);
    setYears(yearsData.data);
  }, [
    examVersiondata.loading,
    unitsData.loading,
    gradesData.loading,
    sectiondata.loading,
    subjectsData.loading,
    yearsData.loading,
  ]);
  const createProgressModal = useDisclosure();
  useEffect(() => {
    if (exams.isSuccess == true) {
      setData(exams.data.exams);
      setIsOpen(false);
    } else {
      setIsOpen(true);
      createProgressModal.onClose();
    }
  }, [exams.loading]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (exams.loading == true) {
      setLoading(true);
      setIsOpen(true);
      createProgressModal.onOpen();
    } else {
      setIsOpen(false);
      createProgressModal.onClose();
      setLoading(false);
    }
  }, [exams.loading, subjectParam, gradeParam]);
  const generateExamForm = useForm();
  const editExamsForm = useForm();
  const {
    handleSubmit: handleSubmitExams,
    reset,
    formState: { errors },
  } = editExamsForm;

  const {
    handleSubmit: handleSubmitGeneratedExam,
    control,
    formState: { errors: examErrors },
  } = generateExamForm;
  const handleDeleteExam = (value: any) => {
    setId(value.id);
    alert.onOpen();
  };

  const generateExamsModal = useDisclosure();
  const editExamsModal = useDisclosure();
  const previewExamModal = useDisclosure();
  const [examVersions, setExamVersion] = useState([]);
  const alert = useDisclosure();
  const cancelRef = React.useRef();
  const handleEditExam = (value: any) => {
    setId(value.id);
    reset({
      grade: value.grade.grade,
      examName: value.examName,
      subject: value.subject.subject,
      year: value.year.year,
      examVersion: value.examVersion.examVersion,
      duration: value.duration,
      noOfQuestions: value.noOfQuestions,
      passingScore: value.passingScore,
    });
    editExamsModal.onOpen();
  };
  const history = useHistory();
  const handlePreviewExam = (values: any) => {
    history.push(`/admin/exams/${values.id}`);
  };
  const [previewData, setPreviewData] = useState([]);
  const handlePreviewMobile = (values: any) => {
    setPreviewData(values);

    previewExamModal.onOpen();
  };
  const editQuestionModal = useDisclosure();

  const [data, setData] = React.useState([]);

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };
  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const pageSize = Number(event.target.value);

    setPageSize(pageSize);
  };
  const handleBackPagination = (current: any) => {
    if (current > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleForwardPagination = (current: any) => {
    if (current < pagesCount) setCurrentPage(currentPage + 1);
  };

  return (
    <Box
      borderRadius="0px"
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >

      <ProgressModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <Box p={3} bg="white">
        <PrimaryLabel>Filters</PrimaryLabel>
        <Divider borderWidth="1px" borderColor="#D9D9D9" m={2} />
        <Flex justify="space-between" flex="row">
          <HStack w="50%" spacing="24px">
            <Select
              className="select-input"
              placeholder="select Grade"
              value={gradeParam}
              _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
              onChange={(e: any) => {
                setSubjects(
                  subjectsData.data.subjects.filter(
                    (subject: any) => subject.grade.grade === e.target.value
                  )
                );
                setGradeParam(e.target.value);
              }}
              variant="filled"
            >
              <option key="all" value="all">
                All
              </option>
              {grades?.map((grade: any) => (
                <option key={grade.id} value={grade.grade}>
                  {grade.grade}
                </option>
              ))}
            </Select>
            <Select
              className="select-input"
              placeholder="select Year"
              value={yearParam}
              _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
              onChange={(e: any) => {
                setYearParam(e.target.value);
              }}
              variant="filled"
            >
              <option key="all" value="all">
                All
              </option>
              {years?.map((year: any) => (
                <option key={year.id} value={year.year}>
                  {year.year}
                </option>
              ))}
            </Select>
            <Select
              className="select-input"
              placeholder="select subject"
              value={subjectParam}
              _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
              onChange={(e: any) => setSubjectParam(e.target.value)}
              variant="filled"
            >
              <option key="all" value="all">
                All
              </option>
              {subjects?.map((item: any) => (
                <option key={item.id} value={item.subject.subject}>
                  {item.subject.subject}
                </option>
              ))}
            </Select>

            <Box>
              <PrimaryButton
                onClick={() => {
                  setGradeParam("all");
                  setSubjectParam("all");
                  setYearParam("all");
                }}
              >
                Reset
              </PrimaryButton>
            </Box>
          </HStack>
          <HStack w="20%" spacing="24px" justify="end">
            <Button
              fontSize={{ sm: "10px", md: "10px", lg: "14" }}
              bg="#0076FD"
              mx={5}
              fontWeight="500"
              color="white"
              borderRadius="0"
              onClick={generateExamsModal.onOpen}
            >
              <AddIcon w={4} h={4} px="1px" color="white" />
              Generate Exam
            </Button>
          </HStack>
        </Flex>
      </Box>
      <Box>
        <PrimaryTable columns={columns} data={data} />
        <MainPagination
          pagesCount={pagesCount}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          handleBackPagination={handleBackPagination}
          handleForwardPagination={handleForwardPagination}
          pages={pages}
          handlePageSizeChange={handlePageSizeChange}
        />

        <Modal isOpen={editExamsModal.isOpen} onClose={editExamsModal.onClose}>
          <ModalOverlay />
          <ModalContent>
            <Box borderLeftWidth="3px" borderColor="#7F77F1">
              <ModalHeader>Edit Grades</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <EditExamsForm
                  id={id}
                  editExamsForm={editExamsForm}
                  subjects={subjects}
                  grades={grades}
                  units={units}
                  editExamsModal={editExamsModal}
                  years={years}
                  examVersions={examVersions}
                />
              </ModalBody>
              <ModalFooter></ModalFooter>
            </Box>
          </ModalContent>
        </Modal>

        <AlertBox
          cancelRef={cancelRef}
          alert={alert}
          header="Delete Exam?"
          alertBody="Are you sure you want to delete this Exam"
          onDelete={onDeleteExam}
        />

        <Modal
          isOpen={generateExamsModal.isOpen}
          onClose={generateExamsModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <Box borderLeftWidth="3px" borderColor="#7F77F1">
              <ModalHeader>Generate Exam</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <GenerateExamForm
                  subjects={subjects}
                  grades={grades}
                  units={units}
                  generateExamsModal={generateExamsModal}
                  years={years}
                  examVersions={examVersions}
                />
              </ModalBody>
              <ModalFooter></ModalFooter>
            </Box>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={previewExamModal.isOpen}
          onClose={previewExamModal.onClose}
        >
          <ModalOverlay />
          <ModalContent maxW="600px">
            <Box borderLeftWidth="3px" borderColor="#7F77F1">
              <ModalHeader>Exam Preview</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <ExamMobilePreview data={previewData} />
              </ModalBody>
            </Box>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
};

export default ExamsTable;
