import { SearchIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertIcon,
  AlertTitle, Box, Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon, IconButton, Input, InputGroup, InputLeftElement, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Select,
  Tag,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel, SortingState, useReactTable
} from "@tanstack/react-table";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import TableHeader from "components/Atoms/Label/TableHeader";
import AlertBox from "components/Molecules/Alerts/AlertBox";
import { FcCancel } from "react-icons/fc";
// Custom components
import StatusBadge from "components/Atoms/StatusBadge";
import Card from "components/Molecules/card/Card";
import SecondaryTable from "components/Molecules/Tables/LocalTable";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaCircleCheck } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import {
  getDirectPaymentList, rejectDirectPayment, verifyDirectPayment
} from "reduxtoolkit/reducers/DirectBankaymentSlice";
import { deleteSubscriptionPackage } from "reduxtoolkit/reducers/paymentSlice";
import { formatDateTime } from "utils/get/generalhelpers";
// Assets

type RowObj = {
  id: any;
  depositedByName: any;
  subscriptionPackage: any;
  paymentStatus: any;
  paymentOption: any;
  bankName: any;
  paymentGateWay: any;
  textReference: any;
  switchSubscribtionCount: any;
  createdAt: any;
  user: any;
  actions: any;
};

const columnHelper = createColumnHelper<RowObj>();

export default function ComplexTable(props: { tableData: any }) {
  const { tableData } = props;
  const deleteIconColor = useColorModeValue("red.600", "white");
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [rejectMessage, setRejectMessage] = useState<any>("");
  const [id, setId] = useState<any>("");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [activeFilter, setActiveFilter] = useState('all');
  const [inputFilter, setInputFilter] = useState('');
  const [toggleSearchParam, setToggleSearchParam] = useState('phone');
  // const [statusFilter, setStatusFilter] = useState('all');
  const [paymentStatus, setPaymentStatus] = useState('');
  let defaultData = tableData;
  const textColorSecondary = "gray.400";
  const columns = [
    columnHelper.accessor("depositedByName", {
      id: "depositedByName",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          Deposited By
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.cell.row.original.depositedByName}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("user", {
      id: "user",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          Phone Number
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.cell.row.original.user?.phoneNumber}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("textReference", {
      id: "referenceNo",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          Reference No
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("subscriptionPackage", {
      id: "subscriptionPackage",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          Subscription Package
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.row.original?.subscriptionPackage?.subscriptionPlan}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("paymentStatus", {
      id: "paymentStatus",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          Payment Status
        </Text>
      ),
      cell: (info) => (
        <>
          <StatusBadge status={info.getValue()} />




        </>
      ),
    }),

    columnHelper.accessor("paymentOption", {
      id: "paymentOption",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          Payment Option
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("bankName", {
      id: "bankName",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          Bank Name
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("paymentGateWay", {
      id: "paymentGateWay",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          Payment GateWay
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("createdAt", {
      id: "createdAt",
      header: () => <TableHeader title="createdAt" />,
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {formatDateTime(info.getValue())}

          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("actions", {
      id: "actions",
      header: () => <TableHeader title="Actions" />,
      cell: (info) => (
        <>
          <Flex justify="space-evenly">
            <Icon
              color="green"
              as={FaCircleCheck}
              onClick={() => {
                handleApprovePayment(info.row);
              }}
            />
            <Icon
              color={deleteIconColor}
              as={FcCancel}
              onClick={() => handleRejectPayment(info.row)}
            />
          </Flex>
        </>
      ),
    }),
  ];

  const handleApprovePayment = (value: any) => {

    setId(value.original.id);
    setReferenceNo(value.original.textReference);
    const data = value.original;

    verifyPaymentModal.onOpen();
  };
  const handleRejectPayment = (value: any) => {
    setId(value.original.id);
    setReferenceNo(value.original.textReference);
    rejectPaymentModal.onOpen();
  };

  const [data, setData] = React.useState(() => []);
  //  con

  const sortedData = [...data].sort(
    (a, b) => Date.parse(b?.createdAt) - Date.parse(a?.createdAt)
  );
  const qtable = {
    data: data,
    columns,

    enableRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  };
  const dispatch = useDispatch();
  const createPackageForm = useForm();
  const { handleSubmit: handleSubmitNotification } = createPackageForm;
  const rejectPaymentModal = useDisclosure();
  const verifyPaymentModal = useDisclosure();
  const editPackageForm = useForm();
  const { handleSubmit: handleSubmitEditPackage, reset } = editPackageForm;
  const toast = useToast();
  const alert = useDisclosure();
  const searchIconColor = useColorModeValue('gray.700', 'white');
  const inputBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const cancelRef = React.useRef();
  const onDeletePackage = () => {
    dispatch(deleteSubscriptionPackage(id)).then((data: any) => {
      if (data.type == "notification/deleteData/fulfilled") {
        toast({
          title: "Success",
          description: "Notification Deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        alert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      }
    });
  };
  const [referenceNo, setReferenceNo] = useState("");
  const onVerifyPayment = (values: any) => {
    const data = { id: id, payload: { referenceNo: referenceNo.toString() } };

    dispatch(verifyDirectPayment(data)).then((data: any) => {
      if (data.type == "verify/directPayment/fulfilled") {
        toast({
          title: "Success",
          description: "Payment Verified successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setReload(!reload);
        alert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      }
      verifyPaymentModal.onClose();
    });
  };
  const onRejectPayment = (values: any) => {
    const data = { id: id, rejectMessage: rejectMessage };
    console.log(data);
    dispatch(rejectDirectPayment(data)).then((data: any) => {
      if (data.type == "reject/directPayment/fulfilled") {
        toast({
          title: "Success",
          description: "Payment Rejected successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setRejectMessage("");
        setReload(!reload);
        alert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      }

      rejectPaymentModal.onClose();
    });
  };
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  const [reload, setReload] = useState(true);

  const createProgressModal = useDisclosure();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (loading == true) {

      createProgressModal.onOpen();
    } else {
      createProgressModal.onClose();

    }
  }, [loading]);

  useEffect(() => {
    const params = {
      userStatus: activeFilter,
      phoneNumber: toggleSearchParam == 'phone' ? inputFilter : null,
      textReference: toggleSearchParam == 'reference' ? inputFilter : null,
      paymentStatus: paymentStatus,
      skip: 0,
      limit: 1000,

    }
    setLoading(true);
    dispatch(getDirectPaymentList(params)).then((data: any) => {
      if (data.payload.paymentMadeByDirectBank !== undefined) {
        setData(data.payload.paymentMadeByDirectBank);
        setLoading(false);
      }
    });
  }, [reload, paymentStatus, inputFilter, activeFilter]);

  const handleSearchPayment = () => {
    setInputFilter(inputValue);
  };
  const [inputValue, setInputValue] = useState('');
  const handleChange = (value: any) => {
    console.log(value.target.value);
    setToggleSearchParam(value.target.value);
  };
  const handleKeyDown = (event: any) => {
    if (event.key === ' ' || event.key === 'Enter') {

      // Call your method here, for example:
      setInputFilter(inputValue);
    }
  };

  const handleInputChange = (event: any) => {

    setInputValue(event.target.value);


  };


  return (
    <Card
      borderRadius="0px"
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >

      {loading ? <Progress w="1/2" isIndeterminate /> : null}
      <Modal
        isOpen={createProgressModal.isOpen}
        onClose={createProgressModal.onClose}
      >
        <ModalOverlay />
        <ModalContent bg="transparent" maxW="100%">
          <Box borderLeftWidth="3px" borderColor="#7F77F1">
            <ModalBody pb={6}></ModalBody>
          </Box>
        </ModalContent>
      </Modal>
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="15px"
          fontWeight="700"
          lineHeight="100%"
        >
          Direct Bank Payments
        </Text>
        <Box h="10">

          <InputGroup >

            <InputLeftElement
              children={
                <IconButton
                  aria-label='search'
                  bg='inherit'
                  borderRadius='0px'
                  _active={{
                    bg: 'inherit',
                    transform: 'none',
                    borderColor: 'transparent'
                  }}
                  _focus={{
                    boxShadow: 'none'
                  }}
                  icon={<SearchIcon onClick={handleSearchPayment}
                    color={searchIconColor} w='15px' h='15px' />}
                />
              }
            />

            <Input
              variant='search'
              fontSize={{ sm: '10px', md: '10px', lg: '14' }}
              bg={inputBg}
              color={inputText}
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}

              fontWeight='500'
              _placeholder={{ color: 'gray.400', fontSize: '14px' }}
              borderRadius='0px'
              placeholder={`Filter by ${toggleSearchParam}`}
            />
            <Select defaultValue="phone" onChange={handleChange} size="sm" width={40}>
              <option value="phone">Phone</option>
              <option value="reference">Reference</option>
            </Select>
          </InputGroup>

        </Box>

        <Modal
          isOpen={rejectPaymentModal.isOpen}
          onClose={rejectPaymentModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitNotification(onRejectPayment)}>
              <ModalHeader>Reject Payment</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel>Reject Message </FormLabel>
                  <Input
                    name="rejectMessage"
                    placeholder="Type Reject Message"
                    onChange={(e: any) => setRejectMessage(e.target.value)}
                    type="text"
                    value={rejectMessage}
                  />
                </FormControl>
                <HStack>

                  <PrimaryButton type="submit">Reject</PrimaryButton>
                  <Button
                    colorScheme="gray"
                    onClick={rejectPaymentModal.onClose}
                  >
                    Cancel
                  </Button>
                </HStack>
              </ModalBody>
            </form>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={verifyPaymentModal.isOpen}
          onClose={verifyPaymentModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitEditPackage(onVerifyPayment)}>
              <ModalCloseButton />
              <ModalHeader> </ModalHeader>
              <ModalBody pb={6}>
                <Alert>
                  <AlertIcon boxSize="40px" mr={0} />
                  <AlertTitle>Verify Payment!</AlertTitle>
                </Alert>
              </ModalBody>

              <ModalFooter alignContent="space-evenly">
                <Button colorScheme="green" mr={3} type="submit">
                  Verify
                </Button>
                <Button colorScheme="gray" onClick={verifyPaymentModal.onClose}>
                  Cancel
                </Button>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
        <AlertBox
          cancelRef={cancelRef}
          alert={alert}
          header="Delete Subscription Package?"
          alertBody="Are you sure you want to delete this Package"
          onDelete={onDeletePackage}
        />

        <HStack justifyContent='space-evenly'>

          <Select
            size="md"
            value={paymentStatus}
            onChange={(e) => setPaymentStatus(e.target.value)}
            variant="outlined"
          >
            <option key="All" value="all">
              All
            </option>
            <option key="Success" value="success">
              Success
            </option>
            <option key="Pending" value="pending">
              Pending
            </option>
            <option key="Rejected" value="rejected">
              Rejected
            </option>
          </Select>

          <div>
            <Tag size="md" variant={activeFilter == 'all' ? "solid" : 'outline'} colorScheme="teal" onClick={() => setActiveFilter('all')}>All</Tag>
          </div>
          <div>
            <Tag size="md" variant={activeFilter == 'live' ? "solid" : 'outline'} colorScheme="teal" onClick={() => setActiveFilter('live')}>live</Tag>
          </div>
          <div>

            <Tag size="md" variant={activeFilter == 'deleted' ? "solid" : 'outline'} colorScheme="teal" onClick={() => setActiveFilter('deleted')}>deleted</Tag>
          </div>

        </HStack>
      </Flex>

      <SecondaryTable tableOptions={qtable} />
    </Card >
  );
}
