import { usePagination } from "@ajna/pagination";
import { Box, Flex, FormControl, useDisclosure, useToast } from '@chakra-ui/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  MaterialReactTable, type MRT_RowSelectionState
} from 'material-react-table';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { deleteQuestion, updateQuestion } from 'reduxtoolkit/reducers/questionSlice';
import AlertBox from '../Alerts/AlertBox';

const theme = createTheme({
  transitions: {
    duration: {
      enteringScreen: 2000,
      leavingScreen: 100,
    },
  },
});



const EditableTable=(props:any)=> {
  
  
  const editQuestionForm = useForm();
  const {
    handleSubmit: handleSubmitQuestion ,control, getValues,reset } = editQuestionForm;
    const [themes, setTheme] = useState("snow");
  const [datas, setData] = React.useState([])
  const [questionType, setExamTypes] = useState<any>();

	
  const [gradeParam, setGradeParam] = useState<any>()
  const [subjectParam, setSubjectParam] = useState<any>()
  const [yearParam, setYearParam] = useState<any>();
  const grades:any = useSelector((state:any) => state.grade.grade);
  const units:any = useSelector((state:any) => state.unit.data);
  const subjects = useSelector((state:any) => state.subject.data);
  const questionsData:any = useSelector((state:any) => state.question);
  const years:any = useSelector((state:any) => state.year.data);
  const examVersions:any = useSelector((state:any) => state.examVersion.data);
  const sections:any = useSelector((state:any) => state.section.data);
  
  const dispatch = useDispatch();
  let { id }: any = useParams();
	const [questionsTotal, setQuestionsTotal] = useState<number | undefined>(
		undefined
	  );
  const outerLimit = 2;
	const innerLimit = 2;
	const {
		pages,
		pagesCount,
		
		currentPage,
		setCurrentPage,
		setIsDisabled,
		isDisabled,
		pageSize,
		setPageSize
	  } = usePagination({
		total: questionsTotal,
		limits: {
		  outer: outerLimit,
		  inner: innerLimit
		},
		initialState: {
		  pageSize: 10,
		  isDisabled: false,
		  currentPage: 1
		}
	  });
  const handlePageChange = (nextPage: number): void => {
		
	setCurrentPage(nextPage);
  };
  const handlePageSizeChange = (
	event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
	const pageSize = Number(event.target.value);

	setPageSize(pageSize);
  };

  
	
  useEffect(()=>{

	},[questionsData.loading])

	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10, 
	  });
	  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
    const alert = useDisclosure();
    const cancelRef = React.useRef()
    const [idq, setId]= useState<any>("");



 const handleChangeExamType=(value:any)=>{
        setExamTypes(value);    
  }
  const handleChangeGrade=(value:any)=>{
        
       
    setGradeParam(value);
   
     
       
}
  

  
  const handleDeleteQuestion=(value:any)=>{
		setId(value);
	
		 alert.onOpen();
		
  }
  const history = useHistory();


  const textColorSecondary = "gray.400";
  const handleSaveRow =(values:any) =>{
      values.examVersion = 'v1,00';
      values.grade= 'grade_8';
      values.subject = 'Mintesnot';
      values.year = '2015';
      values.unit = '1';
		
      const data = {
        id: values.id,
        payload:  values
      }
      dispatch(updateQuestion(data));
   
    };
	let selectedQuestions:any[] = [];
	const toast = useToast();
	
    const onDeleteQuestion=()=>
    {  
      
        dispatch(deleteQuestion(idq));
        alert.onClose();
    }
  return (
    <div className="p-2">
      <div className="h-2" />
	  <Box overflowX="auto">
    <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
     
          <form onSubmit={()=>{}}>
      
          <FormControl>
          
   
          
          </FormControl>
          </form>
  
          </Flex>
  
	     <ThemeProvider theme={theme}>
	 <MaterialReactTable 
	table={props.table}/>
	</ThemeProvider>
			
    <AlertBox 
							cancelRef={cancelRef} 
							alert={alert}
							header="Delete Question?"
							alertBody="Are you sure you want to delete this Question"
							onDelete={onDeleteQuestion}
							/>
    </Box>
    </div>
  )
			}

export default EditableTable;