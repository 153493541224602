import { ChakraProvider } from "@chakra-ui/react";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { createBrowserHistory } from "history";
import { useAppSelector } from "hook";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import AdminLayout from "./components/Templates/layouts/admin";
import AuthLayout from "./components/Templates/layouts/auth";
import theme from "./styles/theme";
import "./styles/theme/css/App.css";
const themenew = createTheme({
  transitions: {
    duration: {
      enteringScreen: 2000,
      leavingScreen: 100,
    },
  },
});
const history = createBrowserHistory();

const LoggedInRoutes = [
  <Route path={`/admin`} component={AdminLayout} />,
  <Redirect from="/" to="/admin/default" />,
];

const LoggedOutRoutes = [
  <Route path={`/auth`} component={AuthLayout} />,
  <Redirect from="/" to="/auth" />,
];

const App = () => {
  const isAuthenticated: any = useAppSelector(
    (state: any) => state.auth.isAuthenticated
  );

  return (
    <ThemeProvider theme={themenew}>
    <ChakraProvider theme={theme}>
      <Router history={history}>
        <Switch>
          {[
            isAuthenticated && LoggedInRoutes,
            !isAuthenticated && LoggedOutRoutes,
          ]}
        </Switch>
      </Router>
    </ChakraProvider>
    </ThemeProvider>
  );
};
export default App;
