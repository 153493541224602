import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from "@chakra-ui/react";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import PhoneNumberInput from "components/Atoms/fields/phoneNumberInputForm";
import TextInput from "components/Atoms/fields/TextInput";
import { MultiRoleSelect } from "components/Molecules/MultiRoleSelector";

import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { updateAdmin } from "reduxtoolkit/reducers/adminSlice";

    const EditAdmin=({roles, id, userRoles, editUserModal, user, editAdminUsers } :any)=>{
    const {  handleSubmit, control, formState: { errors }, setValue ,clearErrors, reset } = editAdminUsers;
    const toast = useToast();
    const dispatch= useDispatch();
	
    useEffect(()=>{
       if(editUserModal.isOpen == true){
	    
    // Populate form with editable data when component mounts
   
  
		
	
	}
	else{
		
		reset();
		clearErrors();
	}
    },[editUserModal.isOpen, setValue]);
	
    const onEditUser=(values: any)=>{
         

		const roles: any = [];
	
		values.roles.map((item:any)=>{ roles.push(item.label)});
		const data = {
			firstName: values.firstName,
			lastName: values.lastName,
			middleName: values.middleName,
			email: values.email,
			phoneNumber: values.phoneNumber,
			//password: values.password,
			adminRole: roles,
		}
		const val= {id: id, data: data}
		dispatch(updateAdmin(val)).then((data:any)=>{
			if(data.type == "admin/updateAdmin/fulfilled") {
				toast({
					title: 'Success',
					description: "Admin Update successfully",
					status: 'success',
					duration: 9000,
					isClosable: true,
				  });
				}
				else{
					toast({
						title: 'Error',
						description:   `${data.payload.response.data.message}`,
						status: 'error',
						duration: 9000,
						isClosable: true,
					  });
				  }
		})
		editUserModal.onClose();
	}
    return(
        <Modal 
        isOpen={editUserModal.isOpen}
        onClose={editUserModal.onClose}>
            <ModalOverlay />
            <ModalContent>
            <Box borderLeftWidth='3px' borderColor='#7F77F1'>
            <form onSubmit={handleSubmit(onEditUser)}>
            <ModalHeader>Edit User</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
            <TextInput
						id="firstName"
						label="First Name"
						type="text"
						
						control={control}
						validation={{ 
							required: 'First Name is required.',  
							pattern: { value: /^[A-Za-z]+$/i, message: "Invalid Input"},
						   }}
						errorMessage="Please enter your First Name."
					/>
					{errors?.firstName && (
						<Text color="red.500" mt={2} fontSize="sm">
						{errors?.firstName.message.toString()}
						</Text>
					)}
					<TextInput
						id="middleName"
						label="Middle Name"
						type="text"
						
						control={control}
						validation={{ 
							required: 'Middle Name is required.',  
							pattern: { value: /^[A-Za-z]+$/i, message: "Invalid Input"},
						   }}
						errorMessage="Please enter your Middle Name."
					/>
					{errors?.middleName && (
						<Text color="red.500" mt={2} fontSize="sm">
						{errors?.middleName.message.toString()}
						</Text>
					)}
					<TextInput
						id="lastName"
						label="Last Name"
						type="text"
						
						control={control}
						validation={{ 
							required: 'Last Name is required.',  
							pattern: { value: /^[A-Za-z]+$/i, message: "Invalid Input"},
						   }}
						errorMessage="Please enter your Last Name."
					/>
					{errors?.middleName && (
						<Text color="red.500" mt={2} fontSize="sm">
						{errors?.middleName.message.toString()}
						</Text>
					)}			
					 <PhoneNumberInput 
							        id="phoneNumber" 
								
									label="Phone Number" 
									control={control}
									validation={{
										pattern: {
										value: /^\+251\d{9}$/,
										message: "Phone number must start with +251 and be 12 digits long (including the country code)",
									  }}}
									errorMessage="Please enter your Phone Number"
							
								/>
						{errors?.phoneNumber && (
						<Text color="red.500" mt={2} fontSize="sm">
						{errors?.phoneNumber.message.toString()}
						</Text>
					)}
							<TextInput
						id="email"
						label="Email"
						 
						type="email"
						control={control}
						validation={{ 
							required: 'Email is required.',  
							pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Invalid Input"},
						   }}
						errorMessage="Please enter your Email."
					/>
					{errors?.email && (
						<Text color="red.500" mt={2} fontSize="sm">
						{errors?.email.message.toString()}
						</Text>
					)}			
						
							<MultiRoleSelect 
									control={control}
									name="adminRole"	
									defaultValue={userRoles} 
									roles={roles} 
									/>
					{errors?.adminRole && (
						<Text color="red.500" mt={2} fontSize="sm">
						{errors?.adminRole.message.toString()}
						</Text>
					)}			
            </ModalBody>
            <ModalFooter>
		
            <PrimaryButton type='submit'>
                Save
                </PrimaryButton>
                
            </ModalFooter>
            </form>
            </Box>
            </ModalContent>

        </Modal>
    )
}
export { EditAdmin };
