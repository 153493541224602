import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel, SortingState, useReactTable
} from "@tanstack/react-table";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import AlertBox from "components/Molecules/Alerts/AlertBox";
import PrimaryTable from "components/Molecules/Tables/PrimaryTable";
// Custom components
import { usePagination } from "@ajna/pagination";
import Card from "components/Molecules/card/Card";
import { MainPagination } from "components/Molecules/Pagination";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  createSubscriptionPackage,
  deleteSubscriptionPackage,
  getAllPayments
} from "reduxtoolkit/reducers/paymentSlice";
// Assets

type RowObj = {
  subscriptionPlan: string;
  id: any;
  paymentStatus: any;
  paymentOption: any;
  paymentGateWay: any;
  subscriptionPackage: any;
  user: any;
  switchSubscribtionCount: any;
};

const columnHelper = createColumnHelper<RowObj>();

export default function ComplexTable(props: { tableData: any }) {
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [id, setId] = useState<any>("");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  let defaultData = tableData;
  const columns = [
    columnHelper.accessor("user", {
      id: "user",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          user
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.row?.original?.user?.firstName}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("paymentStatus", {
      id: "paymentStatus",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          Payment Status
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("paymentOption", {
      id: "paymentOption",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          Payment Option
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("paymentGateWay", {
      id: "paymentGateWay",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          paymentGateWay
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("subscriptionPackage", {
      id: "subscriptionPackage",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          subscriptionPackage
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.cell.row.original.subscriptionPackage.subscriptionPlan}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("switchSubscribtionCount", {
      id: "switchSubscribtionCount",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          switchSubscribtionCount
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
  ];
  const handleEditSubscriptionPlan = (value: any) => {
    setId(value.original.id);
    const data = value.original;
    reset({
      notification: data.notification,
    });
    editPackageModal.onOpen();
  };
  const handleDeleteSubscriptionPlan = (value: any) => {
    setId(value.id);
    alert.onOpen();
  };
  const [total, setTotal] = useState(1);
  const [data, setData] = React.useState(() => []);
  const dispatch = useDispatch();
  const createPackageForm = useForm();
  const { handleSubmit: handleSubmitNotification } = createPackageForm;
  const createPackageModal = useDisclosure();
  const editPackageModal = useDisclosure();
  const editPackageForm = useForm();
  const outerLimit = 2;
  const innerLimit = 2;
  const {
    pages,
    pagesCount,
    currentPage,
    setCurrentPage,
    setIsDisabled,
    isDisabled,
    pageSize,
    setPageSize,
  } = usePagination({
    total: total,
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  });

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };
  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const pageSize = Number(event.target.value);

    setPageSize(pageSize);
  };
  const handleBackPagination = (current: any) => {
    if (current > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleForwardPagination = (current: any) => {
    if (current < pagesCount) setCurrentPage(currentPage + 1);
  };

  const { handleSubmit: handleSubmitEditPackage, reset } = editPackageForm;
  const toast = useToast();
  const alert = useDisclosure();
  const cancelRef = React.useRef();
  const onDeletePackage = () => {
    dispatch(deleteSubscriptionPackage(id)).then((data: any) => {
      if (data.type == "notification/deleteData/fulfilled") {
        toast({
          title: "Success",
          description: "Notification Deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        alert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      }
    });
  };
  const onEditPackage = (values: any) => {
    const pack = {
      subscriptionPlan: values.subscriptionPlan,
      duration: Number(values.duration),
      price: Number(values.price),
    };
    dispatch(createSubscriptionPackage(pack)).then(() => {
      createPackageModal.onClose();
    });
  };
  const onCreatePackage = (values: any) => {
    const pack = {
      subscriptionPlan: values.subscriptionPlan,
      duration: Number(values.duration),
      price: Number(values.price),
    };
    dispatch(createSubscriptionPackage(pack)).then(() => {
      createPackageModal.onClose();
    });
  };
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    let params = {

      page: currentPage,
      limit: pageSize,
    };
    dispatch(getAllPayments(params)).then((data: any) => {

      const nonNullValues = data.payload.payment.filter(
        (item: any) => item !== null
      );
      setTotal(data.payload.totalCount);
      setData(nonNullValues);
    });
  }, [currentPage, pageSize]);
  return (
    <Card
      borderRadius="0px"
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Payments
        </Text>

        <Modal
          isOpen={createPackageModal.isOpen}
          onClose={createPackageModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitNotification(onCreatePackage)}>
              <ModalHeader>Add Subscription Plan</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Subscription Plan</FormLabel>
                  <Input
                    id="subscriptionplan"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Subscription Plan"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createPackageForm.register("subscriptionPlan", {
                      required: "Subscription Plan is required",
                    })}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Duration (in Days)</FormLabel>
                  <Input
                    id="duration"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Duration"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createPackageForm.register("duration", {
                      required: "Duration Plan is required",
                    })}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Price</FormLabel>
                  <Input
                    id="price"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Price"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createPackageForm.register("price", {
                      required: "price Plan is required",
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={editPackageModal.isOpen}
          onClose={editPackageModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitEditPackage(onEditPackage)}>
              <ModalHeader>Edit Subscription Plan</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Subscription Plan</FormLabel>
                  <Input
                    id="subscriptionplan"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Subscription Plan"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editPackageForm.register("subscriptionPlan", {
                      required: "Subscription Plan is required",
                    })}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Duration (in Days)</FormLabel>
                  <Input
                    id="duration"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Duration"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editPackageForm.register("duration", {
                      required: "Duration Plan is required",
                    })}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Price</FormLabel>
                  <Input
                    id="price"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Price"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editPackageForm.register("price", {
                      required: "price Plan is required",
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
        <AlertBox
          cancelRef={cancelRef}
          alert={alert}
          header="Delete Subscription Package?"
          alertBody="Are you sure you want to delete this Package"
          onDelete={onDeletePackage}
        />
      </Flex>

      <PrimaryTable id="users" data={data} columns={columns} />
      <MainPagination
        pagesCount={pagesCount}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        handleBackPagination={handleBackPagination}
        handleForwardPagination={handleForwardPagination}
        pages={pages}
        handlePageSizeChange={handlePageSizeChange}
      />
    </Card>
  );
}
