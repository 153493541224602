import { Box, Button, Divider, Flex, Input, Select, useToast } from "@chakra-ui/react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
// Custom components
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import { PrimaryLabel } from "components/Atoms/Label/Labels";
import { PrimaryText } from "components/Atoms/Title/Titles";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createChallenge, getChallenge } from 'reduxtoolkit/reducers/challengeSlice';
import { getGrades } from 'reduxtoolkit/reducers/gradeSlice';
import { getSection } from 'reduxtoolkit/reducers/sectionSlice';
import { getStudies } from 'reduxtoolkit/reducers/studiesSlice';
import { getSubjects } from 'reduxtoolkit/reducers/subjectSlice';
import { getUnits } from 'reduxtoolkit/reducers/unitSlice';
type Challenge = {
  subject: string;
  unit: string;
  section: string;
  
};

type ChallengeDay = {
  day: string;
  rest: boolean;
  challenge: Challenge[];
};

type FormValues = {
  challengeTitle: string;
  grade: string;
  challenges: ChallengeDay[];
};

 const CreateChallengeForm=()=> {
  const [selectedGrade, setSelectedGrade]= useState("");
  const [grades, setGrades] = useState([]);
	const [studies, setStudies] = useState([]);
	const [sections, setSections] = useState([]);
	const [subjects, setSubjects] = useState([]);
	const [units, setUnits] = useState([]);
	const dispatch = useDispatch();
  const history = useHistory();
  useEffect(()=>{
    dispatch(getGrades()).then((data:any)=>{
     setSelectedGrade(data.payload[0].grade)
     
     setGrades(data.payload)});
     dispatch(getSection()).then((data:any)=>{
     
     setSections(data.payload)});
      dispatch(getSubjects()).then((data:any)=>{
     
      setSubjects(data.payload)});
      dispatch(getUnits()).then((data:any)=>{
      
        setUnits(data.payload)
      });
     dispatch(getStudies()).then((data:any)=>{
       
     })
     dispatch(getChallenge()).then((data:any)=>{
       
   
        

      });
   
   },[]);

   const toast = useToast();
  const onCreateChallenge=(values: any)=>{
		


	
		dispatch(createChallenge(values)).then((data:any)=>{
			if(data.type === "admin/createChallenge/fulfilled") {
					
				toast({
					title: 'Success',
					description: "Challenge Created successfully",
					status: 'success',
					duration: 9000,
					isClosable: true,
				  });
				  history.push('challengephase/')
			}
				else{
					toast({
						title: 'Error',
						description:   `${data.payload.response.data.message}`,
						status: 'error',
						duration: 9000,
						isClosable: true,
					  });
				  
		}
	});
	
		}

    const { control, handleSubmit, register } = useForm();
    const { fields: challenges, append: appendChallenge, remove: removeChallenge } = useFieldArray({
      control,
      name: 'challenges',
    });
  
    const { fields: challengeItems, append: appendChallengeItem, remove: removeChallengeItem } = useFieldArray({
      control,
      name: 'challenges.0.challenge',
    });

  

  const addChallenge = (dayIndex: number) => {
    // append({`challenges.${dayIndex}.challenge`, { subject: "", unit: "", section: "" }});
    
    // append(`challenge.${dayIndex}.challenge.`, { firstNested: "", secondNested: "" });
    // append({ day: dayIndex, rest: false, challenge: [{ subject: "", unit: "", section: "" }] })
  };

  return (
    <Box my={50} px={10} borderRadius='0px' py='10px' bg='white' flexDirection='column' w='100%'>
    <form onSubmit={handleSubmit(onCreateChallenge)}>
      <Flex justify='space-evenly'gap={5}>
      <PrimaryText >Challenge Title:</PrimaryText>
      <Controller
        name="challengeTitle"
        control={control}
        render={({ field }) => <Input {...field} />}
      />

      <PrimaryText>Grade:</PrimaryText>
      <Controller
        name="grade"
        control={control}
        render={({ field }) => (
          <Select {...field} placeholder="Select Grade">
            {grades.map((grade) => (
              <option key={grade.grade} value={grade.grade}>
                {grade.grade}
              </option>
            ))}
          </Select>
        )}
      />
      </Flex>
     
      <div>
        <PrimaryText>Challenges:</PrimaryText>
        {challenges.map((challenge, index) => (
          <div key={challenge.id}>
          <Flex justify='flex-start'>
         <Box w='20%'> <PrimaryText>Day: {`${index+1}`}</PrimaryText>
            <Controller
              name={`challenges[${index}].day`}
              control={control}
              defaultValue={`${index+1}`}
              render={({ field }) => <Input placeholder={`Day ${index+1}`} {...field} disabled hidden type="text" />}
            />
            </Box>
            <PrimaryText>Rest:</PrimaryText>
            <Controller
              name={`challenges[${index}].rest`}
              control={control}
              render={({ field }) => <input {...field} type="checkbox" />}
            />
</Flex>
            <div>
              <PrimaryLabel>Challenge</PrimaryLabel>
              {challengeItems.map((item, itemIndex) => (
                <Flex gap={5} key={item.id}>
                  <PrimaryText>Subject:</PrimaryText>
              
                     <Controller
                        name={`challenges[${index}].challenge[${itemIndex}].subject`}
                      control={control}
                      render={({ field }) => (
                        <Select {...field} placeholder="Select Subject">
                          {subjects.map((subject) => (
                            <option key={subject.subject} value={subject.subject}>
                              {subject.subject}
                            </option>
                          ))}
                        </Select>
        )}
      />

                  <PrimaryText>Unit:</PrimaryText>
             
                      <Controller
                         name={`challenges[${index}].challenge[${itemIndex}].unit`}
                      control={control}
                      render={({ field }) => (
                        <Select placeholder="Select UNit" {...field}>
                          {units.map((unit) => (
                            <option key={unit.unit} value={unit.unit}>
                              {unit.unit}
                            </option>
                          ))}
                        </Select>
        )}
      />

                  <PrimaryText>Section:</PrimaryText>
                 
                            <Controller
                          name={`challenges[${index}].challenge[${itemIndex}].section`}
                      control={control}
                      render={({ field }) => (
                        <Select {...field} placeholder="Select Section">

                          {sections.map((section) => (
                            <option key={section.section} value={section.section}>
                              {section.section}
                            </option>
                          ))}
                        </Select>
        )}
      />
                 
                  <PrimaryButton type="button" onClick={() => removeChallengeItem(itemIndex)}>
                    Remove Challenge
                  </PrimaryButton>
                
                </Flex>
              ))}
              <Button
                my={5}
                type="button"
                onClick={() => appendChallengeItem({ subject: '', unit: '', section: '' })}
              >
                Add Challenge 
              </Button>
            </div>

            <PrimaryButton type="button" onClick={() => removeChallenge(index)}>
              Remove Challenge Day 
            </PrimaryButton>
          </div>
        ))}
      <Divider borderWidth="1px" borderColor='#D9D9D9'/>
      </div>
<Flex gap={5}>  <PrimaryButton type="button" onClick={() => appendChallenge({ day: 0, rest: true, challenge: [] })}>
          Add Challenge Day
        </PrimaryButton>
        <PrimaryButton type="submit">Save</PrimaryButton>
        </Flex>
     
    </form>
 
    </Box>
  );
}
export default CreateChallengeForm;
