import { combineReducers } from "@reduxjs/toolkit";
import adminSlice from "reduxtoolkit/reducers/adminSlice";
import analyticsSlice from "reduxtoolkit/reducers/analyticSlice";
import { authSlice } from "reduxtoolkit/reducers/authSlice";
import challengeSlice from "reduxtoolkit/reducers/challengeSlice";
import commentSlice from "reduxtoolkit/reducers/commentSlice";
import contactusSlice from "reduxtoolkit/reducers/contactUsSlice";
import dashboardSlice from "reduxtoolkit/reducers/dashboardSlice";
import generateExamSlice from "reduxtoolkit/reducers/examGenerateSlice";
import examVersionSlice from "reduxtoolkit/reducers/examVersionSlice";
import faqSlice from "reduxtoolkit/reducers/faqSlice";
import feedbackSlice from "reduxtoolkit/reducers/feedbackSlice";
import gradeSlice from "reduxtoolkit/reducers/gradeSlice";
import notificationSlice from "reduxtoolkit/reducers/notificationSlice";
import paymentSlice from "reduxtoolkit/reducers/paymentSlice";
import questionSlice from "reduxtoolkit/reducers/questionSlice";
import regionSlice from "reduxtoolkit/reducers/regionSlice";
import sectionSlice from "reduxtoolkit/reducers/sectionSlice";
import studySlice from "reduxtoolkit/reducers/studiesSlice";
import subjectManagementSlice from "reduxtoolkit/reducers/subjectManagementSlice";
import subjectSlice from "reduxtoolkit/reducers/subjectSlice";
import tipSlice from "reduxtoolkit/reducers/tipsSlice";
import tipTypeSlice from "reduxtoolkit/reducers/tipsTypeSlice";
import unitSlice from "reduxtoolkit/reducers/unitSlice";
import UserGuidelice from "reduxtoolkit/reducers/userGuideSlice";
import userSlice from "reduxtoolkit/reducers/userSlice";
import yearSlice from "reduxtoolkit/reducers/yearSlice";
import DirectBankaymentSlice from "./reducers/DirectBankaymentSlice";

const rootReducer = combineReducers({
  auth: authSlice.reducer,
  admin: adminSlice.reducer,
  subject: subjectSlice.reducer,
  region: regionSlice.reducer,
  users: userSlice.reducer,
  grade: gradeSlice.reducer,
  unit: unitSlice.reducer,
  section: sectionSlice.reducer,
  year: yearSlice.reducer,
  examVersion: examVersionSlice.reducer,
  question: questionSlice.reducer,
  study: studySlice.reducer,
  exam: generateExamSlice.reducer,
  subjectManagement: subjectManagementSlice.reducer,
  challenge: challengeSlice.reducer,
  faq: faqSlice.reducer,
  contact: contactusSlice.reducer,
  comment: commentSlice.reducer,
  feedback: feedbackSlice.reducer,
  tiptypes: tipTypeSlice.reducer,
  tips: tipSlice.reducer,
  dashboard: dashboardSlice.reducer,
  userGuide: UserGuidelice.reducer,
  notification: notificationSlice.reducer,
  payment: paymentSlice.reducer,
  analytics: analyticsSlice.reducer,
  directPayment: DirectBankaymentSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
