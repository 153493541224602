const groupedUsers: { [key: string]: any[] } = {};
var arrayOfKeys: string[];
var resultArray: number[];

 const processData = (data:any) => {
  data.forEach((user:any) => {
    if (user.loginTimeStamp) {
        const date = new Date(user.loginTimeStamp);
        const dateString = date.toISOString().split('T')[0];
      
        if (groupedUsers[dateString]) {
            groupedUsers[dateString].push(user);
        } else {
            groupedUsers[dateString] = [user];
        }
    }
});
for (let i = 0; i < 7; i++) {
  const date = new Date("2024-02-01T10:56:43.767Z");
  date.setDate(date.getDate() + i);
  const key = date.toISOString().split('T')[0];
  if (!groupedUsers[key]) {
      groupedUsers[key] = [];
  }
}

const result: { [key: string]: number } = {};
for (const key in groupedUsers) {
  result[key] = groupedUsers[key].length;
}
arrayOfKeys= Object.keys(result);



resultArray = Object.values(result);
    
   
    return {arrayOfKeys, resultArray};
  };

  // Prepare data for the line chart
export  const prepareChartData = (data:any) => {
    const {arrayOfKeys, resultArray} = processData(data);
    // Example: Prepare data for line chart
  const lineChartDataTotalSpent = [
      {
        name: "Logins",
        data: resultArray,
      },
      {
        name: "Logins",
        data: [10, 10, 4, 6, 9, 8, 8],
      },
    ];
const lineChartOptionsTotalSpent: any = {
	chart: {
		toolbar: {
			show: false
		},
		dropShadow: {
			enabled: true,
			top: 13,
			left: 0,
			blur: 10,
			opacity: 0.1,
			color: '#4318FF'
		}
	},
	colors: ['#4318FF', '#39B8FF'],
	markers: {
		size: 0,
		colors: 'white',
		strokeColors: '#7551FF',
		strokeWidth: 3,
		strokeOpacity: 0.9,
		strokeDashArray: 0,
		fillOpacity: 1,
		discrete: [],
		shape: 'circle',
		radius: 2,
		offsetX: 0,
		offsetY: 0,
		showNullDataPoints: true
	},
	tooltip: {
		theme: 'dark'
	},
	dataLabels: {
		enabled: false
	},
	stroke: {
		curve: 'smooth',
		type: 'line'
	},
	xaxis: {
		type: 'numeric',
		categories: arrayOfKeys,
		labels: {
			style: {
				colors: '#A3AED0',
				fontSize: '12px',
				fontWeight: '500'
			}
		},
		axisBorder: {
			show: false
		},
		axisTicks: {
			show: false
		}
	},
	yaxis: {
		show: false
	},
	legend: {
		show: false
	},
	grid: {
		show: false,
		column: {
			color: ['#7551FF', '#39B8FF'],
			opacity: 0.5
		}
	},
	color: ['#7551FF', '#39B8FF']
};

    return {lineChartDataTotalSpent, lineChartOptionsTotalSpent};
  };
