
 
// Chakra imports
import { Box, SimpleGrid } from '@chakra-ui/react';
import MainTab from 'components/Molecules/Tabs/MainTab';

// Custom components
import PaymentTable from 'screens/admin/dataTables/components/PaymentTable';
import PackagesTable from 'screens/admin/dataTables/components/SubscriptionPackageTable';
import paymentDataComplex from 'screens/admin/dataTables/variables/paymentTableData';
// Assets

export default function Overview() {
	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			{/* Main Fields */}
			<MainTab/>
			<SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
				
				<PackagesTable  tableData={paymentDataComplex} />
				<PaymentTable  tableData={paymentDataComplex} />
			</SimpleGrid>
		</Box>
	);
}
