import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from 'utils/axiosMiddleware';

export const getTotalUsers:any =  createAsyncThunk(
  "user/getTotal",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
      
    try {
      const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/user/total`,{headers: { Authorization: `Bearer ${token}` },
      },);
      
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  })


  export const getTotalWeeklyUsers:any =  createAsyncThunk(
    "user/getTotalWeekly",
    async (arg, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
      try {
        const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/user/total/weekly`,{headers: { Authorization: `Bearer ${token}` },
        },);
        
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })

    export const getTotalMonthlyUsers:any =  createAsyncThunk(
        "user/getTotalMonthly",
        async (arg, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
            
          try {
            const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/user/total/monthly`,{headers: { Authorization: `Bearer ${token}` },
            },);
            
            return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })

        export const getTotalDailyUsers:any =  createAsyncThunk(
            "user/getTotalDaily",
            async (arg, { rejectWithValue }) => {
              const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
                
              try {
                const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/user/total/daily`,{headers: { Authorization: `Bearer ${token}` },
                },);
                
                return data;
              } catch (error) {
                return rejectWithValue(error);
              }
            })

            export const getTotalHourlyUsers:any =  createAsyncThunk(
                "user/getTotalHourly",
                async (arg, { rejectWithValue }) => {
                  const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
                    
                  try {
                    const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/user/total/hourly`,{headers: { Authorization: `Bearer ${token}` },
                    },);
                    
                    return data;
                  } catch (error) {
                    return rejectWithValue(error);
                  }
                })


            export const getTotalHourlyLogin:any =  createAsyncThunk(
                "user/getTotalHourlyLogin",
                async (arg, { rejectWithValue }) => {
                  const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
                    
                  try {
                    const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/user/total/hourly/loggedin`,{headers: { Authorization: `Bearer ${token}` },
                    },);
                    
                    return data;
                  } catch (error) {
                    return rejectWithValue(error);
                  }
                })

                export const getTotalRandomExamTaken:any =  createAsyncThunk(
                  "user/getTotalRandomExamTaken",
                  async (arg, { rejectWithValue }) => {
                    const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
                      
                    try {
                      const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/exam/admin/userstookrandomexam`,
                      {headers: { Authorization: `Bearer ${token}` },
                      },);
                      
                      return data;
                    } catch (error) {
                      return rejectWithValue(error);
                    }
                  })

                  export const getSubscribersByRegion:any =  createAsyncThunk(
                    "user/getSubscribersByRegion",
                    async (arg, { rejectWithValue }) => {
                      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
                        
                      try {
                        const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/payment/subscribercountbyregion`,
                        {headers: { Authorization: `Bearer ${token}` },
                        },);
                        
                        return data;
                      } catch (error) {
                        return rejectWithValue(error);
                      }
                    })
                
    
const dashboardSlice:any = createSlice({
  name: "dashboard",
  initialState: {
    totalUser: {},
    totalWeekly: {},
    totalMonthly: {},
    totalHourly: {},
    totalDaily: {},
    totalHourlyLogin: {},
    isSuccess: false,
    message: "",
    loading: false,
  },
  reducers: {
       

  },
  extraReducers: {
    [getTotalUsers.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getTotalUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.totalUser = payload;
      state.isSuccess = true;
    },
    [getTotalUsers.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [getTotalDailyUsers.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getTotalDailyUsers.fulfilled]: (state:any, { payload }) => {
        state.loading = false;
        state.totalDaily = payload;
        state.isSuccess = true;
    },
    [getTotalDailyUsers.rejected]: (state, { payload }) => {
      state.message = payload.data;
      state.loading = false;
      state.isSuccess = false;
    },
    [getTotalHourlyUsers.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getTotalHourlyUsers.fulfilled]: (state:any, { payload , meta}) => {
     
        state.loading = false;
        state.totalHourly = payload;
        state.isSuccess = true;
    },
    [getTotalHourlyUsers.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [getTotalWeeklyUsers.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getTotalWeeklyUsers.fulfilled]: (state:any, {payload}) => {
        state.loading = false;
        state.totalWeekly = payload;
        state.isSuccess = true;
    },
    [getTotalWeeklyUsers.rejected]: (state:any, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },

    [getTotalMonthlyUsers.pending]: (state, { payload }) => {
        state.loading = true;
      },
      [getTotalMonthlyUsers.fulfilled]: (state:any, {payload}) => {
          state.loading = false;
          state.totalMonthly = payload;
          state.isSuccess = true;
      },
      [getTotalMonthlyUsers.rejected]: (state:any, { payload }) => {
        state.message = payload;
        state.loading = false;
        state.isSuccess = false;
      },
      [getTotalHourlyLogin.pending]: (state, { payload }) => {
        state.loading = true;
      },
      [getTotalHourlyLogin.fulfilled]: (state:any, {payload}) => {
          state.loading = false;
          state.totalMonthly = payload;
          state.isSuccess = true;
      },
      [getTotalHourlyLogin.rejected]: (state:any, { payload }) => {
        state.message = payload;
        state.loading = false;
        state.isSuccess = false;
      },

  },
});

export default dashboardSlice;
