import { configureStore } from '@reduxjs/toolkit';
import rootReducer from 'reduxtoolkit/rootReducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
})

export default store;


