import React from "react";

// chakra imports
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import Content from "components/Molecules/sidebar/components/Content";
import { MdMenu } from "react-icons/md";
// Assets

function Sidebar(props: { routes: RoutesType[]; [x: string]: any }) {
  const { routes } = props;

  let variantChange = "0.2s linear";
  let shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  // Chakra Color Mode
  let sidebarBg = useColorModeValue("navy.950", "navy.800");
  let sidebarMargins = "0px";

  // SIDEBAR
  return (
    <Box
      display={{ sm: "none", md: "none", xl: "block" }}
      position="fixed"
      minH="100%"
    >
      <Box
        bg={sidebarBg}
        transition={variantChange}
        w="220px"
        h="100vh"
        m={sidebarMargins}
        minH="100%"
        overflowY="auto"
        overflowX="hidden"
        boxShadow={shadow}
      >
        <Content routes={routes} />
      </Box>
    </Box>
  );
}

// FUNCTIONS
export function SidebarResponsive(props: { routes: RoutesType[] }) {
  let sidebarBackgroundColor = useColorModeValue("white", "navy.800");
  let variantChange = "0.2s linear";
  let shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
    "unset"
  );
  // Chakra Color Mode
  let sidebarBg = useColorModeValue("navy.950", "navy.800");
  let sidebarMargins = "0px";
  // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const { routes } = props;
  return (
    <Flex display={{ sm: "flex", md: "flex", xl: "none" }} alignItems="start">
      <Icon as={MdMenu} onClick={() => onOpen()} />
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === "rtl" ? "right" : "left"}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <Box
          bg={sidebarBg}
          transition={variantChange}
          w="220px"
          h="100vh"
          m={sidebarMargins}
          minH="100%"
          overflowY="auto"
          overflowX="hidden"
          boxShadow={shadow}
        >
          <DrawerContent w="285px" maxW="285px" bg={sidebarBackgroundColor}>
            <DrawerCloseButton
              zIndex="3"
              onClick={onClose}
              _focus={{ boxShadow: "none" }}
              _hover={{ boxShadow: "none" }}
            />
            <DrawerBody maxW="285px" px="0rem" pb="0">
              <Content routes={routes} />
            </DrawerBody>
          </DrawerContent>
        </Box>
      </Drawer>
    </Flex>
  );
}
// PROPS

export default Sidebar;
