import { Input } from "@chakra-ui/react";

const TextInputForm=(
    props: 
    {   id:string,
        placeholder: string,
         formName:any,
     })=>{

        const { id, placeholder, formName } = props;
    return(
      <Input
                id={id}
                fontWeight='500'
                variant='main'
                h='44px'
                maxH='44px' 
                borderRadius='0px'
                type="text"
                name={id}
                placeholder={placeholder}
                _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
                { ...formName.register(`${id}`, {
                    required: 'This is required',
                    pattern: { value: /^[A-Za-z]+$/i, message: "Invalid Last Name"}
                })}
                />
    )
}
export default TextInputForm;