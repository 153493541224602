import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
const TableContent = (props: any) => {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const deleteIconColor = useColorModeValue("red.600", "white");

  return (
    <Flex align="center">
      <Text
        color={textColor}
        fontSize={{ sm: "10px", md: "10px", lg: "14" }}
        fontWeight="normal"
      >
        {props.title}
      </Text>
    </Flex>
  );
};
export default TableContent;
