import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from 'utils/axiosMiddleware';


export const getStudiesById:any =  createAsyncThunk(
  "studies/getStudyById",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
      
    try {
      const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/study/study/${arg}`,
      {headers: { Authorization: `Bearer ${token}` },
    
      },);
      
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  })
export const getStudies:any =  createAsyncThunk(
  "studies/getData",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
      
    try {
      const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/study/study`,
      {headers: { Authorization: `Bearer ${token}` },
      params: arg,
      },);
      
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  })
  export const publishStudy:any =  createAsyncThunk(
    "study/Publish",
    async (arg:any, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
      try {
        const data = await axios({
          method: 'put',
          url: `${process.env.REACT_APP_API_URL}/study/publish/${arg}`,
          headers: { Authorization: `Bearer ${token}` },
         
          
      });
      return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })

    export const unPublishStudy:any =  createAsyncThunk(
      "study/unPublish",
      async (arg:any, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
        try {
          const data = await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/study/unpublish/${arg}`,
            headers: { Authorization: `Bearer ${token}` },
            
        });
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      })
  export const addStudyPdf:any =  createAsyncThunk(
    "studies/addStudyPdf",
    async (arg:any, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
      
      try {
        const data = await axios({
          method: 'put',
          url: `${process.env.REACT_APP_API_URL}/study/addpdfs/${arg.id}`,
          headers: { Authorization: `Bearer ${token}` },
          data: arg.payload,
          
      });
      return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })
    export const addStudLinks:any =  createAsyncThunk(
      "studies/addStudyLinks",
      async (arg:any, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
        try {
          const data = await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/study/addvideolinks/${arg.id}`,
            headers: { Authorization: `Bearer ${token}` },
            data: arg.payload,
            
        });
      
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      })
      
    export const studyAddQuestion:any =  createAsyncThunk(
      "studies/addStudyQuestion",
      async (arg:any, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
        try {
          const data = await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_URL}/study/addquestion/${arg.id}`,
            headers: { Authorization: `Bearer ${token}` },
            data: arg.payload,
            
        });
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      })
      
      export const studyDeletePdfs:any =  createAsyncThunk(
        "studies/deleteStudyPdfs",
        async (arg:any, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
          try {
            const data = await axios({
              method: 'delete',
              url: `${process.env.REACT_APP_API_URL}/study/deletepdfs/${arg.id}`,
              headers: { Authorization: `Bearer ${token}` },
              data: arg.payload,
              
          });
          return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })

        

        export const studyDeleteMobileVideoLinks:any =  createAsyncThunk(
          "studies/deleteStudyLinks",
          async (arg:any, { rejectWithValue }) => {
            const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
            
            try {
              const data = await axios({
                method: 'delete',
                url: `${process.env.REACT_APP_API_URL}/study/deletemobilevideolinks/${arg.id}`,
                headers: { Authorization: `Bearer ${token}` },
                data: arg.payload,
                
            });
          
            return data;
            } catch (error) {
              return rejectWithValue(error);
            }
          })
    

      export const studyDeleteVideoLinks:any =  createAsyncThunk(
        "studies/deleteStudyLinks",
        async (arg:any, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
          try {
            const data = await axios({
              method: 'delete',
              url: `${process.env.REACT_APP_API_URL}/study/deletevideolinks/${arg.id}`,
              headers: { Authorization: `Bearer ${token}` },
              data: arg.payload,
              
          });
      
          return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })
      export const studyRemoveQuestion:any =  createAsyncThunk(
        "studies/removeStudyQuestion",
        async (arg:any, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
          try {
            const data = await axios({
              method: 'delete',
              url: `${process.env.REACT_APP_API_URL}/study/deletequestion/${arg.id}`,
              headers: { Authorization: `Bearer ${token}` },
              data: arg.payload,
              
          });
          return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })

    
  export const updateStudy:any =  createAsyncThunk(
    "studies/updateData",
    async (arg:any, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
      try {
        const data = await axios({
          method: 'put',
          url: `${process.env.REACT_APP_API_URL}/study/update${arg.id}`,
          headers: { Authorization: `Bearer ${token}` },
          data: arg.payload,
          
      });
      return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })

    export const deleteStudy:any =  createAsyncThunk(
      "study/deleteData",
      async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
        try {
     
          const data = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/study/delete/${arg}`,
            headers: { Authorization: `Bearer ${token}` },
        });
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      })
      
      export const createStudy:any =  createAsyncThunk(
        "study/createStudy",
        async (arg, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';  
          try {   
            const data:any = await axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URL}/study/create`,
              headers: { Authorization: `Bearer ${token}` },
              data: arg,
          });
          return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })

const studySlice:any = createSlice({
  name: "study",
  initialState: {
    data: [],
    isSuccess: false,
    message: "",
    loading: false,
  },
  reducers: {
       

  },
  extraReducers: {
    [getStudies.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getStudies.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getStudies.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [createStudy.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createStudy.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      
      state.data.studies.push(payload.data.study);
      state.isSuccess = true;
    },
    [createStudy.rejected]: (state, { payload }) => {
      state.message = payload.data;
      state.loading = false;
      state.isSuccess = false;
    },
    [updateStudy.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateStudy.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
   
       const index = state.data.studies.findIndex((study:any) => study.id === payload.data.study.id);
             if (index !== -1) {
            
           state.data.studies[index] = payload.data.study;
         }
      state.isSuccess = true;
    },
    [updateStudy.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [studyAddQuestion.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [studyAddQuestion.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
   
      state.isSuccess = true;
    },
    [studyAddQuestion.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [addStudyPdf.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [addStudyPdf.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
   
       const index = state.data.studies.findIndex((study:any) => study.id === payload.data.study.id);
             if (index !== -1) {
            
           state.data.studies[index] = payload.data.study;
         }
      state.isSuccess = true;
    },
    [addStudyPdf.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [addStudLinks.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [addStudLinks.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
   
       const index = state.data.studies.findIndex((study:any) => study.id === payload.data.study.id);
             if (index !== -1) {
            
           state.data.studies[index] = payload.data.study;
         }
      state.isSuccess = true;
    },
    [addStudLinks.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    
    [deleteStudy.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteStudy.fulfilled]: (state:any, action) => {
      state.loading = false;
       state.data.studies = state.data.studies.filter((study:any) => study.id !== action.meta.arg);
      state.isSuccess = true;
    },
    [deleteStudy.rejected]: (state:any, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [publishStudy.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [publishStudy.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
    
      
       let studies = [...state.data.studies];
       var index = state.data.studies.findIndex((study:any) => study.id === payload.data.study.id);
   
       studies[index] = payload.data.study;
       
      
       state.data = {...state.data, studies }
     // temp.data.exams = [...exams, payload.data.exam];
      
      
      state.isSuccess = true;
    },
    [publishStudy.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [unPublishStudy.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [unPublishStudy.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      let studies = [...state.data.studies];
      var index = state.data.studies.findIndex((study:any) => study.id === payload.data.study.id);
  
      studies[index] = payload.data.study;
      
     
      state.data = {...state.data, studies }
      state.isSuccess = true;
    },
    [studyRemoveQuestion.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [studyRemoveQuestion.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      state.isSuccess = true;
    },
    [studyRemoveQuestion.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
   
  },
});

export default studySlice;
