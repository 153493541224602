import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Icon, Select, Tab, TabList, TabPanel, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';

import {
	AspectRatio, BoxProps, Container, FormControl, FormLabel, forwardRef,
	Heading, Image, Input, InputGroup, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack,
	Text
} from '@chakra-ui/react';
import {
	createColumnHelper, getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable
} from '@tanstack/react-table';
import illustration from "assets/images/auth/logo.jpg";
import { PrimaryButton } from 'components/Atoms/Button/Buttons';
import TableContent from 'components/Atoms/Label/TableCell';
import TableHeader from 'components/Atoms/Label/TableHeader';
import AlertBox from 'components/Molecules/Alerts/AlertBox';
// Custom components
import PrimaryTable from 'components/Molecules/Tables/PrimaryTable';
import MainTab from 'components/Molecules/Tabs/MainTab';
import { motion, useAnimation } from 'framer-motion';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, UseFormRegisterReturn } from "react-hook-form";
import { FaEdit } from 'react-icons/fa';
import { MdDeleteOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { getGrades } from 'reduxtoolkit/reducers/gradeSlice';
import { createSubjectManagement, deleteSubjectManagement, getSubjectsManagement, updateSubjectManagement } from 'reduxtoolkit/reducers/subjectManagementSlice';
import { gradeType } from 'types/examTypes';

type FileUploadProps = {
  register?: UseFormRegisterReturn
  accept?: string
  onChangePicture: any
  multiple?: boolean
  children?: React.ReactNode
}

const FileUpload = (props: FileUploadProps) => {
  const { register, accept, multiple, onChangePicture, children } = props
  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const { ref, ...rest } = register as {ref: (instance: HTMLInputElement | null) => void}
 
  const handleClick = () => inputRef.current?.click()
  const [imageURL, setImageURL] = useState(null);
  return (
      <InputGroup onClick={handleClick}>
        <input
          type='file'
          multiple={multiple || false}
          hidden
          accept={accept}
		  onChange={onChangePicture}
	
          {...rest}
          ref={(e) => {
            ref(e)
            inputRef.current = e
			
          }}
        />
        <>
          {children}
        </>
      </InputGroup>
  )
}

type FormValues = {
  file_: FileList
}

type RowObj = {
	id: string;
	subject: any;
	grade: gradeType;
	description: string;
	icon: string;
	actions: any;
};

const first = {
	rest: {
	  rotate: "-15deg",
	  scale: 0.95,
	  x: "-50%",
	  filter: "grayscale(80%)",
	  transition: {
		duration: 0.5,
		type: "tween",
		ease: "easeIn"
	  }
	},
	hover: {
	  x: "-70%",
	  scale: 1.1,
	  rotate: "-20deg",
	  filter: "grayscale(0%)",
	  transition: {
		duration: 0.4,
		type: "tween",
		ease: "easeOut"
	  }
	}
  };
  
  const second = {
	rest: {
	  rotate: "15deg",
	  scale: 0.95,
	  x: "50%",
	  filter: "grayscale(80%)",
	  transition: {
		duration: 0.5,
		type: "tween",
		ease: "easeIn"
	  }
	},
	hover: {
	  x: "70%",
	  scale: 1.1,
	  rotate: "20deg",
	  filter: "grayscale(0%)",
	  transition: {
		duration: 0.4,
		type: "tween",
		ease: "easeOut"
	  }
	}
  };
  
  const third = {
	rest: {
	  scale: 1.1,
	  filter: "grayscale(80%)",
	  transition: {
		duration: 0.5,
		type: "tween",
		ease: "easeIn"
	  }
	},
	hover: {
	  scale: 1.3,
	  filter: "grayscale(0%)",
	  transition: {
		duration: 0.4,
		type: "tween",
		ease: "easeOut"
	  }
	}
  };
  
  const PreviewImage = forwardRef<BoxProps, typeof Box>((props, ref) => {
	return (
	  <Box
		bg="white"
		top="0"
		height="100%"
		width="100%"
		position="absolute"
		borderWidth="1px"
		borderStyle="solid"
		rounded="sm"
		borderColor="gray.400"
		as={motion.div}
		backgroundSize="cover"
		backgroundRepeat="no-repeat"
		backgroundPosition="center"
		backgroundImage={illustration}
		{...props}
		ref={ref}
	  />
	);
  });
const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function ComplexTable() {

	const controls = useAnimation();
	const startAnimation = () => controls.start("hover");
	const stopAnimation = () => controls.stop();
	const createSubjectModal = useDisclosure();
	const [gradesParam, setGradesParam] =useState<any>();
	const [ sorting, setSorting ] = React.useState<SortingState>([]);
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const iconColor = useColorModeValue('secondaryGray.500', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	
	type FileUploadProps = {
		register: UseFormRegisterReturn
		accept?: string
		multiple?: boolean
		children?: any
	  }
	const columns = [
	
		columnHelper.accessor('subject', {
			id: 'subject',
			header: () => (
				<TableHeader title="SUBJECT"/>
			
			),
			cell: (info) => (
				
				<TableContent title={info.row.original?.subject?.subject}/>
			)
		}),
		columnHelper.accessor('grade', {
			id: 'grade',
			header: () => (
				<TableHeader title="GRADE"/>
			),
			cell: (info) => (
				<TableContent title={info.row.original?.grade?.grade}/>
			)
		}),
		columnHelper.accessor('icon', {
			id: 'icon',
			header: () => (
				<TableHeader title="ICON"/>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Image
					    borderRadius='10px'
						src={info.row.original.icon}
						alt="Example image"
						boxSize="200px"
						objectFit="cover"
						/>
				</Flex>
			)
		}),
		columnHelper.accessor('actions', {
		    id: 'actions',
			header: () => (
				<TableHeader title="Actions"/>
			),
			cell: (info) => (
				<Flex justify='space-evenly'>
				<Icon boxSize={6}  onClick={()=>handleEditSubject(info.row)} as={FaEdit} color='gray.400' />
				<Icon boxSize={6} onClick={()=> handleDeleteSubject(info.row.original)} as={MdDeleteOutline} color='red' />
			</Flex>
			)	
			}),	
			
	];

	const subjectsManagement = useSelector((state:any) => state.subjectManagement);
    
	const [id, setId]= useState<any>("");

		
	
	const SubjectEditform = useForm();
	const {
		
	handleSubmit , reset, formState: {errors: editErrors}} = SubjectEditform;
	const editSubjectModal = useDisclosure();
	const alert = useDisclosure();
	const handleEditSubject=(value:any)=>{
		
		setId(value.original.id);
		const data =value.original;
	
		setImgData(value.original.icon);
		reset({
			subject: data.subject.subject,
			grade: data.grade.grade,
			description: data.description

		})
		editSubjectModal.onOpen();
		}
		const toast = useToast();
		const onDeleteSubject=()=>
		{
            
			dispatch(deleteSubjectManagement(id)).then((data:any)=>{
				if(data.type == "subject/deleteData/fulfilled")
				{
					toast({
						title: 'Success',
						description: "Subject Deleted successfully",
						status: 'success',
						duration: 9000,
						isClosable: true,
					  });
				}
				else{
					toast({
						title: 'Error',
						description:   `${data.payload.response.data.message}`,
						status: 'error',
						duration: 9000,
						isClosable: true,
					  });
				}
			});
			
			alert.onClose();
		
		}
		const handleDeleteSubject=(value:any)=>{
			
			setId(value.id);
			alert.onOpen();
	   }
	const onCreateSubject=(values: any)=>{
		
		var temp : any = Object.fromEntries(Object.entries(values).filter(([key]) => key.includes('icon')));
		 let result = Object.keys(temp?.icon).map(function (key) {
		 	return [Number(key), temp?.icon[key]];
		 });
		
		const formData = new FormData();
		 formData.append('description', "no_description");
		 formData.append('subject', values.subject);
		 formData.append('grade', values.grade);
         formData.append('icon', result[0][1]);

		  dispatch(createSubjectManagement(formData)).then((data:any)=>{
			if(data.type == "SubjectManagement/createSubject/fulfilled")
			{
				toast({
					title: 'Success',
					description: "Subject Created successfully",
					status: 'success',
					duration: 9000,
					isClosable: true,
				  });
			}
			else{
				toast({
					title: 'Error',
					description:   `${data.payload.response.data.message}`,
					status: 'error',
					duration: 9000,
					isClosable: true,
				  });
			}
		});
		  createSubjectModal.onClose();
		
	}

	const onEditSubject=(values: any)=>{
		var temp : any = Object.fromEntries(Object.entries(values).filter(([key]) => key.includes('icon')));
		let result = Object.keys(temp?.icon).map(function (key) {
			return [Number(key), temp?.icon[key]];
		});
		const formData = new FormData();
		 formData.append('description', values.description);
		 formData.append('subject', values.subject);
		 formData.append('grade', values.grade);
         formData.append('icon', result[0][1]);

		const data = {
			id: id,
			payload: formData
		}
		
		dispatch(updateSubjectManagement(data)).then((data:any)=>{
			if(data.type == "subjectManagement/updateData/fulfilled")
			{
				toast({
					title: 'Success',
					description: "Subject updated successfully",
					status: 'success',
					duration: 9000,
					isClosable: true,
				  });
			}
			else{
				toast({
					title: 'Error',
					description:   `${data.payload.response.data.message}`,
					status: 'error',
					duration: 9000,
					isClosable: true,
				  });
			}
		});
		editSubjectModal.onClose();
	}
	const subjects:any = useSelector((state:any) => state.subject.data);
	const dispatch = useDispatch();
	
     useEffect(()=>{

	    	 dispatch(getSubjectsManagement());
	    	
	    	 dispatch(getGrades()).then((data:any)=>{
	 		setGrades(data.payload)
			
			 setSelectedGrade(data.payload[0].grade)
 });

 },[])

	 useEffect(()=>{
		
	if(subjectsManagement.isSuccess == true){
	   
		setData(subjectsManagement.data.subjects)
	}
	 },[subjectsManagement?.loading])
	const [grades, setGrades] = useState([]);
    const [regions, setRegions] = useState([]);
	const subjectForm = useForm();
	const cancelRef = React.useRef()
	const {
	control,
	handleSubmit: handleSubjectSubmit,formState:{errors} } = subjectForm;
	const [ data, setData ] = React.useState(() => []);
	const [ tabData, setTabData ] = React.useState(() => []);
	
	const table = useReactTable({
		data,
		columns,
		state: {
			sorting
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		debugTable: true
	});
	const [imgData, setImgData] = useState(illustration);
	const onChangePicture = (e:any) => {
		
		if (e.target.files[0]) {
		  
		
		  const reader:any = new FileReader();
		  reader.addEventListener("load", () => {
			setImgData(reader.result);
		  });
		  reader.readAsDataURL(e.target.files[0]);
		}
	  };
	  const [selectedGrade, setSelectedGrade]= useState("");

	  const TabHeaders=()=>{
		return(
			<TabList 	bg='#EDF3F8' justifyContent="space-evenly">
			{grades.map((grade)=>(
				<Tab _selected={{ color: '#000000' }} 
				fontWeight= '600'  
				fontSize='16px'
				 color= '#BBBBBB'
				  onClick={()=> setSelectedGrade(grade.grade)} key={grade.grade}>
					{grade.grade}
					</Tab>
			  ))}
			  </TabList>
		)
	  }

	  const TabFilters=()=>{
		return(
			<Flex py={5} justify='space-between'>
	
					 <Box w='300px'>
		
				</Box> 
				<Box w='150px'>
			
				<Button 
				    fontSize={{sm: '10px', md: '10px', lg: '14'}}
					bg="#0076FD"
					fontWeight='normal'
					
					color='white'
					borderRadius="0"
					onClick={createSubjectModal.onOpen}
					>
						<AddIcon w={4} h={4} px='1px' color="white" />
					    Create Subject
						</Button> 
						</Box>
						
				
					</Flex>
		)
	  }

	  const brandColor = useColorModeValue('brand.500', 'white');
	  useEffect(()=>{
		if(subjectsManagement.isSuccess === true){
		
		 let tabSubjects = subjectsManagement.data.subjects.filter((subject:any)=> subject.grade.grade === selectedGrade);
		 
		 setTabData(tabSubjects);
	}
	},[selectedGrade, subjectsManagement.loading]);
	const [imageURL, setImageURL] = useState(null);
	return (
		<Box borderRadius='0px' flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			
			
			
			
			<Box>
				<TabFilters/>
			<MainTab  headers={<TabHeaders/>} >
			
			

			<TabPanel>
			 <PrimaryTable data={tabData} columns={columns} />
			 </TabPanel>
			 <TabPanel>
			 <PrimaryTable data={tabData} columns={columns} />
			 </TabPanel>
			 <TabPanel>
			 <PrimaryTable data={tabData} columns={columns} />
			 </TabPanel>
			 <TabPanel>
			 <PrimaryTable data={tabData} columns={columns} />
			 </TabPanel>
			 <TabPanel>
			 <PrimaryTable data={tabData} columns={columns} />
			 </TabPanel>
			 </MainTab>

			<Modal 
					isOpen={createSubjectModal.isOpen}
					onClose={createSubjectModal.onClose}>
						<ModalOverlay />
						<ModalContent>
						<Box borderLeftWidth='3px' borderColor='#7F77F1'>
						<form onSubmit={handleSubjectSubmit(onCreateSubject)}>
						<ModalHeader>Creating Subject</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<FormControl>
							<FormLabel>subject</FormLabel>
							<Select
									className='select-input'
									placeholder="Select subject"
									{ ...subjectForm.register('subject', {
										required: 'Subject is required',
										
									})}
									>
										{subjects.map((subject:any) => <option key={subject.id}>{subject.subject}</option>)}
									</Select>
									{errors.subjects && <Text color='red'>{errors.subjects.message.toString()}</Text>}
							</FormControl>
							<FormControl mt={4}>
							<FormLabel>Grade</FormLabel>
							<Select
									className='select-input'
									placeholder="Select Grade"
									{ ...subjectForm.register('grade', {
										required: 'Grade is required',
										
									})}
									>
										{grades.map((grade:any) => <option key={grade.id}>{grade.grade}</option>)}
									</Select>
									{errors.grades && <Text color='red'>{errors.grades.message.toString()}</Text>}
							</FormControl>

							<Box p={5} height="16" width="16" position="relative">
							<PreviewImage
								variants={first}
								backgroundImage={`url(${imgData})`}
							/>
							</Box>
							 <FormControl>
							<input 
								id="profilePic" 
								type="file" 
								accept='image/svg+xml'
								{ ...subjectForm.register('icon', {
									required: 'Image is required',
									
								})}
								onChange={onChangePicture} 
								/>
								{errors.icon && <Text color='red'>{errors.icon.message.toString()}</Text>}
							
								</FormControl>
							</ModalBody>
							<ModalFooter>
							<PrimaryButton type='submit'>
									Save
									</PrimaryButton>	
						</ModalFooter>
							</form>
							</Box>
							</ModalContent>
							</Modal>
							<Modal 
					isOpen={editSubjectModal.isOpen}
					onClose={editSubjectModal.onClose}>
						<ModalOverlay />
						<ModalContent>
						<Box borderLeftWidth='3px' borderColor='#7F77F1'>
						<form onSubmit={handleSubmit(onEditSubject)}>
						<ModalHeader>Edit Subject</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<FormControl mt={4}>
							<FormLabel>Subject Name</FormLabel>
							<Select
									className='select-input'
									placeholder="Select subject"
									{ ...SubjectEditform.register('subject', {
										required: 'This is required',
										minLength: { value: 0, message: 'Minimum length should be 4' },
									})}
									>
										{subjects.map((subject:any) => <option key={subject.id}>{subject.subject}</option>)}
									</Select>
									{editErrors.subject && <Text color='red'>{editErrors.subject.message.toString()}</Text>}
							</FormControl>		
							<FormControl mt={4}>
							<FormLabel>Grade</FormLabel>
							<Select
									className='select-input'
									placeholder="Select Grades"
									{ ...SubjectEditform.register('grade', {
										required: 'This is required',
										minLength: { value: 0, message: 'Minimum length should be 4' },
									})}
									>
										{grades.map((grade:any) => <option key={grade.id}>{grade.grade}</option>)}
									</Select>
									{editErrors.grade && <Text color='red'>{editErrors.grade.message.toString()}</Text>}
							</FormControl>
							<FormControl isRequired>
								<Container>
							<AspectRatio width="64" ratio={1}>
        <Box
          borderColor="gray.300"
          borderStyle="dashed"
          borderWidth="2px"
          rounded="md"
          shadow="sm"
          role="group"
          transition="all 150ms ease-in-out"
          _hover={{
            shadow: "md"
          }}
          as={motion.div}
          initial="rest"
          animate="rest"
          whileHover="hover"
        >
          <Box position="relative" height="100%" width="100%">
            <Box
              position="absolute"
              top="0"
              left="0"
              height="100%"
              width="100%"
              display="flex"
              flexDirection="column"
            >
              <Stack
                height="100%"
                width="100%"
                display="flex"
                alignItems="center"
                justify="center"
                spacing="4"
              >
                <Box height="16" width="12" position="relative">
                  <PreviewImage
                    variants={first}
                    backgroundImage={`url(${imgData})`}
                  />
                  <PreviewImage
                    variants={second}
                    backgroundImage={`url(${imgData})`}
                  />
                  <PreviewImage
                    variants={third}
                    backgroundImage={`url(${imgData})`}                  />
                </Box>
                <Stack p="8" textAlign="center" spacing="1">
                  <Heading fontSize="lg" color="gray.700" fontWeight="bold">
                    Drop images here
                  </Heading>
                  <Text fontWeight="light">or click to upload</Text>
                </Stack>
              </Stack>
            </Box>
          
          </Box>
        </Box>
      </AspectRatio>
    </Container>
				</FormControl>	
							<FormControl>
							<input 
								id="profilePic" 
								type="file" 
								accept='image/svg+xml'
								{ ...SubjectEditform.register('icon', {
									required: 'Icon is required',
									
								})}
								onChange={onChangePicture} 
								/>
								
							<FormLabel>Description</FormLabel>
								<Input
								id='description'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								
								placeholder="icon"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...SubjectEditform.register('description', {
									required: 'This is required',
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})}
								/>
							</FormControl> 				
						</ModalBody>
						<ModalFooter>
						<PrimaryButton type='submit'>
							Save
							</PrimaryButton>
							
						</ModalFooter>
						</form>
						</Box>
						</ModalContent>
					</Modal>
					<AlertBox 
							cancelRef={cancelRef} 
							alert={alert}
							header="Delete Subject?"
							alertBody="Are you sure you want to delete this subject"
							onDelete={onDeleteSubject}
							/>
			</Box>
		</Box>
	);
}
