type RowObj = {
	first_name: string;
	last_name: string;
	gender: string;
	phone_number: string;
	region: string;
	subscription_model: string;
	method: string;
};

const paymentDataComplex: RowObj[] = [
	{
		first_name: 'Abebe Kedir',
		last_name: 'Abebe Kedir',
		gender: 'M',	
		phone_number: '+251900000000',
	    region: 'Addis Ababa',
		subscription_model: 'One Year',
		method: 'Chapa'
	},
	{
		first_name: 'Abebe Kedir',
		last_name: 'Abebe Kedir' ,
		gender: 'M',
		phone_number: '+251900000000',
		region: 'Addis Ababa',
		subscription_model: 'One Year',
		method: 'Chapa'
	},
	{
		first_name: 'Abebe Kedir',
		last_name: 'Abebe Kedir',
		gender: 'M',
		phone_number: '+251900000000',
		region: 'Addis Ababa',
		subscription_model: 'One Year',
		method: 'Chapa'
	},		
	{
		first_name: 'Abebe Kedir',
		last_name:'Abebe Kedir',
		gender: 'M',
		phone_number: '+251900000000',
	    region: 'Addis Ababa',
		subscription_model: 'One Year',
		method: 'Chapa'	},
	{
		first_name: 'Abebe Kedir',
		last_name: 'Abebe Kedir',
		gender: 'M',
		phone_number: '+251900000000',
		region: 'Addis Ababa',
		subscription_model: 'One Year',
		method: 'Chapa'
	},
	{
		first_name: 'Abebe Kedir',
		last_name: 'Abebe Kedir',
		gender: 'M',
		phone_number: '+251900000000',
		region: 'Addis Ababa',
		subscription_model: 'One Year',
		method: 'Chapa'
	},
	{
		first_name: 'Abebe Kedir',
		last_name: 'Abebe Kedir',
		gender: 'M',
		phone_number: '+251900000000',
		region: 'Addis Ababa',
		subscription_model: 'One Year',
		method: 'Chapa'
	},
	{
		first_name: 'Abebe Kedir',
		last_name: 'Abebe Kedir',
		gender: 'M',
		phone_number: '+251900000000',
		region: 'Addis Ababa',
		subscription_model: 'One Year',
		method: 'Chapa'
	},
	{
		first_name: 'Abebe Kedir',
		last_name: 'Abebe Kedir',
		gender: 'M',
		phone_number: '+251900000000',
	    region: 'Addis Ababa',
		subscription_model: 'One Year',
		method: 'Chapa'},
	{
		first_name: 'Abebe Kedir',
		last_name: 'Abebe Kedir',
		gender: 'M',
		phone_number: '+251900000000',
	    region: 'Addis Ababa',
		subscription_model: 'One Year',
		method: 'Chapa'	},
	{
		first_name: 'Abebe Kedir',
		last_name: 'Abebe Kedir',
		gender: 'M',
		phone_number: '+251900000000',
		region: 'Addis Ababa',
		subscription_model: 'One Year',
		method: 'Chapa'
	}
];
export default paymentDataComplex;
