import { AlertDialog, AlertDialogBody, AlertDialogCloseButton, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button } from "@chakra-ui/react";

const AlertBox=(props: any)=>{
    return(
        <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={props.cancelRef}
        onClose={props.alert.onClose}
        isOpen={props.alert.isOpen}
        isCentered
    >
          
    <AlertDialogOverlay />

        <AlertDialogContent>
        <Box borderLeftWidth='3px' borderColor='#de1e14'>
        <AlertDialogHeader>{props.header}</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
           {props.alertBody}
        </AlertDialogBody>
        <AlertDialogFooter>
            <Button ref={props.cancelRef} onClick={props.alert.onClose}>
            No
            </Button>
            <Button colorScheme='red' ml={3} onClick={props.onDelete}>
            Yes
            </Button>
        </AlertDialogFooter>
        </Box>
        </AlertDialogContent>
        
        </AlertDialog>
    )
}

export default AlertBox;