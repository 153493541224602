// Chakra imports
import {
  Box,
  Flex,
  Icon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import Chart from "react-apexcharts";
// Custom components
import Card from "components/Molecules/card/Card";
// Assets
import { useEffect, useState } from "react";
import { RiArrowUpSFill } from "react-icons/ri";
import { getTheCurrentMonth } from "utils/get/generalhelpers";

export default function MonthlyTraffic(props: any) {
  const {
    selectedYear,
    setSelectedYear,
    monthNumber,
    handleMonthlyTraffickFilter,
    setMonthNumber,
    series,
    options,
    ...rest
  } = props;
  const [barchartData, setBarChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    handleMonthlyTraffickFilter(getTheCurrentMonth(), 2024);
  }, []);
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Card p="20px" alignItems="center" flexDirection="column" w="100%">
      <Flex justify="space-between" align="start" px="10px" pt="5px" w="100%">
        <Flex flexDirection="column" align="start" me="20px">
          <Flex w="100%">
            <Text
              me="auto"
              color="secondaryGray.600"
              fontSize={{ sm: "10px", md: "10px", lg: "14" }}
              fontWeight="500"
            >
              Monthly Traffic
            </Text>
          </Flex>
          <Flex align="end">
            <Text
              ms="6px"
              color="secondaryGray.600"
              fontSize={{ sm: "10px", md: "10px", lg: "14" }}
              fontWeight="500"
            >
              Visitors
            </Text>
          </Flex>
        </Flex>
        <Flex align="center">
          <NumberInput
            name="duration"
            onChange={(e) => {
              setMonthNumber(e);
              handleMonthlyTraffickFilter(e, selectedYear);
            }}
            defaultValue={getTheCurrentMonth()}
            min={1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Select
            defaultValue={currentYear}
            onChange={(e) => {
              setSelectedYear(e.target.value);
              handleMonthlyTraffickFilter(monthNumber, e.target.value);
            }}
          >
            {Array.from({ length: 10 }, (_, i) => currentYear - 5 + i).map(
              (year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              )
            )}
          </Select>

          <Icon as={RiArrowUpSFill} color="green.500" />
        </Flex>
      </Flex>
      <Box w="100%" mt="auto">
        <Chart options={options} series={series} type="bar" width="100%" />
      </Box>
    </Card>
  );
}
