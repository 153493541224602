// Chakra imports
import {
  Box,
  Icon,
  Progress,
  SimpleGrid,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";
// Assets
// Custom components
import IconBox from "assets/icons/IconBox";
import MiniStatistics from "components/Molecules/card/MiniStatistics";
import { ProgressModal } from "components/Molecules/Modals/ProgressModal";
import { useEffect, useState } from "react";
import { MdBarChart } from "react-icons/md";
import { useDispatch } from "react-redux";
import {
  getOnlineUsers,
  getPeakDayInMonth,
  getPeakDayInWeek,
  getUsersCountbyRegion,
  getUsersCountbyScore
} from "reduxtoolkit/reducers/analyticSlice";
import {
  getTotalDailyUsers,
  getTotalHourlyLogin,
  getTotalHourlyUsers,
  getTotalMonthlyUsers,
  getTotalRandomExamTaken,
  getTotalUsers,
  getTotalWeeklyUsers
} from "reduxtoolkit/reducers/dashboardSlice";
import { getUsers } from "reduxtoolkit/reducers/userSlice";
import { getCurrentWeek, getTheCurrentMonth } from "utils/get/generalhelpers";
import { prepareChartData } from "utils/prepareChartData";
import DailyTraffic from "./components/DailyTraffic";
import UserScoreChart from "./components/ExamResultChart";
import MonthlyTraffic from "./components/MonthlyTraffic";
import PieCard from "./components/PieCard";
import SubscribersByRegion from "./components/subscribersByRegion";
import SubscriptionAnalytics from "./components/SubscriptionAnalytics";
interface ApiResponse {
  userCountByUserAverageScoreRange?: Record<string, number>;
  totalUsersTookExam?: number;
}




export default function UserReports() {

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalMonthlyUsers, setTotalMonthlyUsers] = useState(0);
  const [totalDailyUsers, setTotalDailyUsers] = useState(0);
  const [totalWeeklyUsers, setTotalWeeklyUsers] = useState(0);
  const [totalHourlyUsers, setTotalHourlyUsers] = useState(0);
  const [totalHourlyLoggedUsers, setTotalHourlyLoggedUsers] = useState(0);
  const [totalRandomExamTaken, setTotalRandomExamTaken] = useState(0);
  const [regions, setRegions] = useState([]);
  const [apiResponse, setApiResponse] = useState<ApiResponse>();
  const [regionsData, setRegionsData] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState(0);
  const [days, setDays] = useState([]);
  const [monthlyDays, setMonthlyDays] = useState([]);
  const [daysCount, setDaysCount] = useState([]);
  const [monthlyDaysCount, setMonthlyDaysCount] = useState([]);
  const [weekNumber, setWeekNumber] = useState(getCurrentWeek());
  const [selectedYear, setSelectedYear] = useState(2024);
  const [monthNumber, setMonthNumber] = useState(getTheCurrentMonth());
  const [lineChartDataTotalSpent, setLineChartDataTotalSpent] = useState([]);
  const [lineChartOptionsTotalSpent, setLineChartOptionsTotalSpent] = useState(
    {}
  );
  useEffect(() => {
    dispatch(getUsersCountbyRegion()).then((data: any) => {
      setRegions(
        data.payload.data.userCountByRegion.map((item: any) => item.region)
      );
      setRegionsData(
        data.payload.data.userCountByRegion.map((item: any) => item.count)
      );
    });
    dispatch(getUsersCountbyScore()).then((data: any) => {

      setApiResponse(data.payload.data);
    })

    const param = {
      startTime: "2024-02-01T10:56:43.767Z",
      endTime: "2024-02-26T10:56:43.767Z",
    };
    dispatch(getUsers(param)).then((data: any) => {
      var tmp = prepareChartData(data.payload.users);

      setLineChartDataTotalSpent(tmp.lineChartDataTotalSpent);
      setLineChartOptionsTotalSpent(tmp.lineChartOptionsTotalSpent);
    });

    dispatch(getOnlineUsers()).then((data: any) => {
      setOnlineUsers(data.payload.data.onlinUsersCount);
    });
    const params = {
      week: weekNumber,
      year: selectedYear,
    };
    dispatch(getPeakDayInWeek(params)).then((data: any) => {
      setDays(
        data.payload.data
          .map((item: any) => item.day)
          .filter((item: any) => item !== null && item !== undefined)
      );

      setDaysCount(
        data.payload.data
          .map((item: any) => item.dailyCount)
          .filter((item: any) => item !== null && item !== undefined)
      );
    });
    const monthparams = {
      month: 11,
      year: 2024,
    };

    dispatch(getTotalUsers()).then((data: any) => {
      setTotalUsers(data.payload.total);
    });
    dispatch(getTotalDailyUsers()).then((data: any) => {
      setTotalDailyUsers(data.payload.total);
    });
    dispatch(getTotalWeeklyUsers()).then((data: any) => {
      setTotalWeeklyUsers(data.payload.total);
    });
    dispatch(getTotalMonthlyUsers()).then((data: any) => {
      setTotalMonthlyUsers(data.payload.total);
    });
    dispatch(getTotalHourlyUsers()).then((data: any) => {
      setTotalHourlyUsers(data.payload.total);
    });
    dispatch(getTotalHourlyLogin()).then((data: any) => {
      setTotalHourlyLoggedUsers(data.payload.total);
    });
    dispatch(getTotalRandomExamTaken()).then((data: any) => {

      setTotalRandomExamTaken(data.payload.count);
    });


  }, []);

  const handleWeeklyTraffickFilter = (week: any, year: any) => {
    const params = {
      week: week,
      year: year,
    };
    dispatch(getPeakDayInWeek(params)).then((data: any) => {
      setDays(
        data.payload.data
          .map((item: any) => item.day)
          .filter((item: any) => item !== null && item !== undefined)
      );
      setDaysCount(
        data.payload.data
          .map((item: any) => item.dailyCount)
          .filter((item: any) => item !== null && item !== undefined)
      );
    });
  };
  const handleMonthlyTraffickFilter = (month: any, year: any) => {
    const params = {
      month: month,
      year: year,
    };
    dispatch(getPeakDayInMonth(params)).then((data: any) => {
      setMonthlyDaysCount(
        data.payload.data
          .map((item: any) => item.dailyCount)
          .filter((item: any) => item !== null && item !== undefined)
      );
      setMonthlyDays(
        data.payload.data
          .map((item: any) => item.day)
          .filter((item: any) => item !== null && item !== undefined)
      );
    });
  };

  useEffect(() => {
    setLoading(true);
    createProgressModal.onOpen();
    setTimeout(() => {
      setLoading(false);
      createProgressModal.onClose();
    }, 3000);
  }, []);



  const pieChartOptions: any = {
    labels: regions,
    colors: [
      "#7FDBFF",
      "#4696F3",
      "#F7CAC9",
      "#00897B",
      "#6AD2FF",
      "#EFF4FB",
      "#9A4374",
      "#FD7171",
      "#6FC757",
      "#F0F9E8",
      "#46BFBD",
      "#D95343",
    ],
    chart: {
      width: "50px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: true,
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: true,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },

    fill: {
      colors: [
        "#7FDBFF",
        "#4696F3",
        "#F7CAC9",
        "#00897B",
        "#6AD2FF",
        "#EFF4FB",
        "#9A4374",
        "#FD7171",
        "#6FC757",
        "#F0F9E8",
        "#46BFBD",
        "#D95343",
      ],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };

  const barChartDataDailyTraffic = [
    {
      name: "Daily Traffic",
      data: daysCount,
    },
  ];

  const barChartDataMonthlyTraffic = [
    {
      name: "Monthly Traffic",
      data: monthlyDaysCount,
    },
  ];

  const barChartOptionsMonthlyTraffic: any = {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: monthlyDays,
    },
  };

  const barChartOptionsDailyTraffic: any = {
    chart: {
      id: "basic-bar",
    },
    xaxis: {
      categories: days,
    },
  };

  const createProgressModal = useDisclosure();
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {loading ? <Progress id="admin" w="1/2" isIndeterminate /> : null}
      <ProgressModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Total Users"
          value={totalUsers}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Total Monthly Users"
          value={totalMonthlyUsers}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Total Weekly Users"
          value={totalWeeklyUsers}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Total Daily Users"
          value={totalDailyUsers}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Total Hourly User"
          value={totalHourlyUsers}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Hourly Logins"
          value={totalHourlyLoggedUsers}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Random Exam Taken"
          value={totalRandomExamTaken}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Logged in users"
          value={onlineUsers}
        />
      </SimpleGrid>

      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
        gap="20px"
        mb="20px"
      >
        <SubscriptionAnalytics />
        <PieCard options={pieChartOptions} series={regionsData} />

        <MonthlyTraffic
          selectedYear={selectedYear}
          monthNumber={monthNumber}
          handleMonthlyTraffickFilter={handleMonthlyTraffickFilter}
          setSelectedYear={setSelectedYear}
          setMonthNumber={setMonthNumber}
          options={barChartOptionsMonthlyTraffic}
          series={barChartDataMonthlyTraffic}
        />
        <DailyTraffic
          selectedYear={selectedYear}
          handleTraffickFilter={handleWeeklyTraffickFilter}
          setSelectedYear={setSelectedYear}
          setWeekNumber={setWeekNumber}
          weekNumber={weekNumber}
          options={barChartOptionsDailyTraffic}
          series={barChartDataDailyTraffic}
        />

        <UserScoreChart apiResponse={apiResponse} />
        <SubscribersByRegion />
      </SimpleGrid>
    </Box>
  );
}
