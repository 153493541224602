import { Badge, Box, Flex, Icon, Switch, Text, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';
import {
	createColumnHelper
} from '@tanstack/react-table';

import { PrimaryButton } from 'components/Atoms/Button/Buttons';
// Custom components
import { AddIcon } from '@chakra-ui/icons';
import TableHeader from 'components/Atoms/Label/TableHeader';
import PrimaryTable from 'components/Molecules/Tables/PrimaryTable';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { activateAdmin, deActivateAdmin, getAdmin, getRoles } from 'reduxtoolkit/reducers/adminSlice';
import { getUsers } from 'reduxtoolkit/reducers/userSlice';
import { CreateAdmin } from './createAdmin';
import { DeleteAdmin } from './deleteAdmin';
import { EditAdmin } from './editAdmin';


// Assets

type RowObj = {
	id: string;
	firstName: string;
	lastName: any;
	middleName: string;
	phoneNumber: string;
	email: string;
	actions: any;
	isActive: boolean;
	adminRole: any;
};

const columnHelper = createColumnHelper<RowObj>();


export default function AdminList() {
	const textColor = useColorModeValue("navy.700", "white");
	const textColorSecondary = "gray.400";
	const deleteIconColor= useColorModeValue("red.600", "white");
	const editAdminUsers = useForm();
	const { reset } = editAdminUsers;
	const [updated, setUpdated] = useState(false);
	const [id, setId]= useState<any>("");
	const [roles, setRoles] = useState([]);	
	const toast = useToast();
	
	const users:any = useSelector((state:any)=> state.admin);
	useEffect(()=>{
		dispatch(getRoles()).then((data:any)=>{
			let rolelist = data.payload.map(setID);
			setRoles(rolelist)
		})
		dispatch(getAdmin());
		dispatch(
			getUsers({
			  page: 0,
			  limit:  10,
			 
			}),
		  );
		},[])

		useEffect(()=>{
		 setData(users.data)
		},[users.loading,updated]);

	const [userData, setUserData]= useState([]);
	const [userRoles, setUserRoles] = useState([]);
	const handleEditUser=(value:any)=>{
		const data =value.original;
		setId(data.id);
		setUserData(data);
		const roles = data?.adminRole.map((item:any) => ({
			label: item.adminRole,
			value: item.adminRole
		}));

		reset({
			id: data.id,
			firstName: data.firstName,
			middleName: data.middleName,
			lastName: data.lastName,
			email: data.email,
			region: data.region,
			grade: data.grade,
			roles: roles,
			phoneNumber: data.phoneNumber,
		})
		setUserRoles(roles);
		
		editUserModal.onOpen();
		}

   
	const handleDeleteUser=(value:any)=>{
		setId(value.id);
		alert.onOpen();
	}
	const dispatch = useDispatch();
    
	const handleUserActivate=(values: any)=>{
		if(values.isActive){
		dispatch(deActivateAdmin(values.id)).then((data:any)=>{
			if(data.type == "admin/deactivateAdmin/fulfilled") {
				toast({
					title: 'Success',
					description: "Admin deActivated successfully",
					status: 'success',
					duration: 9000,
					isClosable: true,
				  });
				}
				else{
					toast({
						title: 'Error',
						description:   `${data.payload.response.data.message}`,
						status: 'error',
						duration: 9000,
						isClosable: true,
					  });
				  }
		})
		setUpdated(!updated);
	   }
		else{
		dispatch(activateAdmin(values.id)).then((data:any)=>{
			if(data.type == "admin/activateAdmin/fulfilled") {
				toast({
					title: 'Success',
					description: "Admin Activated successfully",
					status: 'success',
					duration: 9000,
					isClosable: true,
				  });
				}
				else{
					toast({
						title: 'Error',
						description:   `${data.payload.response.data.message}`,
						status: 'error',
						duration: 9000,
						isClosable: true,
					  });
				  }
		})
			setUpdated(!updated);
		
	}
		}

	const columns = [
	
		columnHelper.accessor('firstName', {
			id: 'firstName',
			header: () => (
				<TableHeader title='FIRST NAME'/>
					
		
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize={{sm: '10px', md: '10px', lg: '12px'}} fontWeight='normal'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),

		columnHelper.accessor('middleName', {
			id: 'middleName',
			header: () => (
				<TableHeader title="FATHER NAME" />
			),
			cell: (info) => (
				<Text color={textColor} fontSize={{sm: '10px', md: '10px', lg: '12px'}} fontWeight='normal'>
				{info.getValue()}
			</Text>
			)
		}),

	
		
		columnHelper.accessor('phoneNumber', {
			id: 'phoneNumber',
			header: () => (
				<TableHeader title="PHONE NUMBER"/>	
						),
			cell: (info) => (
				<Text color={textColor} fontSize={{sm: '10px', md: '10px', lg: '12px'}} fontWeight='normal'>
				{info.getValue()}
			</Text>
			)
		}),
		
		columnHelper.accessor('email', {
			id: 'email',
			header: () => (
				<TableHeader title="EMAIL"/>
				
			),
			cell: (info) => (
				<Text color={textColor} fontSize={{sm: '10px', md: '10px', lg: '12px'}} fontWeight='normal'>
				{info.getValue()}
			</Text>
			)
		}),
		columnHelper.accessor('adminRole', {
		    id: 'adminRole',
			header: () => (
				<TableHeader title="ROLES"/>
			),
			cell: (info) => (
				<Flex  >{info.getValue().map((role:any) => 
					{return( <Badge mx={2} colorScheme='green'>{role.adminRole}</Badge>)}
					)}</Flex>
			)	
			
			}),
		columnHelper.accessor('isActive', {
		    id: 'isActive',
			header: () => (
				<TableHeader title="isActive"/>
			),
			cell: (info) => (
				< >
				<Switch onChange={()=>handleUserActivate(info.row.original)} size='md'  isChecked={info.row.original.isActive} />
			</>
			
			)	
			
			}),
		
		columnHelper.accessor('actions', {
		    id: 'actions',
			header: () => (
				<TableHeader title="Actions"/>
			),
			cell: (info) => (
				<Flex justify='space-evenly'>
													<Icon
														color={textColorSecondary}
													
														as={FaEdit}
														onClick={()=>handleEditUser(info.row)}
														/>	
													<Icon
														color={deleteIconColor}
														
														as={MdDelete}
														onClick={()=> handleDeleteUser(info.row.original)}
														/>				
			</Flex>
			
			)	
			
			}),	
	];
	
	const createUserModal = useDisclosure();
	const editUserModal = useDisclosure();
	
	const alert = useDisclosure();
	const [data, setData] = React.useState([]);

  
function setID(item: any, index:any) {
		let obj:any = {};
		obj["value"] = item;
		obj["label"] = item;
		return obj;
	  }

	return (
		<Box borderRadius='0px'>
			
		 <Flex  justifyContent='flex-end' p='0px' m='0px' align='center'>				
					<PrimaryButton onClick={createUserModal.onOpen}>
						<AddIcon w={4} h={4} px='1px' color="white" />
					Add New Admin
					</PrimaryButton>
			</Flex> 
			<CreateAdmin roles={roles} createUserModal={createUserModal}/>
			<EditAdmin roles={roles} userRoles={userRoles} editAdminUsers={editAdminUsers} id={id} user={userData} editUserModal={editUserModal}/>
			<DeleteAdmin id={id} alert={alert}/>
			<PrimaryTable data={data} columns={columns} />
				
				
				
					
				
				
		</Box>
	);
}

	


