import { Button, Flex, Select, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { updateSingleChallengeDay } from "reduxtoolkit/reducers/challengeSlice";

const EditSingleChallenge = ({
  id,
  setReload,
  subjects,
  studies,
  def,
  editSingleChallengeModal,
}: any) => {
  const { register, reset, handleSubmit, setValue } = useForm();
  const dispatch = useDispatch();
  const toast = useToast();
  useEffect(() => {
    const val = studies.find(
      (study: any) =>
        study.subject.subject === def?.subject &&
        study.section.section === def?.section &&
        study.unit.unit === def?.unit
    );
    setSelectedSubject(def?.subject);
    setSelectedSection(def?.section);
    setSelectedUnit(def?.unit);
    setSelectedStudy(val?.title);
  }, []);
  const onSubmit = (data: any) => {
    data = {
      subject: data.subject === undefined ? selectedSubject : data.subject,
      unit: data.unit === undefined ? selectedUnit : data.unit,
      section: data.section === undefined ? selectedSection : data.section,
    };
    const arg = { id: id, payload: data };
    dispatch(updateSingleChallengeDay(arg)).then((data: any) => {
      if (data.type === "singlechallenge/updateData/fulfilled") {
        toast({
          title: "Success",
          description: "Challenge Updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        reset();
        setSelectedSubject("");
        setSelectedStudy("");
        setSelectedSection("");
        editSingleChallengeModal.onClose();
        setReload(true);
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedStudy, setSelectedStudy] = useState("");
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex gap={3}>
        <Select
          w="50%"
          placeholder="Select Subject"
          value={selectedSubject}
          onChange={(e: any) => {
            setSelectedSubject(e.target.value);
          }}
        >
          {subjects.map((subject: any) => (
            <option key={subject.id} value={subject.subject.subject}>
              {subject.subject.subject}
            </option>
          ))}
        </Select>
        <Select
          placeholder="Select Studies"
          value={selectedStudy}
          onChange={(e: any) => {
            setSelectedStudy(e.target.value);
            const selected = studies.find(
              (study: any) => study.title === e.target.value
            );

            setValue("subject", selected.subject.subject);
            setValue("unit", selected.unit.unit);
            setValue("section", selected.section.section);
          }}
        >
          {studies
            .filter(
              (challenge: any) =>
                challenge?.subject?.subject === selectedSubject
            )
            .map((study: any) => (
              <option key={study.id} value={study.title}>
                {study.title}
              </option>
            ))}
        </Select>
      </Flex>

      <Button mt={5} type="submit">
        Save
      </Button>
    </form>
  );
};

export default EditSingleChallenge;
