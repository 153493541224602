import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, FormControl, FormLabel, Icon, Input, InputGroup, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';
import {
	createColumnHelper, getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable
} from '@tanstack/react-table';

import { PrimaryButton } from 'components/Atoms/Button/Buttons';
import AlertBox from 'components/Molecules/Alerts/AlertBox';
// Custom components
import PrimaryTable from 'components/Molecules/Tables/PrimaryTable';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, UseFormRegisterReturn } from "react-hook-form";
import { FaEdit } from 'react-icons/fa';
import { MdDeleteOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { getGrades } from 'reduxtoolkit/reducers/gradeSlice';
import { createSubject, deleteSubject, getSubjects, updateSubject } from 'reduxtoolkit/reducers/subjectSlice';

type FileUploadProps = {
  register?: UseFormRegisterReturn
  accept?: string
  multiple?: boolean
  children?: React.ReactNode
}

const FileUpload = (props: FileUploadProps) => {
  const { register, accept, multiple, children } = props
  const inputRef = React.useRef<HTMLInputElement | null>(null)
  const { ref, ...rest } = register as {ref: (instance: HTMLInputElement | null) => void}

  const handleClick = () => inputRef.current?.click()

  return (
      <InputGroup onClick={handleClick}>
        <input
          type={'file'}
          multiple={multiple || false}
          hidden
          accept={accept}
          {...rest}
          ref={(e) => {
            ref(e)
            inputRef.current = e
          }}
        />
        <>
          {children}
        </>
      </InputGroup>
  )
}

type FormValues = {
  file_: FileList
}

type RowObj = {
	subject: string;
	
	description: string;
	actions: any;
};
const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function ComplexTable(props: { tableData: any }) {


	const createSubjectModal = useDisclosure();
	const { tableData } = props;
	const [ sorting, setSorting ] = React.useState<SortingState>([]);
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const iconColor = useColorModeValue('secondaryGray.500', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	let defaultData = tableData;

	type FileUploadProps = {
		register: UseFormRegisterReturn
		accept?: string
		multiple?: boolean
		children?: any
	  }
	const columns = [
		columnHelper.accessor('subject', {
			id: 'subject',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize='12px'
					color='gray.400'>
					NAME
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize={{sm: '10px', md: '10px', lg: '14'}} fontWeight='normal'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
		
		columnHelper.accessor('actions', {
		    id: 'actions',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize='12px'
					color='gray.400'>
					Actions
				</Text>
			),
			cell: (info) => (
				<Flex justify='space-evenly'>
				<Icon boxSize={6}  onClick={()=>handleEditSubject(info.row)} as={FaEdit} color='gray.400' /><Icon boxSize={6}  onClick={()=> handleDeleteSubject(info.row.original)} as={MdDeleteOutline} color='red' />
			</Flex>
			)	
			}),	
	];

	const subjects = useSelector((state:any) => state.subject);
    const [updated, setUpdated] = useState(false);
	const [id, setId]= useState<any>("");

		
	
	const SubjectEditform = useForm();
	const {
		
	handleSubmit ,reset ,getValues, formState: {errors: editError}} = SubjectEditform;
	const editSubjectModal = useDisclosure();
	const alert = useDisclosure();
	const handleEditSubject=(value:any)=>{
		
		setId(value.original.id);
		const data =value.original;;
		reset({
			subject: data.subject,
		})
		editSubjectModal.onOpen();
		}
		const onDeleteSubject=()=>
		{
			
            
			dispatch(deleteSubject(id)).then((data:any)=>{
				if(data.type == "subject/deleteData/fulfilled") {
					toast({
						title: 'Success',
						description: "Subject Name Deleted successfully",
						status: 'success',
						duration: 9000,
						isClosable: true,
					  });
					}
					else{
						toast({
							title: 'Error',
							description:   `${data.payload.response.data.message}`,
							status: 'error',
							duration: 9000,
							isClosable: true,
						  });
					  }
			})			
			alert.onClose();
		
		}
		const handleDeleteSubject=(value:any)=>{
			
			setId(value.id);
			alert.onOpen();
	   }
	const onCreateSubject=(values: any)=>{
		
		
	
           const data = {
			subject: values.name
		   }
		dispatch(createSubject(data)).then((data:any)=>{
			if(data.type === "admin/createSubject/fulfilled")
			{
				toast({
					title: 'Success',
					description: "Subject Added successfully",
					status: 'success',
					duration: 9000,
					isClosable: true,
				  });
				  
				  createSubjectModal.onClose();
				}
				else{
					toast({
						title: 'Error',
						description:   `${data.payload.response.data.message}`,
						status: 'error',
						duration: 9000,
						isClosable: true,
					  });
					  alert.onClose();
				  }
		});
		
	
		
	}
	const toast = useToast();
	const onEditSubject=(values: any)=>{
		
		const data = {
			id: id,
			payload: {subject : values.subject}
		}
		
		dispatch(updateSubject(data)).then((data:any)=>{
			if(data.type === "subject/updateData/fulfilled")
			{
				toast({
					title: 'Success',
					description: "Subject updated successfully",
					status: 'success',
					duration: 9000,
					isClosable: true,
				  });
				  
				
				  editSubjectModal.onClose();
				}
				else{
					toast({
						title: 'Error',
						description:   `${data.payload.response.data.message}`,
						status: 'error',
						duration: 9000,
						isClosable: true,
					  });
					  alert.onClose();
				  }
		});
		
	}
	const dispatch = useDispatch();
    useEffect(()=>{
		
		dispatch(getSubjects());
		dispatch(getGrades()).then((data:any)=>{
				setGrades(data.payload)
		});

	},[updated])

	useEffect(()=>{
	
		setData(subjects.data)
	},[subjects.loading , updated])
	const [grades, setGrades] = useState([]);

	const subjectForm = useForm();
	const cancelRef = React.useRef()
	const {
	control,
	handleSubmit: handleSubjectSubmit , formState:{ errors} } = subjectForm;
	const [ data, setData ] = React.useState(() => [ ...defaultData ]);
	const table = useReactTable({
		data,
		columns,
		state: {
			sorting
		},
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		debugTable: true
	});
	return (
		<Box borderRadius="0px" flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justify='space-between' >
			
				<Button  fontSize={{sm: '10px', md: '10px', lg: '14'}}
					bg="#0076FD"
					fontWeight='normal'
					color='white'
					borderRadius="0"
					onClick={createSubjectModal.onOpen}
					>
						<AddIcon w={4} h={4} px='1px' color="white" />
					    Create Subject Name
						</Button> 
			</Flex>
			<Box >
			<PrimaryTable data={data} columns={columns} />
			<Modal 
					isOpen={createSubjectModal.isOpen}
					onClose={createSubjectModal.onClose}>
						<ModalOverlay />
						<ModalContent>
						<Box borderLeftWidth='3px' borderColor='#7F77F1'>
						<form onSubmit={handleSubjectSubmit(onCreateSubject)}>
						<ModalHeader>Add New Subject Name</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<FormControl>
							<FormLabel>Name</FormLabel>
								<Input
								id='firstName'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="Enter Subject Name"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...subjectForm.register('name', {
									required: 'Subject Name is required',
									
								})}
								/>
								{errors.name && <Text color='red'>{errors.name.message.toString()}</Text>}
							</FormControl>
							
							</ModalBody>
							<ModalFooter>
							<PrimaryButton type='submit'>
    					        Save
           					 </PrimaryButton>
							
							</ModalFooter>
							</form>
							</Box>
							</ModalContent>
							</Modal>

							<Modal 
					isOpen={editSubjectModal.isOpen}
					onClose={editSubjectModal.onClose}>
						<ModalOverlay />
						<ModalContent>
						<Box borderLeftWidth='3px' borderColor='#7F77F1'>
						<form onSubmit={handleSubmit(onEditSubject)}>
						<ModalHeader>Edit Subject</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<FormControl mt={4}>
							<FormLabel>Subject Name</FormLabel>
							<Input
								id='middleName'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="subect Name"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...SubjectEditform.register('subject', {
									required: 'This is required',
								
								})}
								/>
								{editError.subject && <Text color='red'>{editError.subject.message.toString()}</Text>}
							</FormControl>							
						</ModalBody>
						<ModalFooter>
						<PrimaryButton type='submit'>
         					   Save
            			</PrimaryButton>
							
						</ModalFooter>
						</form>
						</Box>
						</ModalContent>
					</Modal>
					<AlertBox 
							cancelRef={cancelRef} 
							alert={alert}
							header="Delete Subject?"
							alertBody="Are you sure you want to delete this subject"
							onDelete={onDeleteSubject}
						/>
			</Box>
		</Box>
	);
}
