import { FormLabel, Text, useColorModeValue } from "@chakra-ui/react";
const PrimaryLabel = ({ ...props }) => {
    const textColorBrand = useColorModeValue("black", "white");
    const children: React.ReactNode = props.children;
     return(
        <Text
        px='5'
            color={textColorBrand}
            fontSize={{sm: '10px', md: '10px', lg: '14'}}
            fontWeight='800'>
            {children}
        </Text>
     )
}

const CustomLabel = ({ ...props }) => {
    const textColor = useColorModeValue("navy.700", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const children: React.ReactNode = props.children;
     return(
        <FormLabel
              display='flex'
              ms='4px'     
              fontSize={{sm: '10px', md: '10px', lg: '14'}}
              fontWeight='500'
              color={textColor}
              >
              {children}<Text color={brandStars}>*</Text>
            </FormLabel>
     )
}

export { PrimaryLabel, CustomLabel };
