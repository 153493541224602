import { FormControl, FormLabel } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { Controller } from "react-hook-form";

interface MultiSelectInputProps {
    id: any;
    label: string;
    placeholder: string;
    options: any;
    
    name: string;
    defaultValue: any;
    validation: any;
    control: any;
    errorMessage: any;
  }

const MultiSelect : React.FC<MultiSelectInputProps> =({id,
    label,
    placeholder,
    control,
    validation,
    name,
    options,
    defaultValue,
})=>{
   
    return(
            <Controller
                control={control}
                name={id}		
                rules={validation}
               
                render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { error }
                }) => (
                <FormControl py={4} isInvalid={!!error} id={id}>
                        <FormLabel>{label}</FormLabel>
                    <Select
                    isMulti
                    name={name}
                    ref={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    options={options}
                    placeholder={placeholder}
                    closeMenuOnSelect={false}
                    />	
                </FormControl>
                )}
            /> 
                       
    )
}
export { MultiSelect };
