import { Box, Button, Flex, FormControl, FormLabel, Icon, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useColorModeValue, useDisclosure, useToast } from '@chakra-ui/react';
import {
	createColumnHelper
} from '@tanstack/react-table';

import { PrimaryButton } from 'components/Atoms/Button/Buttons';
// Custom components
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteOutline } from 'react-icons/md';


import TableHeader from 'components/Atoms/Label/TableHeader';
import AlertBox from 'components/Molecules/Alerts/AlertBox';
import PrimaryTable from 'components/Molecules/Tables/PrimaryTable';
import { useDispatch, useSelector } from 'react-redux';
import { createRegion, deleteRegion, getRegions, updateRegion } from 'reduxtoolkit/reducers/regionSlice';
  
// Assets

type RowObj = {
	id: string;
	region: string;
	actions: any;
};

const columnHelper = createColumnHelper<RowObj>();


export default function ComplexTable() {
     


	const dispatch = useDispatch();
	const regions = useSelector((state:any) => state.region);
	const form1 = useForm();
	const {
		
	handleSubmit: handleSubmit1 ,reset ,getValues} = form1;


	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const [updated, setUpdated] = useState(false);
	
	
	
	const [id, setId]= useState<any>("");


	const onEditRegions=(values: any)=>{
		

		const data = {
			id: id,
			payload:{
			region: values.region,}
		}
		dispatch(updateRegion(data));
		editRegionModal.onClose();
	}

	const toast = useToast();
	const onDeleteRegion=()=>
	{
	//	setId(getValues('id'));
		dispatch(deleteRegion(id)).then((data:any)=>{
			if(data.type == "region/deleteData/fulfilled")
			{
				toast({
					title: 'Success',
					description: "Region Deleted successfully",
					status: 'success',
					duration: 9000,
					isClosable: true,
				  });
				  alert.onClose()

				}
				else{
					toast({
						title: 'Error',
						description:   `${data.payload.response.data.message}`,
						status: 'error',
						duration: 9000,
						isClosable: true,
					  });
					  alert.onClose()
				  }
		})
		
	}
    
	useEffect(()=>{
		
		dispatch(getRegions());
	},[])

	useEffect(()=>{
		
		setData(regions.data)
	},[regions.loading])
    

	


	const createRegionForm = useForm();
	const {
	handleSubmit: handleSubmitRegion  } = createRegionForm;
	const createRegionModal = useDisclosure();
	const onCreateRegion=(values: any)=>{
		const data = {
			region: values.region,
			
		}
		
		
		dispatch(createRegion(data));
	}
	
	const handleEditRegion=(value:any)=>{
		
		setId(value.original.id);
		const data =value.original;;
		reset({
			region: data.region,
		})
		editRegionModal.onOpen();
		}

     const alert = useDisclosure();
	 const cancelRef = React.useRef()
	const handleDeleteRegion=(value:any)=>{
		 setId(value.id);
		 alert.onOpen();
	}
	const columns = [
	
	

		columnHelper.accessor('region', {
			id: 'region',
			header: () => (
				<TableHeader title="REGION"/>
			),
			cell: (info) => (
				<Text color={textColor} fontSize={{sm: '10px', md: '10px', lg: '14'}}fontWeight='normal'>
				{info.getValue()}
			</Text>
			)
		}),
		columnHelper.accessor('actions', {
		    id: 'actions',
			header: () => (
				<TableHeader title="Actions"/>
				
			),
			cell: (info) => (
				< >
				<Icon onClick={()=>handleEditRegion(info.row)} as={FaEdit} color='gray.400' /><Icon onClick={()=> handleDeleteRegion(info.row.original)} as={MdDeleteOutline} color='red' />
			</>
			
			)	
			
			}),	
	
	];
	
	
	const editRegionModal = useDisclosure();
	const [data, setData] = React.useState([])

	return (
		<Box borderRadius='0px' flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>

			 <Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px'fontWeight='normal' lineHeight='100%'>
					
				</Text>
				<Button 
				fontSize={{sm: '10px', md: '10px', lg: '14'}}
				 bg="#0076FD"
				 mx={5}
				 fontWeight='500'
				 color='white'
				 borderRadius="0"
					onClick={createRegionModal.onOpen}
					>
				create
			</Button>
			
			</Flex>
			 <Box>
				<PrimaryTable data={data} columns={columns} />
                    
					<Modal 
					isOpen={editRegionModal.isOpen}
					onClose={editRegionModal.onClose}>
						<ModalOverlay />
						<ModalContent>
						<form onSubmit={handleSubmit1(onEditRegions)}>
						<ModalHeader>Edit Region</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<FormControl mt={4}>
							<FormLabel>Region Name</FormLabel>
							<Input
								id='middleName'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="Region Name"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...form1.register('region', {
									required: 'This is required',
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})}
								/>
							</FormControl>							
						</ModalBody>
						<ModalFooter>
						<PrimaryButton type='submit'>
            Save
            </PrimaryButton>
							
						</ModalFooter>
						</form>
						</ModalContent>
					</Modal>
					<AlertBox 
							cancelRef={cancelRef} 
							alert={alert}
							header="Delete Regions?"
							alertBody="Are you sure you want to delete this region"
							onDelete={onDeleteRegion}
							/>	
					</Box>

					<Modal 
					
					isOpen={createRegionModal.isOpen}
					onClose={createRegionModal.onClose}>
						<ModalOverlay />
						<ModalContent>
						<form onSubmit={handleSubmitRegion(onCreateRegion)}>
						<ModalHeader>Add Region</ModalHeader>
						<ModalCloseButton />
						
						<ModalBody pb={6}>
						
							<FormControl mt={4}>
							<FormLabel>Region Name</FormLabel>
							<Input
								id='region'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="Region"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...createRegionForm.register('region', {
									required: 'This is required',
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})}
								/>
							</FormControl>
						</ModalBody>

						<ModalFooter>
						<PrimaryButton type='submit'>
            Save
            </PrimaryButton>
							
						</ModalFooter>
						</form>
						</ModalContent>
	
					</Modal>
		</Box>
	);
}

	


