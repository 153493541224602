import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Progress,
  Select,
  Tab,
  TabList,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  Wrap,
  WrapItem
} from "@chakra-ui/react";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel
} from "@tanstack/react-table";
// Custom components
import {
  FormLabel,
  Input,
  ModalCloseButton,
  ModalFooter,
  ModalHeader
} from "@chakra-ui/react";
import TableContent from "components/Atoms/Label/TableCell";
import TableHeader from "components/Atoms/Label/TableHeader";
import AlertBox from "components/Molecules/Alerts/AlertBox";
import MainTab from "components/Molecules/Tabs/MainTab";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getGrades } from "reduxtoolkit/reducers/gradeSlice";
import { getTiptypes } from "reduxtoolkit/reducers/tipsTypeSlice";
// Custom components

import { PrimaryButton } from "components/Atoms/Button/Buttons";
import {
  createTips,
  deleteTips,
  getTips,
  updateTips
} from "reduxtoolkit/reducers/tipsSlice";

import { ProgressModal } from "components/Molecules/Modals/ProgressModal";
import SecondaryTable from "components/Molecules/Tables/LocalTable";
import { MdDelete } from "react-icons/md";
import { getSubjectsManagement } from "reduxtoolkit/reducers/subjectManagementSlice";
import { getSubjects } from "reduxtoolkit/reducers/subjectSlice";
import { subjectType } from "types/examTypes";
export default function Overview() {
  const createTipsModal = useDisclosure();
  const editTipsModal = useDisclosure();
  const deleteIconColor = useColorModeValue("red.600", "white");
  const cancelRef = useRef();
  const alert = useDisclosure();
  const createTipsForm = useForm();
  const editTipsForm = useForm();
  const [tabData, setTabData] = useState(() => []);
  const {
    handleSubmit: handleSubmitTips,
    formState: { errors },
  } = createTipsForm;
  const {
    handleSubmit: handleSubmitEditTips,
    reset,
    formState: { errors: editerrors },
  } = editTipsForm;
  const handleDeleteTips = (value: any) => {
    setId(value.id);
    alert.onOpen();
  };
  const handleCreateTips = () => {
    setSubjects(
      subjectData?.data?.subjects?.filter(
        (subject: any) => subject.grade.grade === selectedGrade
      )
    );

    createTipsModal.onOpen();
  };
  const [id, setId] = useState<any>();


  const tipdata: any = useSelector((state: any) => state.tips);
  const gradesData: any = useSelector((state: any) => state.grade);
  const subjectData: any = useSelector((state: any) => state.subjectManagement);
  const feedbacks = useSelector((state: any) => state.feedback);
  const [selectedGrade, setSelectedGrade] = useState("Grade 12 Natural");
  const [subjects, setSubjects] = useState<any>([]);
  const dispatch = useDispatch();
  const onCreateTips = (values: any) => {
    const data = {
      tipType: values.tipType,
      grade: selectedGrade,
      subject: values.subject,
      tip: values.tip,
    };
    dispatch(createTips(data)).then((data: any) => {
      if (data.type === "admin/createTips/fulfilled") {
        toast({
          title: "Success",
          description: "Tips Created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        createTipsModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        createTipsModal.onClose();
      }
    });
  };

  const onEditTips = (values: any) => {
    const data = {
      id: id,
      payload: {
        tipType: values.tipType,
        grade: selectedGrade,
        subject: values.subject,
        tip: values.tip,
      },
    };
    dispatch(updateTips(data)).then((data: any) => {
      if (data.type === "tip/updateData/fulfilled") {
        toast({
          title: "Success",
          description: "Tips updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        editTipsModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      }
    });
  };

  const toast = useToast();

  const onDeleteTips = () => {
    dispatch(deleteTips(id)).then((data: any) => {
      if (data.type === "tip/deleteData/fulfilled") {
        toast({
          title: "Success",
          description: "Tips deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      }
    });
  };
  const [grades, setGrades] = useState([]);
  const [data, setData] = useState<any>([]);
  const [tipTypes, setTipTypes] = useState([]);

  const [selectedType, setSelectedType] = useState("");
  useEffect(() => {
    dispatch(getTiptypes()).then((data: any) => {
      setTipTypes(data.payload);
    });

    const param = {
      page: 0,
      limit: 9999999,
    };
    dispatch(getTips(param));
    dispatch(getGrades()).then((data: any) => {
      setGrades(data.payload);

      setSelectedGrade(data.payload[0].grade);
    });
    dispatch(getSubjects()).then(() => { });
    dispatch(getSubjectsManagement());
  }, []);
  const textColorSecondary = "gray.400";

  const getTipTabContent = (data: any) => {
    const filteredObject = data.filter((obj1: any) => {
      return obj1?.grade?.grade === selectedGrade;
    });


    return filteredObject;
  };

  useEffect(() => {
    setIsOpen(true);

    if (tipdata.isSuccess === true && gradesData.isSuccess === true) {
      setSubjects(
        subjectData?.data?.subjects?.filter(
          (subject: any) => subject.grade.grade === selectedGrade
        )
      );

      setTabData(getTipTabContent(tipdata.data.tips));
      setTimeout(() => {
        setIsOpen(false);
      }, 2000);
    } else {
      setIsOpen(true);
    }
  }, [tipdata.loading, selectedGrade]);

  const handleEditTips = (value: any) => {
    const data = value.original;
    setId(data.id);
    reset({
      name: data.name,
      grade: data.grade.grade,
      subject: data.subject.subject,
      tipType: data.tipType,
      tip: data.tip,
    });
    editTipsModal.onOpen();
  };

  type RowObj = {
    id: string;
    tipType: string;
    subject: subjectType;
    tip: string;
    actions: any;
  };

  const columnHelper = createColumnHelper<RowObj>();

  const columns = [
    columnHelper.accessor("subject", {
      id: "subject",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          Subject
        </Text>
      ),
      cell: (info: any) => (
        <TableContent title={info.row?.original?.subject?.subject} />
      ),
    }),
    columnHelper.accessor("tipType", {
      id: "tipType",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          Tip Type
        </Text>
      ),
      cell: (info: any) => <TableContent title={info.getValue()} />,
    }),
    columnHelper.accessor("tip", {
      id: "tip",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          Tip
        </Text>
      ),
      cell: (info: any) => (
        <Wrap>
          {info
            .getValue()
            .split(" ")
            .map((word: string, index: any) => (
              <WrapItem key={index}>
                <Text>{word}</Text>
              </WrapItem>
            ))}
        </Wrap>
      ),
    }),

    columnHelper.accessor("actions", {
      id: "actions",
      header: () => <TableHeader title="Actions" />,
      cell: (info) => (
        <>
          <Flex justify="space-evenly">
            <Icon
              color={textColorSecondary}
              as={FaEdit}
              onClick={() => handleEditTips(info.row)}
            />
            <Icon
              color={deleteIconColor}
              as={MdDelete}
              onClick={() => handleDeleteTips(info.row.original)}
            />
          </Flex>
        </>
      ),
    }),
  ];
  const qtable = {
    data: tabData,
    columns,

    enableRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  };
  const [isOpen, setIsOpen] = useState(true);
  const createProgressModal = useDisclosure();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (feedbacks.loading === true) {
      setLoading(true);
      createProgressModal.onOpen();
    } else {
      createProgressModal.onClose();
      setLoading(false);
    }
  }, [feedbacks.loading]);

  const TabHeaders = () => {
    return (
      <TabList justifyContent="space-evenly">
        {grades.map((grade) => (
          <Tab
            _selected={{ color: "#000000" }}
            fontWeight="600"
            fontSize="16px"
            color="#BBBBBB"
            onClick={() => {
              setSelectedGrade(grade.grade);
            }}
            key={grade.grade}
          >
            {grade.grade}
          </Tab>
        ))}
      </TabList>
    );
  };

  const TabFilters = () => {
    return (
      <Flex bg="white" py={5} justify="space-between">
        <Box w="300px"></Box>
        <Box w="150px">
          <Button
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            bg="white"
            fontWeight="normal"
            color="black"
            variant="outline"
            borderRadius="0"
            onClick={() => handleCreateTips()}
          //</Box>
          >
            <AddIcon w={4} h={4} px="1px" color="black" />
            Create Tips
          </Button>
        </Box>
      </Flex>
    );
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      {loading ? <Progress id="user" w="1/2" isIndeterminate /> : null}
      <ProgressModal isOpen={isOpen} onClose={() => setIsOpen(false)} />

      <Box>
        <MainTab headers={<TabHeaders />} filters={<TabFilters />}></MainTab>
        <SecondaryTable tableOptions={qtable} />
      </Box>

      <Modal isOpen={createTipsModal.isOpen} onClose={createTipsModal.onClose}>
        <ModalOverlay />

        <ModalContent>
          <Box borderLeftWidth="3px" borderColor="#7F77F1">
            <form onSubmit={handleSubmitTips(onCreateTips)}>
              <ModalHeader>Creating Tips</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel>Tip Type</FormLabel>
                  <Select
                    className="select-input"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createTipsForm.register("tipType", {
                      required: " required",
                    })}
                    variant="unstyled"
                  >
                    {tipTypes?.map((tipType: any) => (
                      <option key={tipType} value={tipType}>
                        {tipType}
                      </option>
                    ))}
                  </Select>

                  {errors.tipType && (
                    <Text color="red">{errors.tipType.message.toString()}</Text>
                  )}
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Subject</FormLabel>
                  <Select
                    className="select-input"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createTipsForm.register(`subject`, {
                      required: "Tip subject is required",
                    })}
                    variant="unstyled"
                  >
                    {subjects?.map((subject: any) => (
                      <option key={subject.id}>
                        {subject.subject.subject}
                      </option>
                    ))}
                  </Select>

                  {errors.subject && (
                    <Text color="red">{errors.subject.message.toString()}</Text>
                  )}
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Tip</FormLabel>
                  <Input
                    id="tip"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    name="tip"
                    placeholder="Enter Tip "
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createTipsForm.register(`tip`, {
                      required: "Tip is required",
                    })}
                  />

                  {errors.tip && (
                    <Text color="red">{errors.tip.message.toString()}</Text>
                  )}
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </Box>
        </ModalContent>
      </Modal>

      <Modal isOpen={editTipsModal.isOpen} onClose={editTipsModal.onClose}>
        <ModalOverlay />

        <ModalContent>
          <Box borderLeftWidth="3px" borderColor="#7F77F1">
            <form onSubmit={handleSubmitEditTips(onEditTips)}>
              <ModalHeader>Editing Tips</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel>Tip Type</FormLabel>
                  <Select
                    className="select-input"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editTipsForm.register("tipType", {
                      required: " required",
                    })}
                    variant="unstyled"
                  >
                    {tipTypes?.map((tipType: any) => (
                      <option key={tipType} value={tipType}>
                        {tipType}
                      </option>
                    ))}
                  </Select>

                  {editerrors.tipType && (
                    <Text color="red">
                      {editerrors.tipType.message.toString()}
                    </Text>
                  )}
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Subject</FormLabel>
                  <Select
                    className="select-input"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editTipsForm.register(`subject`, {
                      required: "Tip subject is required",
                    })}
                    variant="unstyled"
                  >
                    {subjects?.map((subject: any) => (
                      <option key={subject.id}>
                        {subject.subject.subject}
                      </option>
                    ))}
                  </Select>

                  {editerrors.subject && (
                    <Text color="red">
                      {editerrors.subject.message.toString()}
                    </Text>
                  )}
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel>Tip</FormLabel>
                  <Input
                    id="tip"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    name="tip"
                    placeholder="Enter Tip "
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editTipsForm.register(`tip`, {
                      required: "Tip is required",
                    })}
                  />

                  {editerrors.tip && (
                    <Text color="red">{editerrors.tip.message.toString()}</Text>
                  )}
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </Box>
        </ModalContent>
      </Modal>

      <AlertBox
        cancelRef={cancelRef}
        alert={alert}
        header="Delete Tip?"
        alertBody="Are you sure you want to delete this Tip"
        onDelete={onDeleteTips}
      />
    </Box>
  );
}
