import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from "utils/axiosMiddleware";

export const getUsers: any = createAsyncThunk(
  "users/getData",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/users`,
        { headers: { Authorization: `Bearer ${token}` }, params: arg }
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createUser: any = createAsyncThunk(
  "user/createUser",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";
    try {
      const data = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/user/createmanually`,
        headers: { Authorization: `Bearer ${token}` },
        data: arg,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const initiateManualPayment: any = createAsyncThunk(
  "user/initiateManualPayment",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";
    try {
      const data = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/payment/initializeandverifypaymentbyadmin`,
        headers: { Authorization: `Bearer ${token}` },
        params: arg,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteUser: any = createAsyncThunk(
  "user/deleteData",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data: any = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/user/delete/${arg}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const searchUsers: any = createAsyncThunk(
  "users/SearchUsers",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/search?page=0&limit=100&search=${arg}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateUser: any = createAsyncThunk(
  "user/updateData",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/user/update/${arg.id}`,
        headers: { Authorization: `Bearer ${token}` },
        data: arg.payload,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState = {
  data: [],
  isSuccess: false,
  message: "",
  loading: false,
} as any;

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: {
    [getUsers.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getUsers.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [searchUsers.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [searchUsers.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [searchUsers.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [createUser.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createUser.fulfilled]: (state: any, { payload }) => {
      state.loading = false;

      state.data.users.push(payload.data.user);
      state.isSuccess = true;
    },
    [createUser.rejected]: (state, { payload }) => {
      state.message = payload.data;
      state.loading = false;
      state.isSuccess = false;
    },
    [deleteUser.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteUser.fulfilled]: (state: any, { payload, meta }) => {
      state.loading = false;
      state.data.users = state.data.users.filter(
        (user: any) => user.id !== meta.arg
      );
      state.isSuccess = true;
    },
    [deleteUser.rejected]: (state: any, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [updateUser.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateUser.fulfilled]: (state: any, { payload }) => {
      state.loading = false;
      var temp = current(state);
      state.data = temp.data;
      const index = state.data.users.findIndex(
        (user: any) => user.id === payload.data.id
      );
      if (index !== -1) {
        state.data.users[index] = payload.data;
      }
      state.isSuccess = true;
    },
    [updateUser.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
  },
});

export default userSlice;
