import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from 'utils/axiosMiddleware';



const initialState = {
  grade: [],
  loading: false,
  isSuccess: false,
  errorMessage: ''
} as any;
export const updateGrade:any =  createAsyncThunk(
  "grade/updateData",
  async (arg:any, { rejectWithValue }) => {
    const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
      
    try {
      const data = await axios({
        method: 'put',
        url: `${process.env.REACT_APP_API_URL}/grade/update/${arg.id}`,
        headers: { Authorization: `Bearer ${token}` },
        data: arg.payload,
        
    });
    return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  })

  export const getGrades:any =  createAsyncThunk(
    "grade/getData",
    async (arg, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
      try {
        const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/grade/grade?page=0&limit=1000`,{headers: { Authorization: `Bearer ${token}` },
        },);
        
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    });
  
    export const createGrade:any =  createAsyncThunk(
      "admin/createGrade",
      async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';  
        try {   
          const data = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_URL}/grade/create`,
            headers: { Authorization: `Bearer ${token}` },
            data: arg,
        });
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      });
      export const deleteGrade:any =  createAsyncThunk(
        "grade/deleteData",
        async (arg, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
            
          try {
       
            const data = await axios({
              method: 'delete',
              url: `${process.env.REACT_APP_API_URL}/grade/delete/${arg}`,
              headers: { Authorization: `Bearer ${token}` },
          });
          return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })

export const gradeSlice: any = createSlice({
  name: 'grade',
  initialState,
  reducers:{
   
},
extraReducers: {
  [getGrades.pending]: (state, action) => {
    state.loading = true;
  },
  [getGrades.fulfilled]: (state, action) => {
    
    state.loading = false; 
    state.isSuccess = true;
    state.grade = action.payload;
  },
  [getGrades.rejected]: (state, { payload }) => {
    state.loading = false;
    state.isSuccess = false;
    state.errorMessage = payload
  },
  [updateGrade.pending]: (state, { payload }) => {
    state.loading = true;
  },
  [updateGrade.fulfilled]: (state:any, { payload , meta}:any) => {
   
    state.loading = false;
    
    const temp = {...current(state)};
    
     var grade = meta.arg.payload.grade;
     const updatedItems = temp.grade.map((item:any) =>
             {
      if (item.id === meta.arg.id) 
          {
              return { ...item,  grade};
            }
          return item;});
          temp.grade = updatedItems;
          state.grade = temp.grade;
          state.isSuccess = true;
  },
  [updateGrade.rejected]: (state, { payload }) => {
    state.message = payload;
    state.loading = false;
    state.isSuccess = false;
  },
  [createGrade.pending]: (state, { payload }) => {
    state.loading = true;
  },
  [createGrade.fulfilled]: (state:any, { payload }) => {
    state.loading = false;
  
    state.grade.push(payload.data.grade);
    state.isSuccess = true;
  },
  [createGrade.rejected]: (state, { payload }) => {
    state.message = payload.data;
    state.loading = false;
    state.isSuccess = false;
  },
  [deleteGrade.pending]: (state, { payload }) => {
    state.loading = true;
  },
  [deleteGrade.fulfilled]: (state:any, { payload }) => {
    state.loading = false;
    
    state.grade = state.grade.filter((grade:any) => grade.grade !== payload.data.grade);
    state.isSuccess = true;
  },
  [deleteGrade.rejected]: (state:any, { payload }) => {
    state.message = payload;
    state.loading = false;
    state.isSuccess = false;
  },
}
});
 

export default gradeSlice;
