// Chakra imports
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/Molecules/card/Card";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
const PieCard = ({ series, options }: any) => {
  const [pieChartOptions, setChartOptions] = useState<any>({});
  const [pieChartData, setPieChartData] = useState<any>([]);

  useEffect(() => {
    if (series.length > 0) {
      setPieChartData(series);
      setChartOptions(options);
    }
  }, [series, options]);

  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  return (
    <Card p="20px" alignItems="center" flexDirection="column" w="100%">
      <Flex
        px={{ base: "0px", "2xl": "10px" }}
        justifyContent="space-between"
        alignItems="center"
        w="100%"
        mb="8px"
      >
        <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
          Users
        </Text>
      </Flex>
      <Box overflow="hidden"></Box>
      <Chart
        options={pieChartOptions}
        series={pieChartData}
        type="pie"
        width="500"
      />
    </Card>
  );
};
export default PieCard;
