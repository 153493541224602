import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import PasswordInputForm from "components/Atoms/fields/passwordInputForm";
import PhoneNumberInput from "components/Atoms/fields/phoneNumberInputForm";
import TextInput from "components/Atoms/fields/TextInput";
import { SingleSelector } from "components/Molecules/SingleSelector";
import { useForm } from "react-hook-form";
import { gradeType } from "types/examTypes";

const CreateUsersForm = ({
  createUserModal,
  onCreateUser,
  grades,
  regions,
}: any) => {
  const adduserForm = useForm();
  const {
    handleSubmit: handleAdduserSubmit,
    formState: { errors },
    control,
  } = adduserForm;
  return (
    <Modal isOpen={createUserModal.isOpen} onClose={createUserModal.onClose}>
      <ModalOverlay />
      <ModalContent>
        <Box borderLeftWidth="3px" borderColor="#7F77F1">
          <form onSubmit={handleAdduserSubmit(onCreateUser)}>
            <ModalHeader>Creating new user</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <TextInput
                id="firstName"
                label="First Name"
                type="text"
                control={control}
                validation={{
                  required: "Last Name is required.",
                  pattern: { value: /^[A-Za-z]+$/i, message: "Invalid Input" },
                }}
                errorMessage="Please Enter First Name"
              />

              {errors.firstName && (
                <Text color="red">{errors.firstName.message.toString()}</Text>
              )}

              <TextInput
                id="lastName"
                label="Last Name"
                type="text"
                control={control}
                validation={{
                  required: "Last Name is required.",
                  pattern: { value: /^[A-Za-z]+$/i, message: "Invalid Input" },
                }}
                errorMessage="Please enter your Last Name."
              />
              {errors.lastName && (
                <Text color="red">{errors.lastName.message.toString()}</Text>
              )}

              <PhoneNumberInput
                id="phoneNumber"
                label="Phone Number"
                control={control}
                validation={{
                  pattern: {
                    value: /^\+251\d{9}$/,
                    message:
                      "Phone number must start with +251 and be 12 digits long (including the country code)",
                  },
                }}
                errorMessage="Please enter your Phone Number"
              />
              {errors.phoneNumber && (
                <Text color="red">{errors.phoneNumber.message.toString()}</Text>
              )}
              <TextInput
                id="email"
                label="Email"
                type="email"
                control={control}
                validation={{
                  required: "Email is required.",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid Input",
                  },
                }}
                errorMessage="Please enter your Email."
              />
              {errors.email && (
                <Text color="red">{errors.email.message.toString()}</Text>
              )}

              <PasswordInputForm
                id="password"
                label="Password"
                placeholder="Password"
                validation={{
                  required: "Password is required",
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_+=])[A-Za-z\d!@#$%^&*()\-_+=]{8,}$/,
                    message:
                      "Password must contain at least one uppercase letter, one lowercase letter, one special character, one number, and be at least 8 characters long",
                  },
                  minLength: {
                    value: 8,
                    message: "Minimum length should be 8",
                  },
                  maxLength: {
                    value: 32,
                    message: "Maximum length should be 32",
                  },
                }}
                errorMessage="Password should not be empty"
                control={control}
              />
              {errors.password && (
                <Text color="red">{errors.password.message.toString()}</Text>
              )}
              <SingleSelector
                id="region"
                label="Region"
                placeholder="Select Region"
                control={control}
                validation={{
                  required: "Region is required",
                }}
                options={regions.map((item: any) => item.region)}
              />
              {errors.region && (
                <Text color="red">{errors.region.message.toString()}</Text>
              )}

              <SingleSelector
                id="gender"
                label="Gender"
                placeholder="Select Gender"
                control={control}
                validation={{
                  required: "Gender is required",
                }}
                options={["MALE", "FEMALE"]}
              />
              {errors.gender && (
                <Text color="red">{errors.gender.message.toString()}</Text>
              )}

              <SingleSelector
                id="grade"
                label="Grade"
                placeholder="Select Grade"
                control={control}
                validation={{
                  required: "Grade is required",
                }}
                options={grades.map((grade: gradeType) => grade.grade)}
              />
              {errors.grade && (
                <Text color="red">{errors.grade.message.toString()}</Text>
              )}
            </ModalBody>

            <ModalFooter>
              <PrimaryButton type="submit">Save</PrimaryButton>
            </ModalFooter>
          </form>
        </Box>
      </ModalContent>
    </Modal>
  );
};
export default CreateUsersForm;
