import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from 'utils/axiosMiddleware';


export const getUnits:any =  createAsyncThunk(
  "units/getData",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
      
    try {
      const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/units/unit`,{headers: { Authorization: `Bearer ${token}` },
      },);
      
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  })

  export const updateUnit:any =  createAsyncThunk(
    "unit/updateData",
    async (arg:any, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
      try {
        const data = await axios({
          method: 'put',
          url: `${process.env.REACT_APP_API_URL}/units/update${arg.id}`,
          headers: { Authorization: `Bearer ${token}` },
          data: arg.payload,
          
      });
      return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })

    export const deleteUnit:any =  createAsyncThunk(
      "unit/deleteData",
      async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
        try {
     
          const data = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/units/delete${arg}`,
            headers: { Authorization: `Bearer ${token}` },
        });
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      })
      export const createUnit:any =  createAsyncThunk(
        "admin/createUnit",
        async (arg, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';  
          try {   
            const data:any = await axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URL}/units/create`,
              headers: { Authorization: `Bearer ${token}` },
              data: arg,
          });
          return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })

const unitSlice:any = createSlice({
  name: "unit",
  initialState: {
    data: [],
    isSuccess: false,
    message: "",
    loading: false,
  },
  reducers: {
       

  },
  extraReducers: {
    [getUnits.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getUnits.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getUnits.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [createUnit.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createUnit.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
     
      //state.data.push(payload.unit);
      state.isSuccess = true;
    },
    [createUnit.rejected]: (state, { payload }) => {
      state.message = payload.data;
      state.loading = false;
      state.isSuccess = false;
    },
    [updateUnit.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateUnit.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
     
       const index = state.data.findIndex((unit:any) => unit.id === payload.data.unit.id);
             if (index !== -1) {
            
           state.data[index] = payload.data.unit;
         }
      state.isSuccess = true;
    },
    [updateUnit.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [deleteUnit.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteUnit.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
    
      state.data = state.data.filter((unit:any) => unit.unit !== payload.data.unit);
      state.isSuccess = true;
    },
    [deleteUnit.rejected]: (state:any, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
  },
});

export default unitSlice;
