import Card from "components/Molecules/card/Card";
import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useDispatch } from "react-redux";
import { getSubscriberAnalytics } from "reduxtoolkit/reducers/analyticSlice";
function SubscriptionAnalytics() {
  const [analyticsData, setAnalyticsData] = useState({
    monthly: 0,
    weekly: 0,
    daily: 0,
    hourly: 0,
    overall: 0,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubscriberAnalytics()).then((res: any) => {
      setAnalyticsData(res.payload.data);
    });
  }, []);
  if (!analyticsData) {
    return <div>Loading...</div>;
  }

  // Extract data for rendering the chart
  const { monthly, weekly, daily, hourly, overall } = analyticsData;
  const options: any = {
    chart: {
      type: "bar",
    },
    xaxis: {
      categories: ["Monthly", "Weekly", "Daily", "Hourly", "Overall"],
    },
  };

  const series = [
    {
      name: "Subscriptions",
      data: [monthly, weekly, daily, hourly, overall],
    },
  ];

  return (
    <Card p="20px" alignItems="center" flexDirection="column" w="100%">
      <h2>Subscription Counts</h2>
      <Chart options={options} series={series} type="bar" height={350} />
    </Card>
  );
}

export default SubscriptionAnalytics;
