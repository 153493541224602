type RowObj = {
	subject_name: string;
	grade: string;
	category: string;
	time_duration: string;
	number_of_question: string;
};

const subjectTableDataComplex: RowObj[] = [
	{
		subject_name: 'Computer Science',
		grade: 'grade 8',	
		category: 'category',
        time_duration: '80 min',
        number_of_question: '120'
	},
	{
		subject_name: 'Computer Science',
		grade: 'grade 8',
		category: 'category',
		time_duration: '80 min',
		number_of_question: '120'
	},
	{
		subject_name: 'Computer Science',
		grade: 'grade 8',
		category: 'category',
		time_duration: '80 min',
		number_of_question: '120'
	},		
	{
		subject_name: 'Computer Science',
		grade:'grade 8',
		category: 'category',
		time_duration: '80 min',
        number_of_question: '120',
    },
	{
		subject_name: 'Computer Science',
		grade: 'grade 8',
		category: 'category',
		time_duration: '80 min',
		number_of_question: '120'
	},
	{
		subject_name: 'Computer Science',
		grade: 'grade 8',
		category: 'category',
		time_duration: '80 min',
		number_of_question: '120'
	},
	{
		subject_name: 'Computer Science',
		grade: 'grade 8',
		category: 'category',
		time_duration: '80 min',
		number_of_question: '120'
	},
	{
		subject_name: 'Computer Science',
		grade: 'grade 8',
		category: 'category',
		time_duration: '80 min',
		number_of_question: '120'
	},
	{
		subject_name: 'Computer Science',
		grade: 'grade 8',
		category: 'category',
		time_duration: '80 min',
	    number_of_question: '120'
    },
	{
		subject_name: 'Computer Science',
		grade: 'grade 8',
		category: 'category',
		time_duration: '80 min',
	    number_of_question: '120'
    },	
	{
		subject_name: 'Computer Science',
		grade: 'grade 8',
		category: 'category',
		time_duration: '80 min',
		number_of_question: '120'
	}
];
export default subjectTableDataComplex;
