import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from "utils/axiosMiddleware";

interface AdminThunk {
  pending: any;
  rejected: any;
  fulfilled: any;
}

interface AdminState {
  data: any;
  isSuccess: boolean;
  message: string;
  loading: boolean;
}

export const createAdmin: any = createAsyncThunk(
  "admin/createAdmin",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";
    try {
      const data: any = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/admin/create`,
        headers: { Authorization: `Bearer ${token}` },
        data: arg,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAdmin: any = createAsyncThunk(
  "admin/getData",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/list`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // const userData: UserData = await data.json();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTrialPeriod: any = createAsyncThunk(
  "admin/getTrialPeriod",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/trial-version/trialversion`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateGuestTrialVersion: any = createAsyncThunk(
  "payment/updateGuestTrialVersion",

  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";
 
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/payment/guesttrialversiondate/update/${arg.id}`,
        {
          duration: arg.payload,
        },
        {
          headers: {
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      return response;
      //   // Handle successful response
    } catch (error) {
      return rejectWithValue(error);
      // Handle error response
    }
  }
);

export const getGuestTrialPeriod: any = createAsyncThunk(
  "admin/getGuestTrialPeriod",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/payment/guesttrialversiondate`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateTrialVersion: any = createAsyncThunk(
  "admin/updateTrial",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/trial-version/update${arg.id}`,
        headers: { Authorization: `Bearer ${token}` },
        data: arg.payload,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRoles: any = createAsyncThunk(
  "admin/getRoles",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/getroles`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const searchAdmin: any = createAsyncThunk(
  "admin/getData",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/search?search=${arg}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const activateAdmin: any = createAsyncThunk(
  "admin/activateAdmin",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/admin/activate/${arg}`,
        headers: { Authorization: `Bearer ${token}` },
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deActivateAdmin: any = createAsyncThunk(
  "admin/deactivateAdmin",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";
    try {
      const data = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/admin/deactivate/${arg}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteAdmin: any = createAsyncThunk(
  "admin/DeleteAdmin",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";
    try {
      const data = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/admin/delete/${arg}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateAdmin: any = createAsyncThunk(
  "admin/updateAdmin",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";
    try {
      const data = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/admin/changeprofile/${arg.id}`,
        headers: { Authorization: `Bearer ${token}` },
        data: arg.data,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const initialState: AdminState = {
  data: {},
  isSuccess: false,
  message: "",
  loading: false,
};
const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: {
    [createAdmin.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createAdmin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data.push(payload.data.adminProfile);
      state.isSuccess = true;
    },
    [createAdmin.rejected]: (state, { payload }) => {
      state.message = payload.data;
      state.loading = false;
      state.isSuccess = false;
    },
    [getAdmin.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getAdmin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getAdmin.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [searchAdmin.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [searchAdmin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [searchAdmin.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },

    [activateAdmin.pending]: (state, { payload }) => {
      state.loading = true;
    },

    [activateAdmin.fulfilled]: (state, action) => {
      const index = state.data.findIndex(
        (admin: any) => admin.id == action.meta.arg
      );

      if (index !== -1) {
        state.data[index].isActive = true;
      }
      state.isSuccess = true;
      state.loading = false;
    },

    [activateAdmin.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },

    [deleteAdmin.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteAdmin.fulfilled]: (state, action) => {
      state.loading = false;

      state.data = state.data.filter(
        (admin: any) => admin.id !== action.meta.arg
      );
      state.isSuccess = true;
    },
    [deleteAdmin.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },

    [deActivateAdmin.pending]: (state, { payload }) => {
      state.loading = true;
    },

    [deActivateAdmin.fulfilled]: (state, action) => {
      const index = state.data.findIndex(
        (admin: any) => admin.id == action.meta.arg
      );

      if (index !== -1) {
        state.data[index].isActive = false;
      }
      state.isSuccess = true;
      state.loading = false;
    },

    [deActivateAdmin.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },

    [updateAdmin.pending]: (state, { payload }) => {
      state.loading = true;
    },

    [updateAdmin.fulfilled]: (state, action) => {
      state.loading = false;
      let temp = current(state);

      state.data = temp.data;

      const index = temp.data.findIndex(
        (admin: any) => admin.id === action.payload.data.adminProfile.id
      );
      if (index !== -1) {
        state.data[index] = action.payload.data.adminProfile;
      }
      state.isSuccess = true;
    },
    [updateAdmin.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
  },
});

export default adminSlice;
