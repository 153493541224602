import { Text, useColorModeValue } from "@chakra-ui/react";

const TableHeader = (props: any) => {
  const textColor = useColorModeValue("navy.700", "Black");
  const textColorSecondary = "gray.400";
  const deleteIconColor = useColorModeValue("red.600", "white");
  return (
    <Text
      justifyContent="space-between"
      align="center"
      fontWeight="600"
      fontSize="10px"
      color={textColor}
    >
      {props.title}
    </Text>
  );
};
export default TableHeader;
