import { Input, Stack, Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";
import { useState } from "react";

const MultipleInput = ({ setData }: any) => {
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState([]);

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event: any) => {
    if (event.key === " " && inputValue.trim() !== "") {
      setTags([...tags, inputValue.trim()]);
      setData([...tags, inputValue.trim()]);
      setInputValue("");
    }
  };

  const removeTag = (index: any) => {
    setData(tags.filter((_, i) => i !== index));
    setTags(tags.filter((_, i) => i !== index));
  };

  return (
    <Stack spacing={4}>
      <Input
        placeholder="Enter Phone Number and press SpaceBar"
        value={inputValue}
        fontWeight="500"
        variant="main"
        h="44px"
        maxH="44px"
        type="text"
        _placeholder={{
          fontWeight: "400",
          color: "secondaryGray.600",
        }}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
      />
      <Stack spacing={2} direction="row">
        {tags.map((tag, index) => (
          <Tag key={index} size="md" variant="solid" colorScheme="teal">
            <TagLabel>{tag}</TagLabel>
            <TagCloseButton onClick={() => removeTag(index)} />
          </Tag>
        ))}
      </Stack>
    </Stack>
  );
};

export default MultipleInput;
