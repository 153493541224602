import { Box, Center } from '@chakra-ui/react';
import YouTube from 'react-youtube';

const VideoPreview = ({ youtubeUrl }: any) => {
  // Extract the video ID from the YouTube URL
  const videoId = extractVideoId(youtubeUrl);

  // Options for the react-youtube component
  const opts = {
    height: '150',
    width: '250',
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <Box>
      <Center>
        <YouTube videoId={videoId} opts={opts} />
      </Center>
    </Box>
  );
};

// Helper function to extract the video ID from a YouTube URL
const extractVideoId = (url:string) => {
  const match = url.match(/[?&]v=([^?&]+)/);
  return match && match[1] ? match[1] : null;
};

export default VideoPreview;
