import { FormControl, FormLabel, Icon, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

const PasswordInputForm=( props: 
    {   id:string,
        placeholder: string,
        label: string;
        validation: any;
        control: any;
        errorMessage: any;
     })=>{
        const { id, placeholder, label,
            
            validation,
            control } = props;
        const textColorSecondary = "gray.400";
    	const [show, setShow] = useState(false);
	const handleClick = () => setShow(!show);
        return( 
   <>
   <FormControl id={id}>
      <FormLabel>{label}</FormLabel>
      <InputGroup size='md'>
      <Controller
        render={({ field: { onChange, ref } }) => (
         
            <Input 
          autoComplete="off"
          ref={ref} onChange={onChange} 
          type={show ? 'text' : 'password'}
          
          />
        )}
        name={id}
        control={control}
        rules={validation}
      />
  <InputRightElement  display='flex' alignItems='center' mt='4px'>
                          <Icon
                          color={textColorSecondary}
                          _hover={{ cursor: "pointer" }}
                          as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                          onClick={handleClick}
                          />
      
   </InputRightElement>
   </InputGroup>
</FormControl>
   
       
   </>
   )
}
export default PasswordInputForm;