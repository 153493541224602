import { Badge } from '@chakra-ui/react';

const StatusBadge = ({ status }: any) => {
    let color;

    switch (status) {
        case 'pending':
            color = 'yellow';
            break;
        case 'success':
            color = 'green';
            break;
        case 'reject':
            color = 'red';
            break;
        default:
            color = 'gray';
    }

    return (
        <Badge borderRadius="full" px="2" colorScheme={color}>
            {status}
        </Badge>
    );
};

export default StatusBadge;
