import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from 'utils/axiosMiddleware';
 
export const getNotifications:any =  createAsyncThunk(
  "notification/getData",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
      
    try {
      const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/notification/notifications`,{headers: { Authorization: `Bearer ${token}` },
      params: arg,  
    },);
      
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  })


export const getNotificationTypes:any =  createAsyncThunk(
  "notification/getType",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
      
    try {
      const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/notification/getnotificationtypes`,{headers: { Authorization: `Bearer ${token}` },
      },);
      
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  })

  export const updateNotification:any =  createAsyncThunk(
    "notification/updateData",
    async (arg:any, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
      try {
        const data = await axios({
          method: 'put',
          url: `${process.env.REACT_APP_API_URL}/notification/update${arg.id}`,
          headers: { Authorization: `Bearer ${token}` },
          data: arg.payload,
          
      });
      return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })

    export const deleteNotification:any =  createAsyncThunk(
      "notification/deleteData",
      async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
        try {
     
          const data = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/notification/delete/${arg}`,
            headers: { Authorization: `Bearer ${token}` },
        });
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      })
      
      export const sendGeneralNotification:any =  createAsyncThunk(
        "admin/sendgeneralnotification",
        async (arg, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';  
          try {   
            const data = await axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URL}/notification/sendgeneralnotification`,
              headers: { Authorization: `Bearer ${token}` },
              params: arg,
          });
          return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })
        export const sendGeneralPushNotification:any =  createAsyncThunk(
          "admin/sendgeneralpushnotification",
          async (arg, { rejectWithValue }) => {
            const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';  
            try {   
              const data = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/notification/sendpushnotification`,
                headers: { Authorization: `Bearer ${token}` },
                data: arg,
            });
            return data;
            } catch (error) {
              return rejectWithValue(error);
            }
          })
      export const createNotification:any =  createAsyncThunk(
        "admin/createNotification",
        async (arg, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';  
          try {   
            const data = await axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URL}/notification/create`,
              headers: { Authorization: `Bearer ${token}` },
              data: arg,
          });
          return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })

const notificationSlice:any = createSlice({
  name: "notification",
  initialState: {
    data: {},
    isSuccess: false,
    message: "",
    loading: false,
  },
  reducers: {
       

  },
  extraReducers: {
    [getNotifications.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getNotifications.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getNotifications.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [createNotification.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createNotification.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      
      // state.data.push(payload.data.notification);
      state.isSuccess = true;
    },
    [createNotification.rejected]: (state, { payload }) => {
      state.message = payload.data;
      state.loading = false;
      state.isSuccess = false;
    },
    [updateNotification.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateNotification.fulfilled]: (state:any, { payload , meta}) => {
     
     state.loading = false;
    //  var temp = current(state);
    //  state.data = temp.data;
    //  const index = temp.data.findIndex((notification:any) => notification.id === meta.arg.id);
   

      // if (index !== -1) {
         
      //   state.data[index].notification = payload.data.notification.notification;
      // }
      state.isSuccess = true;
    },
    [updateNotification.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [deleteNotification.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteNotification.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      
      // state.data = state.data.filter((notification:any) => notification.notification !== payload.data.notification);
      state.isSuccess = true;
    },
    [deleteNotification.rejected]: (state:any, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
  },
});

export default notificationSlice;
