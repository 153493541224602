import { usePagination } from "@ajna/pagination";
import { AddIcon } from "@chakra-ui/icons";

import {
	Box,
	Button,
	Divider,
	Flex,
	Select,
	Spinner,
	useDisclosure,
	useToast
} from "@chakra-ui/react";
import { PrimaryLabel } from "components/Atoms/Label/Labels";
import AlertBox from "components/Molecules/Alerts/AlertBox";
import Card from "components/Molecules/card/Card";
import { SearchBar } from "components/Molecules/navbar/searchBar/SearchBar";
import { MainPagination } from "components/Molecules/Pagination";
import QuestionList from "components/organisms/questionList";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { getexamVersion } from "reduxtoolkit/reducers/examVersionSlice";
import { getGrades } from "reduxtoolkit/reducers/gradeSlice";
import {
	deleteQuestion,
	getQuestions
} from "reduxtoolkit/reducers/questionSlice";
import { getSection } from "reduxtoolkit/reducers/sectionSlice";
import { getSubjects } from "reduxtoolkit/reducers/subjectSlice";
import { getUnits } from "reduxtoolkit/reducers/unitSlice";
import { getYear } from "reduxtoolkit/reducers/yearSlice";

type RowObj = {
	number: string;
	questionType: any;
	question: any;
	A: string;
	B: string;
	C: string;
	D: string;
	answer: string;
	description: string;
	actions: any;
};

export default function ComplexTable() {
	const [gradeParam, setGradeParam] = useState<any>();
	const [subjectParam, setSubjectParam] = useState<any>();
	const [yearParam, setYearParam] = useState<any>();
	const [sectionParam, setSectionParam] = useState<any>();
	const [examType, setExamTypes] = useState<any>();
	const [updated, setUpdated] = useState(false);
	const [id, setId] = useState<any>("");
	const [grades, setGrades] = useState([]);
	const [years, setYears] = useState([]);
	const [units, setUnits] = useState([]);
	const [examVersions, setExamVersions] = useState([]);
	const [subjects, setSubjects] = useState([]);
	const [sections, setSections] = useState([]);
	const loading = false;
	const questionsData: any = useSelector((state: any) => state.question);
	const dispatch = useDispatch();
	const subjectData: any = useSelector((state: any) => state.subject);
	const examVersionData: any = useSelector((state: any) => state.examVersion);
	const unitsData: any = useSelector((state: any) => state.unit);
	const yearsData: any = useSelector((state: any) => state.year);
	const sectionsData: any = useSelector((state: any) => state.section);

	const gradesData: any = useSelector((state: any) => state.grade);

	useEffect(() => {
		dispatch(getSubjects());
		dispatch(getGrades());
		dispatch(getYear());
		dispatch(getUnits());
		dispatch(getSection());
		dispatch(getexamVersion());
		dispatch(getQuestions()).then((data: any) => {
			setData(data.payload.data.questions);

			setQuestionsTotal(data.payload.data.totalCount);
		});
	}, []);
	const history = useHistory();
	useEffect(() => {
		if (questionsData.isSuccess == true) {
			setData(questionsData?.data?.questions);
			setQuestionsTotal(questionsData.data.totalCount);
		}
	}, [questionsData.loading]);

	useEffect(() => {
		setUnits(unitsData.data);
		setExamVersions(examVersionData.data);
		setGrades(gradesData.grade);
		setSections(sectionsData.data);
		setSubjects(subjectData.data);
		setYears(yearsData.data);
	}, [
		examVersionData.loading,
		unitsData.loading,
		gradesData.loading,
		sectionsData.loading,
		subjectData.loading,
		yearsData.loading,
	]);



	const alert = useDisclosure();
	const cancelRef = React.useRef();

	const [data, setData] = React.useState([]);
	const handleSearchAdmin = () => { };
	const [questionsTotal, setQuestionsTotal] = useState<number | undefined>(
		undefined
	);
	const toast = useToast();
	const outerLimit = 2;
	const innerLimit = 2;
	const {
		pages,
		pagesCount,

		currentPage,
		setCurrentPage,
		pageSize,
		setPageSize,
	} = usePagination({
		total: questionsTotal,
		limits: {
			outer: outerLimit,
			inner: innerLimit,
		},
		initialState: {
			pageSize: 10,
			isDisabled: false,
			currentPage: 1,
		},
	});

	const handlePageChange = (nextPage: number): void => {
		setCurrentPage(nextPage);
	};
	const handlePageSizeChange = (
		event: React.ChangeEvent<HTMLSelectElement>
	): void => {
		const pageSize = Number(event.target.value);

		setPageSize(pageSize);
	};
	const handleBackPagination = (current: any) => {
		if (current > 1) {
			setCurrentPage(currentPage - 1);
		}
	};
	const handleChangeExamType = (value: any) => {
		setExamTypes(value);
	};
	const handleChangeGrade = (value: any) => {
		setGradeParam(value);
	};
	const handleForwardPagination = (current: any) => {
		setCurrentPage(current + 1);
	};
	useEffect(() => {
		let params = {
			grade: gradeParam,
			subject: subjectParam,
			year: yearParam,
			section: sectionParam,
			page: currentPage,
			limit: pageSize,
		};
		dispatch(getQuestions(params)).then((data: any) => {
			setData(data.payload.data.questions);
			setQuestionsTotal(data.payload.data.totalCount);
		});
	}, [
		currentPage,
		pageSize,
		gradeParam,
		yearParam,
		gradeParam,
		sectionParam,
		subjectParam,
	]);
	const onDeleteQuestion = () => {
		dispatch(deleteQuestion(id)).then((data: any) => {
			if (data.type === "questions/deleteData/fulfilled") {
				toast({
					title: "Success",
					description: "Exam deleted successfully",
					status: "success",
					duration: 9000,
					isClosable: true,
				});
				alert.onClose();
			} else {
				toast({
					title: "Error",
					description: `${data.payload.response.data.message}`,
					status: "error",
					duration: 9000,
					isClosable: true,
				});
				alert.onClose();
			}
		});
	};
	return (
		<Box>
			<Card
				borderRadius={0}
				flexDirection="column"
				w="100%"
				px="0px"
				overflowX={{ sm: "scroll", lg: "hidden" }}
			>
				<PrimaryLabel>Filters</PrimaryLabel>
				<Divider borderWidth="1px" borderColor="black" m={2} />
				<Flex px="25px" mb="8px" justifyContent="space-between" align="center">
					<Box>
						<SearchBar
							base="10px"
							md="unset"
							me="10px"
							borderRadius="30px"
							handle={handleSearchAdmin}
						/>
					</Box>
					<Box>
						<Select
							placeholder="Grade"
							className="select-input"
							borderRadius="0px"
							onChange={(e: any) => handleChangeGrade(e.target.value)}
							_placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
							variant="filled"
						>
							{grades?.map((item: any) => (
								<option key={item.id} value={item.grade}>
									{item.grade}
								</option>
							))}
						</Select>
					</Box>
					<Box>
						<Select
							placeholder="Subject"
							className="select-input"
							borderRadius="0px"
							onChange={(e: any) => setSubjectParam(e.target.value)}
							_placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
							variant="filled"
						>
							{subjects?.map((item: any) => (
								<option key={item.id} value={item.grade}>
									{item.subject}
								</option>
							))}
						</Select>
					</Box>
					<Box>
						<Select
							placeholder="Year"
							borderRadius="0px"
							className="select-input"
							onChange={(e: any) => setYearParam(e.target.value)}
							_placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
							variant="filled"
						>
							{years?.map((item: any) => (
								<option key={item.id} value={item.grade}>
									{item.year}
								</option>
							))}
						</Select>
					</Box>
					<Box>
						<Select
							placeholder="Section"
							className="select-input"
							borderRadius="0px"
							onChange={(e: any) => setSectionParam(e.target.value)}
							_placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
							variant="filled"
						>
							{sections?.map((section: any) => (
								<option key={section.id} value={section.section}>
									{section.section}
								</option>
							))}
						</Select>
					</Box>
					<Box>
						<Select
							placeholder="Exam Type"
							className="select-input"
							borderRadius="0px"
							onChange={(e: any) => handleChangeExamType(e.target.value)}
							_placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
							variant="filled"
						>
							<option key="exam" value="EXAM">
								Exam
							</option>
							<option key="custom" value="CUSTOM">
								Custom
							</option>
						</Select>
					</Box>
					<Box>
						<Button
							fontSize={{ sm: "10px", md: "10px", lg: "14" }}
							bg="#0076FD"
							mx={5}
							fontWeight="500"
							color="white"
							borderRadius="0"
							onClick={() => history.push("questions/create")}
						>
							<AddIcon />
							create questions
						</Button>
					</Box>
				</Flex>
			</Card>
			<Box borderRadius={0}>
				{loading ? (
					<Spinner size="lg" />
				) : (
					<>
						<Box my={10} p={5} bg="white" borderRadius={0}>
							<QuestionList actionsEnabled={true} data={data} />
						</Box>
					</>
				)}
				<Flex>
					<MainPagination
						pagesCount={pagesCount}
						currentPage={currentPage}
						handlePageChange={handlePageChange}
						handleBackPagination={handleBackPagination}
						handleForwardPagination={handleForwardPagination}
						pages={pages}
						handlePageSizeChange={handlePageSizeChange}
					/>
				</Flex>
				<AlertBox
					cancelRef={cancelRef}
					alert={alert}
					header="Delete Question?"
					alertBody="Are you sure you want to delete this Question"
					onDelete={onDeleteQuestion}
				/>
			</Box>
		</Box>
	);
}
