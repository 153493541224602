import React from 'react';
import ReactQuill from 'react-quill';
import { QuillModes } from 'types/examTypes';

interface CustomReactQuillProps {
  field: any; 
  themes: string;
  modules: QuillModes;
  defaultValue: string;
  placeholder: string;
  onChange: (text: string) => void;
}



const CustomReactQuill: React.FC<CustomReactQuillProps> = ({
  field,
  themes,
  modules,
  
  placeholder,
  onChange,
}) => {
  return (
    <ReactQuill
      {...field}
      theme={themes}
      modules={modules}
      
      placeholder={placeholder}
      onChange={(text: string) => {
        onChange(text);
      }}
    />
  );
};

export default CustomReactQuill;
