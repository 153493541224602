

// Chakra imports
import { Box } from '@chakra-ui/react';
import StudiesTable from 'screens/admin/dataTables/components/StudiesTable';
// Assets

export default function Overview() {

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<StudiesTable />
		</Box>
	);
}
