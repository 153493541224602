

// Chakra imports
import { Box, Modal, ModalBody, ModalContent, ModalOverlay, Progress, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import RegionsTable from '../dataTables/components/RegionsTable';


// Assets

export default function Overview() {

	const [loading, setLoading] = useState(true);
const regions:any = useSelector((state:any)=> state.region);
const createProgressModal = useDisclosure();
useEffect(() => {
	if(regions.loading == true){
		createProgressModal.onOpen();
		setLoading(true);
	}
	else {
	   setLoading(false);
		createProgressModal.onClose();
	}
  }, [regions.loading]);

	
	
	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			{/* Main Fields */}
			{ loading ?<Progress w='1/2' isIndeterminate />: null}
			<Modal 
					isOpen={createProgressModal.isOpen}
					onClose={createProgressModal.onClose}>
						<ModalOverlay />
						<ModalContent bg="transparent" maxW="100%">
						<Box borderLeftWidth='3px' borderColor='#7F77F1'>
						<ModalBody pb={6}>
						</ModalBody>
						</Box>
						</ModalContent>
						</Modal>
			<RegionsTable />
			

		</Box>
	);
}
