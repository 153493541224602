import { usePagination } from "@ajna/pagination";
import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  CloseButton,
  Flex,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
  ThemeProvider,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { createTheme } from "@mui/material";
import Project1 from "assets/images/pdf.png";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import Card from "components/Molecules/card/Card";
import { MainPagination } from "components/Molecules/Pagination";
import ViewStudyQuestionTable from "components/Molecules/Tables/ViewStudyQuestionsTable";
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_RowSelectionState,
} from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getQuestions } from "reduxtoolkit/reducers/questionSlice";
import {
  addStudLinks,
  addStudyPdf,
  getStudies,
  studyAddQuestion,
  studyDeletePdfs,
  studyDeleteVideoLinks,
} from "reduxtoolkit/reducers/studiesSlice";
import DynamicInputForms from "./adlink";
const ViewStudy = () => {
  const theme = createTheme({
    transitions: {
      duration: {
        enteringScreen: 2000,
        leavingScreen: 100,
      },
    },
  });
  let selectedQuestions: any[] = [];
  const handleAddQuestions = (value: any) => {
    const selectedRows = table.getSelectedRowModel().rows.map((q: any) => {
      if (q.original?.id !== undefined) {
        selectedQuestions.push(q.original?.id);
      }
    });

    let ids = [];
    const data = {
      id: id,
      payload: { selecteQuestions: selectedQuestions },
    };
    dispatch(studyAddQuestion(data)).then((data: any) => {
      if (data.type === "studies/addStudyQuestion/fulfilled") {
        toast({
          title: "Success",
          description: "Study Questions Added successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const addStudyQuestionForm = useForm();
  const { handleSubmit: handleSubmitStudyQuestion, getValues } =
    addStudyQuestionForm;
  const addStudyFilesForm = useForm();
  const { handleSubmit: handleSubmitStudyFiles } = addStudyFilesForm;
  const addStudyLinksForm = useForm();
  const { handleSubmit: handleSubmitStudyLinks } = addStudyLinksForm;
  const { id }: any = useParams();

  const [study, setStudy] = useState<any>([]);
  const studiesData = useSelector((state: any) => state.study);
  const addStudyFilesModal = useDisclosure();
  const addStudyQuestionModal = useDisclosure();
  const viewStudyQuestionModal = useDisclosure();
  const addStudyLinksModal = useDisclosure();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStudies()).then((data: any) => {
      let val = data.payload.studies.filter((study: any) => study.id === id);

      setStudy(val[0]);
    });
  }, []);
  const addFilesForm = useForm();
  const addQuestions = (values: any) => {
    const data = {
      id: id,
      payload: { selecteQuestions: selecteQuestions },
    };
    dispatch(studyAddQuestion(data));
  };
  const questionsData: any = useSelector((state: any) => state.question);
  const [data, setData] = useState([]);
  const [questionType, setExamTypes] = useState<any>();
  useEffect(() => {
    if (questionsData.isSuccess === true) {
      setData(questionsData.data.questions);
    }
  }, [questionsData.loading]);

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "id",
        header: "id",
        muiTableHeadCellProps: { sx: { color: "green" } },
      },
      {
        accessorKey: "number",
        id: "number",
        header: "number",
      },
      {
        accessorKey: "questionType",
        id: "questionType",
        header: "Question Type",
      },
      {
        accessorKey: "grade",
        id: "grade",
        header: "GRADE",

        Cell: ({ renderedCellValue, row }: any) => (
          <span>{renderedCellValue?.grade}</span>
        ),
      },
      {
        accessorKey: "subject",
        id: "subject",
        header: "Subject",

        Cell: ({ renderedCellValue, row }: any) => (
          <span>{renderedCellValue?.subject}</span>
        ),
      },
      {
        accessorKey: "year",
        id: "year",
        header: "Year",

        Cell: ({ renderedCellValue, row }: any) => (
          <span>{renderedCellValue?.year}</span>
        ),
      },

      {
        accessorKey: "examVersion",
        id: "examVersion",
        header: "exam Version",

        Cell: ({ renderedCellValue, row }: any) => (
          <span>{renderedCellValue?.examVersion}</span>
        ),
      },
      {
        accessorKey: "unit",
        id: "unit",
        header: "Unit",

        Cell: ({ renderedCellValue, row }: any) => (
          <span>{renderedCellValue?.unit}</span>
        ),
      },
      {
        accessorKey: "section",
        id: "section",
        header: "Section",

        Cell: ({ renderedCellValue, row }: any) => (
          <span>{renderedCellValue?.section}</span>
        ),
      },

      {
        accessorKey: "question",
        header: "question",
        id: "question",
        Cell: ({ renderedCellValue, row }: any) => (
          <span dangerouslySetInnerHTML={{ __html: row.original.question }} />
        ),
      },
      {
        accessorKey: "A",
        header: "A",
        Cell: ({ renderedCellValue, row }: any) => (
          <span dangerouslySetInnerHTML={{ __html: row.original.A }} />
        ),
      },

      {
        accessorKey: "B",
        header: "B",

        Cell: ({ renderedCellValue, row }: any) => (
          <span dangerouslySetInnerHTML={{ __html: row.original.B }} />
        ),
      },

      {
        accessorKey: "C",
        header: "C",

        Cell: ({ renderedCellValue, row }: any) => (
          <span dangerouslySetInnerHTML={{ __html: row.original.C }} />
        ),
      },
      {
        accessorKey: "D",
        header: "D",
        Cell: ({ renderedCellValue, row }: any) => (
          <span dangerouslySetInnerHTML={{ __html: row.original.D }} />
        ),
      },
      {
        accessorKey: "answer",
        header: "answer",
      },

      {
        accessorKey: "description",
        header: "description",
      },
    ],
    []
  );
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  let selecteQuestions: any[] = [];
  useEffect(() => {
    const selectedRows = table.getSelectedRowModel().rows.map((q: any) => {
      if (q.original?.id !== undefined) {
        selecteQuestions.push(q.original?.id);
      }
    });
  }, [rowSelection]);

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    getRowId: (row) => row.userId, //give each row a more useful id
    onRowSelectionChange: setRowSelection, //connect internal row selection state to your own
    state: { rowSelection }, //pass our managed row selection state to the table to use
  });
  const addFiles = (values: any) => {
    const formData = new FormData();
    formData.append("pdfs", values.pdfs[0]);
    const data = {
      id: id,
      payload: formData,
    };
    dispatch(addStudyPdf(data)).then((data: any) => {
      if (data.type === "studies/addStudyPdf/fulfilled") {
        toast({
          title: "Success",
          description: "Files added successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setStudy(data.payload.data.study);
        addStudyFilesModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const toast = useToast();
  const [questionsTotal, setQuestionsTotal] = useState<number | undefined>(
    undefined
  );

  const outerLimit = 2;
  const innerLimit = 2;
  const {
    pages,
    pagesCount,

    currentPage,
    setCurrentPage,
    setIsDisabled,
    isDisabled,
    pageSize,
    setPageSize,
  } = usePagination({
    total: questionsTotal,
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };
  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const pageSize = Number(event.target.value);

    setPageSize(pageSize);
  };
  const handleBackPagination = (current: any) => {
    if (current > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleForwardPagination = (current: any) => {
    setCurrentPage(current + 1);
  };
  const handleAddStudyQuestion = () => {
    const param = {
      grade: study.grade.grade,
      subject: study.subject.subject,
      year: study.year.year,
      // limit: q[0].noOfQuestions,
    };

    dispatch(getQuestions(param)).then((data: any) => {
      //setData(questionsData?.questions);

      setData(data.payload.data.questions);
      setQuestionsTotal(data.payload.data.totalCount);
      addStudyQuestionModal.onOpen();
    });

    //	setParams({grade: selectedExam.grade.grade, year: selectedExam.year.year, subject: selectedExam.subject.subject})
    //	addQuestionModal.onOpen()
  };
  const handleStudyViewQuestion = () => {
    viewStudyQuestionModal.onOpen();

    //	setParams({grade: selectedExam.grade.grade, year: selectedExam.year.year, subject: selectedExam.subject.subject})
    //	addQuestionModal.onOpen()
  };

  const handleAddStudyLinks = () => {
    addStudyLinksModal.onOpen();
    //	setParams({grade: selectedExam.grade.grade, year: selectedExam.year.year, subject: selectedExam.subject.subject})
    //	addQuestionModal.onOpen()
  };
  const addLinks = (values: any) => {
    const urls = Object.values(values);

    const data = { id: id, payload: { videoLinks: urls } };

    dispatch(addStudLinks(data)).then((data: any) => {
      if (data.type === "studies/addStudyLinks/fulfilled") {
        toast({
          title: "Success",
          description: "Links added successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setStudy(data.payload.data.study);
        addStudyLinksModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      addStudyLinksModal.onClose();
    });
  };
  const handleDeleteStudyVideo = (value: any) => {
    const data = {
      id: id,
      payload: { videoLinks: [value] },
    };
    dispatch(studyDeleteVideoLinks(data)).then((data: any) => {
      if (data.type === "studies/deleteStudyLinks/fulfilled") {
        toast({
          title: "Success",
          description: "Study Video Links Removed successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        setStudy(data.payload.data.study);
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const handleDeleteStudyPdf = (value: any) => {
    const data = {
      id: id,
      payload: { deletePdfs: [value] },
    };
    dispatch(studyDeletePdfs(data)).then((data: any) => {
      if (data.type === "studies/deleteStudyPdfs/fulfilled") {
        toast({
          title: "Success",
          description: "Study Pdf Removed successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        setStudy(data.payload.data.study);
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const [loading, setLoading] = useState(true);
  const createProgressModal = useDisclosure();
  const history = useHistory();
  useEffect(() => {
    if (studiesData.loading == true) {
      setLoading(true);
      createProgressModal.onOpen();
    } else {
      setLoading(false);
      createProgressModal.onClose();
    }
  }, [studiesData.loading]);

  const { control: control, handleSubmit: handleSubmitFiles } = addFilesForm;

  return (
    <Box
      borderRadius="0px"
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {loading ? <Progress w="1/2" isIndeterminate /> : null}
      <Modal
        isOpen={createProgressModal.isOpen}
        onClose={createProgressModal.onClose}
      >
        <ModalOverlay />
        <ModalContent bg="transparent" maxW="100%">
          <Box borderLeftWidth="3px" borderColor="#7F77F1">
            <ModalBody pb={6}></ModalBody>
          </Box>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={createProgressModal.isOpen}
        onClose={createProgressModal.onClose}
      >
        <ModalOverlay />
        <ModalContent bg="transparent" maxW="100%">
          <Box borderLeftWidth="3px" borderColor="#7F77F1">
            <ModalBody pb={6}></ModalBody>
          </Box>
        </ModalContent>
      </Modal>
      <Card
        alignItems="center"
        flexDirection="column"
        w="100%"
        maxW="max-content"
        p="20px 15px"
        h="max-content"
      >
        <Card
          alignItems="center"
          flexDirection="column"
          w="100%"
          maxW="max-content"
          p="20px 15px"
          h="max-content"
        >
          <Text fontSize="xl" px={10} fontWeight="700">
            Title{" "}
          </Text>{" "}
          <Text> {study?.title}</Text>
        </Card>
        <Card
          alignItems="center"
          flexDirection="column"
          w="100%"
          maxW="max-content"
          p="20px 15px"
          h="max-content"
        >
          <Text fontSize="xl" px={10} fontWeight="700">
            Objective
          </Text>
          <Text>{study?.objective}</Text>
        </Card>
        <Card
          alignItems="center"
          flexDirection="column"
          w="100%"
          maxW="max-content"
          p="20px 15px"
          h="max-content"
        >
          <Text fontSize="xl" px={10} fontWeight="700">
            Grade
          </Text>
          <Text> {study?.grade?.grade}</Text>
        </Card>
        <Card
          alignItems="center"
          flexDirection="column"
          w="100%"
          maxW="max-content"
          p="20px 15px"
          h="max-content"
        >
          <Text fontSize="xl" px={10} fontWeight="700">
            Subject
          </Text>
          <Text> {study?.subject?.subject}</Text>
        </Card>
        <Card
          alignItems="center"
          flexDirection="column"
          w="100%"
          maxW="max-content"
          p="20px 15px"
          h="max-content"
        >
          <Text>Unit {study?.unit?.unit}</Text>
        </Card>
        <Card
          alignItems="center"
          flexDirection="column"
          w="100%"
          maxW="max-content"
          p="20px 15px"
          h="max-content"
        >
          <Text>Year {study?.year?.year}</Text>
        </Card>
        <Card
          alignItems="center"
          flexDirection="column"
          w="100%"
          maxW="max-content"
          p="20px 15px"
          h="max-content"
        >
          <Text>Section {study?.section?.section}</Text>
        </Card>
        <Flex>
          Pdfs{" "}
          {study?.pdf?.map((pdf: any) => (
            <div key={pdf.id}>
              <CloseButton
                size="lg"
                onClick={() => handleDeleteStudyPdf(pdf.id)}
              />
              <Link href={pdf?.pdfDocument}>
                <Image
                  h="80px"
                  w="80px"
                  src={Project1}
                  borderRadius="8px"
                  me="20px"
                />
              </Link>
            </div>
          ))}
        </Flex>
        <Flex width="800px">
          VideoLinks{" "}
          {study?.videoLink?.map((video: any) => (
            <Card
              alignItems="center"
              flexDirection="column"
              w="500px"
              p="20px 15px"
            >
              <CloseButton
                size="lg"
                onClick={() => handleDeleteStudyVideo(video.id)}
              />
              <iframe
                src="https://www.youtube.com/embed/HYyAZCplKxw"
                title="Fikiraddis Nekatibeb - And Sew - ፍቅርአዲስ - ነቃጥበብ - አንድ ሰው Ethiopian Music"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </Card>
          ))}
        </Flex>
        <Flex gap={10}>
          <Button
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            bg="#0076FD"
            fontWeight="500"
            color="white"
            borderRadius="0"
            onClick={() => history.push(`/admin/studies/${id}/addquestion`)}
          >
            Self Assesment Questions
          </Button>
          <Button
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            bg="#0076FD"
            fontWeight="500"
            color="white"
            borderRadius="0"
            onClick={() => handleAddStudyQuestion()}
          >
            <AddIcon w={4} h={4} px="1px" color="white" />
            AddQuestions
          </Button>

          <Button
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            bg="#0076FD"
            fontWeight="500"
            color="white"
            borderRadius="0"
            onClick={() => handleAddStudyLinks()}
          >
            <AddIcon w={4} h={4} px="1px" color="white" />
            Add Video Links
          </Button>
          <Button
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            bg="#0076FD"
            fontWeight="500"
            color="white"
            borderRadius="0"
            onClick={() => addStudyFilesModal.onOpen()}
          >
            Add Pdfs
          </Button>
        </Flex>
      </Card>
      <Modal
        isOpen={addStudyLinksModal.isOpen}
        onClose={addStudyLinksModal.onClose}
      >
        <ModalContent>
          <form onSubmit={handleSubmitStudyLinks(addLinks)}>
            <ModalHeader>Add Links</ModalHeader>
            <>
              {" "}
              <DynamicInputForms form={addStudyLinksForm} />
            </>
            <ModalBody></ModalBody>
            <ModalFooter>
              <PrimaryButton type="submit">Save</PrimaryButton>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={addStudyFilesModal.isOpen}
        onClose={addStudyFilesModal.onClose}
      >
        <ModalContent>
          <form onSubmit={handleSubmitStudyFiles(addFiles)}>
            <ModalHeader>Add Files</ModalHeader>
            <ModalBody>
              <Input
                id="file"
                fontWeight="500"
                variant="main"
                h="44px"
                maxH="44px"
                type="file"
                placeholder="Pdf"
                _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
                {...addStudyFilesForm.register("pdfs", {
                  required: "This is required",
                })}
              />
            </ModalBody>
            <ModalFooter>
              <PrimaryButton type="submit">Save</PrimaryButton>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={addStudyQuestionModal.isOpen}
        onClose={addStudyQuestionModal.onClose}
      >
        <ModalOverlay />

        <ModalContent maxW="1200px">
          <form onSubmit={handleSubmitStudyQuestion(addQuestions)}>
            <ModalHeader>Add Self Assesment Questions</ModalHeader>
            <ModalCloseButton />

            <ModalBody pb={6}>
              <ThemeProvider theme={theme}>
                <MaterialReactTable table={table} />;
                <MainPagination
                  usersTotal={questionsTotal}
                  setCurrentPage={currentPage}
                  handleBackPagination={handleBackPagination}
                  handlePageChange={handlePageChange}
                  currentPage={currentPage}
                  handlePageSizeChange={handlePageSizeChange}
                  handleForwardPagination={handleForwardPagination}
                />
                <Flex>
                  <Button
                    fontSize={{ sm: "10px", md: "10px", lg: "14" }}
                    bg="#0076FD"
                    mx={5}
                    p={10}
                    fontWeight="500"
                    color="white"
                    borderRadius="0"
                    onClick={(e: any) => handleAddQuestions(e)}
                  >
                    Add Selected Questions
                  </Button>
                </Flex>
              </ThemeProvider>
            </ModalBody>

            <ModalFooter></ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={viewStudyQuestionModal.isOpen}
        onClose={viewStudyQuestionModal.onClose}
      >
        <ModalOverlay />
        <ModalContent borderRadius="0px" maxW="1000px">
          <ModalHeader>Self Assesment Questions</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <ViewStudyQuestionTable />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
export default ViewStudy;
