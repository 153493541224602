// axiosMiddleware.ts
import axios, { AxiosError, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';


interface ErrorResponse {
  response?: AxiosResponse;
}
interface CookieOptions {
  secure?: boolean;
  sameSite?: 'Strict' | 'Lax' | 'None';
  domain?: string;
  path?: string;
  expires?: number | Date;
  // Add other options as needed
}

const COOKIE_SECURE_OPTIONS: CookieOptions = {
  secure: true,   
  sameSite: 'Lax', 
};
const clearCookies = () => {
  // Implement your cookie-clearing logic
};

const RedirectToLogin = () => {
  
    Cookies.remove('loggedInAs', { ...COOKIE_SECURE_OPTIONS});
         Cookies.remove('accessToken', { ...COOKIE_SECURE_OPTIONS});
         Cookies.remove('isAuthenticated', { ...COOKIE_SECURE_OPTIONS});
         Cookies.remove('user', { ...COOKIE_SECURE_OPTIONS});
       window.location.reload();
  // Implement your redirect logic to the login page
};

// Add a request interceptor
axios.interceptors.request.use(
  (config: any) => {
    // Do something before request is sent
    const token = localStorage.getItem('accessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  (response: AxiosResponse) => {
    // Do something with the response data
    return response;
  },
  (error: AxiosError<ErrorResponse>) => {
    // Do something with response error
    if (error.response && error.response.status === 401) {
      // Token expired or unauthorized
      clearCookies(); // Implement a function to clear cookies
      RedirectToLogin(); // Implement a function to redirect to the login page
    }
    return Promise.reject(error);
  }
);

export default axios;
