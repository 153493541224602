// Chakra imports
import { Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Custom components

import { ProgressModal } from "components/Molecules/Modals/ProgressModal";
import UsersTable from "components/organisms/UsersTable";
import { userInitialState } from "types/userTypes";

export default function UserManagement() {
  const [isOpen, setIsOpen] = useState(false);
  const users: userInitialState = useSelector((state: any) => state.users);

  useEffect(() => {
    if (users.loading === true) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [users.loading]);
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <ProgressModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <UsersTable />
    </Box>
  );
}
