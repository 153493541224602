import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import TableHeader from "components/Atoms/Label/TableHeader";
import AlertBox from "components/Molecules/Alerts/AlertBox";
import PrimaryTable from "components/Molecules/Tables/PrimaryTable";
// Custom components
import Card from "components/Molecules/card/Card";
import { ProgressModal } from "components/Molecules/Modals/ProgressModal";
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  createSubscriptionPackage,
  deleteSubscriptionPackage,
  getSubscriptionPackages,
  getTrialVersionDate,
  updateSubscriptionPackage,
} from "reduxtoolkit/reducers/paymentSlice";
// Assets

type RowObj = {
  subscriptionPlan: string;
  id: any;
  price: any;
  duration: any;
  actions: any;
};

const columnHelper = createColumnHelper<RowObj>();

export default function ComplexTable(props: { tableData: any }) {
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [id, setId] = useState<any>("");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  let defaultData = tableData;
  const columns = [
    columnHelper.accessor("subscriptionPlan", {
      id: "subscriptionPlan",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          Subscription Plan
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("duration", {
      id: "duration",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          Duration (days)
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("price", {
      id: "price",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize="12px"
          color="gray.400"
        >
          Price
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="700"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("actions", {
      id: "actions",
      header: () => <TableHeader title="Actions" />,
      cell: (info) => (
        <>
          <Icon
            onClick={() => handleEditSubscriptionPlan(info.row)}
            as={FaEdit}
            color="gray.400"
          />
          <Icon
            onClick={() => handleDeleteSubscriptionPlan(info.row.original)}
            as={MdDeleteOutline}
            color="red"
          />
        </>
      ),
    }),
  ];
  const handleEditSubscriptionPlan = (value: any) => {
    setId(value.original.id);

    const data = value.original;
    reset({
      duration: data.duration,
      subscriptionPlan: data.subscriptionPlan,
      price: data.price,
    });
    editPackageModal.onOpen();
  };
  const handleDeleteSubscriptionPlan = (value: any) => {
    setId(value.id);
    alert.onOpen();
  };

  const [data, setData] = React.useState(() => []);
  const dispatch = useDispatch();
  const createPackageForm = useForm();
  const { handleSubmit: handleSubmitNotification } = createPackageForm;
  const createPackageModal = useDisclosure();
  const editPackageModal = useDisclosure();
  const editPackageForm = useForm();
  const { handleSubmit: handleSubmitEditPackage, reset } = editPackageForm;
  const toast = useToast();
  const alert = useDisclosure();
  const cancelRef = React.useRef();
  const onDeletePackage = () => {
    //	setId(getValues('id'));
    dispatch(deleteSubscriptionPackage(id)).then((data: any) => {
      if (data.type === "payment/deleteSubscriptionPackage/fulfilled") {
        toast({
          title: "Success",
          description: "Subscription Package Deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        alert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      }
    });
  };
  const onEditPackage = (values: any) => {
    const pack = {
      id: id,
      payload: {
        subscriptionPlan: values.subscriptionPlan,
        duration: Number(values.duration),
        price: Number(values.price),
      },
    };
    dispatch(updateSubscriptionPackage(pack)).then((res: any) => {
      if (res.type === "payment/updateData/fulfilled") {
        toast({
          title: "Success",
          description: "Subscription plan Updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        alert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${res.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      }

      editPackageModal.onClose();
    });
  };
  const onCreatePackage = (values: any) => {
    const pack = {
      subscriptionPlan: values.subscriptionPlan,
      duration: Number(values.duration),
      price: Number(values.price),
    };
    dispatch(createSubscriptionPackage(pack)).then(() => {
      createPackageModal.onClose();
    });
  };
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  const packageData = useSelector((state: any) => state.payment);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  useEffect(() => {
    dispatch(getTrialVersionDate()).then((data: any) => {});
    dispatch(getSubscriptionPackages());
  }, []);

  useEffect(() => {
    if (packageData.isSuccess === true) setData(packageData.data);
  }, [packageData.loading]);

  useEffect(() => {
    if (packageData.loading === true) {
      setIsOpen(true);
      setLoading(true);
    } else {
      setLoading(false);
      setIsOpen(false);
    }
  }, [packageData.loading]);
  return (
    <Card
      borderRadius="0px"
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {loading ? <Progress id="user" w="1/2" isIndeterminate /> : null}
      <ProgressModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Subscription Plan
        </Text>

        <Button
          fontSize={{ sm: "10px", md: "10px", lg: "14" }}
          bg="#0076FD"
          mx={5}
          fontWeight="500"
          color="white"
          borderRadius="0"
          onClick={createPackageModal.onOpen}
        >
          create
        </Button>

        <Modal
          isOpen={createPackageModal.isOpen}
          onClose={createPackageModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitNotification(onCreatePackage)}>
              <ModalHeader>Add Subscription Plan</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Subscription Plan</FormLabel>
                  <Input
                    id="subscriptionplan"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Subscription Plan"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createPackageForm.register("subscriptionPlan", {
                      required: "Subscription Plan is required",
                    })}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Duration (in Days)</FormLabel>
                  <Input
                    id="duration"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Duration"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createPackageForm.register("duration", {
                      required: "Duration Plan is required",
                    })}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Price</FormLabel>
                  <Input
                    id="price"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Price"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createPackageForm.register("price", {
                      required: "price Plan is required",
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={editPackageModal.isOpen}
          onClose={editPackageModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmitEditPackage(onEditPackage)}>
              <ModalHeader>Edit Subscription Plan</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Subscription Plan</FormLabel>
                  <Input
                    id="subscriptionplan"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Subscription Plan"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editPackageForm.register("subscriptionPlan", {
                      required: "Subscription Plan is required",
                    })}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Duration (in Days)</FormLabel>
                  <Input
                    id="duration"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Duration"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editPackageForm.register("duration", {
                      required: "Duration Plan is required",
                    })}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Price</FormLabel>
                  <Input
                    id="price"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Enter Price"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editPackageForm.register("price", {
                      required: "price Plan is required",
                    })}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
        <AlertBox
          cancelRef={cancelRef}
          alert={alert}
          header="Delete Subscription Package?"
          alertBody="Are you sure you want to delete this Package"
          onDelete={onDeletePackage}
        />
      </Flex>

      <PrimaryTable id="users" data={data} columns={columns} />
    </Card>
  );
}
