import { Route } from "react-router-dom";
import ViewStudy from "screens/admin/studies/view";
const getActiveNavbar = (routes: RoutesType[]): boolean => {
  let activeNavbar = false;
  for (let i = 0; i < routes.length; i++) {
    if (
      window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
    ) {
      return routes[i].secondary;
    }
  }
  return activeNavbar;
};

export const extractLinks = (text: any) => {
  const linkRegex = /https?:\/\/[^\s]+/g;
  const links = text.match(linkRegex);
  const texts = text.split(linkRegex);

  if (!links) return { links: [], texts: texts.filter(Boolean) };

  return {
    links: links.map((link: any) => {
      const type = link.includes("youtube.com") || link.includes("youtu.be") ? "youtube" : "string";
      return {
        "type": type,
        "link": link
      };
    }),
    texts: texts.filter(Boolean)
  };
}


export const getTheCurrentMonth = () => {
  const currentDate = new Date();
  const currentMonthNumber = currentDate.getMonth() + 1;
  return currentMonthNumber;
};
export const getCurrentWeek = () => {
  const today = new Date();
  const firstDayOfYear: Date = new Date(today.getFullYear(), 0, 1);
  const pastDaysOfYear: number =
    (today.getTime() - firstDayOfYear.getTime()) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
};

const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
  let activeNavbar = false;
  for (let i = 0; i < routes.length; i++) {
    if (
      window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
    ) {
      return routes[i].name;
    }
  }
  return activeNavbar;
};

export const formatDateTime = (timestamp: any) => {

  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const formattedDateTime = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;


  return formattedDateTime;
}

const getRoutes = (routes: RoutesType[]): any => {
  var mainRoutes = routes?.map((route: RoutesType, key: any) => {
    if (route.layout === "/admin") {
      return (
        <Route
          path={route.layout + route.path}
          component={route.component}
          key={key}
        />
      );
    } else {
      return null;
    }
  });

  let subRoutes: any = [];
  routes?.map((route: RoutesType, key: any) => {
    if (route.layout === "/admin" && route.subMenu !== undefined) {
      return route.subMenu.map((subRoute: any, subkey: any) => {
        subRoutes.push(
          <Route
            path={route.layout + subRoute.path}
            component={subRoute.component}
            key={subkey}
          />
        );
      });
    }
  });
  mainRoutes.push(
    <Route
      path={`/admin/studies/:id`}
      component={() => <ViewStudy />}
      key="stdy"
    />
  );

  return mainRoutes.concat(subRoutes);
};

const getActiveRoute = (routes: RoutesType[]): string => {
  let activeRoute = "Default Brand Text";
  for (let i = 0; i < routes.length; i++) {
    if (
      window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
    ) {
      return routes[i].name;
    }
  }
  return activeRoute;
};

export { getActiveNavbar, getActiveNavbarText, getActiveRoute, getRoutes };

