// Chakra imports
import { Box, Portal, useDisclosure } from "@chakra-ui/react";

// Layout components
import Navbar from "components/Molecules/navbar/NavbarAdmin";
import Sidebar from "components/Molecules/sidebar/Sidebar";
import ExamAddQuestion from "components/organisms/ExamaddQuestion";
import StudyAddQuestion from "components/organisms/StudyaddQuestion";
import ExamView from "components/organisms/ViewExam";
import { SidebarContext } from "contexts/SidebarContext";
import { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes";
import CreateChallengeForm from "screens/admin/challengephase/sample";
import CreateQuestion from "screens/admin/questions/create";
import EditQuestion from "screens/admin/questions/edit";

import {
  getActiveNavbar,
  getActiveNavbarText,
  getActiveRoute,
  getRoutes,
} from "utils/get/generalhelpers";

// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(true);
  const [toggleSidebar, setToggleSidebar] = useState(true);
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };

  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();

  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        <Sidebar routes={routes} display="none" {...rest} />
        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: "100%", xl: "100%" }}
          maxWidth={{ base: "100%", xl: "87%" }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={"Exam - Time - Admin"}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                message={getActiveNavbarText(routes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal>

          {getRoute() ? (
            <Box
              mx="auto"
              p={{ base: "20px", md: "30px" }}
              pe="20px"
              minH="100vh"
              pt="50px"
            >
              <Switch>
                <Route
                  path="/admin/studies/:id/addquestion"
                  component={StudyAddQuestion}
                  key="addstudyquestion"
                />
                <Route
                  path="/admin/studies/:id"
                  component={StudyAddQuestion}
                  key="viewStudy"
                />
                <Route
                  path="/admin/exams/:id"
                  component={ExamView}
                  key="exams"
                />
                <Route
                  path="/admin/exams/:id/addquestion"
                  component={ExamAddQuestion}
                  key="examsaddquestion"
                />
                <Route
                  path="/admin/challengephase/create"
                  component={CreateChallengeForm}
                  key="createchallenges"
                />
                <Route
                  path="/admin/questions/create"
                  component={CreateQuestion}
                  key="createquestions"
                />
                <Route
                  path="/admin/questions/:id"
                  component={EditQuestion}
                  key="editquestions"
                />

                {getRoutes(routes)}

                <Redirect from="/" to="/admin/default" />
              </Switch>
            </Box>
          ) : null}
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
