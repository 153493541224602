import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from 'utils/axiosMiddleware';

export const getUserGuide:any =  createAsyncThunk(
  "UserGuide/getData",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
      
    try {
      const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/user-guide/userguide`,{headers: { Authorization: `Bearer ${token}` },
      },);
      
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  })

  export const updateUserGuide:any =  createAsyncThunk(
    "UserGuide/updateData",
    async (arg:any, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
      try {
        const data = await axios({
          method: 'put',
          url: `${process.env.REACT_APP_API_URL}/user-guide/update/${arg.id}`,
          headers: { Authorization: `Bearer ${token}` },
          data: arg.payload,
          
      });
      return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })

    export const deleteUserGuide:any =  createAsyncThunk(
      "UserGuide/deleteData",
      async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
        try {
     
          const data = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/user-guide/delete/${arg}`,
            headers: { Authorization: `Bearer ${token}` },
        });
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      })
      export const createUserGuide:any =  createAsyncThunk(
        "admin/createUserGuide",
        async (arg, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';  
          try {   
            const data = await axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URL}/user-guide/create`,
              headers: { Authorization: `Bearer ${token}` },
              data: arg,
          });
          return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })

const UserGuidelice:any = createSlice({
  name: "UserGuide",
  initialState: {
    data: {},
    isSuccess: false,
    message: "",
    loading: false,
  },
  reducers: {
       

  },
  extraReducers: {
    [getUserGuide.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getUserGuide.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getUserGuide.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [createUserGuide.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createUserGuide.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      
      state.data.push(payload.data.userGuide);
      state.isSuccess = true;
    },
    [createUserGuide.rejected]: (state, { payload }) => {
      state.message = payload.data;
      state.loading = false;
      state.isSuccess = false;
    },
    [updateUserGuide.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateUserGuide.fulfilled]: (state:any, { payload , meta}) => {
     
     state.loading = false;
    
     const index = state.data.findIndex((UserGuide:any) => UserGuide.id === meta.arg.id);
      if (index !== -1) {

        state.data[index] = payload.data.userGuide;
      }
      state.isSuccess = true;
    },
    [updateUserGuide.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [deleteUserGuide.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteUserGuide.fulfilled]: (state:any, action:any) => {
      state.loading = false;
      
      state.data = state.data.filter((UserGuide:any) => UserGuide.id !== action.meta.arg);
      state.isSuccess = true;
    },
    [deleteUserGuide.rejected]: (state:any, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
  },
});

export default UserGuidelice;
