import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from "utils/axiosMiddleware";

export const getDirectPaymentList: any = createAsyncThunk(
  "payment/directbankpayment",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";
    try {

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/payment/directbankpayment`, {
        headers: { Authorization: `Bearer ${token}` },
        params: arg,
      },);




      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyDirectPayment: any = createAsyncThunk(
  "verify/directPayment",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/payment/verifydirectbankpayment/${arg.id}`,
        data: arg.payload,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const rejectDirectPayment: any = createAsyncThunk(
  "reject/directPayment",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/payment/rejectdirectbankpayment/${arg.id}`,
        params: { rejectMessage: arg.rejectMessage },
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const DirectBankaymentSlice: any = createSlice({
  name: "directpayment",
  initialState: {
    data: {},
    isSuccess: false,
    message: "",
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [getDirectPaymentList.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getDirectPaymentList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // state.data = payload;
      state.isSuccess = true;
    },
    [getDirectPaymentList.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
  },
});

export default DirectBankaymentSlice;
