import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from 'utils/axiosMiddleware';


export const getSubjects:any =  createAsyncThunk(
  "subject/getData",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
      
    try {
      const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/subject/subject?page=0&limit=1000`,{headers: { Authorization: `Bearer ${token}` },
      },);
      
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  })

  export const updateSubject:any =  createAsyncThunk(
    "subject/updateData",
    async (arg:any, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
      try {
        const data = await axios({
          method: 'put',
          url: `${process.env.REACT_APP_API_URL}/subject/update${arg.id}`,
          headers: { Authorization: `Bearer ${token}` },
          data: arg.payload,
          
      });
      return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })

    export const deleteSubject:any =  createAsyncThunk(
      "subject/deleteData",
      async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
        try {
     
          const data = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/subject/delete${arg}`,
            headers: { Authorization: `Bearer ${token}` },
        });
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      })
      export const createSubject:any =  createAsyncThunk(
        "admin/createSubject",
        async (arg, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';  
          try {   
            const data = await axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URL}/subject/create`,
              headers: { Authorization: `Bearer ${token}` },
              data: arg,
          });
          return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })

const subjectSlice:any = createSlice({
  name: "subject",
  initialState: {
    data: [],
    isSuccess: false,
    message: "",
    loading: false,
  },
  reducers: {
       

  },
  extraReducers: {
    [getSubjects.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getSubjects.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getSubjects.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [createSubject.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createSubject.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      
      state.data.push(payload.data.subject);
      state.isSuccess = true;
    },
    [createSubject.rejected]: (state, { payload }) => {
      state.message = payload.data;
      state.loading = false;
      state.isSuccess = false;
    },
    [updateSubject.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateSubject.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
    
       const index = state.data.findIndex((subject:any) => subject.id === payload.data.subject.id);
             if (index !== -1) {
            
           state.data[index] = payload.data.subject;
         }
      state.isSuccess = true;
    },
    [updateSubject.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [deleteSubject.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteSubject.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      
      state.data = state.data.filter((subject:any) => subject.subject !== payload.data.subject);
      state.isSuccess = true;
    },
    [deleteSubject.rejected]: (state:any, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
  },
});

export default subjectSlice;
