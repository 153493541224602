import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from 'utils/axiosMiddleware';


export const getSubjectsManagement:any =  createAsyncThunk(
  "subjectManagement/getData",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
    
    try {
      const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/subjectmanagement/subject?page=0&limit=1000`,{headers: { Authorization: `Bearer ${token}` },
      },);
      
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  })

  export const updateSubjectManagement:any =  createAsyncThunk(
    "subjectManagement/updateData",
    async (arg:any, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
      try {
        const data = await axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_URL}/subjectmanagement/update/${arg.id}`,
          headers: { Authorization: `Bearer ${token}` },
          data: arg.payload,
          
      });
      return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })

    export const deleteSubjectManagement:any =  createAsyncThunk(
      "subjectManagement/deleteData",
      async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
        try {
     
          const data = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/subjectmanagement/delete/${arg}`,
            headers: { Authorization: `Bearer ${token}` },
        });
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      })
      export const createSubjectManagement:any =  createAsyncThunk(
        "SubjectManagement/createSubject",
        async (arg, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';  
          try {   
            const data = await axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URL}/subjectmanagement/create`,
              headers: { Authorization: `Bearer ${token}` },
              data: arg,
          });
          return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })

const subjectManagementSlice:any = createSlice({
  name: "subjectManagement",
  initialState: {
    data: [],
    isSuccess: false,
    message: "",
    loading: false,
  },
  reducers: {
       

  },
  extraReducers: {
    [getSubjectsManagement.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getSubjectsManagement.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getSubjectsManagement.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [createSubjectManagement.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createSubjectManagement.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      
      state.data.subjects.push(payload.data.subject);
      state.isSuccess = true;
    },
    [createSubjectManagement.rejected]: (state, { payload }) => {
      state.message = payload.data;
      state.loading = false;
      state.isSuccess = false;
    },
    [updateSubjectManagement.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateSubjectManagement.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
    
       const index = state.data.subjects.findIndex((subject:any) => subject.id === payload.data.subject.id);
             if (index !== -1) {
            
           state.data.subjects[index] = payload.data.subject;
         }
      state.isSuccess = true;
    },
    [updateSubjectManagement.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [deleteSubjectManagement.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteSubjectManagement.fulfilled]: (state:any, { meta }) => {
      state.loading = false;
      
      state.data.subjects = state.data.subjects.filter((subject:any) => subject.id !== meta.arg);
      state.isSuccess = true;
    },
    [deleteSubjectManagement.rejected]: (state:any, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
  },
});

export default subjectManagementSlice;
