import { usePagination } from "@ajna/pagination";
import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";

const PrimaryTable = (props: any) => {
  let navbarBg = useColorModeValue(
    "rgba(250, 251, 252, 0.9)",
    "rgba(11,20,55,0.5)"
  );
  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: 2647,
    initialState: { currentPage: 1 },
  });
  const { data, columns } = props;
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  return (
    <div id={props.id}>
      <TableContainer>
        <Table
          bg={navbarBg}
          border="1px solid #D9D9D9"
          variant="simple"
          color="gray.900"
          mb="24px"
          mt="10px"
        >
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr border="1px solid #D9D9D9" key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      colSpan={header.colSpan}
                      border="1px solid #D9D9D9"
                      bg="#EDF3F8"
                      borderColor={borderColor}
                      cursor="pointer"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <Flex
                        justifyContent="space-between"
                        align="center"
                        fontSize="10px"
                        fontWeight="normal"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </Flex>
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <Tr border="1px solid #D9D9D9" key={row.id} borderColor="black">
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td
                        border="1px solid #D9D9D9"
                        key={cell.id}

                        // minW={{ sm: '100px', md: '150px', lg: 'auto' }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default PrimaryTable;
