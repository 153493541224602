import { Box, Flex, FormControl, FormLabel, Icon, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spinner, Text, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import {
	createColumnHelper
} from '@tanstack/react-table';

import { PrimaryButton } from 'components/Atoms/Button/Buttons';
// Custom components
import TableContent from 'components/Atoms/Label/TableCell';
import TableHeader from 'components/Atoms/Label/TableHeader';
import AlertBox from 'components/Molecules/Alerts/AlertBox';
import ViewExamsTable from 'components/Molecules/Tables/ViewExamsTable';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { generateExam } from 'reduxtoolkit/reducers/examGenerateSlice';
import { updateGrade } from 'reduxtoolkit/reducers/gradeSlice';


  
// Assets

type RowObj = {
	
	number: string;
	questionType: any;
	question: any;
	A: string;
	B: string;
	C: string;
	D: string;
	answer: string;
	description: string;
    actions: any;
};

const columnHelper = createColumnHelper<RowObj>();
 
const textColor = 'gray.400';

 const ExamView=()=> {
	
	const tableCellColor  = useColorModeValue("navy.700", "white");
const columns = [

	columnHelper.accessor('number', {
		id: 'number',
		header:  () => (<TableHeader textColor={textColor} title="NUMBER"/>),
		cell:  (info) => (
			<TableContent textColor={tableCellColor} title={info.getValue()}/>
		  ),
	}),
	columnHelper.accessor('questionType', {
		id: 'questionType',
		header:  () => (<TableHeader textColor={textColor} title="QUESTION TYPE"/>),
		cell:  (info) => (
			<TableContent textColor={tableCellColor} title={info.getValue()}/>
		  ),
	}),
	columnHelper.accessor('question', {
		id: 'question',
		header:  () => (<TableHeader textColor={textColor} title="QUESTION"/>),
		cell:  (info) => (
			<span dangerouslySetInnerHTML={{ __html: 'httpLink' }} />
		  ),
	}),
	columnHelper.accessor('A', {
		id: 'A',
		header:  () => (<TableHeader textColor={textColor} title="A"/>),
		cell:  (info) => (
			<TableContent textColor={tableCellColor} title={info.getValue()}/>
		  ),
	}),
	columnHelper.accessor('A', {
		id: 'A',
		header:  () => (<TableHeader textColor={textColor} title="A"/>),
		cell:  (info) => (
			<TableContent textColor={tableCellColor} title={info.getValue()}/>
		  ),
	}),
	columnHelper.accessor('B', {
		id: 'B',
		header:  () => (<TableHeader textColor={textColor} title="B"/>),
		cell:  (info) => (
			<TableContent textColor={tableCellColor} title={info.getValue()}/>
		  ),
	}),
	columnHelper.accessor('D', {
		id: 'D',
		header:  () => (<TableHeader textColor={textColor} title="D"/>),
		cell:  (info) => (
			<TableContent textColor={tableCellColor} title={info.getValue()}/>
		  ),
	}),
	columnHelper.accessor('answer', {
		id: 'answer',
		header:  () => (<TableHeader textColor={textColor} title="ANSWER"/>),
		cell:  (info) => (
			<TableContent textColor={tableCellColor} title={info.getValue()}/>
		  ),
	}),
	columnHelper.accessor('description', {
		id: 'description',
		header:  () => (<TableHeader textColor={textColor} title="DESCRIPTION"/>),
		cell:  (info) => (
			<TableContent textColor={tableCellColor} title={info.getValue()}/>
		  ),
	}),
	columnHelper.accessor('actions', {
		id: 'actions',
		header: () => (<TableHeader textColor={textColor} title="ACTIONS"/>),
		cell: (info) => (
			< >
			<Icon onClick={()=>handleEditQuestion(info.row)} as={FaEdit} color='gray.400' /><Icon onClick={()=> handleDeleteQuestion(info.row.original)} as={MdDeleteOutline} color='red' />
		</>
		)	
		}),	
];
const gradesData:any = useSelector((state:any) => state.grade);
const subjectsData = useSelector((state:any) => state.subject);
const unitsData:any = useSelector((state:any) => state.unit);
const yearsData:any = useSelector((state:any) => state.year);
const sectiondata:any = useSelector((state:any) => state.section);
const examVersiondata:any = useSelector((state:any) => state.examVersion);
let { id : param }: any = useParams();
	const form5 = useForm();
	const {
		
	handleSubmit: handleSubmit5 ,reset ,getValues} = form5;
	
	const [updated, setUpdated] = useState(false);	
	const [id, setId]= useState<any>("");
	
	const onGenerateExam=(values: any)=>{
	  const data={
			examName: values.examName,
			grade: values.grade,
			subject: values.subject,
			year: values.year,
			examVersion: values.examVersion,
			duration:  Number(values.duration),
			passingScore:  Number(values.passingScore),
			noOfQuestions:  Number(values.noOfQuestions),
			selecteQuestions:["003d3494-2746-405b-b5e5-ff6aeae9da67"]

	  }
	dispatch(generateExam(data)).then((data:any)=>{

	 });
	
}



	const onEditGrades=(values: any)=>{
		
     
		const data = {
			grade: values.grade,
		}
	    dispatch(updateGrade());
		
	}


	const onDeleteGrade=(value:any)=>
	{
		setId(getValues('id'));
		
	
	}
	const [grades, setGrades] = useState([]);
	const [subjects, setSubjects] = useState([]);
	const [years, setYears] = useState([]);
	const [units, setUnits] = useState([]);
	const [sections, setSections] =useState([]);
	const loading = false;
	const questionsData:any = useSelector((state:any) => state.question);
	const dispatch = useDispatch();

    
	useEffect(()=>{
		// setData(questionsData.data);
	},[questionsData.loading])
    
	const generateExamForm = useForm();
	
	const {
		handleSubmit: handleSubmitGeneratedExam ,control , formState: {errors: examErrors}} = generateExamForm;
	const handleDeleteQuestion=(value:any)=>{

	     setId(value.id);
		 alert.onOpen();
		// const data =value.original;;
		// reset({
		// 	grade: data.grade,
		// })
		// editGradeModal.onOpen();
		}
	const generateExamsModal = useDisclosure();
	const [examVersions, setExamVersion] = useState([]);
    const alert = useDisclosure();
	const cancelRef = React.useRef()
	const handleEditQuestion=(value:any)=>{
		 setId(value.id);
		 alert.onOpen();
	}
	const editQuestionModal = useDisclosure();
	const [data, setData] = React.useState([]);
    const handleSearchAdmin=(values:any)=>{
		//dispatch(searchUsers(values));
  }

 
	return (
		<Box flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
		<Flex px='25px' mt={10}  justifyContent='space-between' align='center'>
	
			
		</Flex>
			<Box>
			 { loading ?  <Spinner size='lg' /> :
			  <ViewExamsTable data={data} />
				} 
					<Modal 
					
					isOpen={editQuestionModal.isOpen}
					onClose={editQuestionModal.onClose}>
						<ModalOverlay />
						<ModalContent>
						<form onSubmit={handleSubmit5(onEditGrades)}>
						<ModalHeader>Edit Grade</ModalHeader>
						<ModalCloseButton />
						<ModalBody pb={6}>
							<FormControl mt={4}>
							<FormLabel>Grade</FormLabel>
							<Input
								id='grade'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="Grade Name"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...form5.register('grade', {
									required: 'Grade is required',
									minLength: { value: 4, message: 'Minimum length should be 4' },
								})}
								/>
							</FormControl>
						</ModalBody>

						<ModalFooter>
						<PrimaryButton type='submit'>
            Save
            </PrimaryButton>
							
						</ModalFooter>
						</form>
						</ModalContent>
	
					</Modal>
					<AlertBox 
							cancelRef={cancelRef} 
							alert={alert}
							header="Delete Question?"
							alertBody="Are you sure you want to delete this Question"
							onDelete={onDeleteGrade}
							/>
					
					<Modal					
					isOpen={generateExamsModal.isOpen}
					onClose={generateExamsModal.onClose}>
						<ModalOverlay />
						<ModalContent>
						<form onSubmit={handleSubmitGeneratedExam(onGenerateExam)}>
						<ModalHeader>Generate Exam</ModalHeader>
						
						<ModalCloseButton />
						
						<ModalBody pb={6}>
						<FormControl mt={4}>
							<FormLabel>Grade</FormLabel>
							<Select 
							className='select-input'
							placeholder="Select grade"
							_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...generateExamForm.register('grade', {
									required: 'Grade is required',
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})} variant='unstyled' >
							  {grades?.map((item:any, index) => (
								<option key={item.id} value={item.grade}>{item.grade}</option>
								))}
							</Select>
							{examErrors.grade && 				
							<Text> dff {examErrors.grade.message.toString()}</Text>}
							</FormControl>
							<FormControl mt={4}>
							<FormLabel>ExamName</FormLabel>
							<Input
								id='number'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="Exam Name"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...generateExamForm.register('examName', {
									required: 'Exam name is required',
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})}
								/>
							{examErrors.examName && <Text color='red'>{examErrors.examName.message.toString()}</Text>}
							</FormControl>
							<FormControl mt={4}>
							<FormLabel>Exam TYpe</FormLabel>
							<Select
							className='select-input'
							_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...generateExamForm.register('examType', {
									required: 'Exam type is required',
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})} variant='unstyled' >
								<option key="exam" value="EXAM">Exam</option>
								<option key="custom" value="CUSTOM">Custom</option>
								
							</Select>
							{examErrors.examType && <Text color='red'>{examErrors.examType.message.toString()}</Text>}
							</FormControl>
						
							<FormControl mt={4}>
							<FormLabel>Subject</FormLabel>
							<Select 
								placeholder="Select Subject"
							className='select-input'
							_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...generateExamForm.register('subject', {
									required: 'Subject is required',
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})} variant='unstyled' >
							  {subjects?.map((item:any, index) => (
								<option key={item.id} value={item.subject}>{item.subject}</option>
								))}
							</Select>
							{examErrors.subject && <Text color='red'>{examErrors.subject.message.toString()}</Text>}
							</FormControl>

									
							<FormControl mt={4}>
							<FormLabel>Year</FormLabel>
							<Select 
							className='select-input'
							placeholder="Select year"
							_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...generateExamForm.register('year', {
									required: 'Year is required',
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})} variant='unstyled' >
							  {years?.map((item:any) => (
								<option key={item.id} value={item.year}>{item.year}</option>
								))}
							</Select>
							{examErrors.year && <Text color='red'>{examErrors.year.message.toString()}</Text>}
							</FormControl>

							<FormControl mt={4}>
							<FormLabel>Exam Version</FormLabel>
										<Select 
											placeholder="Select Exam version"
										className='select-input'
										_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
											{ ...generateExamForm.register('examVersion', {
												required: 'ExamVersion is required',
												minLength: { value: 0, message: 'Minimum length should be 4' },
											})} variant='unstyled' >
										{examVersions?.map((item:any) => (
											<option key={item.id} value={item.examVersion}>{item.examVersion}</option>
											))}
										</Select>
										{examErrors.examVersion && <Text color='red'>{examErrors.examVersion.message.toString()}</Text>}
						 </FormControl>

						 <FormControl mt={4}>
							<FormLabel>Duration</FormLabel>
							<Input
								id='number'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="Duration in Minutes"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...generateExamForm.register('duration', {
									required: 'Duration is required',
									
								})}
								/>
								{examErrors.duration && <Text color='red'>{examErrors.duration.message.toString()}</Text>}
							</FormControl>

							<FormControl mt={4}>
							<FormLabel>Passing Score</FormLabel>
							<Input
								id='passingScore'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="Passing Score"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...generateExamForm.register('passingScore', {
									required: 'Passing Score is required',
									
								})}
								/>
								{examErrors.passingScore && <Text color='red'>{examErrors.passingScore.message.toString()}</Text>}
							</FormControl>

							<FormControl mt={4}>
							<FormLabel>number Of Questions </FormLabel>
							<Input
								id='noOfQuestions'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="No Of Questions"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...generateExamForm.register('noOfQuestions', {
									required: 'Number of Questions is required',
									
								})}
								/>
								{examErrors.noOfQuestions && <Text color='red'>{examErrors.noOfQuestions.message.toString()}</Text>}
							</FormControl>
											</ModalBody>
						<ModalFooter>
						<PrimaryButton>
            Save
            </PrimaryButton>
							
						</ModalFooter>
						</form>
						</ModalContent>
	
					</Modal>
			</Box>
		</Box>
	);
}

	
export default ExamView;

