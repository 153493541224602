import {
  Flex,
  Heading,
  Icon,
  Radio,
  RadioGroup,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { FiChevronLeft } from "react-icons/fi";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import MobileDevicePreview from "sanity-mobile-preview";
import "sanity-mobile-preview/dist/index.css?raw";
const theme = createTheme({
  transitions: {
    duration: {
      enteringScreen: 2000,
      leavingScreen: 100,
    },
  },
});
const ExamMobilePreview = (props: any) => {
  const [data, setData] = React.useState<any>([]);
  const [exam, setExam] = React.useState<any>([]);
  const [value, setValue] = React.useState("1");

  React.useEffect(() => {
    setExam(props.data);
    setData(props.data.examQuestion);
  });
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const images = [
    {
      label: "San Francisco – Oakland Bay Bridge, United States",
      imgPath:
        "https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60",
    },
    {
      label: "Bird",
      imgPath:
        "https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60",
    },
    {
      label: "Bali, Indonesia",
      imgPath:
        "https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250",
    },
    {
      label: "Goč, Serbia",
      imgPath:
        "https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60",
    },
  ];

  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: any) => {
    setActiveStep(step);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ alignContent: "center", maxWidth: 400, flexGrow: 1 }}>
        <MobileDevicePreview
          showMenu={false}
          preSelectedDevice={"iphone-x"}
          preSelectedColor={"black"}
          allowedDevices={["ipad", "iphone-x"]}
        >
          <Paper
            square
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              height: 50,
              pl: 2,
              bgcolor: "background.default",
            }}
          ></Paper>
          <Heading
            fontSize="lg"
            textAlign="left"
            w="full"
            mb={2}
            p={5}
            color="black"
          >
            <Icon pt="10" as={FiChevronLeft} w="24px" h="24px" />
            <span style={{ paddingTop: "10px" }}> {exam.examName}</span>
          </Heading>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {data.map((question: any, index: any) => (
              <div key={index}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box
                    sx={{
                      display: "block",
                      maxWidth: 400,
                      overflow: "hidden",
                      width: "100%",
                    }}
                  >
                    <Flex
                      key={index}
                      boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
                      width="100%"
                      p={5}
                    >
                      <Box>
                        <Flex
                          key={index}
                          boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
                          justifyContent="center"
                          width="100%"
                          overflow="hidden"
                          color="black"
                          bg="base.d100"
                          borderRadius={20}
                        >
                          <VStack>
                            <Flex justify="space-between">
                              <Heading
                                fontSize="lg"
                                textAlign="left"
                                w="full"
                                fontWeight="500"
                                color="black"
                                p={5}
                              >
                                {" "}
                                Questions
                                <span>
                                  {" "}
                                  {index}/{exam?.noOfQuestions}
                                </span>
                                <Button
                                  variant="outlined"
                                  sx={{
                                    color: "#0081BA",
                                    display: "inline",
                                    fontWeight: "bold",

                                    mx: 0.5,
                                    fontSize: 14,
                                  }}
                                  size="small"
                                >
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      color: "Red",
                                      fontWeight: "bold",
                                    }}
                                    component="h2"
                                  >
                                    Directions
                                  </Typography>
                                </Button>
                              </Heading>
                            </Flex>
                            <Heading
                              fontSize="lg"
                              textAlign="left"
                              w="full"
                              p={5}
                              color="black"
                            >
                              {" "}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: question.question,
                                }}
                              />
                            </Heading>
                          </VStack>
                        </Flex>
                        <RadioGroup mt={5} onChange={setValue} value={value}>
                          <Stack alignContent="start">
                            <Flex
                              key={index}
                              boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
                              overflow="hidden"
                              color="black"
                              width="100%"
                              borderRadius={10}
                              p={5}
                              float="left"
                            >
                              <Radio value="1">
                                {" "}
                                <Flex direction="row">
                                  {" "}
                                  <span>A : </span>{" "}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: question.A,
                                    }}
                                  />{" "}
                                </Flex>
                              </Radio>
                            </Flex>
                            <Flex
                              key={index}
                              boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
                              overflow="hidden"
                              color="black"
                              p={5}
                            >
                              <Radio value="2">
                                {" "}
                                <Flex direction="row" justifyContent="start">
                                  {" "}
                                  <span>B :</span>{" "}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: question.B,
                                    }}
                                  />
                                </Flex>{" "}
                              </Radio>
                            </Flex>
                            <Flex
                              key={index}
                              boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
                              borderRadius={10}
                              overflow="hidden"
                              color="black"
                              p={5}
                            >
                              <Radio value="3">
                                <Flex direction="row" justifyContent="start">
                                  <span>C : </span>{" "}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: question.C,
                                    }}
                                  />{" "}
                                </Flex>
                              </Radio>
                            </Flex>{" "}
                            <Flex
                              key={index}
                              boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
                              justifyContent="space-between"
                              borderRadius={10}
                              overflow="hidden"
                              color="black"
                              p={5}
                            >
                              <Radio value="4">
                                <Flex direction="row" justifyContent="start">
                                  {" "}
                                  <span>D : </span>{" "}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: question.D,
                                    }}
                                  />
                                </Flex>{" "}
                              </Radio>
                            </Flex>{" "}
                          </Stack>
                        </RadioGroup>
                      </Box>
                    </Flex>
                  </Box>
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                variant="contained"
                sx={{
                  color: "#0081BA",
                  display: "inline",
                  fontWeight: "bold",
                  borderRadius: 100,
                  mx: 0.5,
                  fontSize: 14,
                }}
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "Red",

                    fontWeight: "bold",
                  }}
                  component="h2"
                >
                  Next
                </Typography>
              </Button>
            }
            backButton={
              <Button
                variant="contained"
                sx={{
                  color: "#0081BA",
                  display: "inline",
                  fontWeight: "bold",
                  borderRadius: 100,
                  mx: 0.5,
                  fontSize: 14,
                }}
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "Red",

                    fontWeight: "bold",
                  }}
                  component="h2"
                >
                  Prev.
                </Typography>
              </Button>
            }
          />
        </MobileDevicePreview>
      </Box>
    </ThemeProvider>
  );
};
export default ExamMobilePreview;
