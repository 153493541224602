import { Button } from '@chakra-ui/react';

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  variant?: 'solid' | 'outline' | 'ghost' | 'link';
  colorScheme?: string;
  type?: any
  rest?: any;
}


const PrimaryButton = ({ children, onClick, size = 'md', variant = 'solid', type="button", colorScheme = 'blue', ...rest }: ButtonProps) => {
  
  return (
    <Button 
     
     borderRadius="0px"
     size='sm' 
     type={type}
     variant={variant} 
     colorScheme={colorScheme} 
     onClick={onClick} {...rest}>
      {children}
    </Button>
  );
};

export { PrimaryButton };

