import {
  Pagination,
  PaginationContainer,
  PaginationPage,
  PaginationPageGroup,
  PaginationSeparator,
  usePagination
} from "@ajna/pagination";
import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Select,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import CustomReactQuill from "components/Atoms/fields/PrimaryRichText";
import QuestionList from "components/organisms/ExamAddedQuestionList";
import {
  useMaterialReactTable,
  type MRT_RowSelectionState
} from "material-react-table";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  examAddQuestion,
  examRemoveQuestion,
  getExamById
} from "reduxtoolkit/reducers/examGenerateSlice";
import {
  deleteQuestion,
  getQuestions,
  updateQuestion
} from "reduxtoolkit/reducers/questionSlice";
import { rtxModules } from "utils/tableconstants";
import AlertBox from "../Alerts/AlertBox";
import { ProgressModal } from "../Modals/ProgressModal";
import EditableTable from "./EditableTable";

export default function ViewExamsTable(props: any) {
  const editQuestionForm = useForm();
  const {
    handleSubmit: handleSubmitQuestion,
    control,
    getValues,
  } = editQuestionForm;
  const [themes, setTheme] = useState("snow");
  const [datas, setData] = React.useState<any>([]);
  const [addquestions, setAddQuestions] = useState<any>([]);
  const [allAddedQuestion, setAllAddedQuestion] = useState([]);
  const [questionType, setExamTypes] = useState<any>();
  const [units, setUnits] = useState([]);
  const [gradeParam, setGradeParam] = useState<any>();
  const [ispublished, setIsPublished] = useState(false);
  const [subjectParam, setSubjectParam] = useState<any>();
  const [yearParam, setYearParam] = useState<any>();
  const grades: any = useSelector((state: any) => state.grade.grade);
  const [reload, setReload] = useState(false);
  const subjects = useSelector((state: any) => state.subject.data);
  const questionsData: any = useSelector((state: any) => state.question);
  const years: any = useSelector((state: any) => state.year.data);
  const examVersions: any = useSelector((state: any) => state.examVersion.data);
  const sections: any = useSelector((state: any) => state.section.data);
  const exams: any = useSelector((state: any) => state.exam);
  const dispatch = useDispatch();
  const [selectedExam, setSelectedExam] = useState<any>([]);
  const [params, setParams] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const createProgressModal = useDisclosure();

  const rtxTodules = {
    toolbar: [
      [{ script: "sub" }, { script: "super" }],
      [{ size: ["small", false, "large", "huge"] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link", "image", "video"],
      ["clean"],
      [{ font: '' }],
      [{ align: ['left'] }],
    ],
    clipboard: {
      matchVisual: false,
    },
  }
  let { id }: any = useParams();
  useEffect(() => {
    if (exams.loading == true || questionsData.loading == true) {
      setIsOpen(true);
      setLoading(true);
    } else {
      setLoading(false);
      setIsOpen(false);
      createProgressModal.onClose();
    }
  }, [exams.loading, questionsData.loading]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "number",
        id: "number",
        header: "number",
      },
      {
        accessorKey: "questionType",
        id: "questionType",
        header: "Question Type",
        Edit: ({ cell, column, row }: any) => (
          <Select
            defaultValue={row.original.questionType}
            _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
            variant="unstyled"
          >
            <option key="exam" value="EXAM">
              Exam
            </option>
            <option key="custom" value="CUSTOM">
              Custom
            </option>
          </Select>
        ),
      },
      {
        accessorKey: "grade",
        id: "grade",
        header: "GRADE",
        Edit: ({ cell, column, row }: any) => (
          <Select
            className="select-input"
            defaultValue={row.original.grade.grade}
            _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
            variant="unstyled"
          >
            {grades?.map((item: any, index: any) => (
              <option key={item.grade} value={item.grade}>
                {item.grade}
              </option>
            ))}
          </Select>
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span>{renderedCellValue?.grade}</span>
        ),
      },
      {
        accessorKey: "subject",
        id: "subject",
        header: "Subject",
        Edit: ({ cell, column, row }: any) => (
          <Select
            className="select-input"
            defaultValue={row.original.subject.subject}
            onChange={(e: any) => setSubjectParam(e.target.value)}
            _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
            variant="unstyled"
          >
            {subjects?.map((item: any, index: any) => (
              <option key={item.id} value={item.subject}>
                {item.subject}
              </option>
            ))}
          </Select>
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span>{renderedCellValue?.subject}</span>
        ),
      },
      {
        accessorKey: "year",
        id: "year",
        header: "Year",
        Edit: ({ cell, column, row }: any) => (
          <Select
            className="select-input"
            defaultValue={row.original.year.year}
            onChange={(e: any) => setYearParam(e.target.value)}
            _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
            variant="unstyled"
          >
            {years?.map((item: any, index: any) => (
              <option key={item.id} value={item.year}>
                {item.year}
              </option>
            ))}
          </Select>
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span>{renderedCellValue?.year}</span>
        ),
      },

      {
        accessorKey: "examVersion",
        id: "examVersion",
        header: "exam Version",
        Edit: ({ cell, row, table }: any) => (
          <Select
            name="examVersion"
            defaultValue={row.original.examVersion}
            className="select-input"
            _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
            variant="unstyled"
          >
            {examVersions?.map((item: any) => (
              <option key={item.id} value={item.examVersion}>
                {item.examVersion}
              </option>
            ))}
          </Select>
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span>{renderedCellValue?.examVersion}</span>
        ),
      },
      {
        accessorKey: "unit",
        id: "unit",
        header: "Unit",
        Edit: ({ cell, column, row }: any) => (
          <Select
            className="select-input"
            defaultValue={row.original.unit}
            _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
            variant="unstyled"
          >
            {units?.map((item: any) => (
              <option key={item.id} value={item.unit}>
                {item.unit.umit}
              </option>
            ))}
          </Select>
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span>{renderedCellValue?.unit}</span>
        ),
      },
      {
        accessorKey: "section",
        id: "section",
        header: "Section",
        Edit: ({ cell, column, row }: any) => (
          <Select
            className="select-input"
            defaultValue={row.original.section}
            _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
            variant="unstyled"
          >
            {sections?.map((item: any) => (
              <option key={item.id} value={item.section}>
                {item.section}
              </option>
            ))}
          </Select>
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span>{renderedCellValue?.section}</span>
        ),
      },

      {
        accessorKey: "question",
        header: "question",
        id: "question",
        Edit: ({ cell, column, row }: any) => (
          <Controller
            name="question"
            control={control}
            rules={{
              required: "Please enter the question",
            }}
            defaultValue={row.original.question}
            render={({ field }) => (
              <CustomReactQuill
              field={field}
              themes={themes}
              modules={rtxTodules}
              defaultValue="question"
              placeholder="Enter your question"
              onChange={(text: string) => {
                field.onChange(text); 
              }}
            />
            
            )}
          />
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span dangerouslySetInnerHTML={{ __html: row.original.question }} />
        ),
      },
      {
        accessorKey: "A",
        header: "A",
        Edit: ({ cell, column, row, info }: any) => (
          <Controller
            name={cell.id}
            defaultValue={row.original.A}
            control={control}
            rules={{
              required: "Please enter task description",
            }}
            render={({ field }) => (
              <CustomReactQuill
                      field={field}
                      themes={themes}
                      modules={rtxModules}
                      defaultValue="question"
                      placeholder="Enter Choice A"
                      onChange={(text: string) => {
                        field.onChange(text); 
                      }}
                    />
            )}
          />
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span dangerouslySetInnerHTML={{ __html: row.original.A }} />
        ),
      },

      {
        accessorKey: "B",
        header: "B",
        Edit: ({ cell, column, row, info }: any) => (
          <Controller
            name="B"
            control={control}
            rules={{
              required: "Please enter task description",
            }}
            defaultValue={row.original.B}
            render={({ field }) => (
              <CustomReactQuill
              field={field}
              themes={themes}
              modules={rtxModules}
              defaultValue="B"
              placeholder="Enter Your B"
              onChange={(text: string) => {
                field.onChange(text); 
              }}
            />
            )}
          />
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span dangerouslySetInnerHTML={{ __html: row.original.B }} />
        ),
      },

      {
        accessorKey: "C",
        header: "C",
        Edit: ({ cell, column, row, table }: any) => (
          <Controller
            name="C"
            control={control}
            rules={{
              required: "Please enter task description",
            }}
            defaultValue={row.original.C}
            render={({ field }) => (
              <CustomReactQuill
              field={field}
              themes={themes}
              modules={rtxModules}
              defaultValue="B"
              placeholder="Enter Your C"
              onChange={(text: string) => {
                field.onChange(text); 
              }}
            />
            )}
          />
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span dangerouslySetInnerHTML={{ __html: row.original.C }} />
        ),
      },
      {
        accessorKey: "D",
        header: "D",
        Edit: ({ cell, column, row, table }: any) => (
          <Controller
            name="D"
            defaultValue={row.original.D}
            control={control}
            rules={{
              required: "Please enter task description",
            }}
            render={({ field }) => (
              <CustomReactQuill
              field={field}
              themes={themes}
              modules={rtxModules}
              defaultValue="B"
              placeholder="Enter Your D"
              onChange={(text: string) => {
                field.onChange(text); 
              }}
            />
            )}
          />
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span dangerouslySetInnerHTML={{ __html: row.original.D }} />
        ),
      },
      {
        accessorKey: "answer",
        header: "answer",
        Edit: ({ cell, column, table }: any) => (
          <Select
            className="select-input"
            _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
            {...editQuestionForm.register("answer", {
              required: "Answer is required",
              minLength: { value: 0, message: "Minimum length should be 4" },
            })}
            variant="unstyled"
          >
            <option key="a" value="A">
              A
            </option>
            <option key="b" value="B">
              B
            </option>
            <option key="c" value="C">
              C
            </option>
            <option key="d" value="D">
              D
            </option>
          </Select>
        ),
      },

      {
        accessorKey: "description",
        header: "description",
        Edit: ({ cell, column, row }: any) => (
          <Controller
            name="question"
            control={control}
            rules={{
              required: "Please enter task description",
            }}
            defaultValue={row.original.description}
            render={({ field }) => (
              <CustomReactQuill
              field={field}
              themes={themes}
              modules={rtxModules}
              defaultValue="B"
              placeholder="Enter Your question"
              onChange={(text: string) => {
                field.onChange(text); 
              }}
            />
            )}
          />
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span
            dangerouslySetInnerHTML={{ __html: row.original.description }}
          />
        ),
      },
      {
        accessorKey: "metadata",
        header: "metadata",
        Edit: ({ cell, column, row, table }: any) => (
          <Controller
            name="metadata"
            defaultValue={row.original.metadata}
            control={control}
            rules={{
              required: "Please enter task description",
            }}
            render={({ field }) => (
              <CustomReactQuill
              field={field}
              themes={themes}
              modules={rtxModules}
              defaultValue="B"
              placeholder="Enter Your metadata"
              onChange={(text: string) => {
                field.onChange(text); 
              }}
            />
            )}
          />
        ),
        Cell: ({ renderedCellValue, row }: any) => (
          <span dangerouslySetInnerHTML={{ __html: row.original?.metadata }} />
        ),
      },
    ],
    []
  );
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [rowaddSelection, setaddRowSelection] = useState<MRT_RowSelectionState>(
    {}
  );
  const alert = useDisclosure();
  const cancelRef = React.useRef();
  const [idq, setId] = useState<any>("");

  function selectDistinctValues(request1: any, request2: any) {
    var arr = request2.filter(function (item: any) {
      return (
        request1?.findIndex(function (obj: any) {
          return obj?.id === item?.id;
        }) === -1
      );
    });
    return arr;
  }

  const [questionNumber, setQuestionNumber] = useState<number | undefined>(
    undefined
  );
  const [questionsTotal, setQuestionsTotal] = useState<number | undefined>(
    undefined
  );
  const toast = useToast();
  const outerLimit = 2;
  const innerLimit = 2;
  const [currentAddedPage, setCurrentAddedPage] = useState(0);

  const addedQuestionPagination = usePagination({
    total: allAddedQuestion.length,

    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  });
  const questionPagination = usePagination({
    total: questionsTotal,
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
    initialState: {
      pageSize: 20,
      isDisabled: false,
      currentPage: 1,
    },
  });
  const {
    pages,
    pagesCount,
    currentPage,
    setCurrentPage,
    setIsDisabled,
    isDisabled,
    pageSize,
    setPageSize,
  } = questionPagination;

  const {
    pages: addPages,
    pagesCount: addPageCount,

    currentPage: addCurrentPage,
    setCurrentPage: addSetCurrentPage,
    setIsDisabled: addSetIsDisabled,
    isDisabled: addIsDisabled,
    pageSize: addPageSize,
    setPageSize: addSetPageSize,
  } = addedQuestionPagination;

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const handlePageChange = (nextPage: number): void => {
    questionPagination.setCurrentPage(nextPage);
  };
  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const pageSize = Number(event.target.value);

    setaddRowSelection({});
    questionPagination.setPageSize(pageSize);
  };
  const handleBackPagination = (current: any) => {
    if (current > 1) {
      setaddRowSelection({});

      questionPagination.setCurrentPage(current - 1);
    }
  };

  const handleForwardPagination = (current: any) => {
    setaddRowSelection({});

    questionPagination.setCurrentPage(current + 1);
  };

  const handleAddedPageChange = (nextPage: number): void => {
    setAddQuestions(
      allAddedQuestion.slice(
        (nextPage - 1) * addPageSize,
        (nextPage - 1) * addPageSize + addPageSize
      )
    );
    addSetCurrentPage(nextPage);
    // setCurrentAddedPage(nextPage);
  };
  const handleAddedPageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const pageSize = Number(event.target.value);

    setAddQuestions(allAddedQuestion.slice(0, 0 + pageSize));
    addSetPageSize(pageSize);
    addSetCurrentPage(1);
    const newPagesCount = Math.ceil(allAddedQuestion.length / pageSize);

    addedQuestionPagination.setPageSize(pageSize);
  };
  const handleAddedBackPagination = (current: any) => {
    if (current > 1) {
      setAddQuestions(
        allAddedQuestion.slice(
          (addCurrentPage - 2) * addPageSize,
          (addCurrentPage - 2) * addPageSize + addPageSize
        )
      );
      addSetCurrentPage(current);
      addSetCurrentPage(addCurrentPage - 1);
    }
  };

  const handleAddedForwardPagination = (current: any) => {
    if (addCurrentPage < addPageCount) {
      setAddQuestions(
        allAddedQuestion.slice(
          addCurrentPage * addPageSize,
          addCurrentPage * addPageSize + addPageSize
        )
      );
      addSetCurrentPage(addCurrentPage + 1);
    }
  };
  useEffect(() => {
    dispatch(getExamById(id)).then((data: any) => {
      var q = data.payload.exam;

      setGradeParam(q.grade.grade);
      setSubjectParam(q.subject.subject);
      setYearParam(q.year.year);
      setQuestionNumber(q.addedQuestions);
      setIsPublished(q.isPublished);
      setAllAddedQuestion(q?.examQuestion);
    });
  }, [reload]);

  useEffect(() => {
    if (
      exams.isSuccess == true &&
      gradeParam != undefined &&
      subjectParam != undefined &&
      yearParam != undefined
    ) {
      const param = {
        grade: gradeParam,
        subject: subjectParam,
        year: yearParam,
        page: currentPage,
        limit: pageSize,
      };

      if (ispublished != true) {
        dispatch(getQuestions(param)).then((data: any) => {
          const val = { ...data.payload };
          var unique = selectDistinctValues(
            allAddedQuestion,
            val.data.questions
          );

          setData(unique);
          setQuestionsTotal(data.payload.data.totalCount);
        });
      }
    }
  }, [
    questionType,
    questionPagination.currentPage,
    gradeParam,
    yearParam,
    subjectParam,
    questionPagination.pageSize,
  ]);

  const handleDeleteQuestion = (value: any) => {
    setId(value);

    alert.onOpen();
  };

  const addQuestionModal = useDisclosure();

  const onDeleteQuestion = () => {
    dispatch(deleteQuestion(idq)).then((data: any) => {
      if (data.type === "questions/deleteData/fulfilled") {
        toast({
          title: "Success",
          description: "Exam deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      }
    });
    alert.onClose();
  };
  let selectedQuestions: any[] = [];
  const handleRemoveQuestions = (value: any) => {
    const selectedId = Object.keys(selectedExam);

    if (selectedExam.length == 0) {
      toast({
        title: "Warning",
        description: "No Questions Selected",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    } else {
      const data = {
        id: id,
        payload: { selecteQuestions: selectedExam },
      };
      dispatch(examRemoveQuestion(data)).then((data: any) => {
        if (data.type === "exam/RemoveQuestion/fulfilled") {
          toast({
            title: "Success",
            description: "Exam Questions Removed successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setAllAddedQuestion(data.payload.data.exam.examQuestion);
        } else {
          toast({
            title: "Error",
            description: `${data.payload.response.data.message}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (allAddedQuestion.length < addPageSize) {
      setAddQuestions(allAddedQuestion);
    } else if (allAddedQuestion.length == 0) {
      setAddQuestions(null);
    } else {
      setAddQuestions(
        allAddedQuestion.slice(0, currentAddedPage + addPageSize)
      );
    }
  }, [allAddedQuestion]);
  const history = useHistory();
  useEffect(() => {}, [selectedExam]);

  const table = useMaterialReactTable({
    columns,
    enableRowSelection: true,

    onRowSelectionChange: setaddRowSelection,
    state: { rowSelection: rowaddSelection },
    data: datas,
    enableEditing: true,
    editDisplayMode: "row",
    onEditingRowSave: ({ table, values, row }: any) => {
      const data = {
        id: row.original.id,
        payload: {
          number: values.number,
          grade: values.grade.grade,

          subject: values.subject.subject,
          year: values.year.year,
          unit: values.unit.unit,
          section: values.section.section,
          questionType: values.questionType,
          question: values.question,
          examVersion: "Version 1",
          A: values.A,
          B: values.B,
          C: values.C,
          D: values.D,
          answer: values.answer,
          description: values.description,
          metadata: values?.metadata || row.original.metadata || "",
        },
      };
      dispatch(updateQuestion(data)).then((data: any) => {
        if (data.type == "questions/updateData/fulfilled") {
          toast({
            title: "Success",
            description: "Question updated successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: `${data.payload.response.data.message}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      });

      table.setEditingRow(null);
    },
    onEditingRowCancel: () => {},

    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    mrtTheme: (theme: any) => ({
      baseBackgroundColor: theme.palette.background.default,
    }),
    muiTableBodyRowProps: { hover: false },
    muiTableProps: {
      sx: {
        border: "1px solid rgba(81, 81, 81, .5)",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        border: "1px solid rgba(81, 81, 81, .5)",
        fontStyle: "italic",
        fontWeight: "normal",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid rgba(81, 81, 81, .5)",
      },
    },
  });

  const handleAddQuestions = (value: any) => {
    const selectedRows = table.getSelectedRowModel().rows.map((q: any) => {
      if (q.original?.id !== undefined) {
        selectedQuestions.push(q.original?.id);
      }
    });

    let ids = [];
    if (selectedQuestions.length == 0) {
      toast({
        title: "Warning",
        description: "No Questions Selected",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    } else {
      const data = {
        id: id,
        payload: { selecteQuestions: selectedQuestions },
      };
      dispatch(examAddQuestion(data)).then((data: any) => {
        if (data.type === "exam/addQuestion/fulfilled") {
          toast({
            title: "Success",
            description: "Questions Added successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setaddRowSelection({});
          let question = data.payload.data.exam.examQuestion;

          setAllAddedQuestion(question);
          setQuestionNumber(question.length);
        } else {
          toast({
            title: "Error",
            description: `${data.payload.response.data.message}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      });
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {loading ? <Progress id="user" w="1/2" isIndeterminate /> : null}
      <ProgressModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <Box mx={5} bg="white" p={20} overflowX="auto">
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Flex width="100%">
            <Box>
              <Button
                fontSize={{
                  sm: "10px",
                  md: "10px",
                  lg: "14px",
                }}
                variant="solid"
                fontWeight="500"
                onClick={(e: any) => handleRemoveQuestions(e)}
              >
                Remove Selected Questions {selectedExam.length}
              </Button>
            </Box>
          </Flex>
        </Flex>

        <QuestionList
          actionsEnabled={true}
          setSelectedExam={setSelectedExam}
          reload={setReload}
          data={addquestions}
        />

        <Flex>
          <Pagination
            pagesCount={addedQuestionPagination.pagesCount}
            currentPage={addedQuestionPagination.currentPage}
            onPageChange={handleAddedPageChange}
          >
            <PaginationContainer mx="auto">
              <PrimaryButton
                variant="outline"
                size="sm"
                onClick={() =>
                  handleAddedBackPagination(addedQuestionPagination.currentPage)
                }
              >
                <Icon as={MdArrowBackIos} />
              </PrimaryButton>
              <PaginationPageGroup
                isInline
                align="center"
                separator={
                  <PaginationSeparator
                    onClick={() =>
                     {}
                    }
                    bg="blue.300"
                    fontSize="sm"
                    w={7}
                    mx={2}
                    jumpSize={11}
                  />
                }
              >
                {addedQuestionPagination.pages.map((page: number) => (
                  <PaginationPage
                    border="solid 0px #1890FF"
                    mx={2}
                    key={`pagination_page_${page}`}
                    page={page}
                    onClick={() => {}}
                    borderRadius="0"
                    fontSize="sm"
                    _hover={{
                      bg: "gray.300",
                    }}
                    _current={{
                      border: "solid 1px #1890FF",
                      fontSize: "sm",
                      w: 7,
                    }}
                  />
                ))}
              </PaginationPageGroup>
              <PrimaryButton
                variant="outline"
                size="sm"
                onClick={() =>
                  handleAddedForwardPagination(
                    addedQuestionPagination.currentPage
                  )
                }
              >
                {" "}
                <Icon as={MdArrowForwardIos} />{" "}
              </PrimaryButton>
            </PaginationContainer>
          </Pagination>
          <Select ml={3} onChange={handleAddedPageSizeChange} w={40}>
            <option value="10">10 / page</option>
            <option value="25">25 / page</option>
            <option value="50">50 / page</option>
            <option value="100">100 / page</option>
          </Select>
        </Flex>

        {ispublished ? (
          <></>
        ) : (
          <>
            <Flex>
              <Button
                fontSize={{ sm: "10px", md: "10px", lg: "14" }}
                variant="brand"
                fontWeight="500"
                onClick={(e) => handleAddQuestions(e)}
              >
                Add Selected Questions
              </Button>
            </Flex>

            <EditableTable params={params} table={table} />

            <Flex w="100%" py="10">
              <Pagination
                pagesCount={questionPagination.pagesCount}
                currentPage={questionPagination.currentPage}
                onPageChange={handlePageChange}
              >
                <PaginationContainer mx="auto">
                  <PrimaryButton
                    variant="outline"
                    size="sm"
                    onClick={() =>
                      handleBackPagination(questionPagination.currentPage)
                    }
                  >
                    <Icon as={MdArrowBackIos} />
                  </PrimaryButton>
                  <PaginationPageGroup
                    isInline
                    align="center"
                    separator={
                      <PaginationSeparator
                        onClick={() =>
                          {}
                        }
                        bg="blue.300"
                        fontSize="sm"
                        w={7}
                        mx={2}
                        jumpSize={11}
                      />
                    }
                  >
                    {questionPagination.pages.map((page: number) => (
                      <PaginationPage
                        border="solid 0px #1890FF"
                        mx={2}
                        key={`pagination_page_${page}`}
                        page={page}
                        onClick={() => {}}
                        borderRadius="0"
                        fontSize="sm"
                        _hover={{
                          bg: "gray.300",
                        }}
                        _current={{
                          border: "solid 1px #1890FF",
                          fontSize: "sm",
                          w: 7,
                        }}
                      />
                    ))}
                  </PaginationPageGroup>
                  <PrimaryButton
                    variant="outline"
                    size="sm"
                    onClick={() =>
                      handleForwardPagination(questionPagination.currentPage)
                    }
                  >
                    {" "}
                    <Icon as={MdArrowForwardIos} />{" "}
                  </PrimaryButton>
                </PaginationContainer>
              </Pagination>
              <Select ml={3} onChange={handlePageSizeChange} w={40}>
                <option value="10">10 / page</option>
                <option value="25">25 / page</option>
                <option value="50">50 / page</option>
                <option value="100">100 / page</option>
              </Select>
            </Flex>
          </>
        )}

        <Modal
          isOpen={addQuestionModal.isOpen}
          onClose={addQuestionModal.onClose}
        >
          <ModalOverlay />

          <ModalContent maxW="1000px">
            <form>
              <ModalHeader>Create Questions</ModalHeader>

              <ModalCloseButton />
              <Flex>
                {" "}
                <Button
                  fontSize={{ sm: "10px", md: "10px", lg: "14" }}
                  variant="brand"
                  fontWeight="500"
                  onClick={(e) => handleAddQuestions(e)}
                >
                  Add Selected Questions
                </Button>
              </Flex>
              <EditableTable params={params} table={table} />
              <ModalBody pb={6}></ModalBody>

              <ModalFooter>
                <PrimaryButton>Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

        <AlertBox
          cancelRef={cancelRef}
          alert={alert}
          header="Delete Question?"
          alertBody="Are you sure you want to delete this Question"
          onDelete={onDeleteQuestion}
        />
      </Box>
    </Box>
  );
}
