import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";

import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel
} from "@tanstack/react-table";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
// Custom components
import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";

import TableHeader from "components/Atoms/Label/TableHeader";
import AlertBox from "components/Molecules/Alerts/AlertBox";
import { ProgressModal } from "components/Molecules/Modals/ProgressModal";
import SecondaryTable from "components/Molecules/Tables/LocalTable";
import { SendFilteredNotification } from "components/Templates/notification/SendFilteredNotificationModal";
import { SendPushNotification } from "components/Templates/notification/sendPushNotification";
import { useDispatch, useSelector } from "react-redux";
import { getGrades } from "reduxtoolkit/reducers/gradeSlice";
import {
  createNotification,
  deleteNotification, getNotifications, getNotificationTypes, updateNotification
} from "reduxtoolkit/reducers/notificationSlice";
import { getRegions } from "reduxtoolkit/reducers/regionSlice";
import { extractLinks } from "utils/get/generalhelpers";

// Assets

type RowObj = {
  id: string;
  notification: string;
  notificationType: string;
  links: any;
  actions: any;
};

const columnHelper = createColumnHelper<RowObj>();

export default function ComplexTable() {
  const dispatch = useDispatch();
  const notifications = useSelector((state: any) => state.notification);
  const form1 = useForm();
  const { handleSubmit: handleSubmit1, reset, getValues } = form1;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [notificationType, setNotificationType] = useState([]);

  const [id, setId] = useState<any>("");

  const onEditNotifications = (values: any) => {
    let noti = extractLinks(values.notification);



    const data = {
      id: id,
      payload: {
        notification: noti.texts.join(" "),
        notificationType: values.notificationType,
        grade: values.grade,
        link: noti.links,
      },
    };

    dispatch(updateNotification(data)).then(() => {
      setReload(true);
    });

    editNotificationModal.onClose();
  };

  const toast = useToast();
  const onDeleteNotification = () => {
    //	setId(getValues('id'));
    dispatch(deleteNotification(id)).then((data: any) => {
      if (data.type === "notification/deleteData/fulfilled") {
        toast({
          title: "Success",
          description: "Notification Deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setReload(true);
        alert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      }
    });
  };
  const [grades, setGrades] = useState();
  const [regions, setRegions] = useState();
  useEffect(() => {
    dispatch(getRegions()).then((data: any) => {
      setRegions(data.payload);
    });
    dispatch(getGrades()).then((data: any) => {
      setGrades(data.payload);
    });
    dispatch(getNotificationTypes()).then((data: any) => {
      setNotificationType(data.payload);
    });
    const param = {
      page: 0,
      limit: 9999999,
    };
    setLoading(true);
    dispatch(getNotifications(param)).then((res: any) => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (notifications.isSuccess === true)
      setData(notifications.data.notifications);
  }, [notifications.loading]);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    if (notifications.isSuccess === true && reload === true) {
      const param = {
        page: 0,
        limit: 9999999,
      };
      setLoading(true);
      dispatch(getNotifications(param)).then((data: any) => {
        setData(data.payload.notifications);
        setLoading(false);
      });

      setReload(false);
    }
  }, [reload]);
  const createNotificationForm = useForm();
  const { handleSubmit: handleSubmitNotification } = createNotificationForm;
  const createNotificationModal = useDisclosure();
  const sendNotificationModal = useDisclosure();
  const sendPushNotificationModal = useDisclosure();
  const onCreateNotification = (values: any) => {
    let links = extractLinks(values.notification);
    const data = {
      notificationType: values.notificationType,
      notification: links.texts.join(' '),
      grade: values.grade,
      link: links.links,
    }
    dispatch(createNotification(data)).then((response: any) => {
      if (response.type === "admin/createNotification/fulfilled") {
        toast({
          title: "Success",
          description: "Notification Created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        createNotificationModal.onClose();

        setReload(true);
        alert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${response.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      }
    });
  };

  const handleEditNotification = (value: any) => {
    setId(value.original.id);
    const data = value.original;
    // let lnk = data.links.map((item:any) => item.link);
    const lnk = data?.links?.map((item: any) => item.link).join('\n') + ' ';
    reset({
      notification: `${data.notification} ${lnk}`,
      notificationType: data.notificationType,
    });
    editNotificationModal.onOpen();
  };

  const alert = useDisclosure();
  const cancelRef = React.useRef();
  const handleDeleteNotification = (value: any) => {
    setId(value.id);
    alert.onOpen();
  };
  const columns = [
    columnHelper.accessor("notificationType", {
      id: "notificationType",
      header: () => <TableHeader title="Notification Type" />,
      cell: (info) => (
        <Text
          color={textColor}
          fontSize={{ sm: "10px", md: "10px", lg: "14" }}
          fontWeight="normal"
        >
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("notification", {
      id: "notification",
      header: () => <TableHeader title="Notification" />,
      cell: (info) => (
        <Text
          color={textColor}
          fontSize={{ sm: "10px", md: "10px", lg: "14" }}
          fontWeight="normal"
        >
          {info.getValue()}
          <div>
            {info.row.original?.links?.map((link: any) => {
              return (<span>
                {link.link}
              </span>)
            })}
          </div>
        </Text>
      ),
    }),
    columnHelper.accessor("actions", {
      id: "actions",
      header: () => <TableHeader title="Actions" />,
      cell: (info) => (
        <>
          <Icon
            onClick={() => handleEditNotification(info.row)}
            as={FaEdit}
            color="gray.400"
          />
          <Icon
            onClick={() => handleDeleteNotification(info.row.original)}
            as={MdDeleteOutline}
            color="red"
          />
        </>
      ),
    }),
  ];
  const [data, setData] = React.useState([]);
  const sortedData = [...data].sort(
    (a, b) => Date.parse(b?.createdAt) - Date.parse(a?.createdAt)
  );
  const qtable = {
    data: sortedData,
    columns,

    enableRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  };
  const editNotificationModal = useDisclosure();
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  return (
    <Box
      borderRadius="0px"
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <ProgressModal isOpen={isLoading} onClose={() => setLoading(false)} />
      <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="normal"
          lineHeight="100%"
        ></Text>
        <HStack>
          <Button
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            bg="#0076FD"
            mx={5}
            fontWeight="500"
            color="white"
            borderRadius="0"
            onClick={createNotificationModal.onOpen}
          >
            create
          </Button>
          <Button
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            bg="#0076FD"
            mx={5}
            fontWeight="500"
            color="white"
            borderRadius="0"
            onClick={() => sendNotificationModal.onOpen()}
          >
            General Notifications
          </Button>
          <Button
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            bg="#0076FD"
            mx={5}
            fontWeight="500"
            color="white"
            borderRadius="0"
            onClick={() => sendPushNotificationModal.onOpen()}
          >
            Push Notifications
          </Button>
        </HStack>
      </Flex>
      <Box>
        <SecondaryTable tableOptions={qtable} />
        <SendFilteredNotification
          sendNotificationModal={sendNotificationModal}
          notifications={data}
          regions={regions}
          grades={grades}
          setLoading={setLoading}
          isOpen={isModalOpen}
        />
        <SendPushNotification
          sendPushNotificationModal={sendPushNotificationModal}
          notifications={data}
          regions={regions}
          grades={grades}
          setLoading={setLoading}
          isOpen={isModalOpen}
        />
        <Modal
          isOpen={editNotificationModal.isOpen}
          onClose={editNotificationModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <form onSubmit={handleSubmit1(onEditNotifications)}>
              <ModalHeader>Edit Notification</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Notification Type</FormLabel>
                  <Select
                    className="select-input"
                    placeholder="Select Type"
                    {...form1.register("notificationType", {
                      required: "type is required",
                    })}
                  >
                    {notificationType.map((type: any) => (
                      <option key={type}>{type}</option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Notification</FormLabel>
                  <Textarea
                    id="notification"
                    size="sm"
                    placeholder="Enter your notification"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...form1.register("notification", {
                      required: "This is required",
                      minLength: {
                        value: 0,
                        message: "Minimum length should be 4",
                      },
                    })}
                  />
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
        <AlertBox
          cancelRef={cancelRef}
          alert={alert}
          header="Delete Notifications?"
          alertBody="Are you sure you want to delete this notification"
          onDelete={onDeleteNotification}
        />
      </Box>

      <Modal
        isOpen={createNotificationModal.isOpen}
        onClose={createNotificationModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmitNotification(onCreateNotification)}>
            <ModalHeader>Add Notification</ModalHeader>
            <ModalCloseButton />

            <ModalBody pb={6}>
              <FormControl mt={4}>
                <FormLabel>Notification Type</FormLabel>
                <Select
                  className="select-input"
                  placeholder="Select Type"
                  {...createNotificationForm.register("notificationType", {
                    required: "type is required",
                  })}
                >
                  {notificationType.map((type: any) => (
                    <option key={type}>{type}</option>
                  ))}
                </Select>
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Notification</FormLabel>
                <Textarea
                  id="notification"
                  size="sm"
                  placeholder="Enter your notification"
                  _placeholder={{
                    fontWeight: "400",
                    color: "secondaryGray.600",
                  }}
                  {...createNotificationForm.register("notification", {
                    required: "This is required",
                    minLength: {
                      value: 0,
                      message: "Minimum length should be 4",
                    },
                  })}
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <PrimaryButton type="submit">Save</PrimaryButton>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Box>
  );
}
