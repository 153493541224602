import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  useToast
} from "@chakra-ui/react";
import { ArrowBack } from "@mui/icons-material";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import CustomReactQuill from "components/Atoms/fields/PrimaryRichText";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getexamVersion } from "reduxtoolkit/reducers/examVersionSlice";
import { getGrades } from "reduxtoolkit/reducers/gradeSlice";
import {
  getQuestionById,
  updateQuestion
} from "reduxtoolkit/reducers/questionSlice";
import { getSection } from "reduxtoolkit/reducers/sectionSlice";
import { getSubjects } from "reduxtoolkit/reducers/subjectSlice";
import { getUnits } from "reduxtoolkit/reducers/unitSlice";
import { getYear } from "reduxtoolkit/reducers/yearSlice";
import { rtxModules } from "utils/tableconstants";

const EditQuestion = () => {
  const dispatch = useDispatch();
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [years, setYears] = useState([]);
  const [units, setUnits] = useState([]);
  const [sections, setSections] = useState([]);
  const [examVersions, setExamVersion] = useState([]);
  const gradesData: any = useSelector((state: any) => state.grade);
  const subjectsData = useSelector((state: any) => state.subject);
  const unitsData: any = useSelector((state: any) => state.unit);
  const yearsData: any = useSelector((state: any) => state.year);
  const sectiondata: any = useSelector((state: any) => state.section);
  const examVersiondata: any = useSelector((state: any) => state.examVersion);
  const questionsData: any = useSelector((state: any) => state.question);
  const toast = useToast();
  const history = useHistory();
  const onEditQuestion = (values: any) => {
    const data = { id: id, payload: values };
    dispatch(updateQuestion(data)).then((data: any) => {
      if (data.type == "questions/updateData/fulfilled") {
        toast({
          title: "Success",
          description: "Question updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        history.goBack();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const editQuestionForm = useForm();
  const {
    handleSubmit: handleSubmitQuestion,
    control,
    reset,
    formState: { errors: editError },
  } = editQuestionForm;
  let { id }: any = useParams();
  useEffect(() => {
    dispatch(getSubjects());
    dispatch(getGrades());
    dispatch(getYear());
    dispatch(getUnits());
    dispatch(getSection());

    dispatch(getexamVersion()).then((data: any) => {});
  }, []);

  useEffect(() => {
    if (
      unitsData.isSuccess == true &&
      examVersiondata.isSuccess == true &&
      gradesData.isSuccess == true &&
      sectiondata.isSuccess == true &&
      subjectsData.isSuccess == true &&
      yearsData.isSuccess == true
    ) {
      setUnits(unitsData.data);
      setExamVersion(examVersiondata.data);
      setGrades(gradesData.grade);
      setSections(sectiondata.data);
      setSubjects(subjectsData.data);
      setYears(yearsData.data);
      dispatch(getQuestionById(id)).then((data: any) => {
        let val = data.payload.data.question;
        reset({
          subject: val.subject?.subject,
          grade: val.grade?.grade,
          number: val?.number,
          A: val?.A,
          B: val?.B,
          C: val?.C,
          D: val?.D,
          answer: val?.answer,
          description: val?.description,
          question: val?.question,
          questionType: val?.questionType,
          section: val?.section.section,
          unit: val?.unit.unit,
          year: val?.year?.year,
          metadata: val?.metadata,
        });
      });
    }
  }, [
    examVersiondata.loading,
    unitsData.loading,
    gradesData.loading,
    sectiondata.loading,
    subjectsData.loading,
    yearsData.loading,
  ]);

  const [theme, setTheme] = useState("snow");
  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Button
          onClick={() => {
            history.goBack();
          }}
          fontSize="30px"
        >
          <ArrowBack /> Edit Question
        </Button>
        <Box p={10} bg="#FFFFFF">
          <form onSubmit={handleSubmitQuestion(onEditQuestion)}>
            <Flex gap={5}>
              <FormControl mt={4}>
                <FormLabel>Question</FormLabel>

                <Controller
                  name="question"
                  control={control}
                  rules={{
                    required: "Please enter task description",
                  }}
                  render={({ field }) => (
                    <CustomReactQuill
										field={field}
										themes={theme}
										modules={rtxModules}
										defaultValue="B"
										placeholder="Enter question"
										onChange={(text: string) => {
											field.onChange(text); 
										}}
										/>   
                  )}
                />
                {editError.question && (
                  <Text color="red">
                    {editError.question.message.toString()}
                  </Text>
                )}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Description</FormLabel>

                <Controller
                  name="description"
                  control={control}
                  rules={{
                    required: "Please enter task description",
                  }}
                  render={({ field }) => (
                    <CustomReactQuill
										field={field}
										themes={theme}
										modules={rtxModules}
										defaultValue="B"
										placeholder="Enter desctiption"
										onChange={(text: string) => {
											field.onChange(text); 
										}}
										/>   
                  )}
                />

                {editError.description && (
                  <Text color="red">
                    {editError.description.message.toString()}
                  </Text>
                )}
              </FormControl>
            </Flex>
            <Flex gap={5}>
              <FormControl mt={4}>
                <FormLabel>A </FormLabel>

                <Controller
                  name="A"
                  control={control}
                  rules={{
                    required: "Please enter task description",
                  }}
                  render={({ field }) => (
                    <CustomReactQuill
										field={field}
										themes={theme}
										modules={rtxModules}
										defaultValue="B"
										placeholder="Enter choice A"
										onChange={(text: string) => {
											field.onChange(text); 
										}}
										/>                     )}
                />
                {editError.A && (
                  <Text color="red">{editError.A.message.toString()}</Text>
                )}
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>B </FormLabel>

                <Controller
                  name="B"
                  control={control}
                  rules={{
                    required: "Please enter task description",
                  }}
                  render={({ field }) => (
                    <CustomReactQuill
										field={field}
										themes={theme}
										modules={rtxModules}
										defaultValue="B"
										placeholder="Enter choice B"
										onChange={(text: string) => {
											field.onChange(text); 
										}}
										/>              
                  )}
                />
                {editError.B && (
                  <Text color="red">{editError.B.message.toString()}</Text>
                )}
              </FormControl>
            </Flex>
            <Flex gap={5}>
              <FormControl mt={4}>
                <FormLabel>C </FormLabel>

                <Controller
                  name="C"
                  control={control}
                  rules={{
                    required: "Please enter task description",
                  }}
                  render={({ field }) => (
                    <CustomReactQuill
										field={field}
										themes={theme}
										modules={rtxModules}
										defaultValue="B"
										placeholder="Enter choice C"
										onChange={(text: string) => {
											field.onChange(text); 
										}}
										/>              
                  )}
                />
                {editError.C && (
                  <Text color="red">{editError.C.message.toString()}</Text>
                )}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>D</FormLabel>

                <Controller
                  name="D"
                  control={control}
                  rules={{
                    required: "Please enter task description",
                  }}
                  render={({ field }) => (
                    <CustomReactQuill
										field={field}
										themes={theme}
										modules={rtxModules}
										defaultValue="B"
										placeholder="Enter choice D"
										onChange={(text: string) => {
											field.onChange(text); 
										}}
										/>              
                  )}
                />
                {editError.D && (
                  <Text color="red">{editError.D.message.toString()}</Text>
                )}
              </FormControl>
            </Flex>
            <Box w="30%">
              <FormControl mt={4}>
                <FormLabel>Answer</FormLabel>
                <Select
                  className="select-input"
                  _placeholder={{
                    fontWeight: "400",
                    color: "secondaryGray.600",
                  }}
                  {...editQuestionForm.register("answer", {
                    required: "This is required",
                    minLength: {
                      value: 0,
                      message: "Minimum length should be 4",
                    },
                  })}
                  variant="filled"
                >
                  <option key="a" value="A">
                    A
                  </option>
                  <option key="b" value="B">
                    B
                  </option>
                  <option key="c" value="C">
                    C
                  </option>
                  <option key="d" value="D">
                    D
                  </option>
                </Select>
                {editError.answer && (
                  <Text color="red">{editError.answer.message.toString()}</Text>
                )}
              </FormControl>
            </Box>
            <Flex p={4} gap={4}>
              <FormControl mt={4}>
                <FormLabel>Grade</FormLabel>
                <Select
                  variant="filled"
                  placeholder="select Grade"
                  name="grade"
                  className="select-input"
                  _placeholder={{
                    fontWeight: "400",
                    color: "secondaryGray.600",
                  }}
                  {...editQuestionForm.register("grade", {
                    required: "This is required",
                  })}
                >
                  {grades?.map((item: any, index) => (
                    <option key={item.id} value={item.grade}>
                      {item.grade}
                    </option>
                  ))}
                </Select>
                {editError.grade && (
                  <Text color="red">{editError.grade.message.toString()}</Text>
                )}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Subject</FormLabel>
                <Select
                  variant="filled"
                  className="select-input"
                  placeholder="select subject"
                  {...editQuestionForm.register("subject", {
                    required: "This is required",
                  })}
                >
                  {subjects?.map((item: any, index) => (
                    <option key={item.id} value={item.subject}>
                      {item.subject}
                    </option>
                  ))}
                </Select>
                {editError.subject && (
                  <Text color="red">
                    {editError.subject.message.toString()}
                  </Text>
                )}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Year</FormLabel>
                <Select
                  variant="filled"
                  className="select-input"
                  _placeholder={{
                    fontWeight: "400",
                    color: "secondaryGray.600",
                  }}
                  {...editQuestionForm.register("year", {
                    required: "This is required",
                  })}
                >
                  {years?.map((item: any) => (
                    <option key={item.id} value={item.year}>
                      {item.year}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {editError.year && (
                <Text color="red">{editError.year.message.toString()}</Text>
              )}

              <FormControl mt={4}>
                <FormLabel>Exam Version</FormLabel>
                <Select
                  variant="filled"
                  className="select-input"
                  _placeholder={{
                    fontWeight: "400",
                    color: "secondaryGray.600",
                  }}
                  {...editQuestionForm.register("examVersion", {
                    required: "This is required",
                  })}
                >
                  {examVersions?.map((item: any) => (
                    <option key={item.id} value={item.examVersion}>
                      {item.examVersion}
                    </option>
                  ))}
                </Select>
              </FormControl>
              {editError.examVersion && (
                <Text color="red">
                  {editError.examVersion.message.toString()}
                </Text>
              )}

              <FormControl mt={4}>
                <FormLabel>Unit</FormLabel>
                <Select
                  variant="filled"
                  className="select-input"
                  _placeholder={{
                    fontWeight: "400",
                    color: "secondaryGray.600",
                  }}
                  {...editQuestionForm.register("unit", {
                    required: "This is required",
                  })}
                >
                  {units?.map((item: any) => (
                    <option key={item.id} value={item.unit}>
                      {item.unit}
                    </option>
                  ))}
                </Select>
                {editError.unit && (
                  <Text color="red">{editError.unit.message.toString()}</Text>
                )}
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Section</FormLabel>
                <Select
                  variant="filled"
                  className="select-input"
                  _placeholder={{
                    fontWeight: "400",
                    color: "secondaryGray.600",
                  }}
                  {...editQuestionForm.register("section", {
                    required: "This is required",
                  })}
                >
                  {sections?.map((item: any) => (
                    <option key={item.id} value={item.section}>
                      {item.section}
                    </option>
                  ))}
                </Select>
                {editError.section && (
                  <Text color="red">
                    {editError.section.message.toString()}
                  </Text>
                )}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Number</FormLabel>
                <Input
                  id="number"
                  fontWeight="500"
                  variant="main"
                  h="44px"
                  maxH="44px"
                  type="text"
                  placeholder="number"
                  _placeholder={{
                    fontWeight: "400",
                    color: "secondaryGray.600",
                  }}
                  {...editQuestionForm.register("number", {
                    required: "This is required",
                  })}
                />
                {editError.number && (
                  <Text color="red">{editError.number.message.toString()}</Text>
                )}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>meta data</FormLabel>
                <Controller
                  name="metadata"
                  control={control}
                  render={({ field }) => (
                    <CustomReactQuill
										field={field}
										themes={theme}
										modules={rtxModules}
										defaultValue="B"
										placeholder="Enter metadata"
										onChange={(text: string) => {
											field.onChange(text); 
										}}
										/>              
                  )}
                />
                {editError.metadata && (
                  <Text color="red">
                    {editError.metadata.message.toString()}
                  </Text>
                )}
              </FormControl>

              <FormControl mt={4}>
                <FormLabel>Question Type</FormLabel>
                <Select
                  className="select-input"
                  _placeholder={{
                    fontWeight: "400",
                    color: "secondaryGray.600",
                  }}
                  {...editQuestionForm.register("questionType", {
                    required: "This is required",
                    minLength: {
                      value: 0,
                      message: "Minimum length should be 4",
                    },
                  })}
                >
                  <option key="exam" value="EXAM">
                    Exam
                  </option>
                  <option key="custom" value="CUSTOM">
                    Custom
                  </option>
                </Select>
                {editError.questionType && (
                  <Text color="red">
                    {editError.questionType.message.toString()}
                  </Text>
                )}
              </FormControl>
            </Flex>

            <PrimaryButton type="submit">Save</PrimaryButton>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default EditQuestion;
