import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from "utils/axiosMiddleware";

export const publishChallenge: any = createAsyncThunk(
  "challenge/Publish",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/challange/publish/${arg}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const unPublishChallenge: any = createAsyncThunk(
  "challenge/unPublish",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data = await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/challange/unpublish/${arg}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getChallenge: any = createAsyncThunk(
  "challenge/getData",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/challange/challenges`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateChallenge: any = createAsyncThunk(
  "challenge/updateData",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/challange/update/${arg.id}`,
        headers: { Authorization: `Bearer ${token}` },
        data: arg.payload,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSingleChallengeDay: any = createAsyncThunk(
  "singlechallenge/updateData",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/challange/singlechallenge/${arg.id}`,
        headers: { Authorization: `Bearer ${token}` },
        data: arg.payload,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const createChallengeDay: any = createAsyncThunk(
  "challengeDay/createData",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/challange/challengeday/${arg.id}`,
        headers: { Authorization: `Bearer ${token}` },
        data: arg.payload,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const createSingleChallengeDay: any = createAsyncThunk(
  "challenge/createSingleData",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/challange/singlechallenge/${arg.id}`,
        headers: { Authorization: `Bearer ${token}` },
        data: arg.payload,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateChallengeDay: any = createAsyncThunk(
  "challengeDay/updateData",
  async (arg: any, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/challange/challengeday/${arg.id}`,
        headers: { Authorization: `Bearer ${token}` },
        data: arg.payload,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteChallenge: any = createAsyncThunk(
  "challange/deleteData",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data: any = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/challange/delete/${arg}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSingleChallenge: any = createAsyncThunk(
  "challange/deleteSingleChallenge",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";

    try {
      const data: any = await axios({
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/challange/singlechallenge/${arg}`,
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createChallenge: any = createAsyncThunk(
  "admin/createChallenge",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";
    try {
      const data: any = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/challange/create`,
        headers: { Authorization: `Bearer ${token}` },
        data: arg,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const challengeSlice: any = createSlice({
  name: "challenge",
  initialState: {
    data: [],
    isSuccess: false,
    message: "",
    loading: false,
  },
  reducers: {},
  extraReducers: {
    [getChallenge.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getChallenge.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getChallenge.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [createChallenge.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createChallenge.fulfilled]: (state: any, { payload }) => {
      state.loading = false;

      // state.data.challenges.push(payload.data.challenge);
      state.isSuccess = true;
    },
    [createChallenge.rejected]: (state, { payload }) => {
      state.message = payload.data;
      state.loading = false;
      state.isSuccess = false;
    },
    [updateChallenge.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateChallenge.fulfilled]: (state: any, action) => {
      state.loading = false;

      //  const index = state.data.challenges.findIndex((challenge:any) => challenge.id === action.meta.arg.id);
      //        if (index !== -1) {

      //         const challenges = state.data.challenges[index] = action.payload.data.challenge;
      //         state.data = {...state.data, challenges}
      //    }
      state.isSuccess = true;
    },
    [updateChallenge.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [updateChallengeDay.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateChallengeDay.fulfilled]: (state: any, action) => {
      state.loading = false;

      state.isSuccess = true;
    },
    [updateChallengeDay.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },

    [updateSingleChallengeDay.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateSingleChallengeDay.fulfilled]: (state: any, action) => {
      state.loading = false;

      state.isSuccess = true;
    },
    [updateSingleChallengeDay.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },

    [deleteChallenge.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteChallenge.fulfilled]: (state: any, action) => {
      state.loading = false;

      state.isSuccess = true;
    },
    [deleteChallenge.rejected]: (state: any, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [deleteSingleChallenge.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteSingleChallenge.fulfilled]: (state: any, action) => {
      state.loading = false;

      state.isSuccess = true;
    },
    [deleteSingleChallenge.rejected]: (state: any, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [publishChallenge.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [publishChallenge.fulfilled]: (state: any, { payload }) => {
      state.loading = false;

      state.isSuccess = true;
    },
    [publishChallenge.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [unPublishChallenge.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [unPublishChallenge.fulfilled]: (state: any, { payload }) => {
      state.loading = false;

      state.isSuccess = true;
    },
    [unPublishChallenge.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
  },
});

export default challengeSlice;
