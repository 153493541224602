
import Chart from "react-apexcharts";
// Chakra imports
import {
  Flex,
  Text
} from "@chakra-ui/react";
// Custom components
import Card from "components/Molecules/card/Card";

// Assets
interface ApiResponse {
  userCountByUserAverageScoreRange?: Record<string, number>;
  totalUsersTookExam?: number;
}

interface UserScoreChartProps {
  apiResponse: ApiResponse;
}

const UserScoreChart : any = ({ apiResponse }:any) => {
  
  const userCountData = apiResponse?.userCountByUserAverageScoreRange || {};
  const options: any = {
    chart: {
      type: "bar"
    },
    xaxis: {
      categories:  Object.values(userCountData)
    }
  };
 
  const series: any = [
    {
      name: "User Count",
      data: Object.values(userCountData)
    }
  ];
 

  return (
    <Card alignItems="center" flexDirection="column" w="100%" >
   
      <Flex flexDirection="column" align="start" me="20px">
       
          <Text
            me="auto"
            color="secondaryGray.600"
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="500"
          >
            Users Count By Score
          </Text>
        
       
      </Flex>
   
    
    <Chart
      options={options}
      series={series}
      type="bar"
      height={350}
    />
    </Card>
  );
};

export default UserScoreChart;
