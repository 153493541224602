
const TextWithLinks = ({text}: any) => {
  // Regular expression to identify URLs
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // Split the text by URLs
  const parts = text !== undefined ? text.split(urlRegex) : undefined;


  // Map each part to either plain text or a link
  const elements = parts?.map((part: any, index: any) => {
    if (part.match(urlRegex)) {
      // If part is a URL, render it as a link
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    } else {
      // Otherwise, render it as plain text
      return <span key={index}>{part}</span>;
    }
  });

  return <div>{elements}</div>;
};

export default TextWithLinks;
