import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from 'utils/axiosMiddleware';

export const getRegions:any =  createAsyncThunk(
  "region/getData",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
      
    try {
      const {  data } = await axios.get(`${process.env.REACT_APP_API_URL}/region/region`,{headers: { Authorization: `Bearer ${token}` },
      },);
      
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  })

  export const updateRegion:any =  createAsyncThunk(
    "region/updateData",
    async (arg:any, { rejectWithValue }) => {
      const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
      try {
        const data = await axios({
          method: 'put',
          url: `${process.env.REACT_APP_API_URL}/region/update/${arg.id}`,
          headers: { Authorization: `Bearer ${token}` },
          data: arg.payload,
          
      });
      return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    })

    export const deleteRegion:any =  createAsyncThunk(
      "region/deleteData",
      async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
          
        try {
     
          const data = await axios({
            method: 'delete',
            url: `${process.env.REACT_APP_API_URL}/region/delete/${arg}`,
            headers: { Authorization: `Bearer ${token}` },
        });
        return data;
        } catch (error) {
          return rejectWithValue(error);
        }
      })
      export const createRegion:any =  createAsyncThunk(
        "admin/createRegion",
        async (arg, { rejectWithValue }) => {
          const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';  
          try {   
            const data = await axios({
              method: 'post',
              url: `${process.env.REACT_APP_API_URL}/region/create`,
              headers: { Authorization: `Bearer ${token}` },
              data: arg,
          });
          return data;
          } catch (error) {
            return rejectWithValue(error);
          }
        })

const regionSlice:any = createSlice({
  name: "region",
  initialState: {
    data: {},
    isSuccess: false,
    message: "",
    loading: false,
  },
  reducers: {
       

  },
  extraReducers: {
    [getRegions.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getRegions.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.isSuccess = true;
    },
    [getRegions.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [createRegion.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [createRegion.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      
      state.data.push(payload.data.region);
      state.isSuccess = true;
    },
    [createRegion.rejected]: (state, { payload }) => {
      state.message = payload.data;
      state.loading = false;
      state.isSuccess = false;
    },
    [updateRegion.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateRegion.fulfilled]: (state:any, { payload , meta}) => {
     
     state.loading = false;
     var temp = current(state);
     state.data = temp.data;
     const index = temp.data.findIndex((region:any) => region.id === meta.arg.id);
   

      if (index !== -1) {
         
        state.data[index].region = payload.data.region.region;
      }
      state.isSuccess = true;
    },
    [updateRegion.rejected]: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
    [deleteRegion.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [deleteRegion.fulfilled]: (state:any, { payload }) => {
      state.loading = false;
      
      state.data = state.data.filter((region:any) => region.region !== payload.data.region);
      state.isSuccess = true;
    },
    [deleteRegion.rejected]: (state:any, { payload }) => {
      state.message = payload;
      state.loading = false;
      state.isSuccess = false;
    },
  },
});

export default regionSlice;
