import { Box, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Spinner } from '@chakra-ui/react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ProgressModal = ({ isOpen, onClose}: ModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
     	<ModalOverlay />
						<ModalContent bg="transparent" maxW="100%">
						<Box borderLeftWidth='3px' borderColor='#7F77F1'>
						<ModalBody pb={6}>
						<Flex justify='center'> <Spinner mx='auto'size='lg' /></Flex>
						</ModalBody>
						</Box>
						</ModalContent>
    </Modal>
  );
};

export { ProgressModal };
