import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from 'utils/axiosMiddleware';

export const getSubscriptionPackages: any = createAsyncThunk(
    "payment/getPackages",
    async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';

        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/payment/subscriptionpackage`, {
                headers: { Authorization: `Bearer ${token}` },
            },);

            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    })
    export const getTrialVersionDate: any = createAsyncThunk(
        "payment/getTrialVersionDate",
        async (arg, { rejectWithValue }) => {
            const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
    
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/payment/trialversiondate`, {
                    headers: { Authorization: `Bearer ${token}` },
                },);
    
                return data;
            } catch (error) {
                return rejectWithValue(error);
            }
        })
    
        
        export const updateTrialVersionDate: any = createAsyncThunk(
            "payment/updateTrialversionDate",
            async (arg: any, { rejectWithValue }) => {
                const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        
                try {
                    const data = await axios({
                        method: 'put',
                        url: `${process.env.REACT_APP_API_URL}/payment/trialversiondate/update/${arg.id}`,
                        headers: { Authorization: `Bearer ${token}` },
                        data: arg.payload,
        
                    });
                    return data;
                } catch (error) {
                    return rejectWithValue(error);
                }
            })
export const getAllPayments: any = createAsyncThunk(
    "payment/getpayments",
    async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';

        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/payment/payments`, {
                params: arg,
                headers: { Authorization: `Bearer ${token}` },
            },);

            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    })

export const updateSubscriptionPackage: any = createAsyncThunk(
    "payment/updateData",
    async (arg: any, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';

        try {
            const data = await axios({
                method: 'put',
                url: `${process.env.REACT_APP_API_URL}/payment/subscriptionpackage/update/${arg.id}`,
                headers: { Authorization: `Bearer ${token}` },
                data: arg.payload,

            });
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    })

export const deleteSubscriptionPackage: any = createAsyncThunk(
    "payment/deleteSubscriptionPackage",
    async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';

        try {

            const data = await axios({
                method: 'delete',
                url: `${process.env.REACT_APP_API_URL}/payment/subscriptionpackage/delete/${arg}`,
                headers: { Authorization: `Bearer ${token}` },
            });
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    })
export const createSubscriptionPackage: any = createAsyncThunk(
    "payment/createSubscriptionPackage",
    async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        try {
            const data = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/payment/subscriptionpackage/create`,
                headers: { Authorization: `Bearer ${token}` },
                data: arg,
            });
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    })
export const initializePaymentByChapa: any = createAsyncThunk(
    "payment/initializePaymentByChapa",
    async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        try {
            const data = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/payment/initializePaymentByChapa`,
                headers: { Authorization: `Bearer ${token}` },
                data: arg,
            });
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    })
export const verifyPaymentByChapa: any = createAsyncThunk(
    "payment/verifyPaymentByChapa",
    async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        try {
            const data = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/payment/verifyPaymentByChapa`,
                headers: { Authorization: `Bearer ${token}` },
                data: arg,
            });
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    })
export const verifyPaymentByChapaWebHook: any = createAsyncThunk(
    "payment/verifyPaymentByChapaWebHook",
    async (arg, { rejectWithValue }) => {
        const token = Cookies.get('accessToken') ? Cookies.get('accessToken') : '';
        try {
            const data = await axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/payment/verifyPaymentByChapaWebHook`,
                headers: { Authorization: `Bearer ${token}` },
                data: arg,
            });
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    })


const paymentSlice: any = createSlice({
    name: "payment",
    initialState: {
        data: {},
        isSuccess: false,
        message: "",
        loading: false,
    },
    reducers: {


    },
    extraReducers: {

        [getAllPayments.pending]: (state, { payload }) => {
            state.loading = true;
        },
        [getAllPayments.fulfilled]: (state, { payload }) => {
            state.loading = false;
            // state.data = payload;
            state.isSuccess = true;
        },
        [getAllPayments.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },
        [getSubscriptionPackages.pending]: (state, { payload }) => {
            state.loading = true;
        },
        [getSubscriptionPackages.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.data = payload;
            state.isSuccess = true;
        },
        [getSubscriptionPackages.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },
        [createSubscriptionPackage.pending]: (state, { payload }) => {
            state.loading = true;
        },
        [createSubscriptionPackage.fulfilled]: (state: any, { payload }) => {
            state.loading = false;

            state.data.push(payload.data.subscriptionPlan);
            state.isSuccess = true;
        },
        [createSubscriptionPackage.rejected]: (state, { payload }) => {
            state.message = payload.data;
            state.loading = false;
            state.isSuccess = false;
        },
        [updateSubscriptionPackage.pending]: (state, { payload }) => {
            state.loading = true;
        },
        [updateSubscriptionPackage.fulfilled]: (state: any, { payload, meta }) => {

            state.loading = false;

            const index = state.data.findIndex((subscriptionpackage: any) => subscriptionpackage.id === meta.arg.id);


            if (index !== -1) {

                state.data[index] = payload.data.subscriptionPlan;
            }
            state.isSuccess = true;
        },
        [updateSubscriptionPackage.rejected]: (state, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },
        [deleteSubscriptionPackage.pending]: (state, { payload }) => {
            state.loading = true;
        },
        [deleteSubscriptionPackage.fulfilled]: (state: any, { payload, meta }) => {
            state.loading = false;

            state.data = state.data.filter((subscriptionpackage: any) => subscriptionpackage.id !== meta.arg);
            state.isSuccess = true;
        },
        [deleteSubscriptionPackage.rejected]: (state: any, { payload }) => {
            state.message = payload;
            state.loading = false;
            state.isSuccess = false;
        },
    },
});

export default paymentSlice;
