import { NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/react";

export const NumberInputs=(props:any)=>{
    const {form} = props;
         return( 
                    <NumberInput defaultValue={0} min={0} >
							<NumberInputField  
							 { ...form.register('number', {
								required: 'Number is required',
								
							})}
							/>
							<NumberInputStepper>
								<NumberIncrementStepper />
								<NumberDecrementStepper />
							</NumberInputStepper>
							</NumberInput>)
                            
                }
            