// Chakra imports
import {
  Box,
  Flex,
  Icon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Chart from "react-apexcharts";
// Custom components
import Card from "components/Molecules/card/Card";

// Assets
import { useState } from "react";
import { RiArrowUpSFill } from "react-icons/ri";
import { getCurrentWeek } from "utils/get/generalhelpers";

export default function DailyTraffic(props: any) {
  const {
    selectedYear,
    setSelectedYear,
    handleYearChange,
    handleTraffickFilter,
    setWeekNumber,
    weekNumber,
    series,
    options,
    ...rest
  } = props;
  const [barchartData, setBarChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  const currentYear = new Date().getFullYear();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Card alignItems="center" flexDirection="column" w="100%" {...rest}>
      <Flex justify="space-between" align="start" px="10px" pt="5px" w="100%">
        <Flex flexDirection="column" align="start" me="20px">
          <Flex w="100%">
            <Text
              me="auto"
              color="secondaryGray.600"
              fontSize={{ sm: "10px", md: "10px", lg: "14" }}
              fontWeight="500"
            >
              WeekLy Traffic
            </Text>
          </Flex>
          <Flex align="end">
            <Text
              ms="6px"
              color="secondaryGray.600"
              fontSize={{ sm: "10px", md: "10px", lg: "14" }}
              fontWeight="500"
            >
              Visitors
            </Text>
          </Flex>
        </Flex>
        <Flex align="center">
          <NumberInput
            name="duration"
            onChange={(e) => {
              setWeekNumber(e);
              handleTraffickFilter(e, selectedYear);
            }}
            defaultValue={getCurrentWeek()}
            min={1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Select
            defaultValue={currentYear}
            onChange={(e) => {
              setSelectedYear(e.target.value);
              handleTraffickFilter(weekNumber, e.target.value);
            }}
          >
            {Array.from({ length: 10 }, (_, i) => currentYear - 5 + i).map(
              (year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              )
            )}
          </Select>

          <Icon as={RiArrowUpSFill} color="green.500" />
        </Flex>
      </Flex>
      <Box h="440px" w="100%" mt="auto">
        <Chart options={options} series={series} type="bar" width="100%" />
      </Box>
    </Card>
  );
}
