import { useState } from "react";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";

import { Box, HStack } from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import "react-time-picker/dist/TimePicker.css";

function DateTimePicker({ setTimestampValue }: any) {
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState('');

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    setTimestampValue(getTimestampString());
  };

  const handleTimeChange = (time: any) => {
    setSelectedTime(time);
    setTimestampValue(getTimestampString());
  };
  const getTimestampString = () => {
 
    const combinedDateTime = new Date(selectedDate);
    const [hours, minutes] = selectedTime?.split(":") ?? [];
    combinedDateTime.setHours(parseInt(hours, 10));
    combinedDateTime.setMinutes(parseInt(minutes, 10));
    const timestampString = combinedDateTime.getTime().toString();

    return timestampString;
  };

  return (
    <HStack justify="start">
      <Box w="40%">
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="MMMM d, yyyy"
        />
      </Box>
      <TimePicker
        onChange={handleTimeChange}
        value={selectedTime}
        disableClock={true}
      />
    </HStack>
  );
}

export default DateTimePicker;
