import { DeleteIcon, EditIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Checkbox,
  Divider,
  Flex,
  Switch,
  Text, useDisclosure,
  useToast, VStack
} from "@chakra-ui/react";
import { PrimaryText } from "components/Atoms/Title/Titles";
import AlertBox from "components/Molecules/Alerts/AlertBox";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  deleteQuestion,
  publishQuestion,
  unPublishQuestion
} from "reduxtoolkit/reducers/questionSlice";

const QuestionList = (props: any) => {
  const alert = useDisclosure();
  const cancelRef = React.useRef();
  useEffect(() => {
    if (props.data.length != 0) {
      setQuestion(props.data);
      props?.data?.forEach((item: any, index: any) => {
        checkboxes.push({
          id: index + 1,
          label: `Checkbox ${index + 1}`,
          isChecked: false,
        });
      });

      setCheckboxes(checkboxes);
    } else {
      setQuestion(props.data);
    }
  });

  const [question, setQuestion] = useState([]);
  const history = useHistory();
  const handleEditQuestion = (value: any) => {
    history.push(`/admin/questions/${value}`);
  };
  const [id, setId] = useState();
  const handleDeleteQuestion = (value: any) => {
    setId(value);
    alert.onOpen();
  };

  const onDeleteQuestion = () => {
    dispatch(deleteQuestion(id));
    alert.onClose();
  };

  const [checkboxes, setCheckboxes] = useState([]);
  const [checkedItems, setCheckedItems] = useState<any>([]);
  const [isAllChecked, setIsAllChecked] = useState(false);

  const handleChange = (value: any) => {
    setCheckedItems(
      checkedItems.includes(value)
        ? checkedItems.filter((item: any) => item !== value)
        : [...checkedItems, value]
    );
    setIsAllChecked(checkedItems.length === props.data.length);
    props.setSelectedExam(
      checkedItems.includes(value)
        ? checkedItems.filter((item: any) => item !== value)
        : [...checkedItems, value]
    );
  };
  const handleSelectAll = () => {
    setCheckedItems(isAllChecked ? [] : props.data.map((item: any) => item.id));
    setIsAllChecked(!isAllChecked);
    props.setSelectedExam(props.data.map((item: any) => item.id));
  };
  const toast = useToast();
  const dispatch = useDispatch();
  const handleQuestionPublish = (values: any) => {
    if (values.isPublished) {
      
      dispatch(unPublishQuestion(values.id)).then((data: any) => {
        if (data.type === "questions/unpublish/fulfilled") {
          toast({
            title: "Success",
            description: "Question UnPublished successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: `${data.payload.response.data.message}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      });
    } else {
      dispatch(publishQuestion(values.id)).then((data: any) => {
        if (data.type === "questions/publish/fulfilled") {
          toast({
            title: "Success",
            description: "Question Published successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Error",
            description: `${data.payload.response.data.message}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      });
    }
  };

  return (
    <Box my={10} p={1} bg="white" borderRadius={0}>
      <Checkbox isChecked={isAllChecked} onChange={() => handleSelectAll()}>
        Select All
      </Checkbox>
      {question.map((record: any) => {
        return (
          <Box>
            <Flex key={record.id}>
              <Box w="80%" key={record.id}>
                <Box>
                  <Text fontSize="13px" color="#706A6A">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: record?.metadata?.replace(/(<? *script)/gi, ""),
                      }}
                    />
                  </Text>
                  <Flex>
                    <Checkbox
                      name={record.id}
                      isChecked={checkedItems.includes(record.id)}
                      onChange={() => handleChange(record.id)}
                      fontSize="13px"
                    />
                    <Text fontSize="13px" color="#706A6A">
                      {" "}
                      {record.number + ".)"}{" "}
                    </Text>
                    <Text fontSize="13px" color="#706A6A">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: record?.question?.replace(
                            /(<? *script)/gi,
                            ""
                          ),
                        }}
                      ></span>
                    </Text>
                  </Flex>
                  <Box>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: record?.description?.replace(
                          /(<? *script)/gi,
                          ""
                        ),
                      }}
                    ></span>
                  </Box>

                  <Flex>
                    <Box>
                      <Flex>
                        <Checkbox
                          fontSize="13px"
                          disabled
                          checked={record?.answer === "A"}
                        >
                          A.
                        </Checkbox>
                        <Text
                          fontSize="13px"
                          style={{whiteSpace: 'pre-wrap'}}
                          dangerouslySetInnerHTML={{
                            __html: record?.A?.replace(/(<? *script)/gi, ""),
                          }}
                        ></Text>
                      </Flex>
                      <Flex>
                        <Checkbox disabled checked={record?.answer === "B"}>
                          B.
                        </Checkbox>
                        <Text
                          fontSize="13px"
                          style={{whiteSpace: 'pre-wrap'}}
                          dangerouslySetInnerHTML={{
                            __html: record?.B?.replace(/(<? *script)/gi, ""),
                          }}
                        ></Text>
                      </Flex>
                      <Flex>
                        <Checkbox
                          fontSize="13px"
                          disabled
                          
                          checked={record?.answer === "C"}
                        >
                          C.
                        </Checkbox>
                       
                        <Text
                          fontSize="13px"
                          style={{whiteSpace: 'pre-wrap'}}
                          dangerouslySetInnerHTML={{
                            __html: record?.C?.replace(/(<? *script)/gi, ""),
                          }}  
                        ></Text>
                      </Flex>
                      <Flex>
                        <Checkbox
                          fontSize="13px"
                          disabled
                          checked={record?.answer === "D"}
                        >
                          D.
                        </Checkbox>
                        <Text
                          fontSize="13px"
                          style={{whiteSpace: 'pre-wrap'}}
                          dangerouslySetInnerHTML={{
                            __html: record?.D?.replace(/(<? *script)/gi, ""),
                          }}
                        ></Text>
                      </Flex>
                    </Box>
                  </Flex>
                </Box>
              </Box>
              <Box></Box>
              {props?.actionsEnabled ? (
                <VStack>
                  <Flex gap={4} justify="space-evenly" w="10%">
                    <ViewIcon w={4} h={4} color="#1890FF" />
                    <EditIcon
                      onClick={() => handleEditQuestion(record.id)}
                      w={4}
                      h={4}
                      color="#1890FF"
                    />
                    <DeleteIcon
                      w={4}
                      h={4}
                      color="red"
                      onClick={() => handleDeleteQuestion(record.id)}
                    />
                  </Flex>
                  <Box h={50}></Box>
                  <Flex gap={1} alignContent="end">
                    <VStack>
                      <Text fontSize="10px" color="#706A6A">
                        {" "}
                        Unit
                      </Text>
                      <Badge
                        fontSize="10px"
                        color="#706A6A"
                        borderRadius="0px"
                        border="solid 1px gray"
                      >
                        {record?.unit?.unit}
                      </Badge>
                    </VStack>
                    <VStack>
                      <Text fontSize="10px" color="#706A6A">
                        Section
                      </Text>
                      <Badge
                        fontSize="10px"
                        color="#706A6A"
                        borderRadius="0px"
                        border="solid 1px gray"
                      >
                        {record?.section?.section}
                      </Badge>
                    </VStack>
                  </Flex>
                  <Switch
                    m="auto"
                    size="md"
                    onChange={() => handleQuestionPublish(record)}
                    isChecked={record.isPublished}
                  />
                </VStack>
              ) : (
                <></>
              )}
            </Flex>
            <Divider
              borderWidth="1px"
              borderColor="#D9D9D9"
              display="block"
              my={10}
            />
          </Box>
        );
      })}
      {question.length === 0 ? (
        <PrimaryText mx="auto">No Questions </PrimaryText>
      ) : (
        <></>
      )}
      <AlertBox
        cancelRef={cancelRef}
        alert={alert}
        header="Delete Question?"
        alertBody="Are you sure you want to delete this Question"
        onDelete={onDeleteQuestion}
      />
    </Box>
  );
};
export default QuestionList;
