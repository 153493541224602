import { FormControl, FormLabel, Input, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

// Define default error message and styles

interface TextInputProps {
  id: any;
  label: string;
  type: string;
  validation: any;
  control: any;
  errorMessage: any;
 
}
const TextInput: React.FC<TextInputProps> = ({
  id,
  label,
  type,
  validation,
  control,
  
}) => {
  
  const { formState: { errors } } = useForm();
  const defaultErrorMessage = 'This field is required.';
  const errorColor = useColorModeValue('red.500', 'red.200');
  const isTouched = !!errors[id]; 
  const hasError = isTouched && !!errors[id]?.message;

  const inputProps = {
    id,
    
    isInvalid: hasError,
    errorBorderColor: errorColor,
  };

 

  return (
    <FormControl id={id}>
      <FormLabel>{label}</FormLabel>
       
      <Controller
        defaultValue=""
        render={({ field}) => (
          <Input 
            type={type} 
            autoComplete="off" {...inputProps} ref={field.ref} 
            value={field.value}
            onChange={(value) => field.onChange(value)} />
        )}
        name={id}
        control={control}
        rules={validation}
      />
     
    </FormControl>
  );
};

export default TextInput;
