import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Tab,
  TabList,
  TabPanel,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from "@tanstack/react-table";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import TableContent from "components/Atoms/Label/TableCell";
import TableHeader from "components/Atoms/Label/TableHeader";
import AlertBox from "components/Molecules/Alerts/AlertBox";
import MainTab from "components/Molecules/Tabs/MainTab";
import { Controller, useForm } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
// Custom components
import EditStudyForm from "components/Templates/studies/EditStudyForm";

import { usePagination } from "@ajna/pagination";
import { PrimaryLabel } from "components/Atoms/Label/Labels";
import TruncatedText from "components/Atoms/Label/trucatedText";
import PrimaryTable from "components/Molecules/Tables/PrimaryTable";
import * as React from "react";
import { useEffect, useState } from "react";
import { MdDelete, MdRemoveRedEye } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getGrades } from "reduxtoolkit/reducers/gradeSlice";
import { getSection } from "reduxtoolkit/reducers/sectionSlice";
import {
  createStudy,
  deleteStudy,
  getStudies,
  publishStudy,
  unPublishStudy,
  updateStudy
} from "reduxtoolkit/reducers/studiesSlice";
import { getSubjectsManagement } from "reduxtoolkit/reducers/subjectManagementSlice";
import { getUnits } from "reduxtoolkit/reducers/unitSlice";

import CustomReactQuill from "components/Atoms/fields/PrimaryRichText";
import { ProgressModal } from "components/Molecules/Modals/ProgressModal";
import { MainPagination } from "components/Molecules/Pagination";
import { gradeType } from "types/examTypes";
import { rtxModules } from "utils/tableconstants";

// Assets

type RowObj = {
  title: any;
  objective: string;
  grade: gradeType;
  subject: any;
  section: any;
  unit: any;
  isPublished: boolean;
  actions: any;
};

const columnHelper = createColumnHelper<RowObj>();

export default function ComplexTable() {
  const [grades, setGrades] = useState([]);

  const handleForwardPagination = (current: any) => {
    if (current < pagesCount) setCurrentPage(currentPage + 1);
  };
  const [units, setUnits] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [sections, setSections] = useState([]);
  const [studies, setStudies] = useState([]);
  const [examVersions, setExamVersion] = useState([]);
  const [data, setData] = React.useState(() => []);
  const [id, setId] = useState<any>("");
  const dispatch = useDispatch();
  const study = useSelector((state: any) => state.study);
  const [theme, setTheme] = useState("snow");
  useEffect(() => {
    if (study.loading === true) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [study.loading]);
  useEffect(() => {
    if (study.isSuccess === true && subjectData.isSuccess === true) {
      setData(study.data.studies);
    }
  }, [study.loading]);

  const handleStudyPublish = (values: any) => {
    if (values.isPublished) {
      dispatch(unPublishStudy(values.id)).then((data: any) => {
        if (data.type === "study/unPublish/fulfilled") {
          toast({
            title: "Success",
            description: "Study UnPublished successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setData(study.data.studies);
        } else {
          toast({
            title: "Error",
            description: `${data.payload.response.data.message}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      });
    } else {
      dispatch(publishStudy(values.id)).then((data: any) => {
        if (data.type === "study/Publish/fulfilled") {
          toast({
            title: "Success",
            description: "Study Published successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setData(study.data.studies);
        } else {
          toast({
            title: "Error",
            description: `${data.payload.response.data.message}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      });
    }
  };
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const deleteIconColor = useColorModeValue("red.600", "white");
  const editStudyModal = useDisclosure();
  const alert = useDisclosure();
  const textColorSecondary = "gray.400";
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const editStudyForm = useForm();
  const { reset } = editStudyForm;

  const handleEditStudy = (value: any) => {
    const data = value.original;
    setId(data.id);
    reset({
      title: data.title,
      objective: data.objective,
      unit: data.unit.unit,
      subject: data.subject.subject,
      grade: data.grade.grade,
      section: data.section?.section,
    });
    editStudyModal.onOpen();
  };
  const handleDeleteStudy = (value: any) => {
    setId(value.id);
    alert.onOpen();
  };

  const history = useHistory();
  const handleViewStudy = (data: any) => {
    history.push(`/admin/studies/${data.id}`);
  };
  const columns = [
    columnHelper.accessor("subject", {
      id: "subject",
      header: () => <TableHeader title="SUBJECT" />,
      cell: (info) => (
        <TableContent title={info.row.original?.subject?.subject} />
      ),
    }),
    columnHelper.accessor("section", {
      id: "section",
      header: () => <TableHeader title="SECTIONS" />,
      cell: (info) => (
        <TableContent title={info.row.original?.section?.section} />
      ),
    }),
    columnHelper.accessor("unit", {
      id: "unit",
      header: () => <TableHeader title="UNIT" />,
      cell: (info) => <TableContent title={info.row.original?.unit?.unit} />,
    }),
    columnHelper.accessor("title", {
      id: "title",
      header: () => <TableHeader title="TITLE" />,
      cell: (info) => (
        <Flex align="center">
          <Text
            color={textColor}
            fontSize={{ sm: "10px", md: "10px", lg: "14" }}
            fontWeight="normal"
          >
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),

    columnHelper.accessor("objective", {
      id: "objective",
      header: () => <TableHeader title="OBJECTIVE" />,
      cell: (info) => (
        <>
          <TruncatedText
            text={info.getValue().replace(/<[^>]*>/g, "")}
            maxLength={25}
          />
        </>
      ),
    }),

    columnHelper.accessor("isPublished", {
      id: "isPublished",
      header: () => <TableHeader textColor={textColor} title="IsPublished" />,
      cell: (info) => (
        <>
          <Switch
            size="md"
            onChange={() => handleStudyPublish(info.row.original)}
            isChecked={info.row.original.isPublished}
          />
        </>
      ),
    }),
    columnHelper.accessor("actions", {
      id: "actions",
      header: () => <TableHeader title="Actions" />,
      cell: (info) => (
        <>
          <Flex justify="space-evenly">
            <Icon
              color={textColorSecondary}
              as={FaEdit}
              onClick={() => handleEditStudy(info.row)}
            />
            <Icon
              color={textColorSecondary}
              as={MdRemoveRedEye}
              onClick={() => handleViewStudy(info.row.original)}
            />
            <Icon
              color={deleteIconColor}
              onClick={() => handleDeleteStudy(info.row.original)}
              as={MdDelete}
            />
          </Flex>
        </>
      ),
    }),
  ];
  const toast = useToast();
  const onEditStudy = (values: any) => {
    const data = {
      id: id,
      payload: {
        title: values.title,
        objective: values.objective,
        subject: values.subject,
        section: values.section,
        grade: selectedGrade,
        unit: values.unit,
      },
    };

    dispatch(updateStudy(data)).then((data: any) => {
      if (data.type === "studies/updateData/fulfilled") {
        toast({
          title: "Success",
          description: "study Updated SuccessFully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        reset();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });

    editStudyModal.onClose();
  };
  const onDeleteStudy = () => {
    dispatch(deleteStudy(id)).then((data: any) => {
      if (data.type === "study/deleteData/fulfilled") {
        toast({
          title: "Success",
          description: "Study Deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
    alert.onClose();
  };
  const table = useReactTable({
    data: data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });
  const cancelRef = React.useRef();
  const [search, setSearch] = useState(true);
  const handleSearchStudies = () => {
    setSearch(!search);
  };
  const [total, setTotal] = useState(0);
  const [subjectParam, setSubjectParam] = useState<any>();
  const [sectionParam, setSectionParam] = useState<any>();
  const [unitParam, setUnitParam] = useState<any>();
  const createStudyModal = useDisclosure();
  const createStudyForm = useForm();
  const oncreateStudy = (values: any) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append(
      "objective",
      values.objective.length != 0 ? values.objective : "<p><br></p>"
    );
    formData.append("subject", values.subject);
    formData.append("grade", selectedGrade);
    formData.append("unit", values.unit);
    formData.append("section", values.section);

    formData.append("selecteQuestions", "");
    formData.append("videoLinks", "");
    formData.append("mobileVideoLinks", "");

    dispatch(createStudy(formData)).then((data: any) => {
      if (data.type === "study/createStudy/fulfilled") {
        toast({
          title: "Success",
          description: "Study Created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        resetStudy();
        createStudyModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const [selectedGrade, setSelectedGrade] = useState();
  const outerLimit = 2;
  const innerLimit = 2;
  const {
    pages,
    pagesCount,

    currentPage,
    setCurrentPage,
    setIsDisabled,
    isDisabled,
    pageSize,
    setPageSize,
  } = usePagination({
    total: total,
    limits: {
      outer: outerLimit,
      inner: innerLimit,
    },
    initialState: {
      pageSize: 10,
      isDisabled: false,
      currentPage: 1,
    },
  });
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  useEffect(() => {
    let params = {
      subject: subjectParam,
      unit: unitParam,
      grade: selectedGrade,
      section: sectionParam,
      page: currentPage,
      limit: pageSize,
    };
    dispatch(getStudies(params)).then((data: any) => {
      setData(data.payload.studies);

      let tabSubjects = data.payload.studies.filter(
        (study: any) => study.grade.grade === selectedGrade
      );
      setTotal(data.payload.totalCount);
      setTabData(tabSubjects);
    });
  }, [
    selectedGrade,
    search,
    subjectParam,
    unitParam,
    sectionParam,
    currentPage,
    pageSize,
  ]);
  const subjectData: any = useSelector((state: any) => state.subjectManagement);
  const examVersionData: any = useSelector((state: any) => state.examVersion);
  const unitsData: any = useSelector((state: any) => state.unit);
  const [isOpen, setIsOpen] = useState(true);
  const sectionsData: any = useSelector((state: any) => state.section);
  const studiesData: any = useSelector((state: any) => state.study);
  const gradesData: any = useSelector((state: any) => state.grade);

  useEffect(() => {
    dispatch(getSubjectsManagement());
    dispatch(getGrades()).then((data: any) => {
      setSelectedGrade(data.payload[0].grade);
    });

    dispatch(getUnits());
    dispatch(getSection());
  }, []);

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
  };
  const handlePageSizeChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const pageSize = Number(event.target.value);

    setPageSize(pageSize);
  };
  const handleBackPagination = (current: any) => {
    if (current > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    setUnits(unitsData.data);
    setExamVersion(examVersionData.data);
    setGrades(gradesData.grade);
    setSections(sectionsData.data);

    setStudies(studiesData.data.studies);
    if (studiesData.isSuccess === true && subjectData.isSuccess === true) {
      setSubjects(
        subjectData.data.subjects.filter(
          (subject: any) => subject.grade.grade === selectedGrade
        )
      );
      let tabSubjects = studiesData?.data?.studies.filter(
        (study: any) => study.grade.grade === selectedGrade
      );
      setTabData(tabSubjects);
    }
  }, [
    examVersionData.loading,
    unitsData.loading,
    gradesData.loading,
    sectionsData.loading,
    subjectData.loading,

    studiesData.loading,
  ]);

  const [tabData, setTabData] = React.useState(() => []);
  const {
    handleSubmit: handleSubmitStudy,
    control,
    reset: resetStudy,
    formState: { errors: studyError },
  } = createStudyForm;
  const TabHeaders = () => {
    return (
      <TabList justifyContent="space-evenly">
        {grades.map((grade) => (
          <Tab
            _selected={{ color: "#000000" }}
            fontWeight="600"
            fontSize="16px"
            color="#BBBBBB"
            onClick={() => setSelectedGrade(grade.grade)}
            key={grade.grade}
          >
            {grade.grade}
          </Tab>
        ))}
      </TabList>
    );
  };
  const TabFilters = () => {
    return (
      <Box
        borderRadius="0px"
        py="10px"
        bg="white"
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
        m={5}
      >
        <ProgressModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
        <PrimaryLabel py="5px"> Filters</PrimaryLabel>
        <Divider borderWidth="1px" borderColor="#D9D9D9" my={2} />

        <Flex flex="row" mx={10}>
          <HStack spacing={3} w="70%">
            <Box>
              <Select
                className="select-input"
                placeholder="select subject"
                value={subjectParam}
                _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
                onChange={(e: any) => setSubjectParam(e.target.value)}
                variant="filled"
              >
                {subjects?.map((item: any) => (
                  <option key={item.id} value={item.subject.subject}>
                    {item.subject.subject}
                  </option>
                ))}
              </Select>
            </Box>
            <Box>
              <Select
                fontSize={{ sm: "9px", md: "10px", lg: "14" }}
                size="sm"
                value={unitParam}
                borderRadius="0px"
                className="select-input"
                placeholder="select unit"
                _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
                onChange={(e: any) => setUnitParam(e.target.value)}
                variant="filled"
              >
                {units?.map((item: any) => (
                  <option key={item.id} value={item.subject}>
                    {item.unit}
                  </option>
                ))}
              </Select>
            </Box>
            <Box>
              <Select
                fontSize={{ sm: "9px", md: "10px", lg: "14" }}
                size="sm"
                borderRadius="0px"
                value={sectionParam}
                className="select-input"
                placeholder="select section"
                _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
                onChange={(e: any) => setSectionParam(e.target.value)}
                variant="filled"
              >
                {sections?.map((item: any) => (
                  <option key={item.id} value={item.subject}>
                    {item.section}
                  </option>
                ))}
              </Select>
            </Box>
            <Box>
              <PrimaryButton
                onClick={() => {
                  setSubjectParam(null);
                  setSectionParam(null);
                  setUnitParam(null);
                }}
              >
                Reset
              </PrimaryButton>
            </Box>
            <Box>
              <PrimaryLabel>Total : {total}</PrimaryLabel>
            </Box>
          </HStack>
          <HStack w="30%" justify="end">
            <Button
              fontSize={{ sm: "10px", md: "10px", lg: "14" }}
              variant="outline"
              fontWeight="500"
              color="black"
              borderRadius="0"
              onClick={createStudyModal.onOpen}
            >
              <AddIcon w={4} h={4} px="1px" color="black" />
              create Study
            </Button>
          </HStack>
        </Flex>
      </Box>
    );
  };
  return (
    <Box
      borderRadius="0px"
      flexDirection="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <MainTab headers={<TabHeaders />} filters={<TabFilters />}>
        {grades.map((grade) => (
          <TabPanel key={grade.id}>
            <PrimaryTable data={tabData} columns={columns} />
            <MainPagination
              pagesCount={pagesCount}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              handleBackPagination={handleBackPagination}
              handleForwardPagination={handleForwardPagination}
              pages={pages}
              handlePageSizeChange={handlePageSizeChange}
            />
          </TabPanel>
        ))}
      </MainTab>

      <Box>
        <EditStudyForm
          {...{
            editStudyModal,
            onEditStudy,
            editStudyForm,
            selectedGrade,
            subjects,
          }}
        />
        <AlertBox
          cancelRef={cancelRef}
          alert={alert}
          header="Delete Study"
          alertBody="Are you sure you want to delete this Study?"
          onDelete={onDeleteStudy}
        />
      </Box>
      <Modal
        isOpen={createStudyModal.isOpen}
        onClose={createStudyModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <Box borderLeftWidth="3px" borderColor="#7F77F1">
            <form onSubmit={handleSubmitStudy(oncreateStudy)}>
              <ModalHeader>Creating Study</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <FormControl mt={4}>
                  <FormLabel>Study Title</FormLabel>
                  <Input
                    borderRadius={0}
                    id="title"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    placeholder="Study Title"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createStudyForm.register("title", {
                      required: "This is required",
                    })}
                  />
                  {studyError.title && (
                    <Text color="red">
                      {studyError.title.message.toString()}
                    </Text>
                  )}
                </FormControl>

                <FormControl>
                  <FormLabel>Objective</FormLabel>
                  <Controller
                    name="objective"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <CustomReactQuill
                      field={field}
                      themes={theme}
                      modules={rtxModules}
                      defaultValue="B"
                      placeholder="Enter objective"
                      onChange={(text: string) => {
                        field.onChange(text); 
                      }}
                    />
                    )}
                  />

                  {studyError.objective && (
                    <Text color="red">
                      {studyError.objective.message.toString()}
                    </Text>
                  )}
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel> Subject</FormLabel>
                  <Select
                    borderRadius={0}
                    className="select-input"
                    placeholder="select subject"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createStudyForm.register("subject", {
                      required: "This is required",
                      minLength: {
                        value: 0,
                        message: "Minimum length should be 4",
                      },
                    })}
                    variant="unstyled"
                  >
                    {subjects?.map((item: any) => (
                      <option key={item.id} value={item.subject.subject}>
                        {item.subject.subject}
                      </option>
                    ))}
                  </Select>
                  {studyError.subject && (
                    <Text color="red">
                      {studyError.subject.message.toString()}
                    </Text>
                  )}
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel> Unit</FormLabel>
                  <Select
                    borderRadius={0}
                    className="select-input"
                    placeholder="select unit"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createStudyForm.register("unit", {
                      required: "Unit is required",
                      minLength: {
                        value: 0,
                        message: "Minimum length should be 4",
                      },
                    })}
                    variant="unstyled"
                  >
                    {units?.map((item: any) => (
                      <option key={item.id} value={item.subject}>
                        {item.unit}
                      </option>
                    ))}
                  </Select>
                  {studyError.unit && (
                    <Text color="red">
                      {studyError.unit.message.toString()}
                    </Text>
                  )}
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Section</FormLabel>
                  <Select
                    borderRadius={0}
                    className="select-input"
                    placeholder="select section"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...createStudyForm.register("section", {
                      required: "Section is required",
                      minLength: {
                        value: 0,
                        message: "Minimum length should be 4",
                      },
                    })}
                    variant="unstyled"
                  >
                    {sections?.map((item: any) => (
                      <option key={item.id} value={item.subject}>
                        {item.section}
                      </option>
                    ))}
                  </Select>
                  {studyError.section && (
                    <Text color="red">
                      {studyError.section.message.toString()}
                    </Text>
                  )}
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
}
