import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { FaEdit } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
// Custom components
import { createColumnHelper } from "@tanstack/react-table";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import { PrimaryLabel } from "components/Atoms/Label/Labels";
import TableContent from "components/Atoms/Label/TableCell";
import TableHeader from "components/Atoms/Label/TableHeader";
import { PrimaryText } from "components/Atoms/Title/Titles";
import AlertBox from "components/Molecules/Alerts/AlertBox";
import { ProgressModal } from "components/Molecules/Modals/ProgressModal";
import { SearchBar } from "components/Molecules/navbar/searchBar/SearchBar";
import PrimaryTable from "components/Molecules/Tables/PrimaryTable";
import MainTab from "components/Molecules/Tabs/MainTab";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  createChallenge,
  createChallengeDay,
  deleteChallenge,
  deleteSingleChallenge,
  getChallenge,
  publishChallenge,
  unPublishChallenge,
  updateChallenge,
  updateChallengeDay
} from "reduxtoolkit/reducers/challengeSlice";
import { getGrades } from "reduxtoolkit/reducers/gradeSlice";
import { getSection } from "reduxtoolkit/reducers/sectionSlice";
import { getStudies } from "reduxtoolkit/reducers/studiesSlice";
import { getSubjectsManagement } from "reduxtoolkit/reducers/subjectManagementSlice";
import { getUnits } from "reduxtoolkit/reducers/unitSlice";
import CreateSingleChallenge from "./createSingleChallengeForm";
import EditSingleChallenge from "./editSingleChallengeForm";

export default function Overview() {
  const [reload, setReload] = useState(false);
  const [grades, setGrades] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedTab, setSelectedTab] = useState([]);
  const addChallengesModal = useDisclosure();
  const editChallengesModal = useDisclosure();
  const dispatch = useDispatch();
  const editChallengeForm = useForm();
  const alert = useDisclosure();
  const toast = useToast();
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [updated, setUpdated] = useState(false);
  const [id, setId] = useState("");
  const [sections, setSections] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [studies, setStudies] = useState([]);
  const [weeks, setWeeks] = useState(1);

  const [units, setUnits] = useState([]);
  const challenge = useSelector((state: any) => state.challenge);
  const {
    handleSubmit: handleSubmitEditChallenges,
    reset: resetChallenge,
    control: controlEdit,
    formState: { errors: Editerrors },
  } = editChallengeForm;
  const handleCreateChallenge = () => {
    //history.push('challengephase/create')
    addChallengesModal.onOpen();
  };
  type FormData = {
    tab1SelectSubject: string;
    tab1SelectSection: string;
    tab1SelectUnit: string;
    tab2Select: string;
  };

  const [weekEnabled, setWeekEnabled] = useState(false);

  useEffect(() => {
    dispatch(getGrades()).then((data: any) => {
      setSelectedGrade(data.payload[0].grade);
      setGrades(data.payload);
    });
    setIsOpen(true);
    dispatch(getChallenge()).then(() => {
      setIsOpen(false);
    });
    const param = {
      limit: 800,
    };
    dispatch(getStudies(param)).then((data: any) => {
      setStudies(data.payload.studies);
    });

    dispatch(getSection()).then((data: any) => {
      setSections(data.payload);
    });
    dispatch(getSubjectsManagement()).then((data: any) => {
      setSubjects(data.payload.subjects);
    });
    dispatch(getUnits()).then((data: any) => {
      setUnits(data.payload);
    });
  }, []);

  useEffect(() => {
    if (challenge.isSuccess === true && reload === true) {
      setIsOpen(true);

      dispatch(getChallenge()).then((data: any) => {
        var chll = [...data.payload.challenges];

        for (let i = 0; i < chll.length; i++) {
          var temp = { ...chll[i] };

          temp.week = Math.ceil(chll[i].challengeDay.length / 7);

          for (let j = 0; j < chll[i].challengeDay.length; j++) {
            var std = { ...chll[i].challengeDay };
          }
          chll[i] = temp;
        }
        setSelectedTab(
          chll.filter(
            (challenge: any) => challenge?.grade?.grade === selectedGrade
          )
        );
      });
      setTimeout(function () {
        setIsOpen(false);
      }, 2000);
      setReload(false);
    }
  }, [reload]);
  useEffect(() => {
    if (challenge.isSuccess === true) {
      var chll: any = [...challenge.data.challenges];
      for (let i = 0; i < chll.length; i++) {
        var temp = { ...chll[i] };

        temp.week = Math.ceil(chll[i].challengeDay.length / 7);

        chll[i] = temp;
      }
      setSelectedTab(
        chll.filter(
          (challenge: any) => challenge?.grade?.grade === selectedGrade
        )
      );
    }
  }, [challenge.loading, selectedGrade]);
  const onDeleteChallenge = () => {
    setIsOpen(true);
    dispatch(deleteChallenge(id)).then((data: any) => {
      if (data.type === "challange/deleteData/fulfilled") {
        toast({
          title: "Success",
          description: "Challenge deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setIsOpen(false);
        setReload(true);
        alert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      }
    });
  };
  const { reset, register, handleSubmit } = useForm({
    defaultValues: {
      challengeTitle: "",
      grade: selectedGrade,
      challenges: [
        {
          day: 1,
          rest: true,
          challenge: [],
        },
      ],
    },
  });

  const onEditChallenge = (values: any) => {
    const data = {
      id: id,
      payload: {
        challengeTitle: values.challengeTitle,
        grade: selectedGrade,
      },
    };
    dispatch(updateChallenge(data)).then((data: any) => {
      if (data.type === "challenge/updateData/fulfilled") {
        toast({
          title: "Success",
          description: "Challenge updated successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setReload(true);
        editChallengesModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        editChallengesModal.onClose();
      }
    });
  };

  const {
    reset: resetsingle,
    register: registerSingle,
    handleSubmit: handleSubmitEditSingle,
  } = useForm({});

  const {
    reset: resetChallengeday,
    register: registerChallengeDay,
    handleSubmit: handleSubmitChallengeDay,
  } = useForm({
    defaultValues: {
      day: 1,
      rest: true,
    },
  });

  const handleEditChallenge = (value: any) => {
    setId(value.id);

    const data = value;
    reset({
      challengeTitle: value.title,
      grade: value.grade.grade,
    });

    editChallengesModal.onOpen();
  };

  const onCreateChallenge = (values: any) => {
    values.grade = selectedGrade;
    const data = values;
    dispatch(createChallenge(data)).then((data: any) => {
      if (data.type === "admin/createChallenge/fulfilled") {
        toast({
          title: "Success",
          description: "Challenge Created successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setReload(true);
        reset();
        addChallengesModal.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        addChallengesModal.onClose();
      }
    });
  };
  type RowObj = {
    id: any;
    subject: any;
    section: any;
    unit: any;
    actions: any;
  };

  const handleDeleteChallenge = (value: any) => {
    setId(value);

    alert.onOpen();
  };

  const onDeleteSingleChallenge = () => {
    dispatch(deleteSingleChallenge(id)).then((data: any) => {
      if (data.type === "challange/deleteSingleChallenge/fulfilled") {
        toast({
          title: "Success",
          description: "Challenge deleted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setReload(true);
        singleAlert.onClose();
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        alert.onClose();
      }
    });
  };
  const handleRestDay = (values: any) => {
    if (values.rest) {
      const arg = {
        id: values.id,
        payload: {
          rest: false,
          day: values.day,
        },
      };

      dispatch(updateChallengeDay(arg)).then((data: any) => {
        if (data.type === "challengeDay/updateData/fulfilled") {
          toast({
            title: "Success",
            description: "Challenge Rest Disabled successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setReload(true);
        } else {
          toast({
            title: "Error",
            description: `${data.payload.response.data.message}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      });
      setUpdated(!updated);
    } else {
      const arg = {
        id: values.id,
        payload: {
          rest: true,
          day: values.day,
        },
      };

      dispatch(updateChallengeDay(arg)).then((data: any) => {
        if (data.type === "challengeDay/updateData/fulfilled") {
          toast({
            title: "Success",
            description: "Challnege Rest Enabled successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setReload(true);
        } else {
          toast({
            title: "Error",
            description: `${data.payload.response.data.message}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      });
      setUpdated(!updated);
    }
  };

  const handleChallengePublish = (values: any) => {
    if (values.isPublished) {
      dispatch(unPublishChallenge(values.id)).then((data: any) => {
        if (data.type === "challenge/unPublish/fulfilled") {
          toast({
            title: "Success",
            description: "Challenge UnPublished successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setReload(true);
        } else {
          toast({
            title: "Error",
            description: `${data.payload.response.data.message}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      });
      setUpdated(!updated);
    } else {
      dispatch(publishChallenge(values.id)).then((data: any) => {
        if (data.type === "challenge/Publish/fulfilled") {
          toast({
            title: "Success",
            description: "Challnege Published successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setReload(true);
        } else {
          toast({
            title: "Error",
            description: `${data.payload.response.data.message}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      });
      setUpdated(!updated);
    }
  };
  const TabFilters = () => {
    return (
      <Box
        borderRadius="0px"
        py="10px"
        bg="white"
        flexDirection="column"
        w="100%"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <PrimaryLabel py="5px"> Filters</PrimaryLabel>
        <Divider borderWidth="1px" borderColor="#D9D9D9" my={2} />
        <Flex justify="space-between" flex="row">
          <HStack w="70%" mx={5} spacing="24px">
            <SearchBar
              placeholder="Search Challenges"
              borderRadius="30px"
              handle={() => { }}
            />
            <Button
              fontSize={{ sm: "10px", md: "10px", lg: "14" }}
              variant="outline"
              mx={5}
              bg="#0076FD"
              fontWeight="500"
              color="white"
              borderRadius="0"
            >
              Search
            </Button>
          </HStack>
          <HStack w="60%" spacing="24px">
            <Box w="300px" h="10"></Box>
          </HStack>
          <HStack w="20%" spacing="24px" justify="space-evenly">
            <Button
              fontSize={{ sm: "10px", md: "10px", lg: "14" }}
              variant="outline"
              fontWeight="500"
              color="black"
              borderRadius="0"
              onClick={(e) => handleCreateChallenge()}
            >
              <AddIcon w={4} h={4} px="1px" color="black" />
              Create Challenge
            </Button>
          </HStack>
        </Flex>
      </Box>
    );
  };
  const singleAlert = useDisclosure();
  const editSingleChallengeModal = useDisclosure();

  const handleDeleteSingleChallenge = (value: any) => {
    setId(value.id);

    singleAlert.onOpen();
  };
  const [def, setDef] = useState({});
  const handleEditSingleChallenge = (value: any) => {
    setId(value.id);

    const data = value;

    setDef({
      section: data.section.section,
      unit: data.unit.unit,
      subject: data.subject.subject,
    });
    editSingleChallengeModal.onOpen();
  };

  const columnHelper = createColumnHelper<RowObj>();
  const columns = [
    columnHelper.accessor("subject", {
      id: "subject",
      header: () => <TableHeader title="SUBJECT" />,
      cell: (info) => (
        <TableContent title={info.row.original?.subject?.subject} />
      ),
    }),
    columnHelper.accessor("section", {
      id: "section",
      header: () => <TableHeader title="SECTION" />,
      cell: (info) => (
        <TableContent title={info.row.original?.section?.section} />
      ),
    }),
    columnHelper.accessor("unit", {
      id: "unit",
      header: () => <TableHeader title="UNIT" />,
      cell: (info) => <TableContent title={info.row.original?.unit?.unit} />,
    }),

    columnHelper.accessor("actions", {
      id: "actions",
      header: () => <TableHeader title="ACTIONS" />,
      cell: (info) => (
        <>
          <Icon
            boxSize={6}
            onClick={() => handleEditSingleChallenge(info.row.original)}
            as={FaEdit}
            color="gray.400"
          />

          <Icon
            boxSize={6}
            onClick={() => handleDeleteSingleChallenge(info.row.original)}
            as={MdDeleteOutline}
            color="red"
          />
        </>
      ),
    }),
  ];

  const TabHeaders = () => {
    return (
      <TabList justifyContent="space-evenly">
        {grades?.map((grade) => (
          <Tab
            _selected={{ color: "#000000" }}
            fontWeight="600"
            fontSize="16px"
            color="#BBBBBB"
            onClick={() => setSelectedGrade(grade.grade)}
            key={grade.grade}
          >
            {grade.grade}
          </Tab>
        ))}
      </TabList>
    );
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <ProgressModal isOpen={isOpen} onClose={() => { }} />
      <MainTab headers={<TabHeaders />} filters={<TabFilters />}>
        {grades?.map((grade) => (
          <TabPanel>
            <Grid templateColumns="80% 10% 10%" gap={1}>
              <GridItem>
                <Flex
                  border="1px solid #D9D9D9"
                  bg="#EDF3F8"
                  borderColor={borderColor}
                  cursor="pointer"
                >
                  <Text>Title of challange</Text>
                </Flex>
              </GridItem>
              <GridItem>
                <Flex
                  border="1px solid #D9D9D9"
                  bg="#EDF3F8"
                  borderColor={borderColor}
                  cursor="pointer"
                >
                  <Text>Publish</Text>
                </Flex>
              </GridItem>
              <GridItem>
                <Flex
                  border="1px solid #D9D9D9"
                  bg="#EDF3F8"
                  borderColor={borderColor}
                  cursor="pointer"
                >
                  <Text>Action</Text>
                </Flex>
              </GridItem>
            </Grid>
            {selectedTab?.map((challenge: any, index: any) => {
              return (
                <Box p={5} bg="white" key={index}>
                  <Grid templateColumns="80% 10% 10%" gap={1}>
                    <GridItem>
                      <Text> {challenge.title}</Text>
                      <Flex gap={3}>
                        {Array.from({ length: challenge.week }, (_, i) => (
                          <Button
                            color={i + 1 === weeks ? "blue.600" : "black"}
                            key={i + 1}
                            onClick={() => setWeeks(i + 1)}
                          >
                            Week {i + 1}
                          </Button>
                        ))}
                        {challenge.challengeDay.length % 7 !== 0 ? (
                          <></>
                        ) : (
                          <AddIcon
                            onClick={() => {
                              dispatch(
                                createChallengeDay({
                                  id: challenge.id,
                                  payload: {
                                    day: challenge.challengeDay.length + 1,
                                    rest: false,
                                  },
                                })
                              ).then((res: any) => {
                                setReload(true);
                              });
                            }}
                          />
                        )}
                      </Flex>
                      <Tabs variant="enclosed-colored" isLazy>
                        <TabList>
                          {challenge.challengeDay
                            .slice((weeks - 1) * 7, (weeks - 1) * 7 + 7)
                            .map((challangeDay: any, index: any) => {
                              if (
                                challenge.challengeDay.length % 7 === 0 &&
                                challenge.challengeDay.length !== 0
                              ) {
                              } //
                              return (
                                <Tab key={`${challangeDay.day} ${index}`}>
                                  Day {challangeDay.day}
                                </Tab>
                              );
                            })}
                          {challenge.challengeDay.slice(
                            (weeks - 1) * 7,
                            (weeks - 1) * 7 + 7
                          ).length === 7 ? (
                            <></>
                          ) : (
                            <Tab
                              onClick={() => {
                                dispatch(
                                  createChallengeDay({
                                    id: challenge.id,
                                    payload: {
                                      day: challenge.challengeDay.length + 1,
                                      rest: false,
                                    },
                                  })
                                ).then((res: any) => {
                                  setReload(true);
                                });
                              }}
                              key={`addDay${index}`}
                            >
                              <AddIcon width="100%" />
                            </Tab>
                          )}
                        </TabList>
                        <TabPanels>
                          {challenge.challengeDay
                            .slice((weeks - 1) * 7, (weeks - 1) * 7 + 7)
                            .map((challangeDay: any) => {
                              const newOrder: any = "asc";
                              const sorted = [
                                ...challangeDay.singleChallenge,
                              ].sort((a, b) => {
                                const dateA: any = new Date(a.updatedAt);
                                const dateB: any = new Date(b.updatedAt);
                                return newOrder === "asc"
                                  ? dateA - dateB
                                  : dateB - dateA;
                              });

                              return (
                                <TabPanel key={challangeDay.id}>
                                  <PrimaryTable
                                    columns={columns}
                                    data={sorted}
                                  />
                                  <CreateSingleChallenge
                                    setReload={setReload}
                                    subjects={subjects.filter(
                                      (challenge: any) =>
                                        challenge?.grade?.grade ===
                                        selectedGrade
                                    )}
                                    units={units}
                                    sections={sections}
                                    studies={studies.filter(
                                      (challenge: any) =>
                                        challenge?.grade?.grade ===
                                        selectedGrade
                                    )}
                                    id={challangeDay.id}
                                  />
                                  <Flex align="flex-start">
                                    <Text>Rest</Text>
                                    <Switch
                                      m="auto"
                                      size="md"
                                      onChange={() =>
                                        handleRestDay(challangeDay)
                                      }
                                      isChecked={challangeDay.rest}
                                    />
                                  </Flex>
                                </TabPanel>
                              );
                            })}
                        </TabPanels>
                      </Tabs>
                    </GridItem>
                    <GridItem justifyContent="center">
                      <Switch
                        m="auto"
                        size="md"
                        onChange={() => handleChallengePublish(challenge)}
                        isChecked={challenge.isPublished}
                      />
                    </GridItem>
                    <GridItem>
                      <HStack justify="space-evenly">
                        <Icon
                          boxSize={6}
                          onClick={() => handleEditChallenge(challenge)}
                          as={FaEdit}
                          color="gray.400"
                        />

                        <Icon
                          boxSize={6}
                          onClick={() => handleDeleteChallenge(challenge.id)}
                          as={MdDeleteOutline}
                          color="red"
                        />
                      </HStack>
                    </GridItem>
                  </Grid>
                  <Divider borderWidth="1px" borderColor="#D9D9D9" my={2} />
                </Box>
              );
            })}
          </TabPanel>
        ))}
      </MainTab>
      <Modal
        isOpen={addChallengesModal.isOpen}
        onClose={addChallengesModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <Box borderLeftWidth="3px" borderColor="#7F77F1">
            <form onSubmit={handleSubmit(onCreateChallenge)}>
              <ModalHeader>Creating Challenges</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={6}>
                <>
                  <PrimaryText>Challenge Title:</PrimaryText>
                  <Input
                    id="Title"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    name="title"
                    placeholder="Title Challenge"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...register(`challengeTitle`, {
                      required: "ChallengeTitl Question is required",
                    })}
                  />
                  <FormControl>
                    <Input
                      name="grade"
                      {...register(`grade`, {})}
                      type="hidden"
                      value={selectedGrade}
                    />
                  </FormControl>
                </>
              </ModalBody>

              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </Box>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={editChallengesModal.isOpen}
        onClose={editChallengesModal.onClose}
      >
        <ModalOverlay />

        <ModalContent>
          <Box borderLeftWidth="3px" borderColor="#7F77F1">
            <form onSubmit={handleSubmitEditChallenges(onEditChallenge)}>
              <ModalHeader>Edit Challenge</ModalHeader>
              <ModalCloseButton />

              <ModalBody pb={6}>
                <FormControl>
                  <FormLabel>Challenge Title</FormLabel>
                  <Input
                    id="challengeTitle"
                    fontWeight="500"
                    variant="main"
                    h="44px"
                    maxH="44px"
                    type="text"
                    name="challengeTitle"
                    placeholder="title"
                    _placeholder={{
                      fontWeight: "400",
                      color: "secondaryGray.600",
                    }}
                    {...editChallengeForm.register(`challengeTitle`, {
                      required: "challenge Title is required",
                    })}
                  />

                  {Editerrors.challengeTitle && (
                    <Text color="red">
                      {Editerrors.challengeTitle.message.toString()}
                    </Text>
                  )}
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <PrimaryButton type="submit">Save</PrimaryButton>
              </ModalFooter>
            </form>
          </Box>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={editSingleChallengeModal.isOpen}
        onClose={editSingleChallengeModal.onClose}
      >
        <ModalOverlay />

        <ModalContent>
          <Box borderLeftWidth="3px" borderColor="#7F77F1">
            <ModalHeader>Edit Single Challenge </ModalHeader>
            <ModalCloseButton />

            <ModalBody pb={6}>
              <EditSingleChallenge
                setReload={setReload}
                def={def}
                subjects={subjects.filter(
                  (challenge: any) => challenge?.grade?.grade === selectedGrade
                )}
                editSingleChallengeModal={editSingleChallengeModal}
                studies={studies.filter(
                  (challenge: any) => challenge?.grade?.grade === selectedGrade
                )}
                id={id}
              />
            </ModalBody>

            <ModalFooter></ModalFooter>
          </Box>
        </ModalContent>
      </Modal>

      <AlertBox
        cancelRef={cancelRef}
        alert={alert}
        header="Delete Challenge?"
        alertBody="Are you sure you want to delete this Challenge"
        onDelete={onDeleteChallenge}
      />

      <AlertBox
        cancelRef={cancelRef}
        alert={singleAlert}
        header="Delete Single Challenge?"
        alertBody="Are you sure you want to delete This days Challenge"
        onDelete={onDeleteSingleChallenge}
      />
    </Box>
  );
}
