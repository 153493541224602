import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Progress,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { ArrowBack } from "@mui/icons-material";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import { PrimaryText } from "components/Atoms/Title/Titles";
import EditableTable from "components/Molecules/Tables/EditableTable";
import { CreateSingleQuestion } from "components/organisms/CreateSIngleQuestion";
import { useMaterialReactTable } from "material-react-table";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useCSVReader } from "react-papaparse";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import readXlsxFile from "read-excel-file";
import { getexamVersion } from "reduxtoolkit/reducers/examVersionSlice";
import { getGrades } from "reduxtoolkit/reducers/gradeSlice";
import {
  createBulkQuestion,
  createQuestion
} from "reduxtoolkit/reducers/questionSlice";
import { getSection } from "reduxtoolkit/reducers/sectionSlice";
import { getSubjects } from "reduxtoolkit/reducers/subjectSlice";
import { getUnits } from "reduxtoolkit/reducers/unitSlice";
import { getYear } from "reduxtoolkit/reducers/yearSlice";
import questionsColumn from "utils/questionColumn";

const CreateQuestion = () => {
  const { CSVReader } = useCSVReader();

  const dispatch = useDispatch();
  const [grades, setGrades] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [years, setYears] = useState([]);
  const [units, setUnits] = useState([]);
  const [sections, setSections] = useState([]);
  const [examVersions, setExamVersion] = useState([]);
  const gradesData: any = useSelector((state: any) => state.grade);
  const subjectsData = useSelector((state: any) => state.subject);
  const unitsData: any = useSelector((state: any) => state.unit);
  const yearsData: any = useSelector((state: any) => state.year);
  const sectiondata: any = useSelector((state: any) => state.section);
  const examVersiondata: any = useSelector((state: any) => state.examVersion);
  const questionsData: any = useSelector((state: any) => state.question);
  const toast = useToast();
  const history = useHistory();
  const onCreateQuestion = (values: any) => {
    dispatch(createQuestion(values)).then((data: any) => {
      if (data.type === "admin/createQuestins/fulfilled") {
        toast({
          title: "Success",
          description: "Question added successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        history.push("/admin/questions");
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const handleSaveQuestions = () => {
    const arr: any = [];
    table.getRowModel().rows.map((row: any) => arr.push(row.original));

    const questions = {
      questions: data,
    };

    dispatch(createBulkQuestion(questions)).then((data: any) => {
      if (data.type === "admin/createBulkQuestions/fulfilled") {
        toast({
          title: "Success",
          description: "Question Uploaded successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: `${data.payload.response.data.message}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };
  const columns = useMemo(() => questionsColumn, []);

  const [data, setData] = useState([]);
  const table = useMaterialReactTable({
    columns,
    data: data,
    enableColumnFilters: false,
    enablePagination: false,
    enableSorting: false,
    enableEditing: true,
    editDisplayMode: "row",
    onEditingRowSave: ({ table, values, row }: any) => {
      data[row.id] = values;
      table.setEditingRow(null);
    },
    onEditingRowCancel: () => {},

    mrtTheme: (theme: any) => ({
      baseBackgroundColor: theme.palette.background.default,
    }),
    muiTableBodyRowProps: { hover: false },
    muiTableProps: {
      sx: {
        border: "1px solid rgba(81, 81, 81, .5)",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        border: "1px solid rgba(81, 81, 81, .5)",
        fontStyle: "italic",
        fontWeight: "normal",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid rgba(81, 81, 81, .5)",
      },
    },
  });
  const createProgressModal = useDisclosure();
  const parseCsv = (csvString: any) => {
   
    const rows = csvString.split("\n");
    const headers = rows[0].split(",");
    return rows.slice(1).map((row: any) => {
      const values = row.split(",");
      return headers.reduce((obj: any, header: any, index: any) => {
        obj[header] = values[index];
        return obj;
      }, {});
    });
  };
  const handleBulkUpload = async (e: any) => {
    createProgressModal.onOpen();

    setUploading(true);
    const file = e.target.files[0];
    await readXlsxFile(file).then((rows: any) => {
      const [keys, ...values] = rows;
      var result = values.map((value: any, index: any) =>
        Object.fromEntries(
          keys.map((key: any, i: any) => [key, value[i]?.toString()])
        )
      );
      for (let index = 0; index < result.length; ++index) {
        result[index].examVersion =
          result[index].examVersion ?? "no_exam_version";
        result[index].questionType = result[index].questionType ?? "EXAM";
        result[index].section = result[index].section ?? "no_section";
        result[index].description =
          result[index].description ?? "no_description";
        result[index].unit = result[index].unit ?? "no_unit";
        result[index].grade = result[index].grade ?? "no_grade";
        result[index].metadata = result[index].metadata ?? "";
      }

      setData(result);
      setUploading(false);
      createProgressModal.onClose();
    });
  };

  useEffect(() => {
    dispatch(getSubjects());
    dispatch(getGrades());
    dispatch(getYear());
    dispatch(getUnits());
    dispatch(getSection());
    dispatch(getexamVersion());
  }, []);
  useEffect(() => {
    setUnits(unitsData.data);
    setExamVersion(examVersiondata.data);
    setGrades(gradesData.grade);
    setSections(sectiondata.data);
    setSubjects(subjectsData.data);
    setYears(yearsData.data);
  }, [
    examVersiondata.loading,
    unitsData.loading,
    gradesData.loading,
    sectiondata.loading,
    subjectsData.loading,
    yearsData.loading,
  ]);
  const createQuestionForm = useForm();
  const {
    handleSubmit: handleSubmitQuestion,
    control,
    formState: { errors: createError },
  } = createQuestionForm;
  const [theme, setTheme] = useState("snow");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (questionsData.loading === true || uploading) {
      setLoading(true);
      createProgressModal.onOpen();
    } else {
      createProgressModal.onClose();
      setLoading(false);
    }
  }, [questionsData.loading]);
  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        {loading ? <Progress w="1/2" isIndeterminate /> : null}
        <Modal
          isOpen={createProgressModal.isOpen}
          onClose={createProgressModal.onClose}
        >
          <ModalOverlay />
          <ModalContent bg="transparent" maxW="100%">
            <Box borderLeftWidth="3px" borderColor="#7F77F1">
              <ModalBody pb={6}></ModalBody>
            </Box>
          </ModalContent>
        </Modal>

        <Button
          onClick={() => {
            history.goBack();
          }}
          fontSize="30px"
        >
          <ArrowBack />
          Create Question
        </Button>

        <CreateSingleQuestion
          handleSubmitQuestion={handleSubmitQuestion}
          control={control}
          examVersions={examVersions}
          onCreateQuestion={onCreateQuestion}
          years={years}
          createQuestionForm={createQuestionForm}
          grades={grades}
          units={units}
          subjects={subjects}
          theme={theme}
          sections={sections}
          createError={createError}
        />

        <Box>
          <CSVReader onUploadAccepted={(results: any) => {}}>
            {({
              getRootProps,
              acceptedFile,
              ProgressBar,
              getRemoveFileProps,
            }: any) => (
              <>
                <div {...getRootProps()}>
                  <input {...getRemoveFileProps()} />
                  {acceptedFile ? (
                    <div>
                      <ProgressBar />
                    </div>
                  ) : (
                    <div>Drop CSV file here or click to upload</div>
                  )}
                </div>
              </>
            )}
          </CSVReader>
          <PrimaryText>Bulk Import</PrimaryText>
          <input
            type="file"
            accept=".xls,.xlsx"
            onChange={(e) => {
              handleBulkUpload(e);
            }}
          />
        </Box>
        <EditableTable table={table} />
        <PrimaryButton
          onClick={() => {
            handleSaveQuestions();
          }}
        >
          Save Questions
        </PrimaryButton>
      </Box>
    </>
  );
};

export default CreateQuestion;
