import {
    Box,
    Flex,
    FormControl,
    FormLabel,
    Select,
    Text
} from "@chakra-ui/react";
import { PrimaryButton } from "components/Atoms/Button/Buttons";
import { NumberInputs } from "components/Atoms/fields/NumberInput";
import CustomReactQuill from "components/Atoms/fields/PrimaryRichText";
import { Controller } from "react-hook-form";
import { rtxModules } from "utils/tableconstants";
export const CreateSingleQuestion = ({
  handleSubmitQuestion,
  control,
  examVersions,
  onCreateQuestion,
  years,
  createQuestionForm,
  grades,
  units,
  subjects,
  theme,
  sections,
  createError,
}: any) => {
  return (
    <Box p={10} bg="#FFFFFF">
      <form onSubmit={handleSubmitQuestion(onCreateQuestion)}>
        <Flex overflowX="scroll" gap={10}>
          <Box w={200}>
            <FormControl w={200} mt={4}>
              <FormLabel>Grade</FormLabel>
              <Select
                w="200"
                placeholder="Select Grade"
                className="select-input"
                _placeholder={{
                  fontWeight: "400",
                  color: "secondaryGray.600",
                }}
                {...createQuestionForm.register("grade", {
                  required: "Grade is required",
                })}
                variant="filled"
              >
                {grades?.map((item: any, index: any) => (
                  <option key={item.id} value={item.grade}>
                    {item.grade}
                  </option>
                ))}
              </Select>
              {createError.grade && (
                <Text color="red">{createError.grade.message.toString()}</Text>
              )}
            </FormControl>
          </Box>
          <Box w={200}>
            <FormControl w={200} mt={4}>
              <FormLabel>Subject</FormLabel>
              <Select
                w="100"
                placeholder="Select Subject"
                className="select-input"
                _placeholder={{
                  fontWeight: "400",
                  color: "secondaryGray.600",
                }}
                {...createQuestionForm.register("subject", {
                  required: "Subject is required",
                })}
                variant="filled"
              >
                {subjects?.map((item: any, index: Number) => (
                  <option key={item.id} value={item.subject}>
                    {item.subject}
                  </option>
                ))}
              </Select>
              {createError.subject && (
                <Text color="red">
                  {createError.subject.message.toString()}
                </Text>
              )}
            </FormControl>
          </Box>
          <Box w={200}>
            <FormControl w={200} mt={4}>
              <FormLabel>Year</FormLabel>
              <Select
                w="100"
                placeholder="Select Year"
                className="select-input"
                _placeholder={{
                  fontWeight: "400",
                  color: "secondaryGray.600",
                }}
                {...createQuestionForm.register("year", {
                  required: "year is required",
                })}
                variant="filled"
              >
                {years?.map((item: any) => (
                  <option key={item.id} value={item.year}>
                    {item.year}
                  </option>
                ))}
              </Select>
              {createError.year && (
                <Text color="red">{createError.year.message.toString()}</Text>
              )}
            </FormControl>
          </Box>
          <Box>
            <FormControl w={200} mt={4}>
              <FormLabel>Exam Version</FormLabel>
              <Select
                w={200}
                placeholder="Select Exam version"
                className="select-input"
                _placeholder={{
                  fontWeight: "400",
                  color: "secondaryGray.600",
                }}
                {...createQuestionForm.register("examVersion", {
                  required: "Exam Version is required",
                })}
                variant="filled"
              >
                {examVersions?.map((item: any) => (
                  <option key={item.id} value={item.examVersion}>
                    {item.examVersion}
                  </option>
                ))}
              </Select>
              {createError.examVersion && (
                <Text color="red">
                  {createError.examVersion.message.toString()}
                </Text>
              )}
            </FormControl>
          </Box>
          <Box w={100}>
            <FormControl w={100} mt={4}>
              <FormLabel>Unit</FormLabel>
              <Select
                w="100"
                placeholder="Select Unit"
                className="select-input"
                _placeholder={{
                  fontWeight: "400",
                  color: "secondaryGray.600",
                }}
                {...createQuestionForm.register("unit", {
                  required: "Unit is required",
                })}
                variant="filled"
              >
                {units?.map((item: any) => (
                  <option key={item.id} value={item.unit}>
                    {item.unit}
                  </option>
                ))}
              </Select>
              {createError.unit && (
                <Text color="red">{createError.unit.message.toString()}</Text>
              )}
            </FormControl>
          </Box>
          <Box w={100}>
            <FormControl w={100} mt={4}>
              <FormLabel>Section</FormLabel>
              <Select
                w="100"
                placeholder="Select Section"
                className="select-input"
                _placeholder={{
                  fontWeight: "400",
                  color: "secondaryGray.600",
                }}
                {...createQuestionForm.register("section", {
                  required: "Section is required",
                })}
                variant="filled"
              >
                {sections?.map((item: any) => (
                  <option key={item.id} value={item.section}>
                    {item.section}
                  </option>
                ))}
              </Select>
              {createError.section && (
                <Text color="red">
                  {createError.section.message.toString()}
                </Text>
              )}
            </FormControl>
          </Box>
          <FormControl w={100} mt={4}>
            <FormLabel>Number</FormLabel>
            <NumberInputs w="100" form={createQuestionForm} />
            {createError.number && (
              <Text color="red">{createError.number.message.toString()}</Text>
            )}
          </FormControl>
          <Box w={200}>
            <FormControl w={200} mt={4}>
              <FormLabel>Question Type</FormLabel>
              <Select
                w="100"
                className="select-input"
                _placeholder={{
                  fontWeight: "400",
                  color: "secondaryGray.600",
                }}
                {...createQuestionForm.register("questionType", {
                  required: "Question Type is required",
                })}
                variant="filled"
              >
                <option key="exam" value="EXAM">
                  Exam
                </option>
                <option key="custom" value="CUSTOM">
                  Custom
                </option>
              </Select>

              {createError.questionType && (
                <Text color="red">
                  {createError.questionType.message.toString()}
                </Text>
              )}
            </FormControl>
          </Box>
          <Box w={500}>
            <FormControl w={500} mt={4}>
              <FormLabel>Question </FormLabel>
              <Controller
                name="question"
                control={control}
                rules={{
                  required: "Please enter question",
                }}
                render={({ field }) => (
                  <CustomReactQuill
                    field={field}
                    themes={theme}
                    modules={rtxModules}
                    defaultValue="question"
                    placeholder="Enter Your question"
                    onChange={(text: string) => {
                      field.onChange(text);
                    }}
                  />
                )}
              />

              {createError.question && (
                <Text color="red">
                  {createError.question.message.toString()}
                </Text>
              )}
            </FormControl>
          </Box>
          <Box w={500}>
            <FormControl w={500} mt={4}>
              <FormLabel>Description</FormLabel>

              <Controller
                name="description"
                control={control}
                rules={{
                  required: "Please enter description",
                }}
                render={({ field }) => (
                  <CustomReactQuill
                    field={field}
                    themes={theme}
                    modules={rtxModules}
                    defaultValue="B"
                    placeholder="Enter Your description"
                    onChange={(text: string) => {
                      field.onChange(text);
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box w={500}>
            <FormControl w={500} mt={4}>
              <FormLabel>A </FormLabel>

              <Controller
                name="A"
                control={control}
                rules={{
                  required: "Please enter option A",
                }}
                render={({ field }) => (
                  <CustomReactQuill
                    field={field}
                    themes={theme}
                    modules={rtxModules}
                    defaultValue="B"
                    placeholder="Enter choice A"
                    onChange={(text: string) => {
                      field.onChange(text);
                    }}
                  />
                )}
              />
              {createError.A && (
                <Text color="red">{createError.A.message.toString()}</Text>
              )}
            </FormControl>
          </Box>
          <Box w={500}>
            <FormControl w={500} mt={4}>
              <FormLabel>B </FormLabel>

              <Controller
                name="B"
                control={control}
                rules={{
                  required: "Please enter option B",
                }}
                render={({ field }) => (
                  <CustomReactQuill
                    field={field}
                    themes={theme}
                    modules={rtxModules}
                    defaultValue="B"
                    placeholder="Enter choice B"
                    onChange={(text: string) => {
                      field.onChange(text);
                    }}
                  />
                )}
              />
              {createError.B && (
                <Text color="red">{createError.B.message.toString()}</Text>
              )}
            </FormControl>
          </Box>
          <Box w={500}>
            <FormControl w={500} mt={4}>
              <FormLabel>C </FormLabel>

              <Controller
                name="C"
                control={control}
                rules={{
                  required: "Please enter option C",
                }}
                render={({ field }) => (
                  <CustomReactQuill
                    field={field}
                    themes={theme}
                    modules={rtxModules}
                    defaultValue="B"
                    placeholder="Enter choice C"
                    onChange={(text: string) => {
                      field.onChange(text);
                    }}
                  />
                )}
              />
              {createError.C && (
                <Text color="red">{createError.C.message.toString()}</Text>
              )}
            </FormControl>
          </Box>
          <Box w={500}>
            <FormControl w={500} mt={4}>
              <FormLabel>D</FormLabel>

              <Controller
                name="D"
                control={control}
                rules={{
                  required: "Please enter option D",
                }}
                render={({ field }) => (
                  <CustomReactQuill
                    field={field}
                    themes={theme}
                    modules={rtxModules}
                    defaultValue="B"
                    placeholder="Enter choice D"
                    onChange={(text: string) => {
                      field.onChange(text);
                    }}
                  />
                )}
              />
              {createError.D && (
                <Text color="red">{createError.D.message.toString()}</Text>
              )}
            </FormControl>
          </Box>
          <Box w={100}>
            <FormControl w={100} mt={4}>
              <FormLabel>Answer</FormLabel>
              <Select
                className="select-input"
                _placeholder={{
                  fontWeight: "400",
                  color: "secondaryGray.600",
                }}
                {...createQuestionForm.register("answer", {
                  required: "Answer is required",
                })}
                variant="filled"
              >
                <option key="a" value="A">
                  A
                </option>
                <option key="b" value="B">
                  B
                </option>
                <option key="c" value="C">
                  C
                </option>
                <option key="d" value="D">
                  D
                </option>
              </Select>
              {createError.answer && (
                <Text color="red">{createError.answer.message.toString()}</Text>
              )}
            </FormControl>
            <FormControl w={200} mt={4}>
              <FormLabel>Meta Data</FormLabel>
              <Controller
                name="metadata"
                control={control}
                render={({ field }) => (
                  <CustomReactQuill
                    field={field}
                    themes={theme}
                    modules={rtxModules}
                    defaultValue="B"
                    placeholder="Enter metadata"
                    onChange={(text: string) => {
                      field.onChange(text);
                    }}
                  />
                )}
              />
              {createError.metadata && (
                <Text color="red">
                  {createError.metadata.message.toString()}
                </Text>
              )}
            </FormControl>
          </Box>
        </Flex>
        <PrimaryButton type="submit">Save</PrimaryButton>
      </form>
    </Box>
  );
};
