import { FormControl, FormLabel } from "@chakra-ui/react";
import { SingleSelect } from "components/Atoms/Selectors/Selectors";
import { Controller } from "react-hook-form";

const SingleSelector=({control, name, defaultValue,label, placeholder, validation, id ,options}: any)=>{

    return(
		<FormControl id={id}>
		<FormLabel>{label}</FormLabel>
		<Controller
		  defaultValue=""
		  render={({ field}) => (
			<SingleSelect 
			 options={options}
			 
			 value={field.value}
			 placeholder={placeholder}
			 onChange={(value:any) => field.onChange(value)}
			/>   
		  )}
		  name={id}
		  control={control}
		  rules={validation}
		/>
	   
	  </FormControl>
		
    )
}
export { SingleSelector };
