import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import axios from "utils/axiosMiddleware";
import { authInitialState as authTypes } from "../../types/authTypes";

export const login: any = createAsyncThunk(
  "auth/Login",
  async (arg, { rejectWithValue }) => {
    try {
      const data: any = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/admin/login`,
        data: arg,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const editProfile: any = createAsyncThunk(
  "auth/editProfile",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";
    try {
      const data: any = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/admin/changeProfile`,
        headers: { Authorization: `Bearer ${token}` },
        data: arg,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changePassword: any = createAsyncThunk(
  "auth/changePassword",
  async (arg, { rejectWithValue }) => {
    const token = Cookies.get("accessToken") ? Cookies.get("accessToken") : "";
    try {
      const data: any = await axios({
        method: "put",
        url: `${process.env.REACT_APP_API_URL}/admin/changepassword`,
        headers: { Authorization: `Bearer ${token}` },
        data: arg,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logout: any = createAsyncThunk(
  "auth/Loggout",
  async (arg, { rejectWithValue }) => {}
);

export const updateLogin: any = createAsyncThunk(
  "auth/updateLoginRole",
  async (arg, { rejectWithValue }) => {}
);

const initialState = {
  user:
    Cookies.get("user") !== undefined ? JSON.parse(Cookies.get("user")!) : {},
  loggedInAs: Cookies.get("loggedInAs") ? Cookies.get("loggedInAs") : null,
  isAuthenticated: Cookies.get("accessToken") ? true : false,
  accessToken: Cookies.get("accessToken") ? Cookies.get("accessToken") : null,
  loading: false,
} as authTypes;
interface CookieOptions {
  secure?: boolean;
  sameSite?: "Strict" | "Lax" | "None";
  domain?: string;
  path?: string;
  expires?: number | Date;
  // Add other options as needed
}

const COOKIE_SECURE_OPTIONS: CookieOptions = {
  secure: true,
  sameSite: "Lax",
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: {
    [login.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [login.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.user = payload.data.adminProfile;
      state.loggedInAs = payload.data.adminProfile.adminRole[0].adminRole;
      state.accessToken = payload.data.accessToken;
      state.isAuthenticated = true;

      Cookies.set("isAuthenticated", JSON.stringify(true), {
        ...COOKIE_SECURE_OPTIONS,
      });
      Cookies.set("accessToken", payload.data.accessToken, {
        ...COOKIE_SECURE_OPTIONS,
      });
      Cookies.set("user", JSON.stringify(payload.data.adminProfile), {
        ...COOKIE_SECURE_OPTIONS,
      });
      Cookies.set(
        "loggedInAs",
        payload.data.adminProfile.adminRole[0].adminRole,
        { ...COOKIE_SECURE_OPTIONS }
      );
    },
    [login.rejected]: (state, { payload }) => {
      state.isAuthenticated = false;
      state.loading = false;
    },
    [updateLogin.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [updateLogin.fulfilled]: (state, action) => {
      state.loading = false;

      state.loggedInAs = action.meta.arg;

      Cookies.set("loggedInAs", action.meta.arg, { ...COOKIE_SECURE_OPTIONS });
    },
    [updateLogin.rejected]: (state, { payload }) => {
      state.isAuthenticated = false;
      state.loading = false;
    },
    [logout.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [logout.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = [];
      state = null;
      Cookies.remove("loggedInAs", { ...COOKIE_SECURE_OPTIONS });
      Cookies.remove("accessToken", { ...COOKIE_SECURE_OPTIONS });
      Cookies.remove("isAuthenticated", { ...COOKIE_SECURE_OPTIONS });
      Cookies.remove("user", { ...COOKIE_SECURE_OPTIONS });
    },
    [logout.rejected]: (state, { payload }) => {
      state.isAuthenticated = false;
      state.loading = false;
    },
    [editProfile.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [editProfile.fulfilled]: (state: any, { payload, meta }: any) => {
      state.loading = false;

      const temp = current(state);

      //  var grade = meta.arg.payload.grade;
      //  const updatedItems = temp.grade.map((item:any) =>
      //          {
      //   if (item.id === meta.arg.id)
      //       {
      //           return { ...item,  grade};
      //         }
      //       return item;});

      temp.user = payload.adminProfile;

      //  state.user = temp.user;
      //state.user = payload.adminProfile
      state.isSuccess = true;
    },
    [editProfile.rejected]: (state, { payload }) => {
      state.loading = false;
    },
  },
});

export default authSlice;
