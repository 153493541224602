

// Chakra imports
import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Progress, useDisclosure, useToast } from '@chakra-ui/react';
import { PrimaryButton } from 'components/Atoms/Button/Buttons';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createGrade } from 'reduxtoolkit/reducers/gradeSlice';
import GradesTable from '../dataTables/components/GradesTable';

// Assets

export default function Overview() {
    const toast = useToast();
	const dispatch = useDispatch();
	const [updated, setUpdated] = useState(false);
	const form3 = useForm();
	const {
	handleSubmit: handleSubmit3  } = form3;
	const createGradeModal = useDisclosure();
	const onCreateGrade=(values: any)=>{
		const data = {
			grade: values.grade,
			
		}
		dispatch(createGrade(data)).then((res:any)=>
		{
			if(res.type == "admin/createGrade/fulfilled")
			{
				toast({
					title: 'Success',
					description: "Grade Updated successfully",
					status: 'success',
					duration: 9000,
					isClosable: true,
				  });
			}
			else {
				toast({
					title: 'Error',
					description:   `${res.payload.response.data.message}`,
					status: 'error',
					duration: 9000,
					isClosable: true,
				  });
				
			}
		});
		
	}
	const createProgressModal = useDisclosure();
	const [loading, setLoading] = useState(true);
	const grades:any = useSelector((state:any)=> state.grade);
	useEffect(() => {
		if(grades.loading == true){
			createProgressModal.onOpen();
		  setLoading(true);}
		else {
			createProgressModal.onClose();
			setLoading(false);
		}
	  }, [grades.loading]);
	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			{/* Main Fields */}
			{ loading ?<Progress w='1/2' isIndeterminate />: null}
			<Modal 
					isOpen={createProgressModal.isOpen}
					onClose={createProgressModal.onClose}>
						<ModalOverlay />
						<ModalContent bg="transparent" maxW="100%">
						<Box borderLeftWidth='3px' borderColor='#7F77F1'>
						<ModalBody pb={6}>
						</ModalBody>
						</Box>
						</ModalContent>
						</Modal>
						<Flex justify='space-between' flex='row'>
						<HStack w='80%' mx={5} spacing='24px' ></HStack>
				<HStack w='10%' mx={5} spacing='24px' >
				<Button 
					fontSize={{sm: '10px', md: '10px', lg: '14'}}
					bg="#0076FD"
					fontWeight='normal'
					
					color='white'
					borderRadius="0"
					onClick={createGradeModal.onOpen}
				><AddIcon w={4} h={4} px='1px' color="white" />
							Create Grades
						</Button>
             </HStack>
			 </Flex>
		
			
	      
		    
			<GradesTable />
					<Modal 
					
					isOpen={createGradeModal.isOpen}
					onClose={createGradeModal.onClose}>
						<ModalOverlay />
						<ModalContent>
						<Box borderLeftWidth='3px' borderColor='#7F77F1'>
						<form onSubmit={handleSubmit3(onCreateGrade)}>
						<ModalHeader>Creating Grade</ModalHeader>
						<ModalCloseButton />
						
						<ModalBody pb={6}>
						
							<FormControl mt={4}>
							<FormLabel>Grade Name</FormLabel>
							<Input
								id='grade'
								fontWeight='500'
								variant='main'
								h='44px'
								maxH='44px' 
								type="text"
								placeholder="Grade"
								_placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
								{ ...form3.register('grade', {
									required: 'This is required',
									minLength: { value: 0, message: 'Minimum length should be 4' },
								})}
								/>
							</FormControl>
						</ModalBody>

						<ModalFooter>
						<PrimaryButton type='submit'>
            Save
            </PrimaryButton>
							
						</ModalFooter>
						</form>
								</Box>
							</ModalContent>
	
					</Modal>

		</Box>
	);
}
